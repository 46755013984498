import { SelectOptionItem } from '@tixlabs/types';
import { EUserStatus } from '@vemaybay-admin/types';

export const USER_STATUS_LABEL = {
  [EUserStatus.ACTIVE]: 'Hoạt động',
  [EUserStatus.INACTIVE]: 'Tạm khoá',
};

export const USER_STATUS_OPTIONS = [
  {
    label: USER_STATUS_LABEL[EUserStatus.ACTIVE],
    value: EUserStatus.ACTIVE,
  },
  {
    label: USER_STATUS_LABEL[EUserStatus.INACTIVE],
    value: EUserStatus.INACTIVE,
  },
];

export const SELECT_STATUS_OPTIONS: SelectOptionItem[] = [
  {
    value: EUserStatus.NONE,
    label: 'Tất cả',
  },
  {
    value: EUserStatus.ACTIVE,
    label: 'Hoạt động',
  },
  {
    value: EUserStatus.INACTIVE,
    label: 'Tạm khoá',
  },
];
