import { ONE_DAY_TIMESTAMP } from '@common-ui';
import { TFormFilterCommission } from '@vemaybay-admin/types';
import {
  ECommissionStatus,
  EReferenceStatus,
} from '@tixlabs/grpc-client/web-partnership';
import { SelectOptionItem } from '@tixlabs/types';

export const STATUS_COMMISSION_LABEL = {
  [ECommissionStatus.WAITINGREVIEW]: 'Chờ duyệt',
  [ECommissionStatus.APPROVED]: 'Đã duyệt',
  [ECommissionStatus.CANCEL]: 'Đã hủy',
  [ECommissionStatus.CNONE]: 'Khác',
};

export const STATUS_REFERENCE_LABEL = {
  [EReferenceStatus.ACTIVE]: 'Active',
  [EReferenceStatus.IDLE]: 'Idle',
  [EReferenceStatus.INACTIVE]: 'Inactive',
};

export const SELECT_STATUS_COMMISSION_OPTIONS: SelectOptionItem[] = [
  {
    label: 'Tất cả',
    value: ECommissionStatus.CNONE,
  },
  {
    label: STATUS_COMMISSION_LABEL[ECommissionStatus.WAITINGREVIEW],
    value: ECommissionStatus.WAITINGREVIEW,
  },
  {
    label: STATUS_COMMISSION_LABEL[ECommissionStatus.APPROVED],
    value: ECommissionStatus.APPROVED,
  },
  {
    label: STATUS_COMMISSION_LABEL[ECommissionStatus.CANCEL],
    value: ECommissionStatus.CANCEL,
  },
];

export const DEFAULT_COMMISSION_FILTER_FORM: TFormFilterCommission = {
  bookingCode: '',
  status: ECommissionStatus.CNONE,
  date: {
    startDate: new Date(
      new Date(Date.now() - ONE_DAY_TIMESTAMP * 7).setHours(0, 0, 0, 0)
    ),
    endDate: new Date(new Date().setHours(23, 59, 59, 999)),
  },
};
