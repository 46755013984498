// source: partner/web_partnership/partner_user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var partner_base_pb = require('../../partner/base_pb.js');
goog.object.extend(proto, partner_base_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.partner.web_partnership.ChangePasswordReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.CreatePartnerUserReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.CreatePartnerUserRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser', null, global);
goog.exportSymbol('proto.partner.web_partnership.CreatePartnerUserWithRolesReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.DeletePartnerUserReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.DisablePartnerUserReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.GetMeReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.GetMeRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.ListPartnerUserBasicReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.ListPartnerUserBasicReq.Filter', null, global);
goog.exportSymbol('proto.partner.web_partnership.ListPartnerUserBasicRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.ListPartnerUserBasicRes.Data', null, global);
goog.exportSymbol('proto.partner.web_partnership.ListPartnerUserReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.ListPartnerUserReq.Filter', null, global);
goog.exportSymbol('proto.partner.web_partnership.ListPartnerUserRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.RetrievePartnerUserReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.RetrievePartnerUserRes', null, global);
goog.exportSymbol('proto.partner.web_partnership.UpdateMeReq', null, global);
goog.exportSymbol('proto.partner.web_partnership.UpdateUserReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.GetMeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.GetMeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.GetMeReq.displayName = 'proto.partner.web_partnership.GetMeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.ChangePasswordReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.ChangePasswordReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.ChangePasswordReq.displayName = 'proto.partner.web_partnership.ChangePasswordReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.CreatePartnerUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.CreatePartnerUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.CreatePartnerUserReq.displayName = 'proto.partner.web_partnership.CreatePartnerUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partnership.CreatePartnerUserWithRolesReq.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partnership.CreatePartnerUserWithRolesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.CreatePartnerUserWithRolesReq.displayName = 'proto.partner.web_partnership.CreatePartnerUserWithRolesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.RetrievePartnerUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.RetrievePartnerUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.RetrievePartnerUserReq.displayName = 'proto.partner.web_partnership.RetrievePartnerUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.ListPartnerUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.ListPartnerUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.ListPartnerUserReq.displayName = 'proto.partner.web_partnership.ListPartnerUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.ListPartnerUserReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.ListPartnerUserReq.Filter.displayName = 'proto.partner.web_partnership.ListPartnerUserReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.DisablePartnerUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.DisablePartnerUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.DisablePartnerUserReq.displayName = 'proto.partner.web_partnership.DisablePartnerUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.DeletePartnerUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.DeletePartnerUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.DeletePartnerUserReq.displayName = 'proto.partner.web_partnership.DeletePartnerUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.GetMeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.GetMeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.GetMeRes.displayName = 'proto.partner.web_partnership.GetMeRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.CreatePartnerUserRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.CreatePartnerUserRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.CreatePartnerUserRes.displayName = 'proto.partner.web_partnership.CreatePartnerUserRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.displayName = 'proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.RetrievePartnerUserRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.RetrievePartnerUserRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.RetrievePartnerUserRes.displayName = 'proto.partner.web_partnership.RetrievePartnerUserRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.ListPartnerUserRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partnership.ListPartnerUserRes.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partnership.ListPartnerUserRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.ListPartnerUserRes.displayName = 'proto.partner.web_partnership.ListPartnerUserRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.UpdateUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.UpdateUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.UpdateUserReq.displayName = 'proto.partner.web_partnership.UpdateUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.UpdateMeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.UpdateMeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.UpdateMeReq.displayName = 'proto.partner.web_partnership.UpdateMeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.ListPartnerUserBasicReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.ListPartnerUserBasicReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.ListPartnerUserBasicReq.displayName = 'proto.partner.web_partnership.ListPartnerUserBasicReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.ListPartnerUserBasicReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.displayName = 'proto.partner.web_partnership.ListPartnerUserBasicReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.ListPartnerUserBasicRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partner.web_partnership.ListPartnerUserBasicRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.ListPartnerUserBasicRes.displayName = 'proto.partner.web_partnership.ListPartnerUserBasicRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partner.web_partnership.ListPartnerUserBasicRes.Data.repeatedFields_, null);
};
goog.inherits(proto.partner.web_partnership.ListPartnerUserBasicRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partner.web_partnership.ListPartnerUserBasicRes.Data.displayName = 'proto.partner.web_partnership.ListPartnerUserBasicRes.Data';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.GetMeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.GetMeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.GetMeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetMeReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.GetMeReq}
 */
proto.partner.web_partnership.GetMeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.GetMeReq;
  return proto.partner.web_partnership.GetMeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.GetMeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.GetMeReq}
 */
proto.partner.web_partnership.GetMeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.GetMeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.GetMeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.GetMeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetMeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.ChangePasswordReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.ChangePasswordReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.ChangePasswordReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ChangePasswordReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldPassword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.ChangePasswordReq}
 */
proto.partner.web_partnership.ChangePasswordReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.ChangePasswordReq;
  return proto.partner.web_partnership.ChangePasswordReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.ChangePasswordReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.ChangePasswordReq}
 */
proto.partner.web_partnership.ChangePasswordReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.ChangePasswordReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.ChangePasswordReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.ChangePasswordReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ChangePasswordReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string old_password = 1;
 * @return {string}
 */
proto.partner.web_partnership.ChangePasswordReq.prototype.getOldPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ChangePasswordReq} returns this
 */
proto.partner.web_partnership.ChangePasswordReq.prototype.setOldPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partner.web_partnership.ChangePasswordReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ChangePasswordReq} returns this
 */
proto.partner.web_partnership.ChangePasswordReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.CreatePartnerUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.CreatePartnerUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.CreatePartnerUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreatePartnerUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    phone: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.CreatePartnerUserReq}
 */
proto.partner.web_partnership.CreatePartnerUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.CreatePartnerUserReq;
  return proto.partner.web_partnership.CreatePartnerUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.CreatePartnerUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.CreatePartnerUserReq}
 */
proto.partner.web_partnership.CreatePartnerUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {!proto.partner.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.CreatePartnerUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.CreatePartnerUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.CreatePartnerUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreatePartnerUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
};


/**
 * optional string phone = 1;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerUserReq.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserReq.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerUserReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerUserReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_name = 4;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerUserReq.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserReq.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerUserReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserReq.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional partner.UserStatus status = 6;
 * @return {!proto.partner.UserStatus}
 */
proto.partner.web_partnership.CreatePartnerUserReq.prototype.getStatus = function() {
  return /** @type {!proto.partner.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.partner.UserStatus} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.CreatePartnerUserWithRolesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    phone: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq}
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.CreatePartnerUserWithRolesReq;
  return proto.partner.web_partnership.CreatePartnerUserWithRolesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq}
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {!proto.partner.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.CreatePartnerUserWithRolesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
};


/**
 * optional string phone = 1;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string user_name = 4;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional partner.UserStatus status = 6;
 * @return {!proto.partner.UserStatus}
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.getStatus = function() {
  return /** @type {!proto.partner.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.partner.UserStatus} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * repeated string roles = 7;
 * @return {!Array<string>}
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.getRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} returns this
 */
proto.partner.web_partnership.CreatePartnerUserWithRolesReq.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.RetrievePartnerUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.RetrievePartnerUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.RetrievePartnerUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.RetrievePartnerUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.RetrievePartnerUserReq}
 */
proto.partner.web_partnership.RetrievePartnerUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.RetrievePartnerUserReq;
  return proto.partner.web_partnership.RetrievePartnerUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.RetrievePartnerUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.RetrievePartnerUserReq}
 */
proto.partner.web_partnership.RetrievePartnerUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.RetrievePartnerUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.RetrievePartnerUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.RetrievePartnerUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.RetrievePartnerUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partner.web_partnership.RetrievePartnerUserReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.RetrievePartnerUserReq} returns this
 */
proto.partner.web_partnership.RetrievePartnerUserReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.ListPartnerUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.ListPartnerUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.ListPartnerUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListPartnerUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.partner.web_partnership.ListPartnerUserReq.Filter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.ListPartnerUserReq}
 */
proto.partner.web_partnership.ListPartnerUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.ListPartnerUserReq;
  return proto.partner.web_partnership.ListPartnerUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.ListPartnerUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.ListPartnerUserReq}
 */
proto.partner.web_partnership.ListPartnerUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partner.web_partnership.ListPartnerUserReq.Filter;
      reader.readMessage(value,proto.partner.web_partnership.ListPartnerUserReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.ListPartnerUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.ListPartnerUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.ListPartnerUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListPartnerUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.partner.web_partnership.ListPartnerUserReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.ListPartnerUserReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.ListPartnerUserReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    phone: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.ListPartnerUserReq.Filter}
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.ListPartnerUserReq.Filter;
  return proto.partner.web_partnership.ListPartnerUserReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.ListPartnerUserReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.ListPartnerUserReq.Filter}
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.partner.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.ListPartnerUserReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.ListPartnerUserReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ListPartnerUserReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.setUsername = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.ListPartnerUserReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.clearUsername = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ListPartnerUserReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.ListPartnerUserReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional partner.UserStatus status = 3;
 * @return {!proto.partner.UserStatus}
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.getStatus = function() {
  return /** @type {!proto.partner.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.partner.UserStatus} value
 * @return {!proto.partner.web_partnership.ListPartnerUserReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string phone = 4;
 * @return {string}
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ListPartnerUserReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.setPhone = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.ListPartnerUserReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.clearPhone = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserReq.Filter.prototype.hasPhone = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Filter filter = 1;
 * @return {?proto.partner.web_partnership.ListPartnerUserReq.Filter}
 */
proto.partner.web_partnership.ListPartnerUserReq.prototype.getFilter = function() {
  return /** @type{?proto.partner.web_partnership.ListPartnerUserReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partnership.ListPartnerUserReq.Filter, 1));
};


/**
 * @param {?proto.partner.web_partnership.ListPartnerUserReq.Filter|undefined} value
 * @return {!proto.partner.web_partnership.ListPartnerUserReq} returns this
*/
proto.partner.web_partnership.ListPartnerUserReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.ListPartnerUserReq} returns this
 */
proto.partner.web_partnership.ListPartnerUserReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.partner.web_partnership.ListPartnerUserReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.partner.web_partnership.ListPartnerUserReq} returns this
*/
proto.partner.web_partnership.ListPartnerUserReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.ListPartnerUserReq} returns this
 */
proto.partner.web_partnership.ListPartnerUserReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.DisablePartnerUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.DisablePartnerUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.DisablePartnerUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DisablePartnerUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.DisablePartnerUserReq}
 */
proto.partner.web_partnership.DisablePartnerUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.DisablePartnerUserReq;
  return proto.partner.web_partnership.DisablePartnerUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.DisablePartnerUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.DisablePartnerUserReq}
 */
proto.partner.web_partnership.DisablePartnerUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.DisablePartnerUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.DisablePartnerUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.DisablePartnerUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DisablePartnerUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partner.web_partnership.DisablePartnerUserReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DisablePartnerUserReq} returns this
 */
proto.partner.web_partnership.DisablePartnerUserReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.DeletePartnerUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.DeletePartnerUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.DeletePartnerUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DeletePartnerUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.DeletePartnerUserReq}
 */
proto.partner.web_partnership.DeletePartnerUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.DeletePartnerUserReq;
  return proto.partner.web_partnership.DeletePartnerUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.DeletePartnerUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.DeletePartnerUserReq}
 */
proto.partner.web_partnership.DeletePartnerUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.DeletePartnerUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.DeletePartnerUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.DeletePartnerUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.DeletePartnerUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partner.web_partnership.DeletePartnerUserReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.DeletePartnerUserReq} returns this
 */
proto.partner.web_partnership.DeletePartnerUserReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.GetMeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.GetMeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.GetMeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetMeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && partner_base_pb.UserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.GetMeRes}
 */
proto.partner.web_partnership.GetMeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.GetMeRes;
  return proto.partner.web_partnership.GetMeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.GetMeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.GetMeRes}
 */
proto.partner.web_partnership.GetMeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new partner_base_pb.UserInfo;
      reader.readMessage(value,partner_base_pb.UserInfo.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.GetMeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.GetMeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.GetMeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.GetMeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      partner_base_pb.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partnership.GetMeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.GetMeRes} returns this
 */
proto.partner.web_partnership.GetMeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partnership.GetMeRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.GetMeRes} returns this
 */
proto.partner.web_partnership.GetMeRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional partner.UserInfo data = 3;
 * @return {?proto.partner.UserInfo}
 */
proto.partner.web_partnership.GetMeRes.prototype.getData = function() {
  return /** @type{?proto.partner.UserInfo} */ (
    jspb.Message.getWrapperField(this, partner_base_pb.UserInfo, 3));
};


/**
 * @param {?proto.partner.UserInfo|undefined} value
 * @return {!proto.partner.web_partnership.GetMeRes} returns this
*/
proto.partner.web_partnership.GetMeRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.GetMeRes} returns this
 */
proto.partner.web_partnership.GetMeRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.GetMeRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.CreatePartnerUserRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.CreatePartnerUserRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.CreatePartnerUserRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreatePartnerUserRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.CreatePartnerUserRes}
 */
proto.partner.web_partnership.CreatePartnerUserRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.CreatePartnerUserRes;
  return proto.partner.web_partnership.CreatePartnerUserRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.CreatePartnerUserRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.CreatePartnerUserRes}
 */
proto.partner.web_partnership.CreatePartnerUserRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser;
      reader.readMessage(value,proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.CreatePartnerUserRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.CreatePartnerUserRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.CreatePartnerUserRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreatePartnerUserRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser}
 */
proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser;
  return proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser}
 */
proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser} returns this
 */
proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partnership.CreatePartnerUserRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserRes} returns this
 */
proto.partner.web_partnership.CreatePartnerUserRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partnership.CreatePartnerUserRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserRes} returns this
 */
proto.partner.web_partnership.CreatePartnerUserRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PartnerUser data = 3;
 * @return {?proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser}
 */
proto.partner.web_partnership.CreatePartnerUserRes.prototype.getData = function() {
  return /** @type{?proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser, 3));
};


/**
 * @param {?proto.partner.web_partnership.CreatePartnerUserRes.PartnerUser|undefined} value
 * @return {!proto.partner.web_partnership.CreatePartnerUserRes} returns this
*/
proto.partner.web_partnership.CreatePartnerUserRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.CreatePartnerUserRes} returns this
 */
proto.partner.web_partnership.CreatePartnerUserRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.CreatePartnerUserRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.RetrievePartnerUserRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.RetrievePartnerUserRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.RetrievePartnerUserRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.RetrievePartnerUserRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && partner_base_pb.UserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.RetrievePartnerUserRes}
 */
proto.partner.web_partnership.RetrievePartnerUserRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.RetrievePartnerUserRes;
  return proto.partner.web_partnership.RetrievePartnerUserRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.RetrievePartnerUserRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.RetrievePartnerUserRes}
 */
proto.partner.web_partnership.RetrievePartnerUserRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new partner_base_pb.UserInfo;
      reader.readMessage(value,partner_base_pb.UserInfo.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.RetrievePartnerUserRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.RetrievePartnerUserRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.RetrievePartnerUserRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.RetrievePartnerUserRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      partner_base_pb.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partnership.RetrievePartnerUserRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.RetrievePartnerUserRes} returns this
 */
proto.partner.web_partnership.RetrievePartnerUserRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partnership.RetrievePartnerUserRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.RetrievePartnerUserRes} returns this
 */
proto.partner.web_partnership.RetrievePartnerUserRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional partner.UserInfo data = 3;
 * @return {?proto.partner.UserInfo}
 */
proto.partner.web_partnership.RetrievePartnerUserRes.prototype.getData = function() {
  return /** @type{?proto.partner.UserInfo} */ (
    jspb.Message.getWrapperField(this, partner_base_pb.UserInfo, 3));
};


/**
 * @param {?proto.partner.UserInfo|undefined} value
 * @return {!proto.partner.web_partnership.RetrievePartnerUserRes} returns this
*/
proto.partner.web_partnership.RetrievePartnerUserRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.RetrievePartnerUserRes} returns this
 */
proto.partner.web_partnership.RetrievePartnerUserRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.RetrievePartnerUserRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partnership.ListPartnerUserRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.ListPartnerUserRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.ListPartnerUserRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.ListPartnerUserRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListPartnerUserRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    partner_base_pb.UserInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.ListPartnerUserRes}
 */
proto.partner.web_partnership.ListPartnerUserRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.ListPartnerUserRes;
  return proto.partner.web_partnership.ListPartnerUserRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.ListPartnerUserRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.ListPartnerUserRes}
 */
proto.partner.web_partnership.ListPartnerUserRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new partner_base_pb.UserInfo;
      reader.readMessage(value,partner_base_pb.UserInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.ListPartnerUserRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.ListPartnerUserRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.ListPartnerUserRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListPartnerUserRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      partner_base_pb.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.ListPartnerUserRes} returns this
 */
proto.partner.web_partnership.ListPartnerUserRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partnership.ListPartnerUserRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ListPartnerUserRes} returns this
 */
proto.partner.web_partnership.ListPartnerUserRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated partner.UserInfo items = 3;
 * @return {!Array<!proto.partner.UserInfo>}
 */
proto.partner.web_partnership.ListPartnerUserRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.partner.UserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, partner_base_pb.UserInfo, 3));
};


/**
 * @param {!Array<!proto.partner.UserInfo>} value
 * @return {!proto.partner.web_partnership.ListPartnerUserRes} returns this
*/
proto.partner.web_partnership.ListPartnerUserRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.partner.UserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.UserInfo}
 */
proto.partner.web_partnership.ListPartnerUserRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.partner.UserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.ListPartnerUserRes} returns this
 */
proto.partner.web_partnership.ListPartnerUserRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.partner.web_partnership.ListPartnerUserRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.partner.web_partnership.ListPartnerUserRes} returns this
*/
proto.partner.web_partnership.ListPartnerUserRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.ListPartnerUserRes} returns this
 */
proto.partner.web_partnership.ListPartnerUserRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.UpdateUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.UpdateUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.UpdateUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 3, ""),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    password: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.UpdateUserReq}
 */
proto.partner.web_partnership.UpdateUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.UpdateUserReq;
  return proto.partner.web_partnership.UpdateUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.UpdateUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.UpdateUserReq}
 */
proto.partner.web_partnership.UpdateUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {!proto.partner.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.UpdateUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.UpdateUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.UpdateUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partner.web_partnership.UpdateUserReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateUserReq} returns this
 */
proto.partner.web_partnership.UpdateUserReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.partner.web_partnership.UpdateUserReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateUserReq} returns this
 */
proto.partner.web_partnership.UpdateUserReq.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.UpdateUserReq} returns this
 */
proto.partner.web_partnership.UpdateUserReq.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.UpdateUserReq.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string phone = 3;
 * @return {string}
 */
proto.partner.web_partnership.UpdateUserReq.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateUserReq} returns this
 */
proto.partner.web_partnership.UpdateUserReq.prototype.setPhone = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.UpdateUserReq} returns this
 */
proto.partner.web_partnership.UpdateUserReq.prototype.clearPhone = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.UpdateUserReq.prototype.hasPhone = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.partner.web_partnership.UpdateUserReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateUserReq} returns this
 */
proto.partner.web_partnership.UpdateUserReq.prototype.setEmail = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.UpdateUserReq} returns this
 */
proto.partner.web_partnership.UpdateUserReq.prototype.clearEmail = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.UpdateUserReq.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional partner.UserStatus status = 5;
 * @return {!proto.partner.UserStatus}
 */
proto.partner.web_partnership.UpdateUserReq.prototype.getStatus = function() {
  return /** @type {!proto.partner.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.partner.UserStatus} value
 * @return {!proto.partner.web_partnership.UpdateUserReq} returns this
 */
proto.partner.web_partnership.UpdateUserReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string password = 6;
 * @return {string}
 */
proto.partner.web_partnership.UpdateUserReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateUserReq} returns this
 */
proto.partner.web_partnership.UpdateUserReq.prototype.setPassword = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.UpdateUserReq} returns this
 */
proto.partner.web_partnership.UpdateUserReq.prototype.clearPassword = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.UpdateUserReq.prototype.hasPassword = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.UpdateMeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.UpdateMeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.UpdateMeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateMeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.UpdateMeReq}
 */
proto.partner.web_partnership.UpdateMeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.UpdateMeReq;
  return proto.partner.web_partnership.UpdateMeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.UpdateMeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.UpdateMeReq}
 */
proto.partner.web_partnership.UpdateMeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {!proto.partner.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.UpdateMeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.UpdateMeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.UpdateMeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.UpdateMeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.partner.web_partnership.UpdateMeReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateMeReq} returns this
 */
proto.partner.web_partnership.UpdateMeReq.prototype.setName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.UpdateMeReq} returns this
 */
proto.partner.web_partnership.UpdateMeReq.prototype.clearName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.UpdateMeReq.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.partner.web_partnership.UpdateMeReq.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateMeReq} returns this
 */
proto.partner.web_partnership.UpdateMeReq.prototype.setPhone = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.UpdateMeReq} returns this
 */
proto.partner.web_partnership.UpdateMeReq.prototype.clearPhone = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.UpdateMeReq.prototype.hasPhone = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.partner.web_partnership.UpdateMeReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.UpdateMeReq} returns this
 */
proto.partner.web_partnership.UpdateMeReq.prototype.setEmail = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.UpdateMeReq} returns this
 */
proto.partner.web_partnership.UpdateMeReq.prototype.clearEmail = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.UpdateMeReq.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional partner.UserStatus status = 4;
 * @return {!proto.partner.UserStatus}
 */
proto.partner.web_partnership.UpdateMeReq.prototype.getStatus = function() {
  return /** @type {!proto.partner.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.partner.UserStatus} value
 * @return {!proto.partner.web_partnership.UpdateMeReq} returns this
 */
proto.partner.web_partnership.UpdateMeReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.ListPartnerUserBasicReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.ListPartnerUserBasicReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.ListPartnerUserBasicReq;
  return proto.partner.web_partnership.ListPartnerUserBasicReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.ListPartnerUserBasicReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partner.web_partnership.ListPartnerUserBasicReq.Filter;
      reader.readMessage(value,proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.ListPartnerUserBasicReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.ListPartnerUserBasicReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    partnershopId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isOwner: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    creatorId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    role: jspb.Message.getFieldWithDefault(msg, 6, ""),
    userName: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.ListPartnerUserBasicReq.Filter;
  return proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershopId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.partner.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOwner(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatorId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCreatorId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string partnershop_id = 1;
 * @return {string}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.getPartnershopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.setPartnershopId = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.clearPartnershopId = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.hasPartnershopId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional partner.UserStatus status = 3;
 * @return {!proto.partner.UserStatus}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.getStatus = function() {
  return /** @type {!proto.partner.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.partner.UserStatus} value
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool is_owner = 4;
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.getIsOwner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.setIsOwner = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.clearIsOwner = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.hasIsOwner = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string creator_id = 5;
 * @return {string}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.getCreatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.setCreatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string role = 6;
 * @return {string}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string user_name = 7;
 * @return {string}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.setUserName = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.clearUserName = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.Filter.prototype.hasUserName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Filter filter = 1;
 * @return {?proto.partner.web_partnership.ListPartnerUserBasicReq.Filter}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.prototype.getFilter = function() {
  return /** @type{?proto.partner.web_partnership.ListPartnerUserBasicReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partnership.ListPartnerUserBasicReq.Filter, 1));
};


/**
 * @param {?proto.partner.web_partnership.ListPartnerUserBasicReq.Filter|undefined} value
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq} returns this
*/
proto.partner.web_partnership.ListPartnerUserBasicReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq} returns this
*/
proto.partner.web_partnership.ListPartnerUserBasicReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicReq} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserBasicReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.ListPartnerUserBasicRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.ListPartnerUserBasicRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.partner.web_partnership.ListPartnerUserBasicRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicRes}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.ListPartnerUserBasicRes;
  return proto.partner.web_partnership.ListPartnerUserBasicRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.ListPartnerUserBasicRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicRes}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.partner.web_partnership.ListPartnerUserBasicRes.Data;
      reader.readMessage(value,proto.partner.web_partnership.ListPartnerUserBasicRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.ListPartnerUserBasicRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.ListPartnerUserBasicRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partner.web_partnership.ListPartnerUserBasicRes.Data.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.partner.web_partnership.ListPartnerUserBasicRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partner.web_partnership.ListPartnerUserBasicRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    partner_base_pb.UserBasicInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicRes.Data}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partner.web_partnership.ListPartnerUserBasicRes.Data;
  return proto.partner.web_partnership.ListPartnerUserBasicRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partner.web_partnership.ListPartnerUserBasicRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicRes.Data}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new partner_base_pb.UserBasicInfo;
      reader.readMessage(value,partner_base_pb.UserBasicInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partner.web_partnership.ListPartnerUserBasicRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partner.web_partnership.ListPartnerUserBasicRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      partner_base_pb.UserBasicInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated partner.UserBasicInfo items = 1;
 * @return {!Array<!proto.partner.UserBasicInfo>}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.partner.UserBasicInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, partner_base_pb.UserBasicInfo, 1));
};


/**
 * @param {!Array<!proto.partner.UserBasicInfo>} value
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicRes.Data} returns this
*/
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.partner.UserBasicInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partner.UserBasicInfo}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.partner.UserBasicInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicRes.Data} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicRes.Data} returns this
*/
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicRes.Data} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.Data.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicRes} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicRes} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.partner.web_partnership.ListPartnerUserBasicRes.Data}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.prototype.getData = function() {
  return /** @type{?proto.partner.web_partnership.ListPartnerUserBasicRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.partner.web_partnership.ListPartnerUserBasicRes.Data, 3));
};


/**
 * @param {?proto.partner.web_partnership.ListPartnerUserBasicRes.Data|undefined} value
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicRes} returns this
*/
proto.partner.web_partnership.ListPartnerUserBasicRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partner.web_partnership.ListPartnerUserBasicRes} returns this
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partner.web_partnership.ListPartnerUserBasicRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.partner.web_partnership);
