/**
 * @fileoverview gRPC-Web generated client stub for airplane.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: airplane/web_partnership/transfer_booking_config.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var airplane_base_pb = require('../../airplane/base_pb.js')

var base_base_pb = require('../../base/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.airplane = {};
proto.airplane.web_partnership = require('./transfer_booking_config_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partnership.TransferBookingConfigServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partnership.TransferBookingConfigServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.CreateTransferBookingConfigsReq,
 *   !proto.airplane.web_partnership.CreateTransferBookingConfigsRes>}
 */
const methodDescriptor_TransferBookingConfigService_CreateTransferBookingConfigs = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.TransferBookingConfigService/CreateTransferBookingConfigs',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.CreateTransferBookingConfigsReq,
  proto.airplane.web_partnership.CreateTransferBookingConfigsRes,
  /**
   * @param {!proto.airplane.web_partnership.CreateTransferBookingConfigsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.CreateTransferBookingConfigsRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.CreateTransferBookingConfigsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.CreateTransferBookingConfigsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.CreateTransferBookingConfigsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.TransferBookingConfigServiceClient.prototype.createTransferBookingConfigs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.TransferBookingConfigService/CreateTransferBookingConfigs',
      request,
      metadata || {},
      methodDescriptor_TransferBookingConfigService_CreateTransferBookingConfigs,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.CreateTransferBookingConfigsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.CreateTransferBookingConfigsRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.TransferBookingConfigServicePromiseClient.prototype.createTransferBookingConfigs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.TransferBookingConfigService/CreateTransferBookingConfigs',
      request,
      metadata || {},
      methodDescriptor_TransferBookingConfigService_CreateTransferBookingConfigs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.UpdateTransferBookingConfigReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_TransferBookingConfigService_UpdateTransferBookingConfig = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.TransferBookingConfigService/UpdateTransferBookingConfig',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.UpdateTransferBookingConfigReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partnership.UpdateTransferBookingConfigReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.UpdateTransferBookingConfigReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.TransferBookingConfigServiceClient.prototype.updateTransferBookingConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.TransferBookingConfigService/UpdateTransferBookingConfig',
      request,
      metadata || {},
      methodDescriptor_TransferBookingConfigService_UpdateTransferBookingConfig,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.UpdateTransferBookingConfigReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.TransferBookingConfigServicePromiseClient.prototype.updateTransferBookingConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.TransferBookingConfigService/UpdateTransferBookingConfig',
      request,
      metadata || {},
      methodDescriptor_TransferBookingConfigService_UpdateTransferBookingConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.ListTransferBookingConfigReq,
 *   !proto.airplane.web_partnership.ListTransferBookingConfigRes>}
 */
const methodDescriptor_TransferBookingConfigService_ListTransferBookingConfig = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.TransferBookingConfigService/ListTransferBookingConfig',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.ListTransferBookingConfigReq,
  proto.airplane.web_partnership.ListTransferBookingConfigRes,
  /**
   * @param {!proto.airplane.web_partnership.ListTransferBookingConfigReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.ListTransferBookingConfigRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.ListTransferBookingConfigReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.ListTransferBookingConfigRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.ListTransferBookingConfigRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.TransferBookingConfigServiceClient.prototype.listTransferBookingConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.TransferBookingConfigService/ListTransferBookingConfig',
      request,
      metadata || {},
      methodDescriptor_TransferBookingConfigService_ListTransferBookingConfig,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.ListTransferBookingConfigReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.ListTransferBookingConfigRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.TransferBookingConfigServicePromiseClient.prototype.listTransferBookingConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.TransferBookingConfigService/ListTransferBookingConfig',
      request,
      metadata || {},
      methodDescriptor_TransferBookingConfigService_ListTransferBookingConfig);
};


module.exports = proto.airplane.web_partnership;

