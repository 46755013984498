// source: skyhub/report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_enum_pb = require('../base/enum_pb.js');
goog.object.extend(proto, base_enum_pb);
goog.exportSymbol('proto.skyhub.SkyHubReport', null, global);
goog.exportSymbol('proto.skyhub.Surcharge', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.SkyHubReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.SkyHubReport.repeatedFields_, null);
};
goog.inherits(proto.skyhub.SkyHubReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.SkyHubReport.displayName = 'proto.skyhub.SkyHubReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.Surcharge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.Surcharge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.Surcharge.displayName = 'proto.skyhub.Surcharge';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.SkyHubReport.repeatedFields_ = [25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.SkyHubReport.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.SkyHubReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.SkyHubReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.SkyHubReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, ""),
    provider: jspb.Message.getFieldWithDefault(msg, 2, ""),
    agentCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contactLastName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactFirstName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    contactCountryCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    contactPhoneNumber: jspb.Message.getFieldWithDefault(msg, 7, ""),
    contactEmail: jspb.Message.getFieldWithDefault(msg, 8, ""),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    pnrCode: jspb.Message.getFieldWithDefault(msg, 10, ""),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 11, ""),
    passengerSurname: jspb.Message.getFieldWithDefault(msg, 12, ""),
    passengerGivenName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    departurePlace: jspb.Message.getFieldWithDefault(msg, 14, ""),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 15, ""),
    departureDate: jspb.Message.getFieldWithDefault(msg, 16, 0),
    fareBasic: jspb.Message.getFloatingPointFieldWithDefault(msg, 17, 0.0),
    taxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 18, 0.0),
    seatPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 19, 0.0),
    baggagePrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    hiddenFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 21, 0.0),
    totalFare: jspb.Message.getFloatingPointFieldWithDefault(msg, 22, 0.0),
    commissionRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 23, 0.0),
    surchargeRoute: jspb.Message.getFieldWithDefault(msg, 24, ""),
    surchargesList: jspb.Message.toObjectList(msg.getSurchargesList(),
    proto.skyhub.Surcharge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.SkyHubReport}
 */
proto.skyhub.SkyHubReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.SkyHubReport;
  return proto.skyhub.SkyHubReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.SkyHubReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.SkyHubReport}
 */
proto.skyhub.SkyHubReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProvider(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactLastName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactFirstName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactCountryCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPhoneNumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactEmail(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPnrCode(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassengerSurname(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassengerGivenName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeparturePlace(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartureDate(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFareBasic(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTaxAmount(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSeatPrice(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBaggagePrice(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHiddenFee(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalFare(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCommissionRate(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setSurchargeRoute(value);
      break;
    case 25:
      var value = new proto.skyhub.Surcharge;
      reader.readMessage(value,proto.skyhub.Surcharge.deserializeBinaryFromReader);
      msg.addSurcharges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.SkyHubReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.SkyHubReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.SkyHubReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.SkyHubReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProvider();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAgentCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContactLastName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContactFirstName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContactCountryCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContactPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getContactEmail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPnrCode();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPassengerSurname();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPassengerGivenName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDeparturePlace();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getDepartureDate();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getFareBasic();
  if (f !== 0.0) {
    writer.writeDouble(
      17,
      f
    );
  }
  f = message.getTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      18,
      f
    );
  }
  f = message.getSeatPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      19,
      f
    );
  }
  f = message.getBaggagePrice();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getHiddenFee();
  if (f !== 0.0) {
    writer.writeDouble(
      21,
      f
    );
  }
  f = message.getTotalFare();
  if (f !== 0.0) {
    writer.writeDouble(
      22,
      f
    );
  }
  f = message.getCommissionRate();
  if (f !== 0.0) {
    writer.writeDouble(
      23,
      f
    );
  }
  f = message.getSurchargeRoute();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getSurchargesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.skyhub.Surcharge.serializeBinaryToWriter
    );
  }
};


/**
 * optional string status = 1;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string provider = 2;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string agent_code = 3;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getAgentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setAgentCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string contact_last_name = 4;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getContactLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setContactLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string contact_first_name = 5;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getContactFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setContactFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string contact_country_code = 6;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getContactCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setContactCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string contact_phone_number = 7;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getContactPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setContactPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string contact_email = 8;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getContactEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setContactEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string booking_code = 9;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string pnr_code = 10;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getPnrCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setPnrCode = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string ticket_number = 11;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setTicketNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string passenger_surname = 12;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getPassengerSurname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setPassengerSurname = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string passenger_given_name = 13;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getPassengerGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setPassengerGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string departure_place = 14;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getDeparturePlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setDeparturePlace = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string arrival_place = 15;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int64 departure_date = 16;
 * @return {number}
 */
proto.skyhub.SkyHubReport.prototype.getDepartureDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setDepartureDate = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional double fare_basic = 17;
 * @return {number}
 */
proto.skyhub.SkyHubReport.prototype.getFareBasic = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 17, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setFareBasic = function(value) {
  return jspb.Message.setProto3FloatField(this, 17, value);
};


/**
 * optional double tax_amount = 18;
 * @return {number}
 */
proto.skyhub.SkyHubReport.prototype.getTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 18, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 18, value);
};


/**
 * optional double seat_price = 19;
 * @return {number}
 */
proto.skyhub.SkyHubReport.prototype.getSeatPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 19, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setSeatPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 19, value);
};


/**
 * optional double baggage_price = 20;
 * @return {number}
 */
proto.skyhub.SkyHubReport.prototype.getBaggagePrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setBaggagePrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional double hidden_fee = 21;
 * @return {number}
 */
proto.skyhub.SkyHubReport.prototype.getHiddenFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 21, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setHiddenFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 21, value);
};


/**
 * optional double total_fare = 22;
 * @return {number}
 */
proto.skyhub.SkyHubReport.prototype.getTotalFare = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 22, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setTotalFare = function(value) {
  return jspb.Message.setProto3FloatField(this, 22, value);
};


/**
 * optional double commission_rate = 23;
 * @return {number}
 */
proto.skyhub.SkyHubReport.prototype.getCommissionRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 23, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setCommissionRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 23, value);
};


/**
 * optional string surcharge_route = 24;
 * @return {string}
 */
proto.skyhub.SkyHubReport.prototype.getSurchargeRoute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.setSurchargeRoute = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * repeated Surcharge surcharges = 25;
 * @return {!Array<!proto.skyhub.Surcharge>}
 */
proto.skyhub.SkyHubReport.prototype.getSurchargesList = function() {
  return /** @type{!Array<!proto.skyhub.Surcharge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skyhub.Surcharge, 25));
};


/**
 * @param {!Array<!proto.skyhub.Surcharge>} value
 * @return {!proto.skyhub.SkyHubReport} returns this
*/
proto.skyhub.SkyHubReport.prototype.setSurchargesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.skyhub.Surcharge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skyhub.Surcharge}
 */
proto.skyhub.SkyHubReport.prototype.addSurcharges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.skyhub.Surcharge, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.SkyHubReport} returns this
 */
proto.skyhub.SkyHubReport.prototype.clearSurchargesList = function() {
  return this.setSurchargesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.Surcharge.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.Surcharge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.Surcharge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.Surcharge.toObject = function(includeInstance, msg) {
  var f, obj = {
    surchargeName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    surchargeBaseAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    surchargeTaxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    surchargeDiscountAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    surchargeTotalAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.Surcharge}
 */
proto.skyhub.Surcharge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.Surcharge;
  return proto.skyhub.Surcharge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.Surcharge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.Surcharge}
 */
proto.skyhub.Surcharge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSurchargeName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSurchargeBaseAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSurchargeTaxAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSurchargeDiscountAmount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSurchargeTotalAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.Surcharge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.Surcharge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.Surcharge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.Surcharge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurchargeName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSurchargeBaseAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getSurchargeTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getSurchargeDiscountAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getSurchargeTotalAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
};


/**
 * optional string surcharge_name = 1;
 * @return {string}
 */
proto.skyhub.Surcharge.prototype.getSurchargeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.Surcharge} returns this
 */
proto.skyhub.Surcharge.prototype.setSurchargeName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double surcharge_base_amount = 2;
 * @return {number}
 */
proto.skyhub.Surcharge.prototype.getSurchargeBaseAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.Surcharge} returns this
 */
proto.skyhub.Surcharge.prototype.setSurchargeBaseAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double surcharge_tax_amount = 3;
 * @return {number}
 */
proto.skyhub.Surcharge.prototype.getSurchargeTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.Surcharge} returns this
 */
proto.skyhub.Surcharge.prototype.setSurchargeTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double surcharge_discount_amount = 4;
 * @return {number}
 */
proto.skyhub.Surcharge.prototype.getSurchargeDiscountAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.Surcharge} returns this
 */
proto.skyhub.Surcharge.prototype.setSurchargeDiscountAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double surcharge_total_amount = 5;
 * @return {number}
 */
proto.skyhub.Surcharge.prototype.getSurchargeTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.Surcharge} returns this
 */
proto.skyhub.Surcharge.prototype.setSurchargeTotalAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


goog.object.extend(exports, proto.skyhub);
