// source: airplane/web_partnership/flight.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var airplane_base_pb = require('../../airplane/base_pb.js');
goog.object.extend(proto, airplane_base_pb);
var airplane_hub_pb = require('../../airplane/hub_pb.js');
goog.object.extend(proto, airplane_hub_pb);
var airplane_flight_pb = require('../../airplane/flight_pb.js');
goog.object.extend(proto, airplane_flight_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.airplane.web_partnership.AirportInfo', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ExportBookingFlightReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ExportBookingFlightReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ExportBookingFlightRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.GetAirportsReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.GetAirportsRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ListBookingFlightReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ListBookingFlightReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ListBookingFlightRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ListBookingFlightRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ListTransferBookingRequestReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ListTransferBookingRequestRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.RefundBookingReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.RetrieveBookingFlightReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.RetrieveBookingFlightRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.RetrieveBookingFlightRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee', null, global);
goog.exportSymbol('proto.airplane.web_partnership.SearchAirlinesReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.SearchAirlinesReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partnership.SearchAirlinesRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.SearchAirlinesRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partnership.SortItem', null, global);
goog.exportSymbol('proto.airplane.web_partnership.SortItemType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.SearchAirlinesReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.SearchAirlinesReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.SearchAirlinesReq.displayName = 'proto.airplane.web_partnership.SearchAirlinesReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.SearchAirlinesReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.SearchAirlinesReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.SearchAirlinesReq.Filter.displayName = 'proto.airplane.web_partnership.SearchAirlinesReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.SearchAirlinesRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.SearchAirlinesRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.SearchAirlinesRes.displayName = 'proto.airplane.web_partnership.SearchAirlinesRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.SearchAirlinesRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.SearchAirlinesRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.SearchAirlinesRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.SearchAirlinesRes.Data.displayName = 'proto.airplane.web_partnership.SearchAirlinesRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.RetrieveBookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.RetrieveBookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.RetrieveBookingFlightReq.displayName = 'proto.airplane.web_partnership.RetrieveBookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.RetrieveBookingFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.RetrieveBookingFlightRes.displayName = 'proto.airplane.web_partnership.RetrieveBookingFlightRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.displayName = 'proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.RetrieveBookingFlightRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.displayName = 'proto.airplane.web_partnership.RetrieveBookingFlightRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.SortItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.SortItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.SortItem.displayName = 'proto.airplane.web_partnership.SortItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ListBookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ListBookingFlightReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ListBookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ListBookingFlightReq.displayName = 'proto.airplane.web_partnership.ListBookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ListBookingFlightReq.Filter.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ListBookingFlightReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ListBookingFlightReq.Filter.displayName = 'proto.airplane.web_partnership.ListBookingFlightReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ListBookingFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ListBookingFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ListBookingFlightRes.displayName = 'proto.airplane.web_partnership.ListBookingFlightRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ListBookingFlightRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ListBookingFlightRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ListBookingFlightRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ListBookingFlightRes.Data.displayName = 'proto.airplane.web_partnership.ListBookingFlightRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ExportBookingFlightReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ExportBookingFlightReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ExportBookingFlightReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ExportBookingFlightReq.displayName = 'proto.airplane.web_partnership.ExportBookingFlightReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ExportBookingFlightReq.Filter.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ExportBookingFlightReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ExportBookingFlightReq.Filter.displayName = 'proto.airplane.web_partnership.ExportBookingFlightReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ExportBookingFlightRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ExportBookingFlightRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ExportBookingFlightRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ExportBookingFlightRes.displayName = 'proto.airplane.web_partnership.ExportBookingFlightRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.RefundBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.RefundBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.RefundBookingReq.displayName = 'proto.airplane.web_partnership.RefundBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.GetAirportsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.GetAirportsReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.GetAirportsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.GetAirportsReq.displayName = 'proto.airplane.web_partnership.GetAirportsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.GetAirportsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.GetAirportsRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.GetAirportsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.GetAirportsRes.displayName = 'proto.airplane.web_partnership.GetAirportsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.AirportInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.AirportInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.AirportInfo.displayName = 'proto.airplane.web_partnership.AirportInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ListTransferBookingRequestReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ListTransferBookingRequestReq.displayName = 'proto.airplane.web_partnership.ListTransferBookingRequestReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.displayName = 'proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ListTransferBookingRequestRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ListTransferBookingRequestRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ListTransferBookingRequestRes.displayName = 'proto.airplane.web_partnership.ListTransferBookingRequestRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.SearchAirlinesReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.SearchAirlinesReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.SearchAirlinesReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchAirlinesReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partnership.SearchAirlinesReq.Filter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.SearchAirlinesReq}
 */
proto.airplane.web_partnership.SearchAirlinesReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.SearchAirlinesReq;
  return proto.airplane.web_partnership.SearchAirlinesReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.SearchAirlinesReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.SearchAirlinesReq}
 */
proto.airplane.web_partnership.SearchAirlinesReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partnership.SearchAirlinesReq.Filter;
      reader.readMessage(value,proto.airplane.web_partnership.SearchAirlinesReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.SearchAirlinesReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.SearchAirlinesReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.SearchAirlinesReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchAirlinesReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partnership.SearchAirlinesReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.SearchAirlinesReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.SearchAirlinesReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.SearchAirlinesReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchAirlinesReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.SearchAirlinesReq.Filter}
 */
proto.airplane.web_partnership.SearchAirlinesReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.SearchAirlinesReq.Filter;
  return proto.airplane.web_partnership.SearchAirlinesReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.SearchAirlinesReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.SearchAirlinesReq.Filter}
 */
proto.airplane.web_partnership.SearchAirlinesReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.SearchAirlinesReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.SearchAirlinesReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.SearchAirlinesReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchAirlinesReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.airplane.web_partnership.SearchAirlinesReq.Filter.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchAirlinesReq.Filter} returns this
 */
proto.airplane.web_partnership.SearchAirlinesReq.Filter.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partnership.SearchAirlinesReq.Filter}
 */
proto.airplane.web_partnership.SearchAirlinesReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partnership.SearchAirlinesReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.SearchAirlinesReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partnership.SearchAirlinesReq.Filter|undefined} value
 * @return {!proto.airplane.web_partnership.SearchAirlinesReq} returns this
*/
proto.airplane.web_partnership.SearchAirlinesReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.SearchAirlinesReq} returns this
 */
proto.airplane.web_partnership.SearchAirlinesReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.SearchAirlinesReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partnership.SearchAirlinesReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partnership.SearchAirlinesReq} returns this
*/
proto.airplane.web_partnership.SearchAirlinesReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.SearchAirlinesReq} returns this
 */
proto.airplane.web_partnership.SearchAirlinesReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.SearchAirlinesReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.SearchAirlinesRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.SearchAirlinesRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.SearchAirlinesRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchAirlinesRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partnership.SearchAirlinesRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.SearchAirlinesRes}
 */
proto.airplane.web_partnership.SearchAirlinesRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.SearchAirlinesRes;
  return proto.airplane.web_partnership.SearchAirlinesRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.SearchAirlinesRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.SearchAirlinesRes}
 */
proto.airplane.web_partnership.SearchAirlinesRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.SearchAirlinesRes.Data;
      reader.readMessage(value,proto.airplane.web_partnership.SearchAirlinesRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.SearchAirlinesRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.SearchAirlinesRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.SearchAirlinesRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchAirlinesRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partnership.SearchAirlinesRes.Data.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.SearchAirlinesRes.Data.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.SearchAirlinesRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.SearchAirlinesRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.SearchAirlinesRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchAirlinesRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_hub_pb.Airline.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.SearchAirlinesRes.Data}
 */
proto.airplane.web_partnership.SearchAirlinesRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.SearchAirlinesRes.Data;
  return proto.airplane.web_partnership.SearchAirlinesRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.SearchAirlinesRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.SearchAirlinesRes.Data}
 */
proto.airplane.web_partnership.SearchAirlinesRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new airplane_hub_pb.Airline;
      reader.readMessage(value,airplane_hub_pb.Airline.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.SearchAirlinesRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.SearchAirlinesRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.SearchAirlinesRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchAirlinesRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      airplane_hub_pb.Airline.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated airplane.Airline items = 1;
 * @return {!Array<!proto.airplane.Airline>}
 */
proto.airplane.web_partnership.SearchAirlinesRes.Data.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.Airline>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_hub_pb.Airline, 1));
};


/**
 * @param {!Array<!proto.airplane.Airline>} value
 * @return {!proto.airplane.web_partnership.SearchAirlinesRes.Data} returns this
*/
proto.airplane.web_partnership.SearchAirlinesRes.Data.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.Airline=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.Airline}
 */
proto.airplane.web_partnership.SearchAirlinesRes.Data.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.Airline, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.SearchAirlinesRes.Data} returns this
 */
proto.airplane.web_partnership.SearchAirlinesRes.Data.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partnership.SearchAirlinesRes.Data.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partnership.SearchAirlinesRes.Data} returns this
*/
proto.airplane.web_partnership.SearchAirlinesRes.Data.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.SearchAirlinesRes.Data} returns this
 */
proto.airplane.web_partnership.SearchAirlinesRes.Data.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.SearchAirlinesRes.Data.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.SearchAirlinesRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.SearchAirlinesRes} returns this
 */
proto.airplane.web_partnership.SearchAirlinesRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.SearchAirlinesRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchAirlinesRes} returns this
 */
proto.airplane.web_partnership.SearchAirlinesRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partnership.SearchAirlinesRes.Data}
 */
proto.airplane.web_partnership.SearchAirlinesRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partnership.SearchAirlinesRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.SearchAirlinesRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partnership.SearchAirlinesRes.Data|undefined} value
 * @return {!proto.airplane.web_partnership.SearchAirlinesRes} returns this
*/
proto.airplane.web_partnership.SearchAirlinesRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.SearchAirlinesRes} returns this
 */
proto.airplane.web_partnership.SearchAirlinesRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.SearchAirlinesRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.RetrieveBookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.RetrieveBookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.RetrieveBookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightReq}
 */
proto.airplane.web_partnership.RetrieveBookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.RetrieveBookingFlightReq;
  return proto.airplane.web_partnership.RetrieveBookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightReq}
 */
proto.airplane.web_partnership.RetrieveBookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.RetrieveBookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.RetrieveBookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.RetrieveBookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partnership.RetrieveBookingFlightReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightReq} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.RetrieveBookingFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.RetrieveBookingFlightRes;
  return proto.airplane.web_partnership.RetrieveBookingFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.RetrieveBookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.RetrieveBookingFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.toObject = function(includeInstance, msg) {
  var f, obj = {
    originPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    makeupPrice: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee;
  return proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOriginPrice(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMakeupPrice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMakeupPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double origin_price = 1;
 * @return {number}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.prototype.getOriginPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.prototype.setOriginPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double makeup_price = 2;
 * @return {number}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.prototype.getMakeupPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.prototype.setMakeupPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reservationCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    passengersList: jspb.Message.toObjectList(msg.getPassengersList(),
    airplane_flight_pb.BookingPassenger.toObject, includeInstance),
    itinerariesList: jspb.Message.toObjectList(msg.getItinerariesList(),
    airplane_flight_pb.BookingItinerary.toObject, includeInstance),
    fee: (f = msg.getFee()) && airplane_base_pb.CalculationFlightFeeResData.toObject(includeInstance, f),
    contactInfo: (f = msg.getContactInfo()) && airplane_flight_pb.BookingContactInfo.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    note: jspb.Message.getFieldWithDefault(msg, 10, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 12, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
    issuedAt: jspb.Message.getFieldWithDefault(msg, 15, 0),
    id: jspb.Message.getFieldWithDefault(msg, 16, ""),
    paymentInfo: (f = msg.getPaymentInfo()) && airplane_hub_pb.PaymentInfo.toObject(includeInstance, f),
    refund: (f = msg.getRefund()) && airplane_flight_pb.RefundInfo.toObject(includeInstance, f),
    makeupFee: (f = msg.getMakeupFee()) && proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.toObject(includeInstance, f),
    agencyCommissionFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    agentCode: jspb.Message.getFieldWithDefault(msg, 21, ""),
    accessTradeRefInfo: (f = msg.getAccessTradeRefInfo()) && airplane_flight_pb.AccessTradeRefInfo.toObject(includeInstance, f),
    invoicingInformation: (f = msg.getInvoicingInformation()) && airplane_flight_pb.InvoicingInformation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.RetrieveBookingFlightRes.Data;
  return proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationCode(value);
      break;
    case 3:
      var value = /** @type {!proto.airplane.BookingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new airplane_flight_pb.BookingPassenger;
      reader.readMessage(value,airplane_flight_pb.BookingPassenger.deserializeBinaryFromReader);
      msg.addPassengers(value);
      break;
    case 5:
      var value = new airplane_flight_pb.BookingItinerary;
      reader.readMessage(value,airplane_flight_pb.BookingItinerary.deserializeBinaryFromReader);
      msg.addItineraries(value);
      break;
    case 6:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.setFee(value);
      break;
    case 7:
      var value = new airplane_flight_pb.BookingContactInfo;
      reader.readMessage(value,airplane_flight_pb.BookingContactInfo.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIssuedAt(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 17:
      var value = new airplane_hub_pb.PaymentInfo;
      reader.readMessage(value,airplane_hub_pb.PaymentInfo.deserializeBinaryFromReader);
      msg.setPaymentInfo(value);
      break;
    case 18:
      var value = new airplane_flight_pb.RefundInfo;
      reader.readMessage(value,airplane_flight_pb.RefundInfo.deserializeBinaryFromReader);
      msg.setRefund(value);
      break;
    case 19:
      var value = new proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee;
      reader.readMessage(value,proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.deserializeBinaryFromReader);
      msg.setMakeupFee(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAgencyCommissionFee(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentCode(value);
      break;
    case 22:
      var value = new airplane_flight_pb.AccessTradeRefInfo;
      reader.readMessage(value,airplane_flight_pb.AccessTradeRefInfo.deserializeBinaryFromReader);
      msg.setAccessTradeRefInfo(value);
      break;
    case 23:
      var value = new airplane_flight_pb.InvoicingInformation;
      reader.readMessage(value,airplane_flight_pb.InvoicingInformation.deserializeBinaryFromReader);
      msg.setInvoicingInformation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReservationCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getPassengersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      airplane_flight_pb.BookingPassenger.serializeBinaryToWriter
    );
  }
  f = message.getItinerariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      airplane_flight_pb.BookingItinerary.serializeBinaryToWriter
    );
  }
  f = message.getFee();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      airplane_flight_pb.BookingContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getIssuedAt();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getPaymentInfo();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      airplane_hub_pb.PaymentInfo.serializeBinaryToWriter
    );
  }
  f = message.getRefund();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      airplane_flight_pb.RefundInfo.serializeBinaryToWriter
    );
  }
  f = message.getMakeupFee();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee.serializeBinaryToWriter
    );
  }
  f = message.getAgencyCommissionFee();
  if (f !== 0.0) {
    writer.writeDouble(
      20,
      f
    );
  }
  f = message.getAgentCode();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getAccessTradeRefInfo();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      airplane_flight_pb.AccessTradeRefInfo.serializeBinaryToWriter
    );
  }
  f = message.getInvoicingInformation();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      airplane_flight_pb.InvoicingInformation.serializeBinaryToWriter
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reservation_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getReservationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setReservationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional airplane.BookingStatus status = 3;
 * @return {!proto.airplane.BookingStatus}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getStatus = function() {
  return /** @type {!proto.airplane.BookingStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated airplane.BookingPassenger passengers = 4;
 * @return {!Array<!proto.airplane.BookingPassenger>}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getPassengersList = function() {
  return /** @type{!Array<!proto.airplane.BookingPassenger>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_flight_pb.BookingPassenger, 4));
};


/**
 * @param {!Array<!proto.airplane.BookingPassenger>} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setPassengersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.airplane.BookingPassenger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.BookingPassenger}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.addPassengers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.airplane.BookingPassenger, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.clearPassengersList = function() {
  return this.setPassengersList([]);
};


/**
 * repeated airplane.BookingItinerary itineraries = 5;
 * @return {!Array<!proto.airplane.BookingItinerary>}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getItinerariesList = function() {
  return /** @type{!Array<!proto.airplane.BookingItinerary>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_flight_pb.BookingItinerary, 5));
};


/**
 * @param {!Array<!proto.airplane.BookingItinerary>} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setItinerariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.airplane.BookingItinerary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.BookingItinerary}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.addItineraries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.airplane.BookingItinerary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.clearItinerariesList = function() {
  return this.setItinerariesList([]);
};


/**
 * optional airplane.CalculationFlightFeeResData fee = 6;
 * @return {?proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getFee = function() {
  return /** @type{?proto.airplane.CalculationFlightFeeResData} */ (
    jspb.Message.getWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 6));
};


/**
 * @param {?proto.airplane.CalculationFlightFeeResData|undefined} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setFee = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.clearFee = function() {
  return this.setFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.hasFee = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional airplane.BookingContactInfo contact_info = 7;
 * @return {?proto.airplane.BookingContactInfo}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getContactInfo = function() {
  return /** @type{?proto.airplane.BookingContactInfo} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.BookingContactInfo, 7));
};


/**
 * @param {?proto.airplane.BookingContactInfo|undefined} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string created_by = 8;
 * @return {string}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 created_at = 9;
 * @return {number}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string note = 10;
 * @return {string}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string currency = 12;
 * @return {string}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string order_id = 13;
 * @return {string}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 expired_at = 14;
 * @return {number}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 issued_at = 15;
 * @return {number}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getIssuedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setIssuedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string id = 16;
 * @return {string}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional airplane.PaymentInfo payment_info = 17;
 * @return {?proto.airplane.PaymentInfo}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getPaymentInfo = function() {
  return /** @type{?proto.airplane.PaymentInfo} */ (
    jspb.Message.getWrapperField(this, airplane_hub_pb.PaymentInfo, 17));
};


/**
 * @param {?proto.airplane.PaymentInfo|undefined} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setPaymentInfo = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.clearPaymentInfo = function() {
  return this.setPaymentInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.hasPaymentInfo = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional airplane.RefundInfo refund = 18;
 * @return {?proto.airplane.RefundInfo}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getRefund = function() {
  return /** @type{?proto.airplane.RefundInfo} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.RefundInfo, 18));
};


/**
 * @param {?proto.airplane.RefundInfo|undefined} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setRefund = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.clearRefund = function() {
  return this.setRefund(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.hasRefund = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional MakeupFee makeup_fee = 19;
 * @return {?proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getMakeupFee = function() {
  return /** @type{?proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee, 19));
};


/**
 * @param {?proto.airplane.web_partnership.RetrieveBookingFlightRes.MakeupFee|undefined} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setMakeupFee = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.clearMakeupFee = function() {
  return this.setMakeupFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.hasMakeupFee = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional double agency_commission_fee = 20;
 * @return {number}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getAgencyCommissionFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setAgencyCommissionFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 20, value);
};


/**
 * optional string agent_code = 21;
 * @return {string}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getAgentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setAgentCode = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional airplane.AccessTradeRefInfo access_trade_ref_info = 22;
 * @return {?proto.airplane.AccessTradeRefInfo}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getAccessTradeRefInfo = function() {
  return /** @type{?proto.airplane.AccessTradeRefInfo} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.AccessTradeRefInfo, 22));
};


/**
 * @param {?proto.airplane.AccessTradeRefInfo|undefined} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setAccessTradeRefInfo = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.clearAccessTradeRefInfo = function() {
  return this.setAccessTradeRefInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.hasAccessTradeRefInfo = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional airplane.InvoicingInformation invoicing_information = 23;
 * @return {?proto.airplane.InvoicingInformation}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.getInvoicingInformation = function() {
  return /** @type{?proto.airplane.InvoicingInformation} */ (
    jspb.Message.getWrapperField(this, airplane_flight_pb.InvoicingInformation, 23));
};


/**
 * @param {?proto.airplane.InvoicingInformation|undefined} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
*/
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.setInvoicingInformation = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.clearInvoicingInformation = function() {
  return this.setInvoicingInformation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.prototype.hasInvoicingInformation = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partnership.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partnership.RetrieveBookingFlightRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.RetrieveBookingFlightRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partnership.RetrieveBookingFlightRes.Data|undefined} value
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes} returns this
*/
proto.airplane.web_partnership.RetrieveBookingFlightRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes} returns this
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.RetrieveBookingFlightRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.SortItem.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.SortItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.SortItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SortItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    desc: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.SortItem}
 */
proto.airplane.web_partnership.SortItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.SortItem;
  return proto.airplane.web_partnership.SortItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.SortItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.SortItem}
 */
proto.airplane.web_partnership.SortItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDesc(value);
      break;
    case 2:
      var value = /** @type {!proto.airplane.web_partnership.SortItemType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.SortItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.SortItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.SortItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SortItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDesc();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional bool desc = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.SortItem.prototype.getDesc = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.SortItem} returns this
 */
proto.airplane.web_partnership.SortItem.prototype.setDesc = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional SortItemType type = 2;
 * @return {!proto.airplane.web_partnership.SortItemType}
 */
proto.airplane.web_partnership.SortItem.prototype.getType = function() {
  return /** @type {!proto.airplane.web_partnership.SortItemType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.airplane.web_partnership.SortItemType} value
 * @return {!proto.airplane.web_partnership.SortItem} returns this
 */
proto.airplane.web_partnership.SortItem.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ListBookingFlightReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ListBookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ListBookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ListBookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListBookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partnership.ListBookingFlightReq.Filter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    sortsList: jspb.Message.toObjectList(msg.getSortsList(),
    proto.airplane.web_partnership.SortItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq}
 */
proto.airplane.web_partnership.ListBookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ListBookingFlightReq;
  return proto.airplane.web_partnership.ListBookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ListBookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq}
 */
proto.airplane.web_partnership.ListBookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partnership.ListBookingFlightReq.Filter;
      reader.readMessage(value,proto.airplane.web_partnership.ListBookingFlightReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.SortItem;
      reader.readMessage(value,proto.airplane.web_partnership.SortItem.deserializeBinaryFromReader);
      msg.addSorts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ListBookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ListBookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ListBookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListBookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partnership.ListBookingFlightReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getSortsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partnership.SortItem.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ListBookingFlightReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    passengerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactPhone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    statusesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    partnershopId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ListBookingFlightReq.Filter;
  return proto.airplane.web_partnership.ListBookingFlightReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassengerName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 7:
      var values = /** @type {!Array<!proto.airplane.BookingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershopId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ListBookingFlightReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.setBookingCode = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.clearBookingCode = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.hasBookingCode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 from_date = 2;
 * @return {number}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.getFromDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.setFromDate = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.clearFromDate = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 to_date = 3;
 * @return {number}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.getToDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.setToDate = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.clearToDate = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string passenger_name = 4;
 * @return {string}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.getPassengerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.setPassengerName = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.clearPassengerName = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.hasPassengerName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string contact_phone = 5;
 * @return {string}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.getContactPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.setContactPhone = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.clearContactPhone = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.hasContactPhone = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string owner_id = 6;
 * @return {string}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.setOwnerId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.clearOwnerId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.hasOwnerId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated airplane.BookingStatus statuses = 7;
 * @return {!Array<!proto.airplane.BookingStatus>}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.airplane.BookingStatus>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.airplane.BookingStatus>} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional string partnershop_id = 8;
 * @return {string}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.getPartnershopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.setPartnershopId = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.clearPartnershopId = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListBookingFlightReq.Filter.prototype.hasPartnershopId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partnership.ListBookingFlightReq.Filter}
 */
proto.airplane.web_partnership.ListBookingFlightReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partnership.ListBookingFlightReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.ListBookingFlightReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partnership.ListBookingFlightReq.Filter|undefined} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq} returns this
*/
proto.airplane.web_partnership.ListBookingFlightReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListBookingFlightReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partnership.ListBookingFlightReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq} returns this
*/
proto.airplane.web_partnership.ListBookingFlightReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListBookingFlightReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SortItem sorts = 3;
 * @return {!Array<!proto.airplane.web_partnership.SortItem>}
 */
proto.airplane.web_partnership.ListBookingFlightReq.prototype.getSortsList = function() {
  return /** @type{!Array<!proto.airplane.web_partnership.SortItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partnership.SortItem, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partnership.SortItem>} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq} returns this
*/
proto.airplane.web_partnership.ListBookingFlightReq.prototype.setSortsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partnership.SortItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.SortItem}
 */
proto.airplane.web_partnership.ListBookingFlightReq.prototype.addSorts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partnership.SortItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ListBookingFlightReq} returns this
 */
proto.airplane.web_partnership.ListBookingFlightReq.prototype.clearSortsList = function() {
  return this.setSortsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ListBookingFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ListBookingFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ListBookingFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListBookingFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partnership.ListBookingFlightRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ListBookingFlightRes}
 */
proto.airplane.web_partnership.ListBookingFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ListBookingFlightRes;
  return proto.airplane.web_partnership.ListBookingFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ListBookingFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ListBookingFlightRes}
 */
proto.airplane.web_partnership.ListBookingFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.ListBookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web_partnership.ListBookingFlightRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ListBookingFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ListBookingFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ListBookingFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListBookingFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partnership.ListBookingFlightRes.Data.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ListBookingFlightRes.Data.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ListBookingFlightRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ListBookingFlightRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ListBookingFlightRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListBookingFlightRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ListBookingFlightRes.Data}
 */
proto.airplane.web_partnership.ListBookingFlightRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ListBookingFlightRes.Data;
  return proto.airplane.web_partnership.ListBookingFlightRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ListBookingFlightRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ListBookingFlightRes.Data}
 */
proto.airplane.web_partnership.ListBookingFlightRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partnership.RetrieveBookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ListBookingFlightRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ListBookingFlightRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ListBookingFlightRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListBookingFlightRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RetrieveBookingFlightRes.Data items = 1;
 * @return {!Array<!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data>}
 */
proto.airplane.web_partnership.ListBookingFlightRes.Data.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partnership.RetrieveBookingFlightRes.Data, 1));
};


/**
 * @param {!Array<!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data>} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightRes.Data} returns this
*/
proto.airplane.web_partnership.ListBookingFlightRes.Data.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partnership.ListBookingFlightRes.Data.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web_partnership.RetrieveBookingFlightRes.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ListBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.ListBookingFlightRes.Data.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partnership.ListBookingFlightRes.Data.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightRes.Data} returns this
*/
proto.airplane.web_partnership.ListBookingFlightRes.Data.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ListBookingFlightRes.Data} returns this
 */
proto.airplane.web_partnership.ListBookingFlightRes.Data.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListBookingFlightRes.Data.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.ListBookingFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightRes} returns this
 */
proto.airplane.web_partnership.ListBookingFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.ListBookingFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightRes} returns this
 */
proto.airplane.web_partnership.ListBookingFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partnership.ListBookingFlightRes.Data}
 */
proto.airplane.web_partnership.ListBookingFlightRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partnership.ListBookingFlightRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.ListBookingFlightRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partnership.ListBookingFlightRes.Data|undefined} value
 * @return {!proto.airplane.web_partnership.ListBookingFlightRes} returns this
*/
proto.airplane.web_partnership.ListBookingFlightRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ListBookingFlightRes} returns this
 */
proto.airplane.web_partnership.ListBookingFlightRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListBookingFlightRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ExportBookingFlightReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ExportBookingFlightReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ExportBookingFlightReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ExportBookingFlightReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partnership.ExportBookingFlightReq.Filter.toObject(includeInstance, f),
    sortsList: jspb.Message.toObjectList(msg.getSortsList(),
    proto.airplane.web_partnership.SortItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ExportBookingFlightReq;
  return proto.airplane.web_partnership.ExportBookingFlightReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ExportBookingFlightReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partnership.ExportBookingFlightReq.Filter;
      reader.readMessage(value,proto.airplane.web_partnership.ExportBookingFlightReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.SortItem;
      reader.readMessage(value,proto.airplane.web_partnership.SortItem.deserializeBinaryFromReader);
      msg.addSorts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ExportBookingFlightReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ExportBookingFlightReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ExportBookingFlightReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partnership.ExportBookingFlightReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getSortsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partnership.SortItem.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ExportBookingFlightReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    passengerName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contactPhone: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    statusesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    partnershopId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ExportBookingFlightReq.Filter;
  return proto.airplane.web_partnership.ExportBookingFlightReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassengerName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactPhone(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 7:
      var values = /** @type {!Array<!proto.airplane.BookingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershopId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ExportBookingFlightReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.setBookingCode = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.clearBookingCode = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.hasBookingCode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 from_date = 2;
 * @return {number}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.getFromDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.setFromDate = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.clearFromDate = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 to_date = 3;
 * @return {number}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.getToDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.setToDate = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.clearToDate = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string passenger_name = 4;
 * @return {string}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.getPassengerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.setPassengerName = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.clearPassengerName = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.hasPassengerName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string contact_phone = 5;
 * @return {string}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.getContactPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.setContactPhone = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.clearContactPhone = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.hasContactPhone = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string owner_id = 6;
 * @return {string}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.setOwnerId = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.clearOwnerId = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.hasOwnerId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated airplane.BookingStatus statuses = 7;
 * @return {!Array<!proto.airplane.BookingStatus>}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.airplane.BookingStatus>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<!proto.airplane.BookingStatus>} value
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional string partnershop_id = 8;
 * @return {string}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.getPartnershopId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.setPartnershopId = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.clearPartnershopId = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.Filter.prototype.hasPartnershopId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partnership.ExportBookingFlightReq.Filter}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partnership.ExportBookingFlightReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.ExportBookingFlightReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partnership.ExportBookingFlightReq.Filter|undefined} value
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq} returns this
*/
proto.airplane.web_partnership.ExportBookingFlightReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SortItem sorts = 3;
 * @return {!Array<!proto.airplane.web_partnership.SortItem>}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.prototype.getSortsList = function() {
  return /** @type{!Array<!proto.airplane.web_partnership.SortItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partnership.SortItem, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partnership.SortItem>} value
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq} returns this
*/
proto.airplane.web_partnership.ExportBookingFlightReq.prototype.setSortsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partnership.SortItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.SortItem}
 */
proto.airplane.web_partnership.ExportBookingFlightReq.prototype.addSorts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partnership.SortItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightReq} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightReq.prototype.clearSortsList = function() {
  return this.setSortsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ExportBookingFlightRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ExportBookingFlightRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ExportBookingFlightRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ExportBookingFlightRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ExportBookingFlightRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightRes}
 */
proto.airplane.web_partnership.ExportBookingFlightRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ExportBookingFlightRes;
  return proto.airplane.web_partnership.ExportBookingFlightRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ExportBookingFlightRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightRes}
 */
proto.airplane.web_partnership.ExportBookingFlightRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.RetrieveBookingFlightRes.Data;
      reader.readMessage(value,proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ExportBookingFlightRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ExportBookingFlightRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ExportBookingFlightRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ExportBookingFlightRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partnership.RetrieveBookingFlightRes.Data.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.ExportBookingFlightRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.ExportBookingFlightRes} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.ExportBookingFlightRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ExportBookingFlightRes} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RetrieveBookingFlightRes.Data items = 3;
 * @return {!Array<!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data>}
 */
proto.airplane.web_partnership.ExportBookingFlightRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partnership.RetrieveBookingFlightRes.Data, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data>} value
 * @return {!proto.airplane.web_partnership.ExportBookingFlightRes} returns this
*/
proto.airplane.web_partnership.ExportBookingFlightRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.RetrieveBookingFlightRes.Data}
 */
proto.airplane.web_partnership.ExportBookingFlightRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partnership.RetrieveBookingFlightRes.Data, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ExportBookingFlightRes} returns this
 */
proto.airplane.web_partnership.ExportBookingFlightRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.RefundBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.RefundBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.RefundBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.RefundBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    note: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.RefundBookingReq}
 */
proto.airplane.web_partnership.RefundBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.RefundBookingReq;
  return proto.airplane.web_partnership.RefundBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.RefundBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.RefundBookingReq}
 */
proto.airplane.web_partnership.RefundBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.RefundBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.RefundBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.RefundBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.RefundBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.airplane.web_partnership.RefundBookingReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.RefundBookingReq} returns this
 */
proto.airplane.web_partnership.RefundBookingReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string note = 2;
 * @return {string}
 */
proto.airplane.web_partnership.RefundBookingReq.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.RefundBookingReq} returns this
 */
proto.airplane.web_partnership.RefundBookingReq.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.GetAirportsReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.GetAirportsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.GetAirportsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.GetAirportsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.GetAirportsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    codesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.GetAirportsReq}
 */
proto.airplane.web_partnership.GetAirportsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.GetAirportsReq;
  return proto.airplane.web_partnership.GetAirportsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.GetAirportsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.GetAirportsReq}
 */
proto.airplane.web_partnership.GetAirportsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCodes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.GetAirportsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.GetAirportsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.GetAirportsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.GetAirportsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCodesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string codes = 1;
 * @return {!Array<string>}
 */
proto.airplane.web_partnership.GetAirportsReq.prototype.getCodesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.airplane.web_partnership.GetAirportsReq} returns this
 */
proto.airplane.web_partnership.GetAirportsReq.prototype.setCodesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.GetAirportsReq} returns this
 */
proto.airplane.web_partnership.GetAirportsReq.prototype.addCodes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.GetAirportsReq} returns this
 */
proto.airplane.web_partnership.GetAirportsReq.prototype.clearCodesList = function() {
  return this.setCodesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.GetAirportsRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.GetAirportsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.GetAirportsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.GetAirportsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.GetAirportsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partnership.AirportInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.GetAirportsRes}
 */
proto.airplane.web_partnership.GetAirportsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.GetAirportsRes;
  return proto.airplane.web_partnership.GetAirportsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.GetAirportsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.GetAirportsRes}
 */
proto.airplane.web_partnership.GetAirportsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.AirportInfo;
      reader.readMessage(value,proto.airplane.web_partnership.AirportInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.GetAirportsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.GetAirportsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.GetAirportsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.GetAirportsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partnership.AirportInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.GetAirportsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.GetAirportsRes} returns this
 */
proto.airplane.web_partnership.GetAirportsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.GetAirportsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.GetAirportsRes} returns this
 */
proto.airplane.web_partnership.GetAirportsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated AirportInfo items = 3;
 * @return {!Array<!proto.airplane.web_partnership.AirportInfo>}
 */
proto.airplane.web_partnership.GetAirportsRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partnership.AirportInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partnership.AirportInfo, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partnership.AirportInfo>} value
 * @return {!proto.airplane.web_partnership.GetAirportsRes} returns this
*/
proto.airplane.web_partnership.GetAirportsRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partnership.AirportInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.AirportInfo}
 */
proto.airplane.web_partnership.GetAirportsRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partnership.AirportInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.GetAirportsRes} returns this
 */
proto.airplane.web_partnership.GetAirportsRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.AirportInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.AirportInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.AirportInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.AirportInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    country: jspb.Message.getFieldWithDefault(msg, 2, ""),
    countryCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    city: jspb.Message.getFieldWithDefault(msg, 4, ""),
    cityCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    code: jspb.Message.getFieldWithDefault(msg, 6, ""),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    lon: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    timezone: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.AirportInfo}
 */
proto.airplane.web_partnership.AirportInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.AirportInfo;
  return proto.airplane.web_partnership.AirportInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.AirportInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.AirportInfo}
 */
proto.airplane.web_partnership.AirportInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountryCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCityCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLon(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.AirportInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.AirportInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.AirportInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.AirportInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountryCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCityCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getLon();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.airplane.web_partnership.AirportInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.AirportInfo} returns this
 */
proto.airplane.web_partnership.AirportInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string country = 2;
 * @return {string}
 */
proto.airplane.web_partnership.AirportInfo.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.AirportInfo} returns this
 */
proto.airplane.web_partnership.AirportInfo.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country_code = 3;
 * @return {string}
 */
proto.airplane.web_partnership.AirportInfo.prototype.getCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.AirportInfo} returns this
 */
proto.airplane.web_partnership.AirportInfo.prototype.setCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string city = 4;
 * @return {string}
 */
proto.airplane.web_partnership.AirportInfo.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.AirportInfo} returns this
 */
proto.airplane.web_partnership.AirportInfo.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string city_code = 5;
 * @return {string}
 */
proto.airplane.web_partnership.AirportInfo.prototype.getCityCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.AirportInfo} returns this
 */
proto.airplane.web_partnership.AirportInfo.prototype.setCityCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string code = 6;
 * @return {string}
 */
proto.airplane.web_partnership.AirportInfo.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.AirportInfo} returns this
 */
proto.airplane.web_partnership.AirportInfo.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.airplane.web_partnership.AirportInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.AirportInfo} returns this
 */
proto.airplane.web_partnership.AirportInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional float lat = 8;
 * @return {number}
 */
proto.airplane.web_partnership.AirportInfo.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.AirportInfo} returns this
 */
proto.airplane.web_partnership.AirportInfo.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float lon = 9;
 * @return {number}
 */
proto.airplane.web_partnership.AirportInfo.prototype.getLon = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.AirportInfo} returns this
 */
proto.airplane.web_partnership.AirportInfo.prototype.setLon = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional string timezone = 10;
 * @return {string}
 */
proto.airplane.web_partnership.AirportInfo.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.AirportInfo} returns this
 */
proto.airplane.web_partnership.AirportInfo.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ListTransferBookingRequestReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ListTransferBookingRequestReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestReq}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ListTransferBookingRequestReq;
  return proto.airplane.web_partnership.ListTransferBookingRequestReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ListTransferBookingRequestReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestReq}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter;
      reader.readMessage(value,proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ListTransferBookingRequestReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ListTransferBookingRequestReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requesterName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    issuerName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    agentCode: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter;
  return proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesterName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuerName(value);
      break;
    case 4:
      var value = /** @type {!proto.airplane.TransferBookingRequestStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequesterName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIssuerName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getAgentCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter} returns this
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string requester_name = 2;
 * @return {string}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.prototype.getRequesterName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter} returns this
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.prototype.setRequesterName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string issuer_name = 3;
 * @return {string}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.prototype.getIssuerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter} returns this
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.prototype.setIssuerName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional airplane.TransferBookingRequestStatus status = 4;
 * @return {!proto.airplane.TransferBookingRequestStatus}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.prototype.getStatus = function() {
  return /** @type {!proto.airplane.TransferBookingRequestStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.airplane.TransferBookingRequestStatus} value
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter} returns this
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string agent_code = 5;
 * @return {string}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.prototype.getAgentCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter} returns this
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter.prototype.setAgentCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partnership.ListTransferBookingRequestReq.Filter|undefined} value
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestReq} returns this
*/
proto.airplane.web_partnership.ListTransferBookingRequestReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestReq} returns this
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestReq} returns this
*/
proto.airplane.web_partnership.ListTransferBookingRequestReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestReq} returns this
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListTransferBookingRequestReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ListTransferBookingRequestRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ListTransferBookingRequestRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_flight_pb.TransferBookingRequestItem.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestRes}
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ListTransferBookingRequestRes;
  return proto.airplane.web_partnership.ListTransferBookingRequestRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ListTransferBookingRequestRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestRes}
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_flight_pb.TransferBookingRequestItem;
      reader.readMessage(value,airplane_flight_pb.TransferBookingRequestItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ListTransferBookingRequestRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ListTransferBookingRequestRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      airplane_flight_pb.TransferBookingRequestItem.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestRes} returns this
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestRes} returns this
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated airplane.TransferBookingRequestItem items = 3;
 * @return {!Array<!proto.airplane.TransferBookingRequestItem>}
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.TransferBookingRequestItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_flight_pb.TransferBookingRequestItem, 3));
};


/**
 * @param {!Array<!proto.airplane.TransferBookingRequestItem>} value
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestRes} returns this
*/
proto.airplane.web_partnership.ListTransferBookingRequestRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.TransferBookingRequestItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.TransferBookingRequestItem}
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.TransferBookingRequestItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestRes} returns this
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestRes} returns this
*/
proto.airplane.web_partnership.ListTransferBookingRequestRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ListTransferBookingRequestRes} returns this
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListTransferBookingRequestRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * @enum {number}
 */
proto.airplane.web_partnership.SortItemType = {
  NONE: 0,
  CREATEDAT: 1
};

goog.object.extend(exports, proto.airplane.web_partnership);
