// source: base/enum.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.base.OrderSubType', null, global);
goog.exportSymbol('proto.base.PassengerType', null, global);
goog.exportSymbol('proto.base.PaymentMethod', null, global);
goog.exportSymbol('proto.base.SkyhubFlightProvider', null, global);
goog.exportSymbol('proto.base.SkyhubTicketStatus', null, global);
goog.exportSymbol('proto.base.TransactionStatus', null, global);
goog.exportSymbol('proto.base.TransactionType', null, global);
/**
 * @enum {number}
 */
proto.base.PaymentMethod = {
  METHODNONE: 0,
  PAYPAL: 1,
  VNPAYEWALLET: 2,
  VNPAYCREDITCARD: 3,
  VNPAYBANKTRANSFER: 4,
  VNPAYQRCODE: 5,
  MANUALBANKTRANSFER: 6,
  MOMO: 7,
  TWOCHECKOUT: 8,
  PAYOOCREDITCARD: 9,
  PAYOODOMESTICCARD: 10,
  PAYOOQRCODE: 11,
  ONEPAYCREDITCARD: 12,
  ONEPAYCREDITCARDDOMESTICISSUE: 13,
  ONEPAYDOMESTICCARD: 14,
  ONEPAYQRCODE: 15,
  APPLEPAY: 16,
  STRIPE: 17,
  BIDVQR: 18,
  EGIFTCARD: 19,
  WALLET: 20,
  NINEPAY: 21,
  NINEPAYCREDITCARD: 22,
  NINEPAYBANKTRANSFER: 23,
  NINEPAYATMCARD: 24,
  ONEFIN: 25
};

/**
 * @enum {number}
 */
proto.base.OrderSubType = {
  INVALID: 0,
  BUYGIFTCARD: 1,
  BUYBRANDCARD: 2,
  PHONETOPUPOTHER: 3,
  PHONETOPUPSELF: 4
};

/**
 * @enum {number}
 */
proto.base.TransactionType = {
  TRANSACTIONTYPEINVALID: 0,
  TOPUP: 1,
  PAY: 2,
  WITHDRAW: 3,
  REFUND: 4,
  TOPUPMANUAL: 5
};

/**
 * @enum {number}
 */
proto.base.TransactionStatus = {
  TRANSACTION_STATUS_INVALID: 0,
  PENDING: 1,
  PROCESSING: 2,
  SUCCESSFUL: 3,
  FAILED: 4,
  CANCELED: 5
};

/**
 * @enum {number}
 */
proto.base.SkyhubFlightProvider = {
  SKYHUB_FLIGHT_PROVIDER_INVALID: 0,
  AMADEUS: 1,
  TRAVELFUSION: 2,
  VIETNAMAIRLINES: 3,
  VIETJETAIR: 4,
  EV: 5,
  EVINTERNATIONAL: 6
};

/**
 * @enum {number}
 */
proto.base.PassengerType = {
  PASSENGER_TYPE_NONE: 0,
  ADT: 1,
  CHD: 2,
  INF: 3
};

/**
 * @enum {number}
 */
proto.base.SkyhubTicketStatus = {
  SKYHUB_TICKET_STATUS_NONE: 0,
  OK: 1,
  PENDING: 2,
  VOIDED: 3,
  UNKNOWN: 4,
  FAILED: 5
};

goog.object.extend(exports, proto.base);
