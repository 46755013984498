// source: airplane/web_partnership/customer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.airplane.web_partnership.SearchCustomerReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.SearchCustomerReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partnership.SearchCustomerRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.SearchCustomerRes.Customer', null, global);
goog.exportSymbol('proto.airplane.web_partnership.SearchCustomerRes.Data', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.SearchCustomerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.SearchCustomerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.SearchCustomerReq.displayName = 'proto.airplane.web_partnership.SearchCustomerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.SearchCustomerReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.SearchCustomerReq.Filter.displayName = 'proto.airplane.web_partnership.SearchCustomerReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.SearchCustomerRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.SearchCustomerRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.SearchCustomerRes.displayName = 'proto.airplane.web_partnership.SearchCustomerRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.SearchCustomerRes.Customer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.SearchCustomerRes.Customer.displayName = 'proto.airplane.web_partnership.SearchCustomerRes.Customer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.SearchCustomerRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.SearchCustomerRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.SearchCustomerRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.SearchCustomerRes.Data.displayName = 'proto.airplane.web_partnership.SearchCustomerRes.Data';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.SearchCustomerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.SearchCustomerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.SearchCustomerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchCustomerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partnership.SearchCustomerReq.Filter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.SearchCustomerReq}
 */
proto.airplane.web_partnership.SearchCustomerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.SearchCustomerReq;
  return proto.airplane.web_partnership.SearchCustomerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.SearchCustomerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.SearchCustomerReq}
 */
proto.airplane.web_partnership.SearchCustomerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partnership.SearchCustomerReq.Filter;
      reader.readMessage(value,proto.airplane.web_partnership.SearchCustomerReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.SearchCustomerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.SearchCustomerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.SearchCustomerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchCustomerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partnership.SearchCustomerReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.SearchCustomerReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.SearchCustomerReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.SearchCustomerReq.Filter}
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.SearchCustomerReq.Filter;
  return proto.airplane.web_partnership.SearchCustomerReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.SearchCustomerReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.SearchCustomerReq.Filter}
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.SearchCustomerReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.SearchCustomerReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerReq.Filter} returns this
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.prototype.setName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.SearchCustomerReq.Filter} returns this
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.prototype.clearName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerReq.Filter} returns this
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.prototype.setPhone = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.SearchCustomerReq.Filter} returns this
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.prototype.clearPhone = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.prototype.hasPhone = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerReq.Filter} returns this
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.prototype.setEmail = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.web_partnership.SearchCustomerReq.Filter} returns this
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.prototype.clearEmail = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.SearchCustomerReq.Filter.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partnership.SearchCustomerReq.Filter}
 */
proto.airplane.web_partnership.SearchCustomerReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partnership.SearchCustomerReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.SearchCustomerReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partnership.SearchCustomerReq.Filter|undefined} value
 * @return {!proto.airplane.web_partnership.SearchCustomerReq} returns this
*/
proto.airplane.web_partnership.SearchCustomerReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.SearchCustomerReq} returns this
 */
proto.airplane.web_partnership.SearchCustomerReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.SearchCustomerReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partnership.SearchCustomerReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partnership.SearchCustomerReq} returns this
*/
proto.airplane.web_partnership.SearchCustomerReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.SearchCustomerReq} returns this
 */
proto.airplane.web_partnership.SearchCustomerReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.SearchCustomerReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.SearchCustomerRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.SearchCustomerRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.SearchCustomerRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchCustomerRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partnership.SearchCustomerRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.SearchCustomerRes}
 */
proto.airplane.web_partnership.SearchCustomerRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.SearchCustomerRes;
  return proto.airplane.web_partnership.SearchCustomerRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.SearchCustomerRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.SearchCustomerRes}
 */
proto.airplane.web_partnership.SearchCustomerRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.SearchCustomerRes.Data;
      reader.readMessage(value,proto.airplane.web_partnership.SearchCustomerRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.SearchCustomerRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.SearchCustomerRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.SearchCustomerRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchCustomerRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partnership.SearchCustomerRes.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.SearchCustomerRes.Customer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.SearchCustomerRes.Customer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    birthday: jspb.Message.getFieldWithDefault(msg, 8, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 9, 0),
    profilePicture: jspb.Message.getFieldWithDefault(msg, 10, ""),
    address: jspb.Message.getFieldWithDefault(msg, 11, ""),
    city: jspb.Message.getFieldWithDefault(msg, 12, ""),
    country: jspb.Message.getFieldWithDefault(msg, 13, ""),
    lastLoginAt: jspb.Message.getFieldWithDefault(msg, 14, 0),
    totalBooking: jspb.Message.getFieldWithDefault(msg, 15, 0),
    totalExpense: jspb.Message.getFloatingPointFieldWithDefault(msg, 16, 0.0),
    firstName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.SearchCustomerRes.Customer;
  return proto.airplane.web_partnership.SearchCustomerRes.Customer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.SearchCustomerRes.Customer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBirthday(value);
      break;
    case 9:
      var value = /** @type {!proto.base.GENDER} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicture(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastLoginAt(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalBooking(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalExpense(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.SearchCustomerRes.Customer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.SearchCustomerRes.Customer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBirthday();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getProfilePicture();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getLastLoginAt();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getTotalBooking();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getTotalExpense();
  if (f !== 0.0) {
    writer.writeDouble(
      16,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 updated_at = 3;
 * @return {number}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone_number = 6;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string phone_code = 7;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 birthday = 8;
 * @return {number}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getBirthday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional base.GENDER gender = 9;
 * @return {!proto.base.GENDER}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getGender = function() {
  return /** @type {!proto.base.GENDER} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.base.GENDER} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string profile_picture = 10;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getProfilePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setProfilePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string address = 11;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string city = 12;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string country = 13;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 last_login_at = 14;
 * @return {number}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getLastLoginAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setLastLoginAt = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 total_booking = 15;
 * @return {number}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getTotalBooking = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setTotalBooking = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional double total_expense = 16;
 * @return {number}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getTotalExpense = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 16, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setTotalExpense = function(value) {
  return jspb.Message.setProto3FloatField(this, 16, value);
};


/**
 * optional string first_name = 17;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string last_name = 18;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Customer.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.SearchCustomerRes.Data.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.SearchCustomerRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.SearchCustomerRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.SearchCustomerRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchCustomerRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partnership.SearchCustomerRes.Customer.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Data}
 */
proto.airplane.web_partnership.SearchCustomerRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.SearchCustomerRes.Data;
  return proto.airplane.web_partnership.SearchCustomerRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.SearchCustomerRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Data}
 */
proto.airplane.web_partnership.SearchCustomerRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partnership.SearchCustomerRes.Customer;
      reader.readMessage(value,proto.airplane.web_partnership.SearchCustomerRes.Customer.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.SearchCustomerRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.SearchCustomerRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.SearchCustomerRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SearchCustomerRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web_partnership.SearchCustomerRes.Customer.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Customer items = 1;
 * @return {!Array<!proto.airplane.web_partnership.SearchCustomerRes.Customer>}
 */
proto.airplane.web_partnership.SearchCustomerRes.Data.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partnership.SearchCustomerRes.Customer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partnership.SearchCustomerRes.Customer, 1));
};


/**
 * @param {!Array<!proto.airplane.web_partnership.SearchCustomerRes.Customer>} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Data} returns this
*/
proto.airplane.web_partnership.SearchCustomerRes.Data.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web_partnership.SearchCustomerRes.Customer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Customer}
 */
proto.airplane.web_partnership.SearchCustomerRes.Data.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web_partnership.SearchCustomerRes.Customer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Data} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Data.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partnership.SearchCustomerRes.Data.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Data} returns this
*/
proto.airplane.web_partnership.SearchCustomerRes.Data.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.SearchCustomerRes.Data} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.Data.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.SearchCustomerRes.Data.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.SearchCustomerRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.SearchCustomerRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partnership.SearchCustomerRes.Data}
 */
proto.airplane.web_partnership.SearchCustomerRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partnership.SearchCustomerRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.SearchCustomerRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partnership.SearchCustomerRes.Data|undefined} value
 * @return {!proto.airplane.web_partnership.SearchCustomerRes} returns this
*/
proto.airplane.web_partnership.SearchCustomerRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.SearchCustomerRes} returns this
 */
proto.airplane.web_partnership.SearchCustomerRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.SearchCustomerRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.airplane.web_partnership);
