import {
  ReportCommissionStatsRes,
  CrossCheckStatsRes,
  CrossCheckStatsReq,
  ReportCommissionReq,
  CommissionItem,
  CommissionStatus,
  CrossCheckItem,
  ReportCrossCheckReq,
  ReferenceStatsRes,
  ReferenceItem,
  ReferenceStatus,
} from '@api/airplane/web_partnership/report_pb';

import { AirplaneReport } from '@api/airplane/report_pb';

import {
  ExportReportBookingReq,
  ExportReportBookingRes,
} from '@api/skyhub/web_partnership/report_pb';

import { SkyHubReport } from '@api/skyhub/report_pb';
export interface IReportCommissionStatsData
  extends ReportCommissionStatsRes.Data.AsObject {}
export interface ICrossCheckStatsData
  extends CrossCheckStatsRes.Data.AsObject {}

export interface IReferenceStatsData extends ReferenceStatsRes.Data.AsObject {}

export interface IFilterCommissionReq
  extends ReportCommissionReq.Filter.AsObject {}

export type ECommissionStatus = CommissionStatus;
export const ECommissionStatus = { ...CommissionStatus } as const;
export type EReferenceStatus = ReferenceStatus;
export const EReferenceStatus = { ...ReferenceStatus } as const;

export interface ICommissionItem extends CommissionItem.AsObject {}
export interface ICrossCheckItem extends CrossCheckItem.AsObject {}
export interface IReferenceItem extends ReferenceItem.AsObject {}
export interface ICrossCheckStatsReq extends CrossCheckStatsReq.AsObject {}
export interface IFilterReportCrossCheckReq
  extends ReportCrossCheckReq.Filter.AsObject {}

export interface IExportReportBookingReq
  extends ExportReportBookingReq.AsObject {}

export interface ISkyHubReport extends SkyHubReport.AsObject {}
export interface IAirplaneReport extends AirplaneReport.AsObject {}
