import { BasicRes, PaginationReq } from '@api/base/base_pb';
import { PartnerUserServiceClient } from '@api/partner/web_partnership/partner_user_grpc_web_pb';

import {
  ChangePasswordReq,
  CreatePartnerUserRes,
  CreatePartnerUserWithRolesReq,
  ListPartnerUserBasicReq,
  ListPartnerUserBasicRes,
  RetrievePartnerUserReq,
  RetrievePartnerUserRes,
  UpdateUserReq,
} from '@api/partner/web_partnership/partner_user_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class PartnerShopUserApi extends BaseApi<PartnerUserServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new PartnerUserServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  listPartnerUserBasicInfo = ({
    filter,
    pagination,
  }: ListPartnerUserBasicReq.AsObject): Promise<ListPartnerUserBasicRes.AsObject> => {
    const req = new ListPartnerUserBasicReq();

    if (filter) {
      const filterReq = new ListPartnerUserBasicReq.Filter();
      filter?.name && filterReq.setName(filter.name);
      filter?.partnershopId && filterReq.setPartnershopId(filter.partnershopId);
      filter?.status && filterReq.setStatus(filter.status);
      filter?.isOwner && filterReq.setIsOwner(filter.isOwner);
      filter?.creatorId && filterReq.setCreatorId(filter.creatorId);
      filter?.role && filterReq.setRole(filter.role);
      filter?.userName && filterReq.setUserName(filter.userName);
      req.setFilter(filterReq);
    }

    if (pagination) {
      const paginationObj = new PaginationReq();
      paginationObj.setPageLimit(pagination.pageLimit);
      paginationObj.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationObj);
    }

    return this.grpc<
      ListPartnerUserBasicReq,
      ListPartnerUserBasicRes,
      ListPartnerUserBasicRes.AsObject
    >(this.serviceClient.listPartnerUserBasicInfo, req, {});
  };

  createPartnerUserWithRoles = ({
    phone,
    password,
    name,
    userName,
    email,
    status,
    rolesList,
  }: CreatePartnerUserWithRolesReq.AsObject): Promise<CreatePartnerUserRes.AsObject> => {
    const req = new CreatePartnerUserWithRolesReq();

    req.setPhone(phone);
    req.setPassword(password);
    req.setName(name);
    req.setUserName(userName);
    req.setEmail(email);
    req.setStatus(status);
    req.setRolesList(rolesList);

    return this.grpc<
      CreatePartnerUserWithRolesReq,
      CreatePartnerUserRes,
      CreatePartnerUserRes.AsObject
    >(this.serviceClient.createPartnerUserWithRoles, req, {});
  };

  updateUser = ({
    id,
    status,
    email,
    name,
    password,
    phone,
  }: UpdateUserReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new UpdateUserReq();

    req.setStatus(status);
    req.setId(id);
    req.setName(name || '');
    req.setPassword(password || '');
    req.setPhone(phone || '');
    req.setEmail(email || '');

    return this.grpc<UpdateUserReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.updateUser,
      req,
      {}
    );
  };

  retrievePartnerUser = ({
    id,
  }: RetrievePartnerUserReq.AsObject): Promise<RetrievePartnerUserRes.AsObject> => {
    const req = new RetrievePartnerUserReq();

    req.setId(id);

    return this.grpc<
      RetrievePartnerUserReq,
      RetrievePartnerUserRes,
      RetrievePartnerUserRes.AsObject
    >(this.serviceClient.retrievePartnerUser, req, {});
  };
}

export const partnerShopUserServiceClient = new PartnerShopUserApi();
export default partnerShopUserServiceClient;
