// source: airplane/web_partnership/report.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var airplane_base_pb = require('../../airplane/base_pb.js');
goog.object.extend(proto, airplane_base_pb);
var airplane_report_pb = require('../../airplane/report_pb.js');
goog.object.extend(proto, airplane_report_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.airplane.web_partnership.AggregateReportAirlineBookingReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.AggregateReportBookingReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.CommissionItem', null, global);
goog.exportSymbol('proto.airplane.web_partnership.CommissionStatus', null, global);
goog.exportSymbol('proto.airplane.web_partnership.CrossCheckItem', null, global);
goog.exportSymbol('proto.airplane.web_partnership.CrossCheckStatsReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.CrossCheckStatsRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.CrossCheckStatsRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partnership.CurrentBookingStatsRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.CurrentBookingStatsRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partnership.DownloadReportCommissionReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.DownloadReportCommissionRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.DownloadReportCrossCheckReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.DownloadReportCrossCheckRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ExportReportBookingReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ExportReportBookingReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ExportReportBookingRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReferenceItem', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReferenceListReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReferenceListRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReferenceListRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReferenceStatsRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReferenceStatsRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReferenceStatus', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportAirlineBookingReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportAirlineBookingRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportBookingReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportBookingRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportCommissionReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportCommissionReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportCommissionRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportCommissionRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportCommissionStatsReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportCommissionStatsRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportCommissionStatsRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportCrossCheckReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportCrossCheckReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportCrossCheckRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ReportCrossCheckRes.Data', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReportBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportBookingReq.displayName = 'proto.airplane.web_partnership.ReportBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportBookingRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ReportBookingRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ReportBookingRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportBookingRes.displayName = 'proto.airplane.web_partnership.ReportBookingRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportAirlineBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReportAirlineBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportAirlineBookingReq.displayName = 'proto.airplane.web_partnership.ReportAirlineBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportAirlineBookingRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ReportAirlineBookingRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ReportAirlineBookingRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportAirlineBookingRes.displayName = 'proto.airplane.web_partnership.ReportAirlineBookingRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.AggregateReportBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.AggregateReportBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.AggregateReportBookingReq.displayName = 'proto.airplane.web_partnership.AggregateReportBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.AggregateReportAirlineBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.AggregateReportAirlineBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.AggregateReportAirlineBookingReq.displayName = 'proto.airplane.web_partnership.AggregateReportAirlineBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.CurrentBookingStatsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.CurrentBookingStatsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.CurrentBookingStatsRes.displayName = 'proto.airplane.web_partnership.CurrentBookingStatsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.CurrentBookingStatsRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.CurrentBookingStatsRes.Data.displayName = 'proto.airplane.web_partnership.CurrentBookingStatsRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportCommissionStatsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReportCommissionStatsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportCommissionStatsReq.displayName = 'proto.airplane.web_partnership.ReportCommissionStatsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportCommissionStatsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReportCommissionStatsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportCommissionStatsRes.displayName = 'proto.airplane.web_partnership.ReportCommissionStatsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReportCommissionStatsRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportCommissionStatsRes.Data.displayName = 'proto.airplane.web_partnership.ReportCommissionStatsRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportCommissionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReportCommissionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportCommissionReq.displayName = 'proto.airplane.web_partnership.ReportCommissionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReportCommissionReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportCommissionReq.Filter.displayName = 'proto.airplane.web_partnership.ReportCommissionReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.CommissionItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.CommissionItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.CommissionItem.displayName = 'proto.airplane.web_partnership.CommissionItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportCommissionRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReportCommissionRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportCommissionRes.displayName = 'proto.airplane.web_partnership.ReportCommissionRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportCommissionRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ReportCommissionRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ReportCommissionRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportCommissionRes.Data.displayName = 'proto.airplane.web_partnership.ReportCommissionRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.DownloadReportCommissionReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.DownloadReportCommissionReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.DownloadReportCommissionReq.displayName = 'proto.airplane.web_partnership.DownloadReportCommissionReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.DownloadReportCommissionRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.DownloadReportCommissionRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.DownloadReportCommissionRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.DownloadReportCommissionRes.displayName = 'proto.airplane.web_partnership.DownloadReportCommissionRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.CrossCheckStatsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.CrossCheckStatsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.CrossCheckStatsReq.displayName = 'proto.airplane.web_partnership.CrossCheckStatsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.CrossCheckStatsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.CrossCheckStatsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.CrossCheckStatsRes.displayName = 'proto.airplane.web_partnership.CrossCheckStatsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.CrossCheckStatsRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.CrossCheckStatsRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.CrossCheckStatsRes.Data.displayName = 'proto.airplane.web_partnership.CrossCheckStatsRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportCrossCheckReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReportCrossCheckReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportCrossCheckReq.displayName = 'proto.airplane.web_partnership.ReportCrossCheckReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportCrossCheckReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReportCrossCheckReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportCrossCheckReq.Filter.displayName = 'proto.airplane.web_partnership.ReportCrossCheckReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.CrossCheckItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.CrossCheckItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.CrossCheckItem.displayName = 'proto.airplane.web_partnership.CrossCheckItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportCrossCheckRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReportCrossCheckRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportCrossCheckRes.displayName = 'proto.airplane.web_partnership.ReportCrossCheckRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReportCrossCheckRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ReportCrossCheckRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ReportCrossCheckRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReportCrossCheckRes.Data.displayName = 'proto.airplane.web_partnership.ReportCrossCheckRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.DownloadReportCrossCheckReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.DownloadReportCrossCheckReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.DownloadReportCrossCheckReq.displayName = 'proto.airplane.web_partnership.DownloadReportCrossCheckReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.DownloadReportCrossCheckRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.DownloadReportCrossCheckRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.DownloadReportCrossCheckRes.displayName = 'proto.airplane.web_partnership.DownloadReportCrossCheckRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReferenceStatsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReferenceStatsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReferenceStatsRes.displayName = 'proto.airplane.web_partnership.ReferenceStatsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReferenceStatsRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReferenceStatsRes.Data.displayName = 'proto.airplane.web_partnership.ReferenceStatsRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReferenceListReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReferenceListReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReferenceListReq.displayName = 'proto.airplane.web_partnership.ReferenceListReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReferenceItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReferenceItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReferenceItem.displayName = 'proto.airplane.web_partnership.ReferenceItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReferenceListRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ReferenceListRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReferenceListRes.displayName = 'proto.airplane.web_partnership.ReferenceListRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ReferenceListRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ReferenceListRes.Data.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ReferenceListRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ReferenceListRes.Data.displayName = 'proto.airplane.web_partnership.ReferenceListRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ExportReportBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ExportReportBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ExportReportBookingReq.displayName = 'proto.airplane.web_partnership.ExportReportBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ExportReportBookingReq.Filter.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ExportReportBookingReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ExportReportBookingReq.Filter.displayName = 'proto.airplane.web_partnership.ExportReportBookingReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ExportReportBookingRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ExportReportBookingRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ExportReportBookingRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ExportReportBookingRes.displayName = 'proto.airplane.web_partnership.ExportReportBookingRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportBookingReq}
 */
proto.airplane.web_partnership.ReportBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportBookingReq;
  return proto.airplane.web_partnership.ReportBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportBookingReq}
 */
proto.airplane.web_partnership.ReportBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 from = 1;
 * @return {number}
 */
proto.airplane.web_partnership.ReportBookingReq.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReportBookingReq} returns this
 */
proto.airplane.web_partnership.ReportBookingReq.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 to = 2;
 * @return {number}
 */
proto.airplane.web_partnership.ReportBookingReq.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReportBookingReq} returns this
 */
proto.airplane.web_partnership.ReportBookingReq.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ReportBookingRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportBookingRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportBookingRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportBookingRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportBookingRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_report_pb.ReportBookingItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportBookingRes}
 */
proto.airplane.web_partnership.ReportBookingRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportBookingRes;
  return proto.airplane.web_partnership.ReportBookingRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportBookingRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportBookingRes}
 */
proto.airplane.web_partnership.ReportBookingRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_report_pb.ReportBookingItem;
      reader.readMessage(value,airplane_report_pb.ReportBookingItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportBookingRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportBookingRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportBookingRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportBookingRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      airplane_report_pb.ReportBookingItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.ReportBookingRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.ReportBookingRes} returns this
 */
proto.airplane.web_partnership.ReportBookingRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.ReportBookingRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ReportBookingRes} returns this
 */
proto.airplane.web_partnership.ReportBookingRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated airplane.ReportBookingItem items = 3;
 * @return {!Array<!proto.airplane.ReportBookingItem>}
 */
proto.airplane.web_partnership.ReportBookingRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.ReportBookingItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_report_pb.ReportBookingItem, 3));
};


/**
 * @param {!Array<!proto.airplane.ReportBookingItem>} value
 * @return {!proto.airplane.web_partnership.ReportBookingRes} returns this
*/
proto.airplane.web_partnership.ReportBookingRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.ReportBookingItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.ReportBookingItem}
 */
proto.airplane.web_partnership.ReportBookingRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.ReportBookingItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ReportBookingRes} returns this
 */
proto.airplane.web_partnership.ReportBookingRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportAirlineBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportAirlineBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportAirlineBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportAirlineBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportAirlineBookingReq}
 */
proto.airplane.web_partnership.ReportAirlineBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportAirlineBookingReq;
  return proto.airplane.web_partnership.ReportAirlineBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportAirlineBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportAirlineBookingReq}
 */
proto.airplane.web_partnership.ReportAirlineBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportAirlineBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportAirlineBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportAirlineBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportAirlineBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 from = 1;
 * @return {number}
 */
proto.airplane.web_partnership.ReportAirlineBookingReq.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReportAirlineBookingReq} returns this
 */
proto.airplane.web_partnership.ReportAirlineBookingReq.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 to = 2;
 * @return {number}
 */
proto.airplane.web_partnership.ReportAirlineBookingReq.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReportAirlineBookingReq} returns this
 */
proto.airplane.web_partnership.ReportAirlineBookingReq.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ReportAirlineBookingRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportAirlineBookingRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportAirlineBookingRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportAirlineBookingRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportAirlineBookingRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_report_pb.ReportAirlineBookingItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportAirlineBookingRes}
 */
proto.airplane.web_partnership.ReportAirlineBookingRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportAirlineBookingRes;
  return proto.airplane.web_partnership.ReportAirlineBookingRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportAirlineBookingRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportAirlineBookingRes}
 */
proto.airplane.web_partnership.ReportAirlineBookingRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_report_pb.ReportAirlineBookingItem;
      reader.readMessage(value,airplane_report_pb.ReportAirlineBookingItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportAirlineBookingRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportAirlineBookingRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportAirlineBookingRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportAirlineBookingRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      airplane_report_pb.ReportAirlineBookingItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.ReportAirlineBookingRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.ReportAirlineBookingRes} returns this
 */
proto.airplane.web_partnership.ReportAirlineBookingRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.ReportAirlineBookingRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ReportAirlineBookingRes} returns this
 */
proto.airplane.web_partnership.ReportAirlineBookingRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated airplane.ReportAirlineBookingItem items = 3;
 * @return {!Array<!proto.airplane.ReportAirlineBookingItem>}
 */
proto.airplane.web_partnership.ReportAirlineBookingRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.ReportAirlineBookingItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_report_pb.ReportAirlineBookingItem, 3));
};


/**
 * @param {!Array<!proto.airplane.ReportAirlineBookingItem>} value
 * @return {!proto.airplane.web_partnership.ReportAirlineBookingRes} returns this
*/
proto.airplane.web_partnership.ReportAirlineBookingRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.ReportAirlineBookingItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.ReportAirlineBookingItem}
 */
proto.airplane.web_partnership.ReportAirlineBookingRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.ReportAirlineBookingItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ReportAirlineBookingRes} returns this
 */
proto.airplane.web_partnership.ReportAirlineBookingRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.AggregateReportBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.AggregateReportBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.AggregateReportBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.AggregateReportBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.AggregateReportBookingReq}
 */
proto.airplane.web_partnership.AggregateReportBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.AggregateReportBookingReq;
  return proto.airplane.web_partnership.AggregateReportBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.AggregateReportBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.AggregateReportBookingReq}
 */
proto.airplane.web_partnership.AggregateReportBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.AggregateReportBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.AggregateReportBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.AggregateReportBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.AggregateReportBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 from = 1;
 * @return {number}
 */
proto.airplane.web_partnership.AggregateReportBookingReq.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.AggregateReportBookingReq} returns this
 */
proto.airplane.web_partnership.AggregateReportBookingReq.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 to = 2;
 * @return {number}
 */
proto.airplane.web_partnership.AggregateReportBookingReq.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.AggregateReportBookingReq} returns this
 */
proto.airplane.web_partnership.AggregateReportBookingReq.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.AggregateReportAirlineBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.AggregateReportAirlineBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.AggregateReportAirlineBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.AggregateReportAirlineBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.AggregateReportAirlineBookingReq}
 */
proto.airplane.web_partnership.AggregateReportAirlineBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.AggregateReportAirlineBookingReq;
  return proto.airplane.web_partnership.AggregateReportAirlineBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.AggregateReportAirlineBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.AggregateReportAirlineBookingReq}
 */
proto.airplane.web_partnership.AggregateReportAirlineBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.AggregateReportAirlineBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.AggregateReportAirlineBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.AggregateReportAirlineBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.AggregateReportAirlineBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional uint64 from = 1;
 * @return {number}
 */
proto.airplane.web_partnership.AggregateReportAirlineBookingReq.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.AggregateReportAirlineBookingReq} returns this
 */
proto.airplane.web_partnership.AggregateReportAirlineBookingReq.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 to = 2;
 * @return {number}
 */
proto.airplane.web_partnership.AggregateReportAirlineBookingReq.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.AggregateReportAirlineBookingReq} returns this
 */
proto.airplane.web_partnership.AggregateReportAirlineBookingReq.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.CurrentBookingStatsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.CurrentBookingStatsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partnership.CurrentBookingStatsRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.CurrentBookingStatsRes}
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.CurrentBookingStatsRes;
  return proto.airplane.web_partnership.CurrentBookingStatsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.CurrentBookingStatsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.CurrentBookingStatsRes}
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.CurrentBookingStatsRes.Data;
      reader.readMessage(value,proto.airplane.web_partnership.CurrentBookingStatsRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.CurrentBookingStatsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.CurrentBookingStatsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partnership.CurrentBookingStatsRes.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.CurrentBookingStatsRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.CurrentBookingStatsRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    countInprogress: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.CurrentBookingStatsRes.Data}
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.CurrentBookingStatsRes.Data;
  return proto.airplane.web_partnership.CurrentBookingStatsRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.CurrentBookingStatsRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.CurrentBookingStatsRes.Data}
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCountInprogress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.CurrentBookingStatsRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.CurrentBookingStatsRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountInprogress();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 count_inprogress = 1;
 * @return {number}
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.Data.prototype.getCountInprogress = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CurrentBookingStatsRes.Data} returns this
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.Data.prototype.setCountInprogress = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.CurrentBookingStatsRes} returns this
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.CurrentBookingStatsRes} returns this
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partnership.CurrentBookingStatsRes.Data}
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partnership.CurrentBookingStatsRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.CurrentBookingStatsRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partnership.CurrentBookingStatsRes.Data|undefined} value
 * @return {!proto.airplane.web_partnership.CurrentBookingStatsRes} returns this
*/
proto.airplane.web_partnership.CurrentBookingStatsRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.CurrentBookingStatsRes} returns this
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.CurrentBookingStatsRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportCommissionStatsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportCommissionStatsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportCommissionStatsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCommissionStatsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsReq}
 */
proto.airplane.web_partnership.ReportCommissionStatsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportCommissionStatsReq;
  return proto.airplane.web_partnership.ReportCommissionStatsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportCommissionStatsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsReq}
 */
proto.airplane.web_partnership.ReportCommissionStatsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 4:
      var value = /** @type {!proto.airplane.web_partnership.CommissionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportCommissionStatsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportCommissionStatsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportCommissionStatsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCommissionStatsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional uint64 from = 1;
 * @return {number}
 */
proto.airplane.web_partnership.ReportCommissionStatsReq.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsReq} returns this
 */
proto.airplane.web_partnership.ReportCommissionStatsReq.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 to = 2;
 * @return {number}
 */
proto.airplane.web_partnership.ReportCommissionStatsReq.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsReq} returns this
 */
proto.airplane.web_partnership.ReportCommissionStatsReq.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string booking_code = 3;
 * @return {string}
 */
proto.airplane.web_partnership.ReportCommissionStatsReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsReq} returns this
 */
proto.airplane.web_partnership.ReportCommissionStatsReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CommissionStatus status = 4;
 * @return {!proto.airplane.web_partnership.CommissionStatus}
 */
proto.airplane.web_partnership.ReportCommissionStatsReq.prototype.getStatus = function() {
  return /** @type {!proto.airplane.web_partnership.CommissionStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.airplane.web_partnership.CommissionStatus} value
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsReq} returns this
 */
proto.airplane.web_partnership.ReportCommissionStatsReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportCommissionStatsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportCommissionStatsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partnership.ReportCommissionStatsRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsRes}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportCommissionStatsRes;
  return proto.airplane.web_partnership.ReportCommissionStatsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportCommissionStatsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsRes}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.ReportCommissionStatsRes.Data;
      reader.readMessage(value,proto.airplane.web_partnership.ReportCommissionStatsRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportCommissionStatsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportCommissionStatsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partnership.ReportCommissionStatsRes.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportCommissionStatsRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportCommissionStatsRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalCommission: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    serviceFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    profit: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    approved: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsRes.Data}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportCommissionStatsRes.Data;
  return proto.airplane.web_partnership.ReportCommissionStatsRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportCommissionStatsRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsRes.Data}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalCommission(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setServiceFee(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setApproved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportCommissionStatsRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportCommissionStatsRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalCommission();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getServiceFee();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getApproved();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional double total_commission = 1;
 * @return {number}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data.prototype.getTotalCommission = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsRes.Data} returns this
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data.prototype.setTotalCommission = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double service_fee = 2;
 * @return {number}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data.prototype.getServiceFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsRes.Data} returns this
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data.prototype.setServiceFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double profit = 3;
 * @return {number}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data.prototype.getProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsRes.Data} returns this
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data.prototype.setProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double approved = 4;
 * @return {number}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data.prototype.getApproved = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsRes.Data} returns this
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.Data.prototype.setApproved = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsRes} returns this
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsRes} returns this
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partnership.ReportCommissionStatsRes.Data}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partnership.ReportCommissionStatsRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.ReportCommissionStatsRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partnership.ReportCommissionStatsRes.Data|undefined} value
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsRes} returns this
*/
proto.airplane.web_partnership.ReportCommissionStatsRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ReportCommissionStatsRes} returns this
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ReportCommissionStatsRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportCommissionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportCommissionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportCommissionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCommissionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partnership.ReportCommissionReq.Filter.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportCommissionReq}
 */
proto.airplane.web_partnership.ReportCommissionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportCommissionReq;
  return proto.airplane.web_partnership.ReportCommissionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportCommissionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportCommissionReq}
 */
proto.airplane.web_partnership.ReportCommissionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partnership.ReportCommissionReq.Filter;
      reader.readMessage(value,proto.airplane.web_partnership.ReportCommissionReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportCommissionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportCommissionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportCommissionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCommissionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partnership.ReportCommissionReq.Filter.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportCommissionReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportCommissionReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportCommissionReq.Filter}
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportCommissionReq.Filter;
  return proto.airplane.web_partnership.ReportCommissionReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportCommissionReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportCommissionReq.Filter}
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 4:
      var value = /** @type {!proto.airplane.web_partnership.CommissionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportCommissionReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportCommissionReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional uint64 from = 1;
 * @return {number}
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReportCommissionReq.Filter} returns this
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 to = 2;
 * @return {number}
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReportCommissionReq.Filter} returns this
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string booking_code = 3;
 * @return {string}
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ReportCommissionReq.Filter} returns this
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CommissionStatus status = 4;
 * @return {!proto.airplane.web_partnership.CommissionStatus}
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter.prototype.getStatus = function() {
  return /** @type {!proto.airplane.web_partnership.CommissionStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.airplane.web_partnership.CommissionStatus} value
 * @return {!proto.airplane.web_partnership.ReportCommissionReq.Filter} returns this
 */
proto.airplane.web_partnership.ReportCommissionReq.Filter.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partnership.ReportCommissionReq.Filter}
 */
proto.airplane.web_partnership.ReportCommissionReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partnership.ReportCommissionReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.ReportCommissionReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partnership.ReportCommissionReq.Filter|undefined} value
 * @return {!proto.airplane.web_partnership.ReportCommissionReq} returns this
*/
proto.airplane.web_partnership.ReportCommissionReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ReportCommissionReq} returns this
 */
proto.airplane.web_partnership.ReportCommissionReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ReportCommissionReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partnership.ReportCommissionReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partnership.ReportCommissionReq} returns this
*/
proto.airplane.web_partnership.ReportCommissionReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ReportCommissionReq} returns this
 */
proto.airplane.web_partnership.ReportCommissionReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ReportCommissionReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.CommissionItem.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.CommissionItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.CommissionItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CommissionItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    numberTickets: jspb.Message.getFieldWithDefault(msg, 3, 0),
    totalMoney: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    commission: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    status: jspb.Message.getFieldWithDefault(msg, 6, 0),
    serviceFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    profit: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.CommissionItem}
 */
proto.airplane.web_partnership.CommissionItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.CommissionItem;
  return proto.airplane.web_partnership.CommissionItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.CommissionItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.CommissionItem}
 */
proto.airplane.web_partnership.CommissionItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNumberTickets(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalMoney(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setCommission(value);
      break;
    case 6:
      var value = /** @type {!proto.airplane.web_partnership.CommissionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setServiceFee(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.CommissionItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.CommissionItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.CommissionItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CommissionItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNumberTickets();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getTotalMoney();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getCommission();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getServiceFee();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional int64 created_at = 1;
 * @return {number}
 */
proto.airplane.web_partnership.CommissionItem.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CommissionItem} returns this
 */
proto.airplane.web_partnership.CommissionItem.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string booking_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.CommissionItem.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.CommissionItem} returns this
 */
proto.airplane.web_partnership.CommissionItem.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 number_tickets = 3;
 * @return {number}
 */
proto.airplane.web_partnership.CommissionItem.prototype.getNumberTickets = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CommissionItem} returns this
 */
proto.airplane.web_partnership.CommissionItem.prototype.setNumberTickets = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional double total_money = 4;
 * @return {number}
 */
proto.airplane.web_partnership.CommissionItem.prototype.getTotalMoney = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CommissionItem} returns this
 */
proto.airplane.web_partnership.CommissionItem.prototype.setTotalMoney = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double commission = 5;
 * @return {number}
 */
proto.airplane.web_partnership.CommissionItem.prototype.getCommission = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CommissionItem} returns this
 */
proto.airplane.web_partnership.CommissionItem.prototype.setCommission = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional CommissionStatus status = 6;
 * @return {!proto.airplane.web_partnership.CommissionStatus}
 */
proto.airplane.web_partnership.CommissionItem.prototype.getStatus = function() {
  return /** @type {!proto.airplane.web_partnership.CommissionStatus} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.airplane.web_partnership.CommissionStatus} value
 * @return {!proto.airplane.web_partnership.CommissionItem} returns this
 */
proto.airplane.web_partnership.CommissionItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional double service_fee = 7;
 * @return {number}
 */
proto.airplane.web_partnership.CommissionItem.prototype.getServiceFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CommissionItem} returns this
 */
proto.airplane.web_partnership.CommissionItem.prototype.setServiceFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double profit = 8;
 * @return {number}
 */
proto.airplane.web_partnership.CommissionItem.prototype.getProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CommissionItem} returns this
 */
proto.airplane.web_partnership.CommissionItem.prototype.setProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportCommissionRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportCommissionRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportCommissionRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCommissionRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partnership.ReportCommissionRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportCommissionRes}
 */
proto.airplane.web_partnership.ReportCommissionRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportCommissionRes;
  return proto.airplane.web_partnership.ReportCommissionRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportCommissionRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportCommissionRes}
 */
proto.airplane.web_partnership.ReportCommissionRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.ReportCommissionRes.Data;
      reader.readMessage(value,proto.airplane.web_partnership.ReportCommissionRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportCommissionRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportCommissionRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportCommissionRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCommissionRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partnership.ReportCommissionRes.Data.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ReportCommissionRes.Data.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportCommissionRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportCommissionRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportCommissionRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCommissionRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partnership.CommissionItem.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportCommissionRes.Data}
 */
proto.airplane.web_partnership.ReportCommissionRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportCommissionRes.Data;
  return proto.airplane.web_partnership.ReportCommissionRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportCommissionRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportCommissionRes.Data}
 */
proto.airplane.web_partnership.ReportCommissionRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partnership.CommissionItem;
      reader.readMessage(value,proto.airplane.web_partnership.CommissionItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportCommissionRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportCommissionRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportCommissionRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCommissionRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web_partnership.CommissionItem.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CommissionItem items = 1;
 * @return {!Array<!proto.airplane.web_partnership.CommissionItem>}
 */
proto.airplane.web_partnership.ReportCommissionRes.Data.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partnership.CommissionItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partnership.CommissionItem, 1));
};


/**
 * @param {!Array<!proto.airplane.web_partnership.CommissionItem>} value
 * @return {!proto.airplane.web_partnership.ReportCommissionRes.Data} returns this
*/
proto.airplane.web_partnership.ReportCommissionRes.Data.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web_partnership.CommissionItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.CommissionItem}
 */
proto.airplane.web_partnership.ReportCommissionRes.Data.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web_partnership.CommissionItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ReportCommissionRes.Data} returns this
 */
proto.airplane.web_partnership.ReportCommissionRes.Data.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partnership.ReportCommissionRes.Data.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partnership.ReportCommissionRes.Data} returns this
*/
proto.airplane.web_partnership.ReportCommissionRes.Data.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ReportCommissionRes.Data} returns this
 */
proto.airplane.web_partnership.ReportCommissionRes.Data.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ReportCommissionRes.Data.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.ReportCommissionRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.ReportCommissionRes} returns this
 */
proto.airplane.web_partnership.ReportCommissionRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.ReportCommissionRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ReportCommissionRes} returns this
 */
proto.airplane.web_partnership.ReportCommissionRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partnership.ReportCommissionRes.Data}
 */
proto.airplane.web_partnership.ReportCommissionRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partnership.ReportCommissionRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.ReportCommissionRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partnership.ReportCommissionRes.Data|undefined} value
 * @return {!proto.airplane.web_partnership.ReportCommissionRes} returns this
*/
proto.airplane.web_partnership.ReportCommissionRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ReportCommissionRes} returns this
 */
proto.airplane.web_partnership.ReportCommissionRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ReportCommissionRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.DownloadReportCommissionReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.DownloadReportCommissionReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.DownloadReportCommissionReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.DownloadReportCommissionReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    from: jspb.Message.getFieldWithDefault(msg, 1, 0),
    to: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bookingCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.DownloadReportCommissionReq}
 */
proto.airplane.web_partnership.DownloadReportCommissionReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.DownloadReportCommissionReq;
  return proto.airplane.web_partnership.DownloadReportCommissionReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.DownloadReportCommissionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.DownloadReportCommissionReq}
 */
proto.airplane.web_partnership.DownloadReportCommissionReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrom(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 4:
      var value = /** @type {!proto.airplane.web_partnership.CommissionStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.DownloadReportCommissionReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.DownloadReportCommissionReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.DownloadReportCommissionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.DownloadReportCommissionReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFrom();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getTo();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional uint64 from = 1;
 * @return {number}
 */
proto.airplane.web_partnership.DownloadReportCommissionReq.prototype.getFrom = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.DownloadReportCommissionReq} returns this
 */
proto.airplane.web_partnership.DownloadReportCommissionReq.prototype.setFrom = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 to = 2;
 * @return {number}
 */
proto.airplane.web_partnership.DownloadReportCommissionReq.prototype.getTo = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.DownloadReportCommissionReq} returns this
 */
proto.airplane.web_partnership.DownloadReportCommissionReq.prototype.setTo = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string booking_code = 3;
 * @return {string}
 */
proto.airplane.web_partnership.DownloadReportCommissionReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.DownloadReportCommissionReq} returns this
 */
proto.airplane.web_partnership.DownloadReportCommissionReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional CommissionStatus status = 4;
 * @return {!proto.airplane.web_partnership.CommissionStatus}
 */
proto.airplane.web_partnership.DownloadReportCommissionReq.prototype.getStatus = function() {
  return /** @type {!proto.airplane.web_partnership.CommissionStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.airplane.web_partnership.CommissionStatus} value
 * @return {!proto.airplane.web_partnership.DownloadReportCommissionReq} returns this
 */
proto.airplane.web_partnership.DownloadReportCommissionReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.DownloadReportCommissionRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.DownloadReportCommissionRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.DownloadReportCommissionRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.DownloadReportCommissionRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.DownloadReportCommissionRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partnership.CommissionItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.DownloadReportCommissionRes}
 */
proto.airplane.web_partnership.DownloadReportCommissionRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.DownloadReportCommissionRes;
  return proto.airplane.web_partnership.DownloadReportCommissionRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.DownloadReportCommissionRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.DownloadReportCommissionRes}
 */
proto.airplane.web_partnership.DownloadReportCommissionRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.CommissionItem;
      reader.readMessage(value,proto.airplane.web_partnership.CommissionItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.DownloadReportCommissionRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.DownloadReportCommissionRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.DownloadReportCommissionRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.DownloadReportCommissionRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partnership.CommissionItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.DownloadReportCommissionRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.DownloadReportCommissionRes} returns this
 */
proto.airplane.web_partnership.DownloadReportCommissionRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.DownloadReportCommissionRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.DownloadReportCommissionRes} returns this
 */
proto.airplane.web_partnership.DownloadReportCommissionRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CommissionItem items = 3;
 * @return {!Array<!proto.airplane.web_partnership.CommissionItem>}
 */
proto.airplane.web_partnership.DownloadReportCommissionRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partnership.CommissionItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partnership.CommissionItem, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partnership.CommissionItem>} value
 * @return {!proto.airplane.web_partnership.DownloadReportCommissionRes} returns this
*/
proto.airplane.web_partnership.DownloadReportCommissionRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partnership.CommissionItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.CommissionItem}
 */
proto.airplane.web_partnership.DownloadReportCommissionRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partnership.CommissionItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.DownloadReportCommissionRes} returns this
 */
proto.airplane.web_partnership.DownloadReportCommissionRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.CrossCheckStatsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.CrossCheckStatsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.CrossCheckStatsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CrossCheckStatsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.CrossCheckStatsReq}
 */
proto.airplane.web_partnership.CrossCheckStatsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.CrossCheckStatsReq;
  return proto.airplane.web_partnership.CrossCheckStatsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.CrossCheckStatsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.CrossCheckStatsReq}
 */
proto.airplane.web_partnership.CrossCheckStatsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.CrossCheckStatsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.CrossCheckStatsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.CrossCheckStatsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CrossCheckStatsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 date = 1;
 * @return {number}
 */
proto.airplane.web_partnership.CrossCheckStatsReq.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CrossCheckStatsReq} returns this
 */
proto.airplane.web_partnership.CrossCheckStatsReq.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.CrossCheckStatsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.CrossCheckStatsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CrossCheckStatsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partnership.CrossCheckStatsRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.CrossCheckStatsRes}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.CrossCheckStatsRes;
  return proto.airplane.web_partnership.CrossCheckStatsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.CrossCheckStatsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.CrossCheckStatsRes}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.CrossCheckStatsRes.Data;
      reader.readMessage(value,proto.airplane.web_partnership.CrossCheckStatsRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.CrossCheckStatsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.CrossCheckStatsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CrossCheckStatsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partnership.CrossCheckStatsRes.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.CrossCheckStatsRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.CrossCheckStatsRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CrossCheckStatsRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    approved: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    profit: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.CrossCheckStatsRes.Data}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.CrossCheckStatsRes.Data;
  return proto.airplane.web_partnership.CrossCheckStatsRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.CrossCheckStatsRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.CrossCheckStatsRes.Data}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setServiceFee(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setApproved(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.CrossCheckStatsRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.CrossCheckStatsRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CrossCheckStatsRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceFee();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getApproved();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double service_fee = 1;
 * @return {number}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.Data.prototype.getServiceFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CrossCheckStatsRes.Data} returns this
 */
proto.airplane.web_partnership.CrossCheckStatsRes.Data.prototype.setServiceFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double approved = 2;
 * @return {number}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.Data.prototype.getApproved = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CrossCheckStatsRes.Data} returns this
 */
proto.airplane.web_partnership.CrossCheckStatsRes.Data.prototype.setApproved = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double profit = 3;
 * @return {number}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.Data.prototype.getProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CrossCheckStatsRes.Data} returns this
 */
proto.airplane.web_partnership.CrossCheckStatsRes.Data.prototype.setProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.CrossCheckStatsRes} returns this
 */
proto.airplane.web_partnership.CrossCheckStatsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.CrossCheckStatsRes} returns this
 */
proto.airplane.web_partnership.CrossCheckStatsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partnership.CrossCheckStatsRes.Data}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partnership.CrossCheckStatsRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.CrossCheckStatsRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partnership.CrossCheckStatsRes.Data|undefined} value
 * @return {!proto.airplane.web_partnership.CrossCheckStatsRes} returns this
*/
proto.airplane.web_partnership.CrossCheckStatsRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.CrossCheckStatsRes} returns this
 */
proto.airplane.web_partnership.CrossCheckStatsRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.CrossCheckStatsRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportCrossCheckReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportCrossCheckReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportCrossCheckReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCrossCheckReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partnership.ReportCrossCheckReq.Filter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportCrossCheckReq}
 */
proto.airplane.web_partnership.ReportCrossCheckReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportCrossCheckReq;
  return proto.airplane.web_partnership.ReportCrossCheckReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportCrossCheckReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportCrossCheckReq}
 */
proto.airplane.web_partnership.ReportCrossCheckReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partnership.ReportCrossCheckReq.Filter;
      reader.readMessage(value,proto.airplane.web_partnership.ReportCrossCheckReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportCrossCheckReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportCrossCheckReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportCrossCheckReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCrossCheckReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partnership.ReportCrossCheckReq.Filter.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportCrossCheckReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportCrossCheckReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportCrossCheckReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCrossCheckReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportCrossCheckReq.Filter}
 */
proto.airplane.web_partnership.ReportCrossCheckReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportCrossCheckReq.Filter;
  return proto.airplane.web_partnership.ReportCrossCheckReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportCrossCheckReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportCrossCheckReq.Filter}
 */
proto.airplane.web_partnership.ReportCrossCheckReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportCrossCheckReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportCrossCheckReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportCrossCheckReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCrossCheckReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 date = 1;
 * @return {number}
 */
proto.airplane.web_partnership.ReportCrossCheckReq.Filter.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReportCrossCheckReq.Filter} returns this
 */
proto.airplane.web_partnership.ReportCrossCheckReq.Filter.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partnership.ReportCrossCheckReq.Filter}
 */
proto.airplane.web_partnership.ReportCrossCheckReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partnership.ReportCrossCheckReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.ReportCrossCheckReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partnership.ReportCrossCheckReq.Filter|undefined} value
 * @return {!proto.airplane.web_partnership.ReportCrossCheckReq} returns this
*/
proto.airplane.web_partnership.ReportCrossCheckReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ReportCrossCheckReq} returns this
 */
proto.airplane.web_partnership.ReportCrossCheckReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ReportCrossCheckReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.CrossCheckItem.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.CrossCheckItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.CrossCheckItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CrossCheckItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, 0),
    serviceFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    approved: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    profit: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.CrossCheckItem}
 */
proto.airplane.web_partnership.CrossCheckItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.CrossCheckItem;
  return proto.airplane.web_partnership.CrossCheckItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.CrossCheckItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.CrossCheckItem}
 */
proto.airplane.web_partnership.CrossCheckItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setServiceFee(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setApproved(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setProfit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.CrossCheckItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.CrossCheckItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.CrossCheckItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CrossCheckItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getServiceFee();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getApproved();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getProfit();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional int64 date = 1;
 * @return {number}
 */
proto.airplane.web_partnership.CrossCheckItem.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CrossCheckItem} returns this
 */
proto.airplane.web_partnership.CrossCheckItem.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double service_fee = 2;
 * @return {number}
 */
proto.airplane.web_partnership.CrossCheckItem.prototype.getServiceFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CrossCheckItem} returns this
 */
proto.airplane.web_partnership.CrossCheckItem.prototype.setServiceFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double approved = 3;
 * @return {number}
 */
proto.airplane.web_partnership.CrossCheckItem.prototype.getApproved = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CrossCheckItem} returns this
 */
proto.airplane.web_partnership.CrossCheckItem.prototype.setApproved = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double profit = 4;
 * @return {number}
 */
proto.airplane.web_partnership.CrossCheckItem.prototype.getProfit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CrossCheckItem} returns this
 */
proto.airplane.web_partnership.CrossCheckItem.prototype.setProfit = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportCrossCheckRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportCrossCheckRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportCrossCheckRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCrossCheckRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partnership.ReportCrossCheckRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportCrossCheckRes}
 */
proto.airplane.web_partnership.ReportCrossCheckRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportCrossCheckRes;
  return proto.airplane.web_partnership.ReportCrossCheckRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportCrossCheckRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportCrossCheckRes}
 */
proto.airplane.web_partnership.ReportCrossCheckRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.ReportCrossCheckRes.Data;
      reader.readMessage(value,proto.airplane.web_partnership.ReportCrossCheckRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportCrossCheckRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportCrossCheckRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportCrossCheckRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCrossCheckRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partnership.ReportCrossCheckRes.Data.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ReportCrossCheckRes.Data.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReportCrossCheckRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReportCrossCheckRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReportCrossCheckRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCrossCheckRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partnership.CrossCheckItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReportCrossCheckRes.Data}
 */
proto.airplane.web_partnership.ReportCrossCheckRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReportCrossCheckRes.Data;
  return proto.airplane.web_partnership.ReportCrossCheckRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReportCrossCheckRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReportCrossCheckRes.Data}
 */
proto.airplane.web_partnership.ReportCrossCheckRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partnership.CrossCheckItem;
      reader.readMessage(value,proto.airplane.web_partnership.CrossCheckItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReportCrossCheckRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReportCrossCheckRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReportCrossCheckRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReportCrossCheckRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web_partnership.CrossCheckItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CrossCheckItem items = 1;
 * @return {!Array<!proto.airplane.web_partnership.CrossCheckItem>}
 */
proto.airplane.web_partnership.ReportCrossCheckRes.Data.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partnership.CrossCheckItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partnership.CrossCheckItem, 1));
};


/**
 * @param {!Array<!proto.airplane.web_partnership.CrossCheckItem>} value
 * @return {!proto.airplane.web_partnership.ReportCrossCheckRes.Data} returns this
*/
proto.airplane.web_partnership.ReportCrossCheckRes.Data.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web_partnership.CrossCheckItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.CrossCheckItem}
 */
proto.airplane.web_partnership.ReportCrossCheckRes.Data.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web_partnership.CrossCheckItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ReportCrossCheckRes.Data} returns this
 */
proto.airplane.web_partnership.ReportCrossCheckRes.Data.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.ReportCrossCheckRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.ReportCrossCheckRes} returns this
 */
proto.airplane.web_partnership.ReportCrossCheckRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.ReportCrossCheckRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ReportCrossCheckRes} returns this
 */
proto.airplane.web_partnership.ReportCrossCheckRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partnership.ReportCrossCheckRes.Data}
 */
proto.airplane.web_partnership.ReportCrossCheckRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partnership.ReportCrossCheckRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.ReportCrossCheckRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partnership.ReportCrossCheckRes.Data|undefined} value
 * @return {!proto.airplane.web_partnership.ReportCrossCheckRes} returns this
*/
proto.airplane.web_partnership.ReportCrossCheckRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ReportCrossCheckRes} returns this
 */
proto.airplane.web_partnership.ReportCrossCheckRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ReportCrossCheckRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.DownloadReportCrossCheckReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.DownloadReportCrossCheckReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.DownloadReportCrossCheckReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.DownloadReportCrossCheckReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.DownloadReportCrossCheckReq}
 */
proto.airplane.web_partnership.DownloadReportCrossCheckReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.DownloadReportCrossCheckReq;
  return proto.airplane.web_partnership.DownloadReportCrossCheckReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.DownloadReportCrossCheckReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.DownloadReportCrossCheckReq}
 */
proto.airplane.web_partnership.DownloadReportCrossCheckReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.DownloadReportCrossCheckReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.DownloadReportCrossCheckReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.DownloadReportCrossCheckReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.DownloadReportCrossCheckReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 date = 1;
 * @return {number}
 */
proto.airplane.web_partnership.DownloadReportCrossCheckReq.prototype.getDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.DownloadReportCrossCheckReq} returns this
 */
proto.airplane.web_partnership.DownloadReportCrossCheckReq.prototype.setDate = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.DownloadReportCrossCheckRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.DownloadReportCrossCheckRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.airplane.web_partnership.CrossCheckItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.DownloadReportCrossCheckRes}
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.DownloadReportCrossCheckRes;
  return proto.airplane.web_partnership.DownloadReportCrossCheckRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.DownloadReportCrossCheckRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.DownloadReportCrossCheckRes}
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.CrossCheckItem;
      reader.readMessage(value,proto.airplane.web_partnership.CrossCheckItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.DownloadReportCrossCheckRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.DownloadReportCrossCheckRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partnership.CrossCheckItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.DownloadReportCrossCheckRes} returns this
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.DownloadReportCrossCheckRes} returns this
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated CrossCheckItem items = 3;
 * @return {!Array<!proto.airplane.web_partnership.CrossCheckItem>}
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.web_partnership.CrossCheckItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partnership.CrossCheckItem, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partnership.CrossCheckItem>} value
 * @return {!proto.airplane.web_partnership.DownloadReportCrossCheckRes} returns this
*/
proto.airplane.web_partnership.DownloadReportCrossCheckRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partnership.CrossCheckItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.CrossCheckItem}
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partnership.CrossCheckItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.DownloadReportCrossCheckRes} returns this
 */
proto.airplane.web_partnership.DownloadReportCrossCheckRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReferenceStatsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReferenceStatsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReferenceStatsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReferenceStatsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partnership.ReferenceStatsRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReferenceStatsRes}
 */
proto.airplane.web_partnership.ReferenceStatsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReferenceStatsRes;
  return proto.airplane.web_partnership.ReferenceStatsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReferenceStatsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReferenceStatsRes}
 */
proto.airplane.web_partnership.ReferenceStatsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.ReferenceStatsRes.Data;
      reader.readMessage(value,proto.airplane.web_partnership.ReferenceStatsRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReferenceStatsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReferenceStatsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReferenceStatsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReferenceStatsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partnership.ReferenceStatsRes.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReferenceStatsRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReferenceStatsRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0),
    active: jspb.Message.getFieldWithDefault(msg, 2, 0),
    idle: jspb.Message.getFieldWithDefault(msg, 3, 0),
    inactive: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReferenceStatsRes.Data}
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReferenceStatsRes.Data;
  return proto.airplane.web_partnership.ReferenceStatsRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReferenceStatsRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReferenceStatsRes.Data}
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActive(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIdle(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInactive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReferenceStatsRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReferenceStatsRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getActive();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getIdle();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getInactive();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 count = 1;
 * @return {number}
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReferenceStatsRes.Data} returns this
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 active = 2;
 * @return {number}
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data.prototype.getActive = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReferenceStatsRes.Data} returns this
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data.prototype.setActive = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 idle = 3;
 * @return {number}
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data.prototype.getIdle = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReferenceStatsRes.Data} returns this
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data.prototype.setIdle = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 inactive = 4;
 * @return {number}
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data.prototype.getInactive = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReferenceStatsRes.Data} returns this
 */
proto.airplane.web_partnership.ReferenceStatsRes.Data.prototype.setInactive = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.ReferenceStatsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.ReferenceStatsRes} returns this
 */
proto.airplane.web_partnership.ReferenceStatsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.ReferenceStatsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ReferenceStatsRes} returns this
 */
proto.airplane.web_partnership.ReferenceStatsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partnership.ReferenceStatsRes.Data}
 */
proto.airplane.web_partnership.ReferenceStatsRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partnership.ReferenceStatsRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.ReferenceStatsRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partnership.ReferenceStatsRes.Data|undefined} value
 * @return {!proto.airplane.web_partnership.ReferenceStatsRes} returns this
*/
proto.airplane.web_partnership.ReferenceStatsRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ReferenceStatsRes} returns this
 */
proto.airplane.web_partnership.ReferenceStatsRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ReferenceStatsRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReferenceListReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReferenceListReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReferenceListReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReferenceListReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReferenceListReq}
 */
proto.airplane.web_partnership.ReferenceListReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReferenceListReq;
  return proto.airplane.web_partnership.ReferenceListReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReferenceListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReferenceListReq}
 */
proto.airplane.web_partnership.ReferenceListReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReferenceListReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReferenceListReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReferenceListReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReferenceListReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional base.PaginationReq pagination = 1;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partnership.ReferenceListReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 1));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partnership.ReferenceListReq} returns this
*/
proto.airplane.web_partnership.ReferenceListReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ReferenceListReq} returns this
 */
proto.airplane.web_partnership.ReferenceListReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ReferenceListReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReferenceItem.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReferenceItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReferenceItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReferenceItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    website: jspb.Message.getFieldWithDefault(msg, 3, ""),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReferenceItem}
 */
proto.airplane.web_partnership.ReferenceItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReferenceItem;
  return proto.airplane.web_partnership.ReferenceItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReferenceItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReferenceItem}
 */
proto.airplane.web_partnership.ReferenceItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebsite(value);
      break;
    case 4:
      var value = /** @type {!proto.airplane.web_partnership.ReferenceStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReferenceItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReferenceItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReferenceItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReferenceItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWebsite();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional int64 created_at = 1;
 * @return {number}
 */
proto.airplane.web_partnership.ReferenceItem.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ReferenceItem} returns this
 */
proto.airplane.web_partnership.ReferenceItem.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.airplane.web_partnership.ReferenceItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ReferenceItem} returns this
 */
proto.airplane.web_partnership.ReferenceItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string website = 3;
 * @return {string}
 */
proto.airplane.web_partnership.ReferenceItem.prototype.getWebsite = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ReferenceItem} returns this
 */
proto.airplane.web_partnership.ReferenceItem.prototype.setWebsite = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ReferenceStatus status = 4;
 * @return {!proto.airplane.web_partnership.ReferenceStatus}
 */
proto.airplane.web_partnership.ReferenceItem.prototype.getStatus = function() {
  return /** @type {!proto.airplane.web_partnership.ReferenceStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.airplane.web_partnership.ReferenceStatus} value
 * @return {!proto.airplane.web_partnership.ReferenceItem} returns this
 */
proto.airplane.web_partnership.ReferenceItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReferenceListRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReferenceListRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReferenceListRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReferenceListRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partnership.ReferenceListRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReferenceListRes}
 */
proto.airplane.web_partnership.ReferenceListRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReferenceListRes;
  return proto.airplane.web_partnership.ReferenceListRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReferenceListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReferenceListRes}
 */
proto.airplane.web_partnership.ReferenceListRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.ReferenceListRes.Data;
      reader.readMessage(value,proto.airplane.web_partnership.ReferenceListRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReferenceListRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReferenceListRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReferenceListRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReferenceListRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partnership.ReferenceListRes.Data.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ReferenceListRes.Data.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ReferenceListRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ReferenceListRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ReferenceListRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReferenceListRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemList: jspb.Message.toObjectList(msg.getItemList(),
    proto.airplane.web_partnership.ReferenceItem.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ReferenceListRes.Data}
 */
proto.airplane.web_partnership.ReferenceListRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ReferenceListRes.Data;
  return proto.airplane.web_partnership.ReferenceListRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ReferenceListRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ReferenceListRes.Data}
 */
proto.airplane.web_partnership.ReferenceListRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partnership.ReferenceItem;
      reader.readMessage(value,proto.airplane.web_partnership.ReferenceItem.deserializeBinaryFromReader);
      msg.addItem(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ReferenceListRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ReferenceListRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ReferenceListRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ReferenceListRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.airplane.web_partnership.ReferenceItem.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReferenceItem item = 1;
 * @return {!Array<!proto.airplane.web_partnership.ReferenceItem>}
 */
proto.airplane.web_partnership.ReferenceListRes.Data.prototype.getItemList = function() {
  return /** @type{!Array<!proto.airplane.web_partnership.ReferenceItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partnership.ReferenceItem, 1));
};


/**
 * @param {!Array<!proto.airplane.web_partnership.ReferenceItem>} value
 * @return {!proto.airplane.web_partnership.ReferenceListRes.Data} returns this
*/
proto.airplane.web_partnership.ReferenceListRes.Data.prototype.setItemList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.airplane.web_partnership.ReferenceItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.ReferenceItem}
 */
proto.airplane.web_partnership.ReferenceListRes.Data.prototype.addItem = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.airplane.web_partnership.ReferenceItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ReferenceListRes.Data} returns this
 */
proto.airplane.web_partnership.ReferenceListRes.Data.prototype.clearItemList = function() {
  return this.setItemList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partnership.ReferenceListRes.Data.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partnership.ReferenceListRes.Data} returns this
*/
proto.airplane.web_partnership.ReferenceListRes.Data.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ReferenceListRes.Data} returns this
 */
proto.airplane.web_partnership.ReferenceListRes.Data.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ReferenceListRes.Data.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.ReferenceListRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.ReferenceListRes} returns this
 */
proto.airplane.web_partnership.ReferenceListRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.ReferenceListRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ReferenceListRes} returns this
 */
proto.airplane.web_partnership.ReferenceListRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partnership.ReferenceListRes.Data}
 */
proto.airplane.web_partnership.ReferenceListRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partnership.ReferenceListRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.ReferenceListRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partnership.ReferenceListRes.Data|undefined} value
 * @return {!proto.airplane.web_partnership.ReferenceListRes} returns this
*/
proto.airplane.web_partnership.ReferenceListRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ReferenceListRes} returns this
 */
proto.airplane.web_partnership.ReferenceListRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ReferenceListRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ExportReportBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ExportReportBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ExportReportBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ExportReportBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.airplane.web_partnership.ExportReportBookingReq.Filter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ExportReportBookingReq}
 */
proto.airplane.web_partnership.ExportReportBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ExportReportBookingReq;
  return proto.airplane.web_partnership.ExportReportBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ExportReportBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ExportReportBookingReq}
 */
proto.airplane.web_partnership.ExportReportBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.web_partnership.ExportReportBookingReq.Filter;
      reader.readMessage(value,proto.airplane.web_partnership.ExportReportBookingReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ExportReportBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ExportReportBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ExportReportBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ExportReportBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.web_partnership.ExportReportBookingReq.Filter.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ExportReportBookingReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ExportReportBookingReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromDate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    toDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    statusesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ExportReportBookingReq.Filter}
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ExportReportBookingReq.Filter;
  return proto.airplane.web_partnership.ExportReportBookingReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ExportReportBookingReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ExportReportBookingReq.Filter}
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromDate(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToDate(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.airplane.BookingStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ExportReportBookingReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ExportReportBookingReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromDate();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getToDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ExportReportBookingReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 from_date = 2;
 * @return {number}
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.prototype.getFromDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ExportReportBookingReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.prototype.setFromDate = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 to_date = 3;
 * @return {number}
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.prototype.getToDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.ExportReportBookingReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.prototype.setToDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated airplane.BookingStatus statuses = 4;
 * @return {!Array<!proto.airplane.BookingStatus>}
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.airplane.BookingStatus>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.airplane.BookingStatus>} value
 * @return {!proto.airplane.web_partnership.ExportReportBookingReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.ExportReportBookingReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ExportReportBookingReq.Filter} returns this
 */
proto.airplane.web_partnership.ExportReportBookingReq.Filter.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};


/**
 * optional Filter filter = 1;
 * @return {?proto.airplane.web_partnership.ExportReportBookingReq.Filter}
 */
proto.airplane.web_partnership.ExportReportBookingReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partnership.ExportReportBookingReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.ExportReportBookingReq.Filter, 1));
};


/**
 * @param {?proto.airplane.web_partnership.ExportReportBookingReq.Filter|undefined} value
 * @return {!proto.airplane.web_partnership.ExportReportBookingReq} returns this
*/
proto.airplane.web_partnership.ExportReportBookingReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ExportReportBookingReq} returns this
 */
proto.airplane.web_partnership.ExportReportBookingReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ExportReportBookingReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ExportReportBookingRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ExportReportBookingRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ExportReportBookingRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ExportReportBookingRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ExportReportBookingRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    airplaneRecordsList: jspb.Message.toObjectList(msg.getAirplaneRecordsList(),
    airplane_report_pb.AirplaneReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ExportReportBookingRes}
 */
proto.airplane.web_partnership.ExportReportBookingRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ExportReportBookingRes;
  return proto.airplane.web_partnership.ExportReportBookingRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ExportReportBookingRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ExportReportBookingRes}
 */
proto.airplane.web_partnership.ExportReportBookingRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_report_pb.AirplaneReport;
      reader.readMessage(value,airplane_report_pb.AirplaneReport.deserializeBinaryFromReader);
      msg.addAirplaneRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ExportReportBookingRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ExportReportBookingRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ExportReportBookingRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ExportReportBookingRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAirplaneRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      airplane_report_pb.AirplaneReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.ExportReportBookingRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.ExportReportBookingRes} returns this
 */
proto.airplane.web_partnership.ExportReportBookingRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.ExportReportBookingRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ExportReportBookingRes} returns this
 */
proto.airplane.web_partnership.ExportReportBookingRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated airplane.AirplaneReport airplane_records = 3;
 * @return {!Array<!proto.airplane.AirplaneReport>}
 */
proto.airplane.web_partnership.ExportReportBookingRes.prototype.getAirplaneRecordsList = function() {
  return /** @type{!Array<!proto.airplane.AirplaneReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_report_pb.AirplaneReport, 3));
};


/**
 * @param {!Array<!proto.airplane.AirplaneReport>} value
 * @return {!proto.airplane.web_partnership.ExportReportBookingRes} returns this
*/
proto.airplane.web_partnership.ExportReportBookingRes.prototype.setAirplaneRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.AirplaneReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.AirplaneReport}
 */
proto.airplane.web_partnership.ExportReportBookingRes.prototype.addAirplaneRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.AirplaneReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ExportReportBookingRes} returns this
 */
proto.airplane.web_partnership.ExportReportBookingRes.prototype.clearAirplaneRecordsList = function() {
  return this.setAirplaneRecordsList([]);
};


/**
 * @enum {number}
 */
proto.airplane.web_partnership.CommissionStatus = {
  CNONE: 0,
  WAITINGREVIEW: 1,
  APPROVED: 2,
  CANCEL: 3
};

/**
 * @enum {number}
 */
proto.airplane.web_partnership.ReferenceStatus = {
  RNONE: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  IDLE: 3
};

goog.object.extend(exports, proto.airplane.web_partnership);
