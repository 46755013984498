import { Empty, PaginationReq } from '@api/base/base_pb';
import { PartnershipUserServiceClient } from '@api/partnership/web_partnership/partnership_user_grpc_web_pb';
import {
  AdminChangeUserPasswordReq,
  AdminChangeUserPasswordRes,
  ChangePasswordReq,
  ChangePasswordV2Res,
  ContactInfo,
  CreateUserV2Req,
  CreateUserV2Res,
  GetMeReq,
  GetMeRes,
  GetMeV2Res,
  GetUserDetailV2Req,
  GetUserDetailV2Res,
  GetUsersReq,
  GetUsersV2Res,
  UpdateUserContactInfo,
  UpdateUserReq,
  UpdateUserV2Req,
  UpdateUserV2Res,
} from '@api/partnership/web_partnership/partnership_user_pb';

import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class PartnerUserApi extends BaseApi<PartnershipUserServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new PartnershipUserServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }
  getMe = (): Promise<GetMeRes.AsObject> => {
    const req = new GetMeReq();
    return this.grpc<GetMeReq, GetMeRes, GetMeRes.AsObject>(
      this.serviceClient.getMe,
      req,
      {}
    );
  };

  getMeV2 = (): Promise<GetMeV2Res.AsObject> => {
    const req = new GetMeReq();
    return this.grpc<GetMeReq, GetMeV2Res, GetMeV2Res.AsObject>(
      this.serviceClient.getMeV2,
      req,
      {}
    );
  };

  getUsersV2 = ({
    name,
    roleList,
    status,
    username,
    pagination,
  }: GetUsersReq.AsObject): Promise<GetUsersV2Res.AsObject> => {
    const req = new GetUsersReq();

    req.setRoleList(roleList);
    req.setName(name);
    req.setUsername(username);
    req.setStatus(status);
    if (pagination) {
      const paginationObj = new PaginationReq();
      paginationObj.setPageLimit(pagination.pageLimit);
      paginationObj.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationObj);
    }

    return this.grpc<GetUsersReq, GetUsersV2Res, GetUsersV2Res.AsObject>(
      this.serviceClient.getUsersV2,
      req,
      {}
    );
  };

  changePassword = ({ oldPassword, password }: ChangePasswordReq.AsObject) => {
    const req = new ChangePasswordReq();
    req.setOldPassword(oldPassword);
    req.setPassword(password);
    return this.grpc<ChangePasswordReq, Empty, Empty.AsObject>(
      this.serviceClient.changePassword,
      req,
      {}
    );
  };

  changePasswordV2 = ({
    oldPassword,
    password,
  }: ChangePasswordReq.AsObject) => {
    const req = new ChangePasswordReq();
    req.setOldPassword(oldPassword);
    req.setPassword(password);
    return this.grpc<
      ChangePasswordReq,
      ChangePasswordV2Res,
      ChangePasswordV2Res.AsObject
    >(this.serviceClient.changePasswordV2, req, {});
  };

  updateUser = ({
    description,
    name,
    profilePicture,
    userId,
  }: UpdateUserReq.AsObject) => {
    const req = new UpdateUserReq();

    req.setUserId(userId);
    req.setName(name);
    req.setProfilePicture(profilePicture);
    req.setDescription(description);

    return this.grpc<UpdateUserReq, Empty, Empty.AsObject>(
      this.serviceClient.updateUser,
      req,
      {}
    );
  };

  updateUserV2 = ({
    roleList,
    status,
    contactInfo,
    userId,
    domainId,
    officeIdsList,
    providerIdsList,
  }: UpdateUserV2Req.AsObject): Promise<UpdateUserV2Res.AsObject> => {
    const req = new UpdateUserV2Req();

    req.setDomainId(domainId);
    req.setOfficeIdsList(officeIdsList);
    req.setProviderIdsList(providerIdsList);
    req.setUserId(userId);
    req.setStatus(status);
    if (contactInfo) {
      const contactInfoObj = new UpdateUserContactInfo();
      contactInfo.email && contactInfoObj.setEmail(contactInfo.email);
      contactInfoObj.setName(contactInfo.name);
      contactInfo.phoneNumber &&
        contactInfoObj.setPhoneNumber(contactInfo.phoneNumber);
      req.setContactInfo(contactInfoObj);
    }
    req.setRoleList(roleList);

    return this.grpc<
      UpdateUserV2Req,
      UpdateUserV2Res,
      UpdateUserV2Res.AsObject
    >(this.serviceClient.updateUserV2, req, {});
  };

  createUserV2 = ({
    roleList,
    status,
    contactInfo,
    username,
    password,
    domainId,
    officeIdsList,
    providerIdsList,
  }: CreateUserV2Req.AsObject): Promise<CreateUserV2Res.AsObject> => {
    const req = new CreateUserV2Req();

    req.setStatus(status);

    if (contactInfo) {
      const contactInfoObj = new UpdateUserContactInfo();
      contactInfo.email && contactInfoObj.setEmail(contactInfo.email);
      contactInfoObj.setName(contactInfo.name);
      contactInfo.phoneNumber &&
        contactInfoObj.setPhoneNumber(contactInfo.phoneNumber);
      req.setContactInfo(contactInfoObj);
    }

    req.setRoleList(roleList);
    req.setUsername(username);
    req.setPassword(password);
    req.setDomainId(domainId);
    req.setOfficeIdsList(officeIdsList);
    req.setProviderIdsList(providerIdsList);

    return this.grpc<
      CreateUserV2Req,
      CreateUserV2Res,
      CreateUserV2Res.AsObject
    >(this.serviceClient.createUserV2, req, {});
  };

  getUserDetailV2 = ({ userId }: GetUserDetailV2Req.AsObject) => {
    const req = new GetUserDetailV2Req();

    req.setUserId(userId);

    return this.grpc<
      GetUserDetailV2Req,
      GetUserDetailV2Res,
      GetUserDetailV2Res.AsObject
    >(this.serviceClient.getUserDetailV2, req, {});
  };

  adminChangeUserPassword = ({
    userId,
    newPassword,
  }: AdminChangeUserPasswordReq.AsObject): Promise<AdminChangeUserPasswordRes.AsObject> => {
    const req = new AdminChangeUserPasswordReq();

    req.setUserId(userId);
    req.setNewPassword(newPassword);

    return this.grpc<
      AdminChangeUserPasswordReq,
      AdminChangeUserPasswordRes,
      AdminChangeUserPasswordRes.AsObject
    >(this.serviceClient.adminChangeUserPassword, req, {});
  };
}

export const partnerUserServiceClient = new PartnerUserApi();
export default partnerUserServiceClient;
