// source: customer/web_partnership/customer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var customer_customer_pb = require('../../customer/customer_pb.js');
goog.object.extend(proto, customer_customer_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.customer.web_partnership.BasicCustomerInfo', null, global);
goog.exportSymbol('proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq', null, global);
goog.exportSymbol('proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes', null, global);
goog.exportSymbol('proto.customer.web_partnership.FindCustomersReq', null, global);
goog.exportSymbol('proto.customer.web_partnership.FindCustomersRes', null, global);
goog.exportSymbol('proto.customer.web_partnership.Gender', null, global);
goog.exportSymbol('proto.customer.web_partnership.GetBasicCustomerReq', null, global);
goog.exportSymbol('proto.customer.web_partnership.GetBasicCustomerRes', null, global);
goog.exportSymbol('proto.customer.web_partnership.GetCustomerByIDReq', null, global);
goog.exportSymbol('proto.customer.web_partnership.GetCustomerByIDRes', null, global);
goog.exportSymbol('proto.customer.web_partnership.GetCustomerByIdsReq', null, global);
goog.exportSymbol('proto.customer.web_partnership.GetCustomerByIdsRes', null, global);
goog.exportSymbol('proto.customer.web_partnership.GetUserReq', null, global);
goog.exportSymbol('proto.customer.web_partnership.GetUserRes', null, global);
goog.exportSymbol('proto.customer.web_partnership.SocialType', null, global);
goog.exportSymbol('proto.customer.web_partnership.UserInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web_partnership.UserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.web_partnership.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web_partnership.UserInfo.displayName = 'proto.customer.web_partnership.UserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web_partnership.BasicCustomerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.web_partnership.BasicCustomerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web_partnership.BasicCustomerInfo.displayName = 'proto.customer.web_partnership.BasicCustomerInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.displayName = 'proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web_partnership.FindCustomersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.web_partnership.FindCustomersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web_partnership.FindCustomersReq.displayName = 'proto.customer.web_partnership.FindCustomersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web_partnership.GetCustomerByIdsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer.web_partnership.GetCustomerByIdsReq.repeatedFields_, null);
};
goog.inherits(proto.customer.web_partnership.GetCustomerByIdsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web_partnership.GetCustomerByIdsReq.displayName = 'proto.customer.web_partnership.GetCustomerByIdsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web_partnership.GetUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.web_partnership.GetUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web_partnership.GetUserReq.displayName = 'proto.customer.web_partnership.GetUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web_partnership.GetBasicCustomerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer.web_partnership.GetBasicCustomerReq.repeatedFields_, null);
};
goog.inherits(proto.customer.web_partnership.GetBasicCustomerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web_partnership.GetBasicCustomerReq.displayName = 'proto.customer.web_partnership.GetBasicCustomerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web_partnership.GetUserRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.web_partnership.GetUserRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web_partnership.GetUserRes.displayName = 'proto.customer.web_partnership.GetUserRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web_partnership.GetCustomerByIdsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer.web_partnership.GetCustomerByIdsRes.repeatedFields_, null);
};
goog.inherits(proto.customer.web_partnership.GetCustomerByIdsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web_partnership.GetCustomerByIdsRes.displayName = 'proto.customer.web_partnership.GetCustomerByIdsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web_partnership.GetBasicCustomerRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer.web_partnership.GetBasicCustomerRes.repeatedFields_, null);
};
goog.inherits(proto.customer.web_partnership.GetBasicCustomerRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web_partnership.GetBasicCustomerRes.displayName = 'proto.customer.web_partnership.GetBasicCustomerRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.repeatedFields_, null);
};
goog.inherits(proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.displayName = 'proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web_partnership.FindCustomersRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.customer.web_partnership.FindCustomersRes.repeatedFields_, null);
};
goog.inherits(proto.customer.web_partnership.FindCustomersRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web_partnership.FindCustomersRes.displayName = 'proto.customer.web_partnership.FindCustomersRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web_partnership.GetCustomerByIDReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.web_partnership.GetCustomerByIDReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web_partnership.GetCustomerByIDReq.displayName = 'proto.customer.web_partnership.GetCustomerByIDReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.customer.web_partnership.GetCustomerByIDRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.customer.web_partnership.GetCustomerByIDRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.customer.web_partnership.GetCustomerByIDRes.displayName = 'proto.customer.web_partnership.GetCustomerByIDRes';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web_partnership.UserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web_partnership.UserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web_partnership.UserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.UserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phoneVerified: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    emailVerified: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 9, ""),
    profilePicture: jspb.Message.getFieldWithDefault(msg, 10, ""),
    description: jspb.Message.getFieldWithDefault(msg, 11, ""),
    inviterId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    birthday: jspb.Message.getFieldWithDefault(msg, 13, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 14, 0),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 15, ""),
    isSocialAccount: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    socialType: jspb.Message.getFieldWithDefault(msg, 17, 0),
    hashCode: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web_partnership.UserInfo}
 */
proto.customer.web_partnership.UserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web_partnership.UserInfo;
  return proto.customer.web_partnership.UserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web_partnership.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web_partnership.UserInfo}
 */
proto.customer.web_partnership.UserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPhoneVerified(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailVerified(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicture(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setInviterId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBirthday(value);
      break;
    case 14:
      var value = /** @type {!proto.customer.web_partnership.Gender} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSocialAccount(value);
      break;
    case 17:
      var value = /** @type {!proto.customer.web_partnership.SocialType} */ (reader.readEnum());
      msg.setSocialType(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setHashCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web_partnership.UserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web_partnership.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web_partnership.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.UserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhoneVerified();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getEmailVerified();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getProfilePicture();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getInviterId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBirthday();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getIsSocialAccount();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getSocialType();
  if (f !== 0.0) {
    writer.writeEnum(
      17,
      f
    );
  }
  f = message.getHashCode();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.customer.web_partnership.UserInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.customer.web_partnership.UserInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 updated_at = 3;
 * @return {number}
 */
proto.customer.web_partnership.UserInfo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.customer.web_partnership.UserInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.customer.web_partnership.UserInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool phone_verified = 6;
 * @return {boolean}
 */
proto.customer.web_partnership.UserInfo.prototype.getPhoneVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setPhoneVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool email_verified = 7;
 * @return {boolean}
 */
proto.customer.web_partnership.UserInfo.prototype.getEmailVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setEmailVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string phone_number = 8;
 * @return {string}
 */
proto.customer.web_partnership.UserInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string phone_code = 9;
 * @return {string}
 */
proto.customer.web_partnership.UserInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string profile_picture = 10;
 * @return {string}
 */
proto.customer.web_partnership.UserInfo.prototype.getProfilePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setProfilePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string description = 11;
 * @return {string}
 */
proto.customer.web_partnership.UserInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string inviter_id = 12;
 * @return {string}
 */
proto.customer.web_partnership.UserInfo.prototype.getInviterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setInviterId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int64 birthday = 13;
 * @return {number}
 */
proto.customer.web_partnership.UserInfo.prototype.getBirthday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional Gender gender = 14;
 * @return {!proto.customer.web_partnership.Gender}
 */
proto.customer.web_partnership.UserInfo.prototype.getGender = function() {
  return /** @type {!proto.customer.web_partnership.Gender} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.customer.web_partnership.Gender} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional string partnership_id = 15;
 * @return {string}
 */
proto.customer.web_partnership.UserInfo.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional bool is_social_account = 16;
 * @return {boolean}
 */
proto.customer.web_partnership.UserInfo.prototype.getIsSocialAccount = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setIsSocialAccount = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional SocialType social_type = 17;
 * @return {!proto.customer.web_partnership.SocialType}
 */
proto.customer.web_partnership.UserInfo.prototype.getSocialType = function() {
  return /** @type {!proto.customer.web_partnership.SocialType} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {!proto.customer.web_partnership.SocialType} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setSocialType = function(value) {
  return jspb.Message.setProto3EnumField(this, 17, value);
};


/**
 * optional string hash_code = 18;
 * @return {string}
 */
proto.customer.web_partnership.UserInfo.prototype.getHashCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.UserInfo} returns this
 */
proto.customer.web_partnership.UserInfo.prototype.setHashCode = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web_partnership.BasicCustomerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web_partnership.BasicCustomerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web_partnership.BasicCustomerInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.BasicCustomerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web_partnership.BasicCustomerInfo}
 */
proto.customer.web_partnership.BasicCustomerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web_partnership.BasicCustomerInfo;
  return proto.customer.web_partnership.BasicCustomerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web_partnership.BasicCustomerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web_partnership.BasicCustomerInfo}
 */
proto.customer.web_partnership.BasicCustomerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web_partnership.BasicCustomerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web_partnership.BasicCustomerInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web_partnership.BasicCustomerInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.BasicCustomerInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.customer.web_partnership.BasicCustomerInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.BasicCustomerInfo} returns this
 */
proto.customer.web_partnership.BasicCustomerInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.customer.web_partnership.BasicCustomerInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.BasicCustomerInfo} returns this
 */
proto.customer.web_partnership.BasicCustomerInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.customer.web_partnership.BasicCustomerInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.BasicCustomerInfo} returns this
 */
proto.customer.web_partnership.BasicCustomerInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_number = 4;
 * @return {string}
 */
proto.customer.web_partnership.BasicCustomerInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.BasicCustomerInfo} returns this
 */
proto.customer.web_partnership.BasicCustomerInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_code = 5;
 * @return {string}
 */
proto.customer.web_partnership.BasicCustomerInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.BasicCustomerInfo} returns this
 */
proto.customer.web_partnership.BasicCustomerInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq;
  return proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq} returns this
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_code = 2;
 * @return {string}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq} returns this
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone_number = 3;
 * @return {string}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq} returns this
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional base.PaginationReq pagination = 4;
 * @return {?proto.base.PaginationReq}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 4));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq} returns this
*/
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq} returns this
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web_partnership.FindCustomersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web_partnership.FindCustomersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web_partnership.FindCustomersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.FindCustomersReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    searchQuery: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web_partnership.FindCustomersReq}
 */
proto.customer.web_partnership.FindCustomersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web_partnership.FindCustomersReq;
  return proto.customer.web_partnership.FindCustomersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web_partnership.FindCustomersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web_partnership.FindCustomersReq}
 */
proto.customer.web_partnership.FindCustomersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchQuery(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web_partnership.FindCustomersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web_partnership.FindCustomersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web_partnership.FindCustomersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.FindCustomersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSearchQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string search_query = 1;
 * @return {string}
 */
proto.customer.web_partnership.FindCustomersReq.prototype.getSearchQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.FindCustomersReq} returns this
 */
proto.customer.web_partnership.FindCustomersReq.prototype.setSearchQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional base.PaginationReq pagination = 2;
 * @return {?proto.base.PaginationReq}
 */
proto.customer.web_partnership.FindCustomersReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 2));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.customer.web_partnership.FindCustomersReq} returns this
*/
proto.customer.web_partnership.FindCustomersReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.web_partnership.FindCustomersReq} returns this
 */
proto.customer.web_partnership.FindCustomersReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.web_partnership.FindCustomersReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.customer.web_partnership.FindCustomersReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.FindCustomersReq} returns this
 */
proto.customer.web_partnership.FindCustomersReq.prototype.setName = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.customer.web_partnership.FindCustomersReq} returns this
 */
proto.customer.web_partnership.FindCustomersReq.prototype.clearName = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.web_partnership.FindCustomersReq.prototype.hasName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string phone = 4;
 * @return {string}
 */
proto.customer.web_partnership.FindCustomersReq.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.FindCustomersReq} returns this
 */
proto.customer.web_partnership.FindCustomersReq.prototype.setPhone = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.customer.web_partnership.FindCustomersReq} returns this
 */
proto.customer.web_partnership.FindCustomersReq.prototype.clearPhone = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.web_partnership.FindCustomersReq.prototype.hasPhone = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.customer.web_partnership.FindCustomersReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.FindCustomersReq} returns this
 */
proto.customer.web_partnership.FindCustomersReq.prototype.setEmail = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.customer.web_partnership.FindCustomersReq} returns this
 */
proto.customer.web_partnership.FindCustomersReq.prototype.clearEmail = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.web_partnership.FindCustomersReq.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer.web_partnership.GetCustomerByIdsReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web_partnership.GetCustomerByIdsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web_partnership.GetCustomerByIdsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web_partnership.GetCustomerByIdsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetCustomerByIdsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web_partnership.GetCustomerByIdsReq}
 */
proto.customer.web_partnership.GetCustomerByIdsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web_partnership.GetCustomerByIdsReq;
  return proto.customer.web_partnership.GetCustomerByIdsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web_partnership.GetCustomerByIdsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web_partnership.GetCustomerByIdsReq}
 */
proto.customer.web_partnership.GetCustomerByIdsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web_partnership.GetCustomerByIdsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web_partnership.GetCustomerByIdsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web_partnership.GetCustomerByIdsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetCustomerByIdsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.customer.web_partnership.GetCustomerByIdsReq.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.customer.web_partnership.GetCustomerByIdsReq} returns this
 */
proto.customer.web_partnership.GetCustomerByIdsReq.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.customer.web_partnership.GetCustomerByIdsReq} returns this
 */
proto.customer.web_partnership.GetCustomerByIdsReq.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer.web_partnership.GetCustomerByIdsReq} returns this
 */
proto.customer.web_partnership.GetCustomerByIdsReq.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web_partnership.GetUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web_partnership.GetUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web_partnership.GetUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web_partnership.GetUserReq}
 */
proto.customer.web_partnership.GetUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web_partnership.GetUserReq;
  return proto.customer.web_partnership.GetUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web_partnership.GetUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web_partnership.GetUserReq}
 */
proto.customer.web_partnership.GetUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web_partnership.GetUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web_partnership.GetUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web_partnership.GetUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.customer.web_partnership.GetUserReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.GetUserReq} returns this
 */
proto.customer.web_partnership.GetUserReq.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_code = 2;
 * @return {string}
 */
proto.customer.web_partnership.GetUserReq.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.GetUserReq} returns this
 */
proto.customer.web_partnership.GetUserReq.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string phone_number = 3;
 * @return {string}
 */
proto.customer.web_partnership.GetUserReq.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.GetUserReq} returns this
 */
proto.customer.web_partnership.GetUserReq.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string partnership_id = 4;
 * @return {string}
 */
proto.customer.web_partnership.GetUserReq.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.GetUserReq} returns this
 */
proto.customer.web_partnership.GetUserReq.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer.web_partnership.GetBasicCustomerReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web_partnership.GetBasicCustomerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web_partnership.GetBasicCustomerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web_partnership.GetBasicCustomerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetBasicCustomerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web_partnership.GetBasicCustomerReq}
 */
proto.customer.web_partnership.GetBasicCustomerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web_partnership.GetBasicCustomerReq;
  return proto.customer.web_partnership.GetBasicCustomerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web_partnership.GetBasicCustomerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web_partnership.GetBasicCustomerReq}
 */
proto.customer.web_partnership.GetBasicCustomerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web_partnership.GetBasicCustomerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web_partnership.GetBasicCustomerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web_partnership.GetBasicCustomerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetBasicCustomerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.customer.web_partnership.GetBasicCustomerReq.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.customer.web_partnership.GetBasicCustomerReq} returns this
 */
proto.customer.web_partnership.GetBasicCustomerReq.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.customer.web_partnership.GetBasicCustomerReq} returns this
 */
proto.customer.web_partnership.GetBasicCustomerReq.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer.web_partnership.GetBasicCustomerReq} returns this
 */
proto.customer.web_partnership.GetBasicCustomerReq.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web_partnership.GetUserRes.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web_partnership.GetUserRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web_partnership.GetUserRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetUserRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    userInfo: (f = msg.getUserInfo()) && proto.customer.web_partnership.UserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web_partnership.GetUserRes}
 */
proto.customer.web_partnership.GetUserRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web_partnership.GetUserRes;
  return proto.customer.web_partnership.GetUserRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web_partnership.GetUserRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web_partnership.GetUserRes}
 */
proto.customer.web_partnership.GetUserRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customer.web_partnership.UserInfo;
      reader.readMessage(value,proto.customer.web_partnership.UserInfo.deserializeBinaryFromReader);
      msg.setUserInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web_partnership.GetUserRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web_partnership.GetUserRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web_partnership.GetUserRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetUserRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.customer.web_partnership.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserInfo user_info = 1;
 * @return {?proto.customer.web_partnership.UserInfo}
 */
proto.customer.web_partnership.GetUserRes.prototype.getUserInfo = function() {
  return /** @type{?proto.customer.web_partnership.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.customer.web_partnership.UserInfo, 1));
};


/**
 * @param {?proto.customer.web_partnership.UserInfo|undefined} value
 * @return {!proto.customer.web_partnership.GetUserRes} returns this
*/
proto.customer.web_partnership.GetUserRes.prototype.setUserInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.web_partnership.GetUserRes} returns this
 */
proto.customer.web_partnership.GetUserRes.prototype.clearUserInfo = function() {
  return this.setUserInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.web_partnership.GetUserRes.prototype.hasUserInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer.web_partnership.GetCustomerByIdsRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web_partnership.GetCustomerByIdsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web_partnership.GetCustomerByIdsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web_partnership.GetCustomerByIdsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetCustomerByIdsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.customer.web_partnership.UserInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web_partnership.GetCustomerByIdsRes}
 */
proto.customer.web_partnership.GetCustomerByIdsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web_partnership.GetCustomerByIdsRes;
  return proto.customer.web_partnership.GetCustomerByIdsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web_partnership.GetCustomerByIdsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web_partnership.GetCustomerByIdsRes}
 */
proto.customer.web_partnership.GetCustomerByIdsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customer.web_partnership.UserInfo;
      reader.readMessage(value,proto.customer.web_partnership.UserInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web_partnership.GetCustomerByIdsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web_partnership.GetCustomerByIdsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web_partnership.GetCustomerByIdsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetCustomerByIdsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.customer.web_partnership.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserInfo items = 1;
 * @return {!Array<!proto.customer.web_partnership.UserInfo>}
 */
proto.customer.web_partnership.GetCustomerByIdsRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.customer.web_partnership.UserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.customer.web_partnership.UserInfo, 1));
};


/**
 * @param {!Array<!proto.customer.web_partnership.UserInfo>} value
 * @return {!proto.customer.web_partnership.GetCustomerByIdsRes} returns this
*/
proto.customer.web_partnership.GetCustomerByIdsRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.customer.web_partnership.UserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.customer.web_partnership.UserInfo}
 */
proto.customer.web_partnership.GetCustomerByIdsRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.customer.web_partnership.UserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer.web_partnership.GetCustomerByIdsRes} returns this
 */
proto.customer.web_partnership.GetCustomerByIdsRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer.web_partnership.GetBasicCustomerRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web_partnership.GetBasicCustomerRes.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web_partnership.GetBasicCustomerRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web_partnership.GetBasicCustomerRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetBasicCustomerRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.customer.web_partnership.BasicCustomerInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web_partnership.GetBasicCustomerRes}
 */
proto.customer.web_partnership.GetBasicCustomerRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web_partnership.GetBasicCustomerRes;
  return proto.customer.web_partnership.GetBasicCustomerRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web_partnership.GetBasicCustomerRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web_partnership.GetBasicCustomerRes}
 */
proto.customer.web_partnership.GetBasicCustomerRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customer.web_partnership.BasicCustomerInfo;
      reader.readMessage(value,proto.customer.web_partnership.BasicCustomerInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web_partnership.GetBasicCustomerRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web_partnership.GetBasicCustomerRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web_partnership.GetBasicCustomerRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetBasicCustomerRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.customer.web_partnership.BasicCustomerInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BasicCustomerInfo items = 1;
 * @return {!Array<!proto.customer.web_partnership.BasicCustomerInfo>}
 */
proto.customer.web_partnership.GetBasicCustomerRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.customer.web_partnership.BasicCustomerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.customer.web_partnership.BasicCustomerInfo, 1));
};


/**
 * @param {!Array<!proto.customer.web_partnership.BasicCustomerInfo>} value
 * @return {!proto.customer.web_partnership.GetBasicCustomerRes} returns this
*/
proto.customer.web_partnership.GetBasicCustomerRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.customer.web_partnership.BasicCustomerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.customer.web_partnership.BasicCustomerInfo}
 */
proto.customer.web_partnership.GetBasicCustomerRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.customer.web_partnership.BasicCustomerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer.web_partnership.GetBasicCustomerRes} returns this
 */
proto.customer.web_partnership.GetBasicCustomerRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.customer.web_partnership.BasicCustomerInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes;
  return proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customer.web_partnership.BasicCustomerInfo;
      reader.readMessage(value,proto.customer.web_partnership.BasicCustomerInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.customer.web_partnership.BasicCustomerInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BasicCustomerInfo items = 1;
 * @return {!Array<!proto.customer.web_partnership.BasicCustomerInfo>}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.customer.web_partnership.BasicCustomerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.customer.web_partnership.BasicCustomerInfo, 1));
};


/**
 * @param {!Array<!proto.customer.web_partnership.BasicCustomerInfo>} value
 * @return {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes} returns this
*/
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.customer.web_partnership.BasicCustomerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.customer.web_partnership.BasicCustomerInfo}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.customer.web_partnership.BasicCustomerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes} returns this
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes} returns this
*/
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes} returns this
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.customer.web_partnership.FindCustomersRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web_partnership.FindCustomersRes.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web_partnership.FindCustomersRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web_partnership.FindCustomersRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.FindCustomersRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.customer.web_partnership.BasicCustomerInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web_partnership.FindCustomersRes}
 */
proto.customer.web_partnership.FindCustomersRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web_partnership.FindCustomersRes;
  return proto.customer.web_partnership.FindCustomersRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web_partnership.FindCustomersRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web_partnership.FindCustomersRes}
 */
proto.customer.web_partnership.FindCustomersRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.customer.web_partnership.BasicCustomerInfo;
      reader.readMessage(value,proto.customer.web_partnership.BasicCustomerInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web_partnership.FindCustomersRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web_partnership.FindCustomersRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web_partnership.FindCustomersRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.FindCustomersRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.customer.web_partnership.BasicCustomerInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BasicCustomerInfo items = 1;
 * @return {!Array<!proto.customer.web_partnership.BasicCustomerInfo>}
 */
proto.customer.web_partnership.FindCustomersRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.customer.web_partnership.BasicCustomerInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.customer.web_partnership.BasicCustomerInfo, 1));
};


/**
 * @param {!Array<!proto.customer.web_partnership.BasicCustomerInfo>} value
 * @return {!proto.customer.web_partnership.FindCustomersRes} returns this
*/
proto.customer.web_partnership.FindCustomersRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.customer.web_partnership.BasicCustomerInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.customer.web_partnership.BasicCustomerInfo}
 */
proto.customer.web_partnership.FindCustomersRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.customer.web_partnership.BasicCustomerInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.customer.web_partnership.FindCustomersRes} returns this
 */
proto.customer.web_partnership.FindCustomersRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.customer.web_partnership.FindCustomersRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.customer.web_partnership.FindCustomersRes} returns this
*/
proto.customer.web_partnership.FindCustomersRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.web_partnership.FindCustomersRes} returns this
 */
proto.customer.web_partnership.FindCustomersRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.web_partnership.FindCustomersRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web_partnership.GetCustomerByIDReq.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web_partnership.GetCustomerByIDReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web_partnership.GetCustomerByIDReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetCustomerByIDReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web_partnership.GetCustomerByIDReq}
 */
proto.customer.web_partnership.GetCustomerByIDReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web_partnership.GetCustomerByIDReq;
  return proto.customer.web_partnership.GetCustomerByIDReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web_partnership.GetCustomerByIDReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web_partnership.GetCustomerByIDReq}
 */
proto.customer.web_partnership.GetCustomerByIDReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web_partnership.GetCustomerByIDReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web_partnership.GetCustomerByIDReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web_partnership.GetCustomerByIDReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetCustomerByIDReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.customer.web_partnership.GetCustomerByIDReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.GetCustomerByIDReq} returns this
 */
proto.customer.web_partnership.GetCustomerByIDReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.customer.web_partnership.GetCustomerByIDRes.prototype.toObject = function(opt_includeInstance) {
  return proto.customer.web_partnership.GetCustomerByIDRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.customer.web_partnership.GetCustomerByIDRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetCustomerByIDRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && customer_customer_pb.UserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.customer.web_partnership.GetCustomerByIDRes}
 */
proto.customer.web_partnership.GetCustomerByIDRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.customer.web_partnership.GetCustomerByIDRes;
  return proto.customer.web_partnership.GetCustomerByIDRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.customer.web_partnership.GetCustomerByIDRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.customer.web_partnership.GetCustomerByIDRes}
 */
proto.customer.web_partnership.GetCustomerByIDRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new customer_customer_pb.UserInfo;
      reader.readMessage(value,customer_customer_pb.UserInfo.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.customer.web_partnership.GetCustomerByIDRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.customer.web_partnership.GetCustomerByIDRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.customer.web_partnership.GetCustomerByIDRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.customer.web_partnership.GetCustomerByIDRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      customer_customer_pb.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.customer.web_partnership.GetCustomerByIDRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.customer.web_partnership.GetCustomerByIDRes} returns this
 */
proto.customer.web_partnership.GetCustomerByIDRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.customer.web_partnership.GetCustomerByIDRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.customer.web_partnership.GetCustomerByIDRes} returns this
 */
proto.customer.web_partnership.GetCustomerByIDRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional customer.UserInfo data = 3;
 * @return {?proto.customer.UserInfo}
 */
proto.customer.web_partnership.GetCustomerByIDRes.prototype.getData = function() {
  return /** @type{?proto.customer.UserInfo} */ (
    jspb.Message.getWrapperField(this, customer_customer_pb.UserInfo, 3));
};


/**
 * @param {?proto.customer.UserInfo|undefined} value
 * @return {!proto.customer.web_partnership.GetCustomerByIDRes} returns this
*/
proto.customer.web_partnership.GetCustomerByIDRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.customer.web_partnership.GetCustomerByIDRes} returns this
 */
proto.customer.web_partnership.GetCustomerByIDRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.customer.web_partnership.GetCustomerByIDRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * @enum {number}
 */
proto.customer.web_partnership.Gender = {
  GENDER_INVALID: 0,
  GENDER_MALE: 1,
  GENDER_FEMALE: 2,
  GENDER_OTHER: 3
};

/**
 * @enum {number}
 */
proto.customer.web_partnership.SocialType = {
  SOCIAL_TYPE_INVALID: 0,
  SOCIAL_TYPE_FACEBOOK: 1,
  SOCIAL_TYPE_GOOGLE: 2,
  SOCIAL_TYPE_APPLE: 3
};

goog.object.extend(exports, proto.customer.web_partnership);
