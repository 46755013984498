// source: airplane/web_partnership/hidden_service_fee.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var airplane_base_pb = require('../../airplane/base_pb.js');
goog.object.extend(proto, airplane_base_pb);
var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter', null, global);
goog.exportSymbol('proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.displayName = 'proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.displayName = 'proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.displayName = 'proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.displayName = 'proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.displayName = 'proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq.displayName = 'proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    vat: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    airlineCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    officeId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    route: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bookingClassList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    percent: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq}
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq;
  return proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq}
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.airplane.FlightItineraryType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVat(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirlineCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoute(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addBookingClass(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getVat();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getAirlineCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRoute();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBookingClassList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional airplane.FlightItineraryType type = 1;
 * @return {!proto.airplane.FlightItineraryType}
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.getType = function() {
  return /** @type {!proto.airplane.FlightItineraryType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.airplane.FlightItineraryType} value
 * @return {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double vat = 3;
 * @return {number}
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.getVat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.setVat = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string airline_code = 4;
 * @return {string}
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.getAirlineCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.setAirlineCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string office_id = 5;
 * @return {string}
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string route = 6;
 * @return {string}
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.getRoute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.setRoute = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string booking_class = 7;
 * @return {!Array<string>}
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.getBookingClassList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.setBookingClassList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.addBookingClass = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.clearBookingClassList = function() {
  return this.setBookingClassList([]);
};


/**
 * optional double percent = 8;
 * @return {number}
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.AddFlightHiddenServiceFeeReq.prototype.setPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    vat: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    airlineCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    route: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bookingClass: jspb.Message.getFieldWithDefault(msg, 7, ""),
    percent: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq}
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq;
  return proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq}
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.airplane.FlightItineraryType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirlineCode(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoute(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingClass(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getVat();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getAirlineCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRoute();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBookingClass();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPercent();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional airplane.FlightItineraryType type = 2;
 * @return {!proto.airplane.FlightItineraryType}
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.getType = function() {
  return /** @type {!proto.airplane.FlightItineraryType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.airplane.FlightItineraryType} value
 * @return {!proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional double amount = 3;
 * @return {number}
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double vat = 4;
 * @return {number}
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.getVat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.setVat = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string airline_code = 5;
 * @return {string}
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.getAirlineCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.setAirlineCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string route = 6;
 * @return {string}
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.getRoute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.setRoute = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string booking_class = 7;
 * @return {string}
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.getBookingClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.setBookingClass = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double percent = 8;
 * @return {number}
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.getPercent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.UpdateFlightHiddenServiceFeeReq.prototype.setPercent = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f),
    filter: (f = msg.getFilter()) && proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq;
  return proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter;
      reader.readMessage(value,proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.toObject = function(includeInstance, msg) {
  var f, obj = {
    airlineCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    officeId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    route: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bookingClassList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter;
  return proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAirlineCode(value);
      break;
    case 2:
      var value = /** @type {!proto.airplane.FlightItineraryType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRoute(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addBookingClass(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirlineCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRoute();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBookingClassList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional string airline_code = 1;
 * @return {string}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.prototype.getAirlineCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter} returns this
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.prototype.setAirlineCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional airplane.FlightItineraryType type = 2;
 * @return {!proto.airplane.FlightItineraryType}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.prototype.getType = function() {
  return /** @type {!proto.airplane.FlightItineraryType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.airplane.FlightItineraryType} value
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter} returns this
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string office_id = 3;
 * @return {string}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter} returns this
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string route = 4;
 * @return {string}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.prototype.getRoute = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter} returns this
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.prototype.setRoute = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated string booking_class = 5;
 * @return {!Array<string>}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.prototype.getBookingClassList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter} returns this
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.prototype.setBookingClassList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter} returns this
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.prototype.addBookingClass = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter} returns this
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter.prototype.clearBookingClassList = function() {
  return this.setBookingClassList([]);
};


/**
 * optional base.PaginationReq pagination = 1;
 * @return {?proto.base.PaginationReq}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 1));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq} returns this
*/
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Filter filter = 2;
 * @return {?proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.prototype.getFilter = function() {
  return /** @type{?proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter, 2));
};


/**
 * @param {?proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.Filter|undefined} value
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq} returns this
*/
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeReq.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    airplane_base_pb.FlightServiceFee.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes;
  return proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new airplane_base_pb.FlightServiceFee;
      reader.readMessage(value,airplane_base_pb.FlightServiceFee.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      airplane_base_pb.FlightServiceFee.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes} returns this
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes} returns this
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated airplane.FlightServiceFee items = 3;
 * @return {!Array<!proto.airplane.FlightServiceFee>}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.airplane.FlightServiceFee>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_base_pb.FlightServiceFee, 3));
};


/**
 * @param {!Array<!proto.airplane.FlightServiceFee>} value
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes} returns this
*/
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.FlightServiceFee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.FlightServiceFee}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.FlightServiceFee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes} returns this
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes} returns this
*/
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes} returns this
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.ListFlightHiddenServiceFeeRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq}
 */
proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq;
  return proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq}
 */
proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq} returns this
 */
proto.airplane.web_partnership.DeleteFlightHiddenServiceFeeReq.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.airplane.web_partnership);
