/**
 * @fileoverview gRPC-Web generated client stub for partner.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: partner/web_partnership/partner.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var partner_base_pb = require('../../partner/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')

var base_enum_pb = require('../../base/enum_pb.js')

var base_base_pb = require('../../base/base_pb.js')
const proto = {};
proto.partner = {};
proto.partner.web_partnership = require('./partner_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.partner.web_partnership.PartnerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.partner.web_partnership.PartnerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.CreatePartnerReq,
 *   !proto.partner.web_partnership.CreatePartnerRes>}
 */
const methodDescriptor_PartnerService_CreatePartner = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerService/CreatePartner',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.CreatePartnerReq,
  proto.partner.web_partnership.CreatePartnerRes,
  /**
   * @param {!proto.partner.web_partnership.CreatePartnerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.CreatePartnerRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.CreatePartnerReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.CreatePartnerRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.CreatePartnerRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerServiceClient.prototype.createPartner =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/CreatePartner',
      request,
      metadata || {},
      methodDescriptor_PartnerService_CreatePartner,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.CreatePartnerReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.CreatePartnerRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerServicePromiseClient.prototype.createPartner =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/CreatePartner',
      request,
      metadata || {},
      methodDescriptor_PartnerService_CreatePartner);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.base.Empty,
 *   !proto.partner.web_partnership.GetOfficesRes>}
 */
const methodDescriptor_PartnerService_GetOffices = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerService/GetOffices',
  grpc.web.MethodType.UNARY,
  base_base_pb.Empty,
  proto.partner.web_partnership.GetOfficesRes,
  /**
   * @param {!proto.base.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.GetOfficesRes.deserializeBinary
);


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.GetOfficesRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.GetOfficesRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerServiceClient.prototype.getOffices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/GetOffices',
      request,
      metadata || {},
      methodDescriptor_PartnerService_GetOffices,
      callback);
};


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.GetOfficesRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerServicePromiseClient.prototype.getOffices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/GetOffices',
      request,
      metadata || {},
      methodDescriptor_PartnerService_GetOffices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.CreateOfficeReq,
 *   !proto.partner.web_partnership.CreateOfficeRes>}
 */
const methodDescriptor_PartnerService_CreateOffice = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerService/CreateOffice',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.CreateOfficeReq,
  proto.partner.web_partnership.CreateOfficeRes,
  /**
   * @param {!proto.partner.web_partnership.CreateOfficeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.CreateOfficeRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.CreateOfficeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.CreateOfficeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.CreateOfficeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerServiceClient.prototype.createOffice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/CreateOffice',
      request,
      metadata || {},
      methodDescriptor_PartnerService_CreateOffice,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.CreateOfficeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.CreateOfficeRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerServicePromiseClient.prototype.createOffice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/CreateOffice',
      request,
      metadata || {},
      methodDescriptor_PartnerService_CreateOffice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.ListMyOfficesReq,
 *   !proto.partner.web_partnership.ListMyOfficesRes>}
 */
const methodDescriptor_PartnerService_ListMyOffices = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerService/ListMyOffices',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.ListMyOfficesReq,
  proto.partner.web_partnership.ListMyOfficesRes,
  /**
   * @param {!proto.partner.web_partnership.ListMyOfficesReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.ListMyOfficesRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.ListMyOfficesReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.ListMyOfficesRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.ListMyOfficesRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerServiceClient.prototype.listMyOffices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/ListMyOffices',
      request,
      metadata || {},
      methodDescriptor_PartnerService_ListMyOffices,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.ListMyOfficesReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.ListMyOfficesRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerServicePromiseClient.prototype.listMyOffices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/ListMyOffices',
      request,
      metadata || {},
      methodDescriptor_PartnerService_ListMyOffices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.GetOfficeDetailReq,
 *   !proto.partner.web_partnership.GetOfficeDetailRes>}
 */
const methodDescriptor_PartnerService_GetOfficeDetail = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerService/GetOfficeDetail',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.GetOfficeDetailReq,
  proto.partner.web_partnership.GetOfficeDetailRes,
  /**
   * @param {!proto.partner.web_partnership.GetOfficeDetailReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.GetOfficeDetailRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.GetOfficeDetailReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.GetOfficeDetailRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.GetOfficeDetailRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerServiceClient.prototype.getOfficeDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/GetOfficeDetail',
      request,
      metadata || {},
      methodDescriptor_PartnerService_GetOfficeDetail,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.GetOfficeDetailReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.GetOfficeDetailRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerServicePromiseClient.prototype.getOfficeDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/GetOfficeDetail',
      request,
      metadata || {},
      methodDescriptor_PartnerService_GetOfficeDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.UpdateOfficeReq,
 *   !proto.partner.web_partnership.UpdateOfficeRes>}
 */
const methodDescriptor_PartnerService_UpdateOffice = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerService/UpdateOffice',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.UpdateOfficeReq,
  proto.partner.web_partnership.UpdateOfficeRes,
  /**
   * @param {!proto.partner.web_partnership.UpdateOfficeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.UpdateOfficeRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.UpdateOfficeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.UpdateOfficeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.UpdateOfficeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerServiceClient.prototype.updateOffice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/UpdateOffice',
      request,
      metadata || {},
      methodDescriptor_PartnerService_UpdateOffice,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.UpdateOfficeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.UpdateOfficeRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerServicePromiseClient.prototype.updateOffice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/UpdateOffice',
      request,
      metadata || {},
      methodDescriptor_PartnerService_UpdateOffice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.UpdateOfficeProvidersReq,
 *   !proto.partner.web_partnership.UpdateOfficeProvidersRes>}
 */
const methodDescriptor_PartnerService_UpdateOfficeProviders = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerService/UpdateOfficeProviders',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.UpdateOfficeProvidersReq,
  proto.partner.web_partnership.UpdateOfficeProvidersRes,
  /**
   * @param {!proto.partner.web_partnership.UpdateOfficeProvidersReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.UpdateOfficeProvidersRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.UpdateOfficeProvidersReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.UpdateOfficeProvidersRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.UpdateOfficeProvidersRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerServiceClient.prototype.updateOfficeProviders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/UpdateOfficeProviders',
      request,
      metadata || {},
      methodDescriptor_PartnerService_UpdateOfficeProviders,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.UpdateOfficeProvidersReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.UpdateOfficeProvidersRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerServicePromiseClient.prototype.updateOfficeProviders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/UpdateOfficeProviders',
      request,
      metadata || {},
      methodDescriptor_PartnerService_UpdateOfficeProviders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.UpdateOfficeHotelInfoReq,
 *   !proto.partner.web_partnership.UpdateOfficeHotelInfoRes>}
 */
const methodDescriptor_PartnerService_UpdateOfficeHotelInfo = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerService/UpdateOfficeHotelInfo',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.UpdateOfficeHotelInfoReq,
  proto.partner.web_partnership.UpdateOfficeHotelInfoRes,
  /**
   * @param {!proto.partner.web_partnership.UpdateOfficeHotelInfoReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.UpdateOfficeHotelInfoRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.UpdateOfficeHotelInfoReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.UpdateOfficeHotelInfoRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.UpdateOfficeHotelInfoRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerServiceClient.prototype.updateOfficeHotelInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/UpdateOfficeHotelInfo',
      request,
      metadata || {},
      methodDescriptor_PartnerService_UpdateOfficeHotelInfo,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.UpdateOfficeHotelInfoReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.UpdateOfficeHotelInfoRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerServicePromiseClient.prototype.updateOfficeHotelInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/UpdateOfficeHotelInfo',
      request,
      metadata || {},
      methodDescriptor_PartnerService_UpdateOfficeHotelInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.GetDomainsReq,
 *   !proto.partner.web_partnership.GetDomainsRes>}
 */
const methodDescriptor_PartnerService_GetDomains = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerService/GetDomains',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.GetDomainsReq,
  proto.partner.web_partnership.GetDomainsRes,
  /**
   * @param {!proto.partner.web_partnership.GetDomainsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.GetDomainsRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.GetDomainsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.GetDomainsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.GetDomainsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerServiceClient.prototype.getDomains =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/GetDomains',
      request,
      metadata || {},
      methodDescriptor_PartnerService_GetDomains,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.GetDomainsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.GetDomainsRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerServicePromiseClient.prototype.getDomains =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/GetDomains',
      request,
      metadata || {},
      methodDescriptor_PartnerService_GetDomains);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.GetProvidersReq,
 *   !proto.partner.web_partnership.GetProvidersRes>}
 */
const methodDescriptor_PartnerService_GetProviders = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerService/GetProviders',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.GetProvidersReq,
  proto.partner.web_partnership.GetProvidersRes,
  /**
   * @param {!proto.partner.web_partnership.GetProvidersReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.GetProvidersRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.GetProvidersReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.GetProvidersRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.GetProvidersRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerServiceClient.prototype.getProviders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/GetProviders',
      request,
      metadata || {},
      methodDescriptor_PartnerService_GetProviders,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.GetProvidersReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.GetProvidersRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerServicePromiseClient.prototype.getProviders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/GetProviders',
      request,
      metadata || {},
      methodDescriptor_PartnerService_GetProviders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.GetUnassignedOfficesReq,
 *   !proto.partner.web_partnership.GetUnassignedOfficesRes>}
 */
const methodDescriptor_PartnerService_GetUnassignedOffices = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerService/GetUnassignedOffices',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.GetUnassignedOfficesReq,
  proto.partner.web_partnership.GetUnassignedOfficesRes,
  /**
   * @param {!proto.partner.web_partnership.GetUnassignedOfficesReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.GetUnassignedOfficesRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.GetUnassignedOfficesReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.GetUnassignedOfficesRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.GetUnassignedOfficesRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerServiceClient.prototype.getUnassignedOffices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/GetUnassignedOffices',
      request,
      metadata || {},
      methodDescriptor_PartnerService_GetUnassignedOffices,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.GetUnassignedOfficesReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.GetUnassignedOfficesRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerServicePromiseClient.prototype.getUnassignedOffices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/GetUnassignedOffices',
      request,
      metadata || {},
      methodDescriptor_PartnerService_GetUnassignedOffices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.ChangeOfficePasswordReq,
 *   !proto.partner.web_partnership.ChangeOfficePasswordRes>}
 */
const methodDescriptor_PartnerService_ChangeOfficePassword = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerService/ChangeOfficePassword',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.ChangeOfficePasswordReq,
  proto.partner.web_partnership.ChangeOfficePasswordRes,
  /**
   * @param {!proto.partner.web_partnership.ChangeOfficePasswordReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.ChangeOfficePasswordRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.ChangeOfficePasswordReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.ChangeOfficePasswordRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.ChangeOfficePasswordRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerServiceClient.prototype.changeOfficePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/ChangeOfficePassword',
      request,
      metadata || {},
      methodDescriptor_PartnerService_ChangeOfficePassword,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.ChangeOfficePasswordReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.ChangeOfficePasswordRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerServicePromiseClient.prototype.changeOfficePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerService/ChangeOfficePassword',
      request,
      metadata || {},
      methodDescriptor_PartnerService_ChangeOfficePassword);
};


module.exports = proto.partner.web_partnership;

