import {
  Button,
  Form,
  FormInput,
  OrdersIcon,
  SearchLineIcon,
} from '@common-ui';
import {
  ICustomerInfo,
  ISearchCustomerFilterReq,
  customerApiService,
} from '@tixlabs/grpc-client/web-partnership';

import { IPaginationRes } from '@tixlabs/grpc-client';

import { useAppMutation } from '@vemaybay-admin/hooks/internals';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import TableContent from './TableContent';

type Props = {
  //
};

const DEFAULT_PAGE_LIMIT = 20;

const DEFAULT_CUSTOMER_FILTER: ISearchCustomerFilterReq = {
  name: '',
  email: '',
  phone: '',
};
export const ListCustomer = (props: Props) => {
  const methods = useForm({
    defaultValues: DEFAULT_CUSTOMER_FILTER,
  });

  const [paginationRes, setPaginationRes] = useState<IPaginationRes>({
    pageCurrent: 1,
    pageLimit: DEFAULT_PAGE_LIMIT,
    totalPage: 1,
    totalRecord: 0,
  });
  const [filterReq, setFilterReq] = useState<ISearchCustomerFilterReq>(
    DEFAULT_CUSTOMER_FILTER
  );
  const [listCustomer, setListCustomer] = useState<ICustomerInfo[]>([]);
  const { mutateAsync: search, isLoading } = useAppMutation({
    mutationKey: ['customerApiService', 'search'],
    mutationFn: customerApiService.search,
    onSuccess: ({ isSuccess, data, errorCode }) => {
      if (isSuccess && data) {
        data.pagination && setPaginationRes(data.pagination);
        setListCustomer(data.itemsList);
      } else {
        console.log('error', errorCode);
      }
    },
    onError: (err) => {
      console.log('oops...', err);
    },
  });

  const handleSubmit = async (data: ISearchCustomerFilterReq) => {
    setFilterReq(data);
    await search({
      filter: data,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: 1,
      },
    });
  };

  const handlePagination = async (page: number) => {
    await search({
      filter: filterReq,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: page,
      },
    });
  };

  const getListCustomer = useCallback(async () => {
    await search({
      filter: DEFAULT_CUSTOMER_FILTER,
      pagination: {
        pageLimit: DEFAULT_PAGE_LIMIT,
        pageNumber: 1,
      },
    });
  }, []);

  useEffect(() => {
    getListCustomer();
  }, []);

  return (
    <div className='space-y-5'>
      <div className='flex space-x-2.5 items-center'>
        <OrdersIcon className='w-5 h-5 shrink-0' />
        <span className='font-semibold text-lg'>Quản lý khách hàng</span>
      </div>
      <div className='w-full bg-white border rounded p-3.5'>
        <Form
          methods={methods}
          onSubmit={handleSubmit}
          className='flex justify-between pb-4 border-b mb-4'>
          <div className='flex space-x-2.5'>
            <FormInput
              name='name'
              label='Tên khách hàng'
              placeholder='Nhập tên khách hàng'
            />
            <FormInput
              name='phone'
              label='Số điện thoại khách hàng'
              placeholder='Nhập số điện thoại'
            />
            <FormInput
              name='email'
              label='Email khách hàng'
              placeholder='Nhập email khách hàng'
            />

            <div className='flex flex-col justify-end'>
              <Button
                type='submit'
                theme='secondary'
                size='sm'
                prefixIcon={<SearchLineIcon />}
                className='font-semibold !px-5'>
                Tìm kiếm
              </Button>
            </div>
          </div>
        </Form>
        <TableContent
          listCustomer={listCustomer}
          isLoading={isLoading}
          pagination={paginationRes}
          onPageChange={handlePagination}
        />
      </div>
    </div>
  );
};

export default ListCustomer;
