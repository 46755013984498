import cn from 'classnames';
import { ReactNode } from 'react';
import { BookingIcon, MoneyDollarCircleFillIcon, TicketIcon } from '../..';
const objectRightImage = {
  booking: BookingIcon,
  ticket: TicketIcon,
  price: MoneyDollarCircleFillIcon,
} as const;

type Props = {
  title: string;
  value: ReactNode;
  iconKey: keyof typeof objectRightImage;
  className?: string;
  titleClassName?: string;
};

export function StatisticCardInfo({
  title,
  value,
  iconKey = 'booking',
  className,
  titleClassName,
}: Props) {
  const RightIcon = objectRightImage[iconKey];
  return (
    <div
      className={cn(
        className,
        'p-3 flex items-center justify-between gap-x-3 bg-neutral-1 border border-neutral-5 rounded'
      )}>
      <div>
        <div className={titleClassName}>{title}</div>
        <div className='font-bold text-2xl'>{value}</div>
      </div>
      <div className='rotate-[-16.203deg] shrink-0'>
        <RightIcon className='text-neutral-4 w-14 h-14 ' />
      </div>
    </div>
  );
}

export default StatisticCardInfo;
