// source: airplane/flight.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var airplane_base_pb = require('../airplane/base_pb.js');
goog.object.extend(proto, airplane_base_pb);
var airplane_hub_pb = require('../airplane/hub_pb.js');
goog.object.extend(proto, airplane_hub_pb);
var airplane_seat_pb = require('../airplane/seat_pb.js');
goog.object.extend(proto, airplane_seat_pb);
var validate_validate_pb = require('../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.airplane.AccessTradeRefInfo', null, global);
goog.exportSymbol('proto.airplane.BaggageCharge', null, global);
goog.exportSymbol('proto.airplane.BaggageInfo', null, global);
goog.exportSymbol('proto.airplane.BaggageOption', null, global);
goog.exportSymbol('proto.airplane.BaggageSelectionInfo', null, global);
goog.exportSymbol('proto.airplane.BookingContactInfo', null, global);
goog.exportSymbol('proto.airplane.BookingItinerary', null, global);
goog.exportSymbol('proto.airplane.BookingPassenger', null, global);
goog.exportSymbol('proto.airplane.ETicket', null, global);
goog.exportSymbol('proto.airplane.InvoicingInformation', null, global);
goog.exportSymbol('proto.airplane.InvoicingInformation.Company', null, global);
goog.exportSymbol('proto.airplane.InvoicingInformation.Receiver', null, global);
goog.exportSymbol('proto.airplane.RefundInfo', null, global);
goog.exportSymbol('proto.airplane.Segment', null, global);
goog.exportSymbol('proto.airplane.TransferBookingRequestItem', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.BookingPassenger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.BookingPassenger.repeatedFields_, null);
};
goog.inherits(proto.airplane.BookingPassenger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.BookingPassenger.displayName = 'proto.airplane.BookingPassenger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.BaggageSelectionInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.BaggageSelectionInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.BaggageSelectionInfo.displayName = 'proto.airplane.BaggageSelectionInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.BookingItinerary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.BookingItinerary.repeatedFields_, null);
};
goog.inherits(proto.airplane.BookingItinerary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.BookingItinerary.displayName = 'proto.airplane.BookingItinerary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.ETicket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.ETicket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.ETicket.displayName = 'proto.airplane.ETicket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.Segment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.Segment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.Segment.displayName = 'proto.airplane.Segment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.BookingContactInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.BookingContactInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.BookingContactInfo.displayName = 'proto.airplane.BookingContactInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.RefundInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.RefundInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.RefundInfo.displayName = 'proto.airplane.RefundInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.InvoicingInformation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.InvoicingInformation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.InvoicingInformation.displayName = 'proto.airplane.InvoicingInformation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.InvoicingInformation.Company = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.InvoicingInformation.Company, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.InvoicingInformation.Company.displayName = 'proto.airplane.InvoicingInformation.Company';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.InvoicingInformation.Receiver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.InvoicingInformation.Receiver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.InvoicingInformation.Receiver.displayName = 'proto.airplane.InvoicingInformation.Receiver';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.BaggageOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.BaggageOption.repeatedFields_, null);
};
goog.inherits(proto.airplane.BaggageOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.BaggageOption.displayName = 'proto.airplane.BaggageOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.BaggageCharge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.BaggageCharge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.BaggageCharge.displayName = 'proto.airplane.BaggageCharge';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.BaggageInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.BaggageInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.BaggageInfo.displayName = 'proto.airplane.BaggageInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.TransferBookingRequestItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.TransferBookingRequestItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.TransferBookingRequestItem.displayName = 'proto.airplane.TransferBookingRequestItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.AccessTradeRefInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.AccessTradeRefInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.AccessTradeRefInfo.displayName = 'proto.airplane.AccessTradeRefInfo';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.BookingPassenger.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.BookingPassenger.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.BookingPassenger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.BookingPassenger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BookingPassenger.toObject = function(includeInstance, msg) {
  var f, obj = {
    paxId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 5, 0),
    dob: jspb.Message.getFieldWithDefault(msg, 6, 0),
    nationality: jspb.Message.getFieldWithDefault(msg, 7, ""),
    passportNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    expirationDate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    idIssueCountry: jspb.Message.getFieldWithDefault(msg, 10, ""),
    seatsList: jspb.Message.toObjectList(msg.getSeatsList(),
    airplane_seat_pb.SeatSelectionInfo.toObject, includeInstance),
    baggagesList: jspb.Message.toObjectList(msg.getBaggagesList(),
    proto.airplane.BaggageSelectionInfo.toObject, includeInstance),
    dobNull: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.BookingPassenger}
 */
proto.airplane.BookingPassenger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.BookingPassenger;
  return proto.airplane.BookingPassenger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.BookingPassenger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.BookingPassenger}
 */
proto.airplane.BookingPassenger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaxId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {!proto.airplane.PaxType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {!proto.base.GENDER} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDob(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNationality(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassportNumber(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpirationDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdIssueCountry(value);
      break;
    case 11:
      var value = new airplane_seat_pb.SeatSelectionInfo;
      reader.readMessage(value,airplane_seat_pb.SeatSelectionInfo.deserializeBinaryFromReader);
      msg.addSeats(value);
      break;
    case 12:
      var value = new proto.airplane.BaggageSelectionInfo;
      reader.readMessage(value,proto.airplane.BaggageSelectionInfo.deserializeBinaryFromReader);
      msg.addBaggages(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDobNull(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.BookingPassenger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.BookingPassenger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.BookingPassenger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BookingPassenger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaxId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSeatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      airplane_seat_pb.SeatSelectionInfo.serializeBinaryToWriter
    );
  }
  f = message.getBaggagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.airplane.BaggageSelectionInfo.serializeBinaryToWriter
    );
  }
  f = message.getDobNull();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional int64 pax_id = 1;
 * @return {number}
 */
proto.airplane.BookingPassenger.prototype.getPaxId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.setPaxId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.airplane.BookingPassenger.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.airplane.BookingPassenger.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional PaxType type = 4;
 * @return {!proto.airplane.PaxType}
 */
proto.airplane.BookingPassenger.prototype.getType = function() {
  return /** @type {!proto.airplane.PaxType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.airplane.PaxType} value
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional base.GENDER gender = 5;
 * @return {!proto.base.GENDER}
 */
proto.airplane.BookingPassenger.prototype.getGender = function() {
  return /** @type {!proto.base.GENDER} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.base.GENDER} value
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 dob = 6;
 * @return {number}
 */
proto.airplane.BookingPassenger.prototype.getDob = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.setDob = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.clearDob = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.BookingPassenger.prototype.hasDob = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string nationality = 7;
 * @return {string}
 */
proto.airplane.BookingPassenger.prototype.getNationality = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.setNationality = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.clearNationality = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.BookingPassenger.prototype.hasNationality = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string passport_number = 8;
 * @return {string}
 */
proto.airplane.BookingPassenger.prototype.getPassportNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.setPassportNumber = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.clearPassportNumber = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.BookingPassenger.prototype.hasPassportNumber = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 expiration_date = 9;
 * @return {number}
 */
proto.airplane.BookingPassenger.prototype.getExpirationDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.setExpirationDate = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.clearExpirationDate = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.BookingPassenger.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string id_issue_country = 10;
 * @return {string}
 */
proto.airplane.BookingPassenger.prototype.getIdIssueCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.setIdIssueCountry = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.clearIdIssueCountry = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.BookingPassenger.prototype.hasIdIssueCountry = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated SeatSelectionInfo seats = 11;
 * @return {!Array<!proto.airplane.SeatSelectionInfo>}
 */
proto.airplane.BookingPassenger.prototype.getSeatsList = function() {
  return /** @type{!Array<!proto.airplane.SeatSelectionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_seat_pb.SeatSelectionInfo, 11));
};


/**
 * @param {!Array<!proto.airplane.SeatSelectionInfo>} value
 * @return {!proto.airplane.BookingPassenger} returns this
*/
proto.airplane.BookingPassenger.prototype.setSeatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.airplane.SeatSelectionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.SeatSelectionInfo}
 */
proto.airplane.BookingPassenger.prototype.addSeats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.airplane.SeatSelectionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.clearSeatsList = function() {
  return this.setSeatsList([]);
};


/**
 * repeated BaggageSelectionInfo baggages = 12;
 * @return {!Array<!proto.airplane.BaggageSelectionInfo>}
 */
proto.airplane.BookingPassenger.prototype.getBaggagesList = function() {
  return /** @type{!Array<!proto.airplane.BaggageSelectionInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.BaggageSelectionInfo, 12));
};


/**
 * @param {!Array<!proto.airplane.BaggageSelectionInfo>} value
 * @return {!proto.airplane.BookingPassenger} returns this
*/
proto.airplane.BookingPassenger.prototype.setBaggagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.airplane.BaggageSelectionInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.BaggageSelectionInfo}
 */
proto.airplane.BookingPassenger.prototype.addBaggages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.airplane.BaggageSelectionInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.clearBaggagesList = function() {
  return this.setBaggagesList([]);
};


/**
 * optional bool dob_null = 13;
 * @return {boolean}
 */
proto.airplane.BookingPassenger.prototype.getDobNull = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.BookingPassenger} returns this
 */
proto.airplane.BookingPassenger.prototype.setDobNull = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.BaggageSelectionInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.BaggageSelectionInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.BaggageSelectionInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BaggageSelectionInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    itineraryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    baggageOption: (f = msg.getBaggageOption()) && proto.airplane.BaggageOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.BaggageSelectionInfo}
 */
proto.airplane.BaggageSelectionInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.BaggageSelectionInfo;
  return proto.airplane.BaggageSelectionInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.BaggageSelectionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.BaggageSelectionInfo}
 */
proto.airplane.BaggageSelectionInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 2:
      var value = new proto.airplane.BaggageOption;
      reader.readMessage(value,proto.airplane.BaggageOption.deserializeBinaryFromReader);
      msg.setBaggageOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.BaggageSelectionInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.BaggageSelectionInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.BaggageSelectionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BaggageSelectionInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBaggageOption();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.airplane.BaggageOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional string itinerary_id = 1;
 * @return {string}
 */
proto.airplane.BaggageSelectionInfo.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BaggageSelectionInfo} returns this
 */
proto.airplane.BaggageSelectionInfo.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional BaggageOption baggage_option = 2;
 * @return {?proto.airplane.BaggageOption}
 */
proto.airplane.BaggageSelectionInfo.prototype.getBaggageOption = function() {
  return /** @type{?proto.airplane.BaggageOption} */ (
    jspb.Message.getWrapperField(this, proto.airplane.BaggageOption, 2));
};


/**
 * @param {?proto.airplane.BaggageOption|undefined} value
 * @return {!proto.airplane.BaggageSelectionInfo} returns this
*/
proto.airplane.BaggageSelectionInfo.prototype.setBaggageOption = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.BaggageSelectionInfo} returns this
 */
proto.airplane.BaggageSelectionInfo.prototype.clearBaggageOption = function() {
  return this.setBaggageOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.BaggageSelectionInfo.prototype.hasBaggageOption = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.BookingItinerary.repeatedFields_ = [16,17,18];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.BookingItinerary.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.BookingItinerary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.BookingItinerary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BookingItinerary.toObject = function(includeInstance, msg) {
  var f, obj = {
    itineraryId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    index: jspb.Message.getFieldWithDefault(msg, 2, 0),
    stopNumber: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cabinClass: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bookingClass: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fareBasis: jspb.Message.getFieldWithDefault(msg, 6, ""),
    availability: jspb.Message.getFieldWithDefault(msg, 7, 0),
    departPlace: jspb.Message.getFieldWithDefault(msg, 8, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 9, 0),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 10, ""),
    arrivalDate: jspb.Message.getFieldWithDefault(msg, 11, 0),
    carrierMarketing: jspb.Message.getFieldWithDefault(msg, 12, ""),
    carrierOperator: jspb.Message.getFieldWithDefault(msg, 13, ""),
    flightNumber: jspb.Message.getFieldWithDefault(msg, 14, ""),
    flightDuration: jspb.Message.getFieldWithDefault(msg, 15, 0),
    freeBaggageList: jspb.Message.toObjectList(msg.getFreeBaggageList(),
    airplane_hub_pb.HubBaggage.toObject, includeInstance),
    eticketsList: jspb.Message.toObjectList(msg.getEticketsList(),
    proto.airplane.ETicket.toObject, includeInstance),
    segmentsList: jspb.Message.toObjectList(msg.getSegmentsList(),
    proto.airplane.Segment.toObject, includeInstance),
    reservationCode: jspb.Message.getFieldWithDefault(msg, 19, ""),
    bookingStatus: jspb.Message.getFieldWithDefault(msg, 20, 0),
    flightId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    itineraryKey: jspb.Message.getFieldWithDefault(msg, 22, ""),
    feeDetails: (f = msg.getFeeDetails()) && airplane_base_pb.CalculationFlightFeeResData.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.BookingItinerary}
 */
proto.airplane.BookingItinerary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.BookingItinerary;
  return proto.airplane.BookingItinerary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.BookingItinerary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.BookingItinerary}
 */
proto.airplane.BookingItinerary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStopNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCabinClass(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingClass(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFareBasis(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAvailability(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalDate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierMarketing(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierOperator(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightNumber(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightDuration(value);
      break;
    case 16:
      var value = new airplane_hub_pb.HubBaggage;
      reader.readMessage(value,airplane_hub_pb.HubBaggage.deserializeBinaryFromReader);
      msg.addFreeBaggage(value);
      break;
    case 17:
      var value = new proto.airplane.ETicket;
      reader.readMessage(value,proto.airplane.ETicket.deserializeBinaryFromReader);
      msg.addEtickets(value);
      break;
    case 18:
      var value = new proto.airplane.Segment;
      reader.readMessage(value,proto.airplane.Segment.deserializeBinaryFromReader);
      msg.addSegments(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationCode(value);
      break;
    case 20:
      var value = /** @type {!proto.airplane.BookingStatus} */ (reader.readEnum());
      msg.setBookingStatus(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setItineraryKey(value);
      break;
    case 23:
      var value = new airplane_base_pb.CalculationFlightFeeResData;
      reader.readMessage(value,airplane_base_pb.CalculationFlightFeeResData.deserializeBinaryFromReader);
      msg.setFeeDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.BookingItinerary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.BookingItinerary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.BookingItinerary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BookingItinerary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItineraryId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getStopNumber();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCabinClass();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBookingClass();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFareBasis();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAvailability();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getArrivalDate();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getCarrierMarketing();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCarrierOperator();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getFlightNumber();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getFlightDuration();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getFreeBaggageList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      airplane_hub_pb.HubBaggage.serializeBinaryToWriter
    );
  }
  f = message.getEticketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.airplane.ETicket.serializeBinaryToWriter
    );
  }
  f = message.getSegmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.airplane.Segment.serializeBinaryToWriter
    );
  }
  f = message.getReservationCode();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getBookingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
  f = message.getFlightId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getItineraryKey();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getFeeDetails();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      airplane_base_pb.CalculationFlightFeeResData.serializeBinaryToWriter
    );
  }
};


/**
 * optional string itinerary_id = 1;
 * @return {string}
 */
proto.airplane.BookingItinerary.prototype.getItineraryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setItineraryId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 index = 2;
 * @return {number}
 */
proto.airplane.BookingItinerary.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 stop_number = 3;
 * @return {number}
 */
proto.airplane.BookingItinerary.prototype.getStopNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setStopNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string cabin_class = 4;
 * @return {string}
 */
proto.airplane.BookingItinerary.prototype.getCabinClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setCabinClass = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string booking_class = 5;
 * @return {string}
 */
proto.airplane.BookingItinerary.prototype.getBookingClass = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setBookingClass = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string fare_basis = 6;
 * @return {string}
 */
proto.airplane.BookingItinerary.prototype.getFareBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setFareBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 availability = 7;
 * @return {number}
 */
proto.airplane.BookingItinerary.prototype.getAvailability = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setAvailability = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string depart_place = 8;
 * @return {string}
 */
proto.airplane.BookingItinerary.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 depart_date = 9;
 * @return {number}
 */
proto.airplane.BookingItinerary.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string arrival_place = 10;
 * @return {string}
 */
proto.airplane.BookingItinerary.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int64 arrival_date = 11;
 * @return {number}
 */
proto.airplane.BookingItinerary.prototype.getArrivalDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setArrivalDate = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string carrier_marketing = 12;
 * @return {string}
 */
proto.airplane.BookingItinerary.prototype.getCarrierMarketing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setCarrierMarketing = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string carrier_operator = 13;
 * @return {string}
 */
proto.airplane.BookingItinerary.prototype.getCarrierOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setCarrierOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string flight_number = 14;
 * @return {string}
 */
proto.airplane.BookingItinerary.prototype.getFlightNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setFlightNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 flight_duration = 15;
 * @return {number}
 */
proto.airplane.BookingItinerary.prototype.getFlightDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setFlightDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated HubBaggage free_baggage = 16;
 * @return {!Array<!proto.airplane.HubBaggage>}
 */
proto.airplane.BookingItinerary.prototype.getFreeBaggageList = function() {
  return /** @type{!Array<!proto.airplane.HubBaggage>} */ (
    jspb.Message.getRepeatedWrapperField(this, airplane_hub_pb.HubBaggage, 16));
};


/**
 * @param {!Array<!proto.airplane.HubBaggage>} value
 * @return {!proto.airplane.BookingItinerary} returns this
*/
proto.airplane.BookingItinerary.prototype.setFreeBaggageList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.airplane.HubBaggage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.HubBaggage}
 */
proto.airplane.BookingItinerary.prototype.addFreeBaggage = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.airplane.HubBaggage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.clearFreeBaggageList = function() {
  return this.setFreeBaggageList([]);
};


/**
 * repeated ETicket etickets = 17;
 * @return {!Array<!proto.airplane.ETicket>}
 */
proto.airplane.BookingItinerary.prototype.getEticketsList = function() {
  return /** @type{!Array<!proto.airplane.ETicket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.ETicket, 17));
};


/**
 * @param {!Array<!proto.airplane.ETicket>} value
 * @return {!proto.airplane.BookingItinerary} returns this
*/
proto.airplane.BookingItinerary.prototype.setEticketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.airplane.ETicket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.ETicket}
 */
proto.airplane.BookingItinerary.prototype.addEtickets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.airplane.ETicket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.clearEticketsList = function() {
  return this.setEticketsList([]);
};


/**
 * repeated Segment segments = 18;
 * @return {!Array<!proto.airplane.Segment>}
 */
proto.airplane.BookingItinerary.prototype.getSegmentsList = function() {
  return /** @type{!Array<!proto.airplane.Segment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.Segment, 18));
};


/**
 * @param {!Array<!proto.airplane.Segment>} value
 * @return {!proto.airplane.BookingItinerary} returns this
*/
proto.airplane.BookingItinerary.prototype.setSegmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.airplane.Segment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.Segment}
 */
proto.airplane.BookingItinerary.prototype.addSegments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.airplane.Segment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.clearSegmentsList = function() {
  return this.setSegmentsList([]);
};


/**
 * optional string reservation_code = 19;
 * @return {string}
 */
proto.airplane.BookingItinerary.prototype.getReservationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setReservationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional BookingStatus booking_status = 20;
 * @return {!proto.airplane.BookingStatus}
 */
proto.airplane.BookingItinerary.prototype.getBookingStatus = function() {
  return /** @type {!proto.airplane.BookingStatus} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.airplane.BookingStatus} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setBookingStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};


/**
 * optional string flight_id = 21;
 * @return {string}
 */
proto.airplane.BookingItinerary.prototype.getFlightId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setFlightId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string itinerary_key = 22;
 * @return {string}
 */
proto.airplane.BookingItinerary.prototype.getItineraryKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.setItineraryKey = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional CalculationFlightFeeResData fee_details = 23;
 * @return {?proto.airplane.CalculationFlightFeeResData}
 */
proto.airplane.BookingItinerary.prototype.getFeeDetails = function() {
  return /** @type{?proto.airplane.CalculationFlightFeeResData} */ (
    jspb.Message.getWrapperField(this, airplane_base_pb.CalculationFlightFeeResData, 23));
};


/**
 * @param {?proto.airplane.CalculationFlightFeeResData|undefined} value
 * @return {!proto.airplane.BookingItinerary} returns this
*/
proto.airplane.BookingItinerary.prototype.setFeeDetails = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.BookingItinerary} returns this
 */
proto.airplane.BookingItinerary.prototype.clearFeeDetails = function() {
  return this.setFeeDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.BookingItinerary.prototype.hasFeeDetails = function() {
  return jspb.Message.getField(this, 23) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.ETicket.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.ETicket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.ETicket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.ETicket.toObject = function(includeInstance, msg) {
  var f, obj = {
    paxId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.ETicket}
 */
proto.airplane.ETicket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.ETicket;
  return proto.airplane.ETicket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.ETicket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.ETicket}
 */
proto.airplane.ETicket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPaxId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.ETicket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.ETicket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.ETicket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.ETicket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPaxId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 pax_id = 1;
 * @return {number}
 */
proto.airplane.ETicket.prototype.getPaxId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.ETicket} returns this
 */
proto.airplane.ETicket.prototype.setPaxId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string ticket_number = 2;
 * @return {string}
 */
proto.airplane.ETicket.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.ETicket} returns this
 */
proto.airplane.ETicket.prototype.setTicketNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.Segment.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.Segment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.Segment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.Segment.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    departPlace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 4, ""),
    arrivalDate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    carrierMarketing: jspb.Message.getFieldWithDefault(msg, 6, ""),
    carrierOperator: jspb.Message.getFieldWithDefault(msg, 7, ""),
    flightNumber: jspb.Message.getFieldWithDefault(msg, 8, ""),
    aircraft: jspb.Message.getFieldWithDefault(msg, 9, ""),
    flightDuration: jspb.Message.getFieldWithDefault(msg, 10, 0),
    departTerminal: jspb.Message.getFieldWithDefault(msg, 11, ""),
    arrivalTerminal: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.Segment}
 */
proto.airplane.Segment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.Segment;
  return proto.airplane.Segment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.Segment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.Segment}
 */
proto.airplane.Segment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setArrivalDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierMarketing(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCarrierOperator(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFlightNumber(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAircraft(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFlightDuration(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartTerminal(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalTerminal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.Segment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.Segment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.Segment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.Segment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getArrivalDate();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getCarrierMarketing();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCarrierOperator();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getFlightNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAircraft();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getFlightDuration();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getDepartTerminal();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getArrivalTerminal();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.airplane.Segment.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.Segment} returns this
 */
proto.airplane.Segment.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string depart_place = 2;
 * @return {string}
 */
proto.airplane.Segment.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Segment} returns this
 */
proto.airplane.Segment.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 depart_date = 3;
 * @return {number}
 */
proto.airplane.Segment.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.Segment} returns this
 */
proto.airplane.Segment.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string arrival_place = 4;
 * @return {string}
 */
proto.airplane.Segment.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Segment} returns this
 */
proto.airplane.Segment.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 arrival_date = 5;
 * @return {number}
 */
proto.airplane.Segment.prototype.getArrivalDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.Segment} returns this
 */
proto.airplane.Segment.prototype.setArrivalDate = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string carrier_marketing = 6;
 * @return {string}
 */
proto.airplane.Segment.prototype.getCarrierMarketing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Segment} returns this
 */
proto.airplane.Segment.prototype.setCarrierMarketing = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string carrier_operator = 7;
 * @return {string}
 */
proto.airplane.Segment.prototype.getCarrierOperator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Segment} returns this
 */
proto.airplane.Segment.prototype.setCarrierOperator = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string flight_number = 8;
 * @return {string}
 */
proto.airplane.Segment.prototype.getFlightNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Segment} returns this
 */
proto.airplane.Segment.prototype.setFlightNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string aircraft = 9;
 * @return {string}
 */
proto.airplane.Segment.prototype.getAircraft = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Segment} returns this
 */
proto.airplane.Segment.prototype.setAircraft = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 flight_duration = 10;
 * @return {number}
 */
proto.airplane.Segment.prototype.getFlightDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.Segment} returns this
 */
proto.airplane.Segment.prototype.setFlightDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string depart_terminal = 11;
 * @return {string}
 */
proto.airplane.Segment.prototype.getDepartTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Segment} returns this
 */
proto.airplane.Segment.prototype.setDepartTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string arrival_terminal = 12;
 * @return {string}
 */
proto.airplane.Segment.prototype.getArrivalTerminal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.Segment} returns this
 */
proto.airplane.Segment.prototype.setArrivalTerminal = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.BookingContactInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.BookingContactInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.BookingContactInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BookingContactInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    address: jspb.Message.getFieldWithDefault(msg, 7, ""),
    note: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.BookingContactInfo}
 */
proto.airplane.BookingContactInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.BookingContactInfo;
  return proto.airplane.BookingContactInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.BookingContactInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.BookingContactInfo}
 */
proto.airplane.BookingContactInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.BookingContactInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.BookingContactInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.BookingContactInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BookingContactInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.airplane.BookingContactInfo.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingContactInfo} returns this
 */
proto.airplane.BookingContactInfo.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.airplane.BookingContactInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingContactInfo} returns this
 */
proto.airplane.BookingContactInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.airplane.BookingContactInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingContactInfo} returns this
 */
proto.airplane.BookingContactInfo.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone_code = 4;
 * @return {string}
 */
proto.airplane.BookingContactInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingContactInfo} returns this
 */
proto.airplane.BookingContactInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string phone_number = 5;
 * @return {string}
 */
proto.airplane.BookingContactInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingContactInfo} returns this
 */
proto.airplane.BookingContactInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.airplane.BookingContactInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingContactInfo} returns this
 */
proto.airplane.BookingContactInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string address = 7;
 * @return {string}
 */
proto.airplane.BookingContactInfo.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingContactInfo} returns this
 */
proto.airplane.BookingContactInfo.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string note = 8;
 * @return {string}
 */
proto.airplane.BookingContactInfo.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BookingContactInfo} returns this
 */
proto.airplane.BookingContactInfo.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.RefundInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.RefundInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.RefundInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.RefundInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    refundedAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    note: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.RefundInfo}
 */
proto.airplane.RefundInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.RefundInfo;
  return proto.airplane.RefundInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.RefundInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.RefundInfo}
 */
proto.airplane.RefundInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRefundedAt(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.RefundInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.RefundInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.RefundInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.RefundInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRefundedAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 refunded_at = 1;
 * @return {number}
 */
proto.airplane.RefundInfo.prototype.getRefundedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.RefundInfo} returns this
 */
proto.airplane.RefundInfo.prototype.setRefundedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string note = 2;
 * @return {string}
 */
proto.airplane.RefundInfo.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.RefundInfo} returns this
 */
proto.airplane.RefundInfo.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.InvoicingInformation.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.InvoicingInformation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.InvoicingInformation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.InvoicingInformation.toObject = function(includeInstance, msg) {
  var f, obj = {
    companyInfo: (f = msg.getCompanyInfo()) && proto.airplane.InvoicingInformation.Company.toObject(includeInstance, f),
    receiverInfo: (f = msg.getReceiverInfo()) && proto.airplane.InvoicingInformation.Receiver.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.InvoicingInformation}
 */
proto.airplane.InvoicingInformation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.InvoicingInformation;
  return proto.airplane.InvoicingInformation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.InvoicingInformation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.InvoicingInformation}
 */
proto.airplane.InvoicingInformation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.airplane.InvoicingInformation.Company;
      reader.readMessage(value,proto.airplane.InvoicingInformation.Company.deserializeBinaryFromReader);
      msg.setCompanyInfo(value);
      break;
    case 2:
      var value = new proto.airplane.InvoicingInformation.Receiver;
      reader.readMessage(value,proto.airplane.InvoicingInformation.Receiver.deserializeBinaryFromReader);
      msg.setReceiverInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.InvoicingInformation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.InvoicingInformation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.InvoicingInformation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.InvoicingInformation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompanyInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.airplane.InvoicingInformation.Company.serializeBinaryToWriter
    );
  }
  f = message.getReceiverInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.airplane.InvoicingInformation.Receiver.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.InvoicingInformation.Company.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.InvoicingInformation.Company.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.InvoicingInformation.Company} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.InvoicingInformation.Company.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taxCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    address: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.InvoicingInformation.Company}
 */
proto.airplane.InvoicingInformation.Company.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.InvoicingInformation.Company;
  return proto.airplane.InvoicingInformation.Company.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.InvoicingInformation.Company} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.InvoicingInformation.Company}
 */
proto.airplane.InvoicingInformation.Company.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.InvoicingInformation.Company.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.InvoicingInformation.Company.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.InvoicingInformation.Company} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.InvoicingInformation.Company.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaxCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.airplane.InvoicingInformation.Company.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.InvoicingInformation.Company} returns this
 */
proto.airplane.InvoicingInformation.Company.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tax_code = 2;
 * @return {string}
 */
proto.airplane.InvoicingInformation.Company.prototype.getTaxCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.InvoicingInformation.Company} returns this
 */
proto.airplane.InvoicingInformation.Company.prototype.setTaxCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.airplane.InvoicingInformation.Company.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.InvoicingInformation.Company} returns this
 */
proto.airplane.InvoicingInformation.Company.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.InvoicingInformation.Receiver.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.InvoicingInformation.Receiver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.InvoicingInformation.Receiver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.InvoicingInformation.Receiver.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    address: jspb.Message.getFieldWithDefault(msg, 4, ""),
    note: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.InvoicingInformation.Receiver}
 */
proto.airplane.InvoicingInformation.Receiver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.InvoicingInformation.Receiver;
  return proto.airplane.InvoicingInformation.Receiver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.InvoicingInformation.Receiver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.InvoicingInformation.Receiver}
 */
proto.airplane.InvoicingInformation.Receiver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.InvoicingInformation.Receiver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.InvoicingInformation.Receiver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.InvoicingInformation.Receiver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.InvoicingInformation.Receiver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.airplane.InvoicingInformation.Receiver.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.InvoicingInformation.Receiver} returns this
 */
proto.airplane.InvoicingInformation.Receiver.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.airplane.InvoicingInformation.Receiver.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.InvoicingInformation.Receiver} returns this
 */
proto.airplane.InvoicingInformation.Receiver.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.airplane.InvoicingInformation.Receiver.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.InvoicingInformation.Receiver} returns this
 */
proto.airplane.InvoicingInformation.Receiver.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string address = 4;
 * @return {string}
 */
proto.airplane.InvoicingInformation.Receiver.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.InvoicingInformation.Receiver} returns this
 */
proto.airplane.InvoicingInformation.Receiver.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string note = 5;
 * @return {string}
 */
proto.airplane.InvoicingInformation.Receiver.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.InvoicingInformation.Receiver} returns this
 */
proto.airplane.InvoicingInformation.Receiver.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Company company_info = 1;
 * @return {?proto.airplane.InvoicingInformation.Company}
 */
proto.airplane.InvoicingInformation.prototype.getCompanyInfo = function() {
  return /** @type{?proto.airplane.InvoicingInformation.Company} */ (
    jspb.Message.getWrapperField(this, proto.airplane.InvoicingInformation.Company, 1));
};


/**
 * @param {?proto.airplane.InvoicingInformation.Company|undefined} value
 * @return {!proto.airplane.InvoicingInformation} returns this
*/
proto.airplane.InvoicingInformation.prototype.setCompanyInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.InvoicingInformation} returns this
 */
proto.airplane.InvoicingInformation.prototype.clearCompanyInfo = function() {
  return this.setCompanyInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.InvoicingInformation.prototype.hasCompanyInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Receiver receiver_info = 2;
 * @return {?proto.airplane.InvoicingInformation.Receiver}
 */
proto.airplane.InvoicingInformation.prototype.getReceiverInfo = function() {
  return /** @type{?proto.airplane.InvoicingInformation.Receiver} */ (
    jspb.Message.getWrapperField(this, proto.airplane.InvoicingInformation.Receiver, 2));
};


/**
 * @param {?proto.airplane.InvoicingInformation.Receiver|undefined} value
 * @return {!proto.airplane.InvoicingInformation} returns this
*/
proto.airplane.InvoicingInformation.prototype.setReceiverInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.InvoicingInformation} returns this
 */
proto.airplane.InvoicingInformation.prototype.clearReceiverInfo = function() {
  return this.setReceiverInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.InvoicingInformation.prototype.hasReceiverInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.BaggageOption.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.BaggageOption.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.BaggageOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.BaggageOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BaggageOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    optionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    itineraryIndex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    segmentIndexList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    baggageInfoList: jspb.Message.toObjectList(msg.getBaggageInfoList(),
    proto.airplane.BaggageInfo.toObject, includeInstance),
    totalWeight: jspb.Message.getFieldWithDefault(msg, 5, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 6, ""),
    totalBaggageCharge: (f = msg.getTotalBaggageCharge()) && proto.airplane.BaggageCharge.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.BaggageOption}
 */
proto.airplane.BaggageOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.BaggageOption;
  return proto.airplane.BaggageOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.BaggageOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.BaggageOption}
 */
proto.airplane.BaggageOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setItineraryIndex(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSegmentIndex(values[i]);
      }
      break;
    case 4:
      var value = new proto.airplane.BaggageInfo;
      reader.readMessage(value,proto.airplane.BaggageInfo.deserializeBinaryFromReader);
      msg.addBaggageInfo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalWeight(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 7:
      var value = new proto.airplane.BaggageCharge;
      reader.readMessage(value,proto.airplane.BaggageCharge.deserializeBinaryFromReader);
      msg.setTotalBaggageCharge(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.BaggageOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.BaggageOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.BaggageOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BaggageOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getItineraryIndex();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getSegmentIndexList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getBaggageInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.airplane.BaggageInfo.serializeBinaryToWriter
    );
  }
  f = message.getTotalWeight();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTotalBaggageCharge();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.airplane.BaggageCharge.serializeBinaryToWriter
    );
  }
};


/**
 * optional string option_id = 1;
 * @return {string}
 */
proto.airplane.BaggageOption.prototype.getOptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BaggageOption} returns this
 */
proto.airplane.BaggageOption.prototype.setOptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 itinerary_index = 2;
 * @return {number}
 */
proto.airplane.BaggageOption.prototype.getItineraryIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BaggageOption} returns this
 */
proto.airplane.BaggageOption.prototype.setItineraryIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated int64 segment_index = 3;
 * @return {!Array<number>}
 */
proto.airplane.BaggageOption.prototype.getSegmentIndexList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.airplane.BaggageOption} returns this
 */
proto.airplane.BaggageOption.prototype.setSegmentIndexList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.airplane.BaggageOption} returns this
 */
proto.airplane.BaggageOption.prototype.addSegmentIndex = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.BaggageOption} returns this
 */
proto.airplane.BaggageOption.prototype.clearSegmentIndexList = function() {
  return this.setSegmentIndexList([]);
};


/**
 * repeated BaggageInfo baggage_info = 4;
 * @return {!Array<!proto.airplane.BaggageInfo>}
 */
proto.airplane.BaggageOption.prototype.getBaggageInfoList = function() {
  return /** @type{!Array<!proto.airplane.BaggageInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.BaggageInfo, 4));
};


/**
 * @param {!Array<!proto.airplane.BaggageInfo>} value
 * @return {!proto.airplane.BaggageOption} returns this
*/
proto.airplane.BaggageOption.prototype.setBaggageInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.airplane.BaggageInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.BaggageInfo}
 */
proto.airplane.BaggageOption.prototype.addBaggageInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.airplane.BaggageInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.BaggageOption} returns this
 */
proto.airplane.BaggageOption.prototype.clearBaggageInfoList = function() {
  return this.setBaggageInfoList([]);
};


/**
 * optional int64 total_weight = 5;
 * @return {number}
 */
proto.airplane.BaggageOption.prototype.getTotalWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BaggageOption} returns this
 */
proto.airplane.BaggageOption.prototype.setTotalWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string unit = 6;
 * @return {string}
 */
proto.airplane.BaggageOption.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BaggageOption} returns this
 */
proto.airplane.BaggageOption.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional BaggageCharge total_baggage_charge = 7;
 * @return {?proto.airplane.BaggageCharge}
 */
proto.airplane.BaggageOption.prototype.getTotalBaggageCharge = function() {
  return /** @type{?proto.airplane.BaggageCharge} */ (
    jspb.Message.getWrapperField(this, proto.airplane.BaggageCharge, 7));
};


/**
 * @param {?proto.airplane.BaggageCharge|undefined} value
 * @return {!proto.airplane.BaggageOption} returns this
*/
proto.airplane.BaggageOption.prototype.setTotalBaggageCharge = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.BaggageOption} returns this
 */
proto.airplane.BaggageOption.prototype.clearTotalBaggageCharge = function() {
  return this.setTotalBaggageCharge(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.BaggageOption.prototype.hasTotalBaggageCharge = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.BaggageCharge.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.BaggageCharge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.BaggageCharge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BaggageCharge.toObject = function(includeInstance, msg) {
  var f, obj = {
    baseAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    taxAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    totalAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    currency: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.BaggageCharge}
 */
proto.airplane.BaggageCharge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.BaggageCharge;
  return proto.airplane.BaggageCharge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.BaggageCharge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.BaggageCharge}
 */
proto.airplane.BaggageCharge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBaseAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTaxAmount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalAmount(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.BaggageCharge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.BaggageCharge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.BaggageCharge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BaggageCharge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaseAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getTaxAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getTotalAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional double base_amount = 1;
 * @return {number}
 */
proto.airplane.BaggageCharge.prototype.getBaseAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BaggageCharge} returns this
 */
proto.airplane.BaggageCharge.prototype.setBaseAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double tax_amount = 2;
 * @return {number}
 */
proto.airplane.BaggageCharge.prototype.getTaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BaggageCharge} returns this
 */
proto.airplane.BaggageCharge.prototype.setTaxAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double total_amount = 3;
 * @return {number}
 */
proto.airplane.BaggageCharge.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BaggageCharge} returns this
 */
proto.airplane.BaggageCharge.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional string currency = 4;
 * @return {string}
 */
proto.airplane.BaggageCharge.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BaggageCharge} returns this
 */
proto.airplane.BaggageCharge.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.BaggageInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.BaggageInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.BaggageInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BaggageInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    weight: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxWeight: jspb.Message.getFieldWithDefault(msg, 4, 0),
    unit: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dimension: jspb.Message.getFieldWithDefault(msg, 6, ""),
    subCode: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.BaggageInfo}
 */
proto.airplane.BaggageInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.BaggageInfo;
  return proto.airplane.BaggageInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.BaggageInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.BaggageInfo}
 */
proto.airplane.BaggageInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWeight(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxWeight(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDimension(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.BaggageInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.BaggageInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.BaggageInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.BaggageInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMaxWeight();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUnit();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDimension();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSubCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.airplane.BaggageInfo.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BaggageInfo} returns this
 */
proto.airplane.BaggageInfo.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 quantity = 2;
 * @return {number}
 */
proto.airplane.BaggageInfo.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BaggageInfo} returns this
 */
proto.airplane.BaggageInfo.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 weight = 3;
 * @return {number}
 */
proto.airplane.BaggageInfo.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BaggageInfo} returns this
 */
proto.airplane.BaggageInfo.prototype.setWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 max_weight = 4;
 * @return {number}
 */
proto.airplane.BaggageInfo.prototype.getMaxWeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.BaggageInfo} returns this
 */
proto.airplane.BaggageInfo.prototype.setMaxWeight = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string unit = 5;
 * @return {string}
 */
proto.airplane.BaggageInfo.prototype.getUnit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BaggageInfo} returns this
 */
proto.airplane.BaggageInfo.prototype.setUnit = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string dimension = 6;
 * @return {string}
 */
proto.airplane.BaggageInfo.prototype.getDimension = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BaggageInfo} returns this
 */
proto.airplane.BaggageInfo.prototype.setDimension = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string sub_code = 8;
 * @return {string}
 */
proto.airplane.BaggageInfo.prototype.getSubCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.BaggageInfo} returns this
 */
proto.airplane.BaggageInfo.prototype.setSubCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.TransferBookingRequestItem.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.TransferBookingRequestItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.TransferBookingRequestItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.TransferBookingRequestItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requesterName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requesterAgencyCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    requesterAgencyName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    requesterEmail: jspb.Message.getFieldWithDefault(msg, 5, ""),
    requesterPhone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    status: jspb.Message.getFieldWithDefault(msg, 8, 0),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    issuerId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    issuerName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    issuerPhone: jspb.Message.getFieldWithDefault(msg, 12, ""),
    issuerEmail: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.TransferBookingRequestItem}
 */
proto.airplane.TransferBookingRequestItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.TransferBookingRequestItem;
  return proto.airplane.TransferBookingRequestItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.TransferBookingRequestItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.TransferBookingRequestItem}
 */
proto.airplane.TransferBookingRequestItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesterName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesterAgencyCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesterAgencyName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesterEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesterPhone(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {!proto.airplane.TransferBookingRequestStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiredAt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuerId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuerName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuerPhone(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssuerEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.TransferBookingRequestItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.TransferBookingRequestItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.TransferBookingRequestItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.TransferBookingRequestItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequesterName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequesterAgencyCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRequesterAgencyName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRequesterEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getRequesterPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getIssuerId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIssuerName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIssuerPhone();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIssuerEmail();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string request_id = 1;
 * @return {string}
 */
proto.airplane.TransferBookingRequestItem.prototype.getRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.TransferBookingRequestItem} returns this
 */
proto.airplane.TransferBookingRequestItem.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string requester_name = 2;
 * @return {string}
 */
proto.airplane.TransferBookingRequestItem.prototype.getRequesterName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.TransferBookingRequestItem} returns this
 */
proto.airplane.TransferBookingRequestItem.prototype.setRequesterName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string requester_agency_code = 3;
 * @return {string}
 */
proto.airplane.TransferBookingRequestItem.prototype.getRequesterAgencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.TransferBookingRequestItem} returns this
 */
proto.airplane.TransferBookingRequestItem.prototype.setRequesterAgencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string requester_agency_name = 4;
 * @return {string}
 */
proto.airplane.TransferBookingRequestItem.prototype.getRequesterAgencyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.TransferBookingRequestItem} returns this
 */
proto.airplane.TransferBookingRequestItem.prototype.setRequesterAgencyName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string requester_email = 5;
 * @return {string}
 */
proto.airplane.TransferBookingRequestItem.prototype.getRequesterEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.TransferBookingRequestItem} returns this
 */
proto.airplane.TransferBookingRequestItem.prototype.setRequesterEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string requester_phone = 6;
 * @return {string}
 */
proto.airplane.TransferBookingRequestItem.prototype.getRequesterPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.TransferBookingRequestItem} returns this
 */
proto.airplane.TransferBookingRequestItem.prototype.setRequesterPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 created_at = 7;
 * @return {number}
 */
proto.airplane.TransferBookingRequestItem.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.TransferBookingRequestItem} returns this
 */
proto.airplane.TransferBookingRequestItem.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional TransferBookingRequestStatus status = 8;
 * @return {!proto.airplane.TransferBookingRequestStatus}
 */
proto.airplane.TransferBookingRequestItem.prototype.getStatus = function() {
  return /** @type {!proto.airplane.TransferBookingRequestStatus} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.airplane.TransferBookingRequestStatus} value
 * @return {!proto.airplane.TransferBookingRequestItem} returns this
 */
proto.airplane.TransferBookingRequestItem.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * optional int64 expired_at = 9;
 * @return {number}
 */
proto.airplane.TransferBookingRequestItem.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.TransferBookingRequestItem} returns this
 */
proto.airplane.TransferBookingRequestItem.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string issuer_id = 10;
 * @return {string}
 */
proto.airplane.TransferBookingRequestItem.prototype.getIssuerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.TransferBookingRequestItem} returns this
 */
proto.airplane.TransferBookingRequestItem.prototype.setIssuerId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string issuer_name = 11;
 * @return {string}
 */
proto.airplane.TransferBookingRequestItem.prototype.getIssuerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.TransferBookingRequestItem} returns this
 */
proto.airplane.TransferBookingRequestItem.prototype.setIssuerName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string issuer_phone = 12;
 * @return {string}
 */
proto.airplane.TransferBookingRequestItem.prototype.getIssuerPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.TransferBookingRequestItem} returns this
 */
proto.airplane.TransferBookingRequestItem.prototype.setIssuerPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string issuer_email = 13;
 * @return {string}
 */
proto.airplane.TransferBookingRequestItem.prototype.getIssuerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.TransferBookingRequestItem} returns this
 */
proto.airplane.TransferBookingRequestItem.prototype.setIssuerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.AccessTradeRefInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.AccessTradeRefInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.AccessTradeRefInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.AccessTradeRefInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    utmSource: jspb.Message.getFieldWithDefault(msg, 1, ""),
    clickId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.AccessTradeRefInfo}
 */
proto.airplane.AccessTradeRefInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.AccessTradeRefInfo;
  return proto.airplane.AccessTradeRefInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.AccessTradeRefInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.AccessTradeRefInfo}
 */
proto.airplane.AccessTradeRefInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUtmSource(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setClickId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.AccessTradeRefInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.AccessTradeRefInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.AccessTradeRefInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.AccessTradeRefInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUtmSource();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getClickId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string utm_source = 1;
 * @return {string}
 */
proto.airplane.AccessTradeRefInfo.prototype.getUtmSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AccessTradeRefInfo} returns this
 */
proto.airplane.AccessTradeRefInfo.prototype.setUtmSource = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string click_id = 2;
 * @return {string}
 */
proto.airplane.AccessTradeRefInfo.prototype.getClickId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.AccessTradeRefInfo} returns this
 */
proto.airplane.AccessTradeRefInfo.prototype.setClickId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.airplane);
