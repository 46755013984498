// base-input base-input-sm base-input-md base-input-lg
// base-input-otp base-input-otp-sm base-input-otp-md base-input-otp-lg
// base-select base-select-sm base-select-md base-select-lg
// base-select-flight-sm base-select-flight-md base-select-flight-lg

import { ThemeConfig } from 'antd';

export const InputSize = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
} as const;

export const InputSizeStyle = {
  [InputSize.SM]: 'py-2 pl-2.5 pr-2',
  [InputSize.MD]: 'py-4 pl-2.5 pr-3',
  [InputSize.LG]: 'py-4 pl-2.5 pr-3',
} as const;

export const themeConfig: ThemeConfig = {
  token: {
    fontSize: 14,
    colorPrimary: '#0a47ff',
    colorInfo: '#0a47ff',
    colorSuccess: '#28bd68',
    colorError: '#f64a4a',
    colorWarning: '#ffa41c',
  },
  components: {
    Layout: {
      headerPadding: '10px 20px',
      headerColor: '#121212',
      headerBg: '#FFF',
      siderBg: '#FFF',
    },
    Menu: {
      itemSelectedBg: 'rgb(235, 240, 255)',
      subMenuItemBg: '#fff',
      itemColor: '#121212',
    },
    // Menu: {
    //   itemColor: '#ffffff',
    //   itemActiveBg: '#ffffff',
    //   itemHoverBg: '#e5e5e5',
    //   itemSelectedBg: '#ffffff',
    //   itemHoverColor: '#3164FF',
    //   itemSelectedColor: '#3164FF',
    //   colorBgContainer: 'transparent',
    //   collapsedWidth: 56,
    //   itemHeight: 36,
    //   activeBarBorderWidth: 0,
    //   itemMarginBlock: '10px 0px',
    //   groupTitleColor: '#ffffff',
    // },
    Table: {
      headerSplitColor: 'transparent',
      headerBg: 'transparent',
      headerColor: '#8C8C8C',
      borderColor: '#D9D9D9',
    },
  },
};
