// import { useMutation } from '@tanstack/react-query';

// export const useAppMutation = useMutation;

import type {
  UseMutationResult,
  UseMutationOptions,
} from '@tanstack/react-query';

import { useMutation as useCustomMutation } from '@tanstack/react-query';

import useToast from './useToast';
import { instanceOfBaseRes } from '@tixlabs/grpc-client/base-api';

export interface IResponseError {
  //
}

interface MutationProps<
  TData = unknown,
  TError = IResponseError,
  TVariables = void,
  TContext = unknown
> extends UseMutationOptions<TData, TError, TVariables, TContext> {}

export function useAppMutation<
  TData = unknown,
  TVariables = unknown,
  TContext = unknown
>(
  {
    mutationFn,
    onSuccess = () => undefined,
    mutationKey = [],
    onError = () => undefined,
    ...restOption
  }: MutationProps<TData, IResponseError, TVariables, TContext>,
  options?: {
    showErrorType: 'message' | 'toast';
  }
): UseMutationResult<TData, IResponseError, TVariables, TContext> {
  const { showErrorType = 'message' } = options || {};
  const toast = useToast();
  const mutations = useCustomMutation<
    TData,
    IResponseError,
    TVariables,
    TContext
  >({
    cacheTime: 0,
    mutationFn,
    mutationKey,
    // onSuccess,
    onSuccess: (data, vars, ctx) => {
      if (instanceOfBaseRes(data)) {
        if (!data.isSuccess && data.errorCode) {
          switch (showErrorType) {
            case 'toast':
              toast.error(data.errorMessage || data.errorCode);
              break;
            default:
              break;
          }
        }
      }

      onSuccess(data, vars, ctx);
    },
    // onError: (error, vars, ctx) => {
    //   console.log('error', error);
    //   switch (showErrorType) {
    //     case 'toast':
    //       break;
    //     default:
    //       break;
    //   }
    //   onError(error, vars, ctx);
    // },
    ...restOption,
  });

  return mutations;
}

export default useAppMutation;
