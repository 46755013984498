/**
 * @fileoverview gRPC-Web generated client stub for partner.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: partner/web_partnership/partner_user.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var partner_base_pb = require('../../partner/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.partner = {};
proto.partner.web_partnership = require('./partner_user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.partner.web_partnership.PartnerUserServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.partner.web_partnership.PartnerUserServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.GetMeReq,
 *   !proto.partner.web_partnership.GetMeRes>}
 */
const methodDescriptor_PartnerUserService_GetMe = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerUserService/GetMe',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.GetMeReq,
  proto.partner.web_partnership.GetMeRes,
  /**
   * @param {!proto.partner.web_partnership.GetMeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.GetMeRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.GetMeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.GetMeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.GetMeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerUserServiceClient.prototype.getMe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/GetMe',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_GetMe,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.GetMeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.GetMeRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerUserServicePromiseClient.prototype.getMe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/GetMe',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_GetMe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.ChangePasswordReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_PartnerUserService_ChangePassword = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerUserService/ChangePassword',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.ChangePasswordReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.partner.web_partnership.ChangePasswordReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.ChangePasswordReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerUserServiceClient.prototype.changePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_ChangePassword,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.ChangePasswordReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerUserServicePromiseClient.prototype.changePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_ChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.CreatePartnerUserReq,
 *   !proto.partner.web_partnership.CreatePartnerUserRes>}
 */
const methodDescriptor_PartnerUserService_CreatePartnerUser = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerUserService/CreatePartnerUser',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.CreatePartnerUserReq,
  proto.partner.web_partnership.CreatePartnerUserRes,
  /**
   * @param {!proto.partner.web_partnership.CreatePartnerUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.CreatePartnerUserRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.CreatePartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.CreatePartnerUserRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.CreatePartnerUserRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerUserServiceClient.prototype.createPartnerUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/CreatePartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_CreatePartnerUser,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.CreatePartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.CreatePartnerUserRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerUserServicePromiseClient.prototype.createPartnerUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/CreatePartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_CreatePartnerUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.RetrievePartnerUserReq,
 *   !proto.partner.web_partnership.RetrievePartnerUserRes>}
 */
const methodDescriptor_PartnerUserService_RetrievePartnerUser = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerUserService/RetrievePartnerUser',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.RetrievePartnerUserReq,
  proto.partner.web_partnership.RetrievePartnerUserRes,
  /**
   * @param {!proto.partner.web_partnership.RetrievePartnerUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.RetrievePartnerUserRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.RetrievePartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.RetrievePartnerUserRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.RetrievePartnerUserRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerUserServiceClient.prototype.retrievePartnerUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/RetrievePartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_RetrievePartnerUser,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.RetrievePartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.RetrievePartnerUserRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerUserServicePromiseClient.prototype.retrievePartnerUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/RetrievePartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_RetrievePartnerUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.ListPartnerUserReq,
 *   !proto.partner.web_partnership.ListPartnerUserRes>}
 */
const methodDescriptor_PartnerUserService_ListPartnerUser = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerUserService/ListPartnerUser',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.ListPartnerUserReq,
  proto.partner.web_partnership.ListPartnerUserRes,
  /**
   * @param {!proto.partner.web_partnership.ListPartnerUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.ListPartnerUserRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.ListPartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.ListPartnerUserRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.ListPartnerUserRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerUserServiceClient.prototype.listPartnerUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/ListPartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_ListPartnerUser,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.ListPartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.ListPartnerUserRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerUserServicePromiseClient.prototype.listPartnerUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/ListPartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_ListPartnerUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.DisablePartnerUserReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_PartnerUserService_DisablePartnerUser = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerUserService/DisablePartnerUser',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.DisablePartnerUserReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.partner.web_partnership.DisablePartnerUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.DisablePartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerUserServiceClient.prototype.disablePartnerUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/DisablePartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_DisablePartnerUser,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.DisablePartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerUserServicePromiseClient.prototype.disablePartnerUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/DisablePartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_DisablePartnerUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.DeletePartnerUserReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_PartnerUserService_DeletePartnerUser = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerUserService/DeletePartnerUser',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.DeletePartnerUserReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.partner.web_partnership.DeletePartnerUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.DeletePartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerUserServiceClient.prototype.deletePartnerUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/DeletePartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_DeletePartnerUser,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.DeletePartnerUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerUserServicePromiseClient.prototype.deletePartnerUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/DeletePartnerUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_DeletePartnerUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.UpdateUserReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_PartnerUserService_UpdateUser = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerUserService/UpdateUser',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.UpdateUserReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.partner.web_partnership.UpdateUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.UpdateUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerUserServiceClient.prototype.updateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_UpdateUser,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.UpdateUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerUserServicePromiseClient.prototype.updateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_UpdateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.UpdateMeReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_PartnerUserService_UpdateMe = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerUserService/UpdateMe',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.UpdateMeReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.partner.web_partnership.UpdateMeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.UpdateMeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerUserServiceClient.prototype.updateMe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/UpdateMe',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_UpdateMe,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.UpdateMeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerUserServicePromiseClient.prototype.updateMe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/UpdateMe',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_UpdateMe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.ListPartnerUserBasicReq,
 *   !proto.partner.web_partnership.ListPartnerUserBasicRes>}
 */
const methodDescriptor_PartnerUserService_ListPartnerUserBasicInfo = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerUserService/ListPartnerUserBasicInfo',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.ListPartnerUserBasicReq,
  proto.partner.web_partnership.ListPartnerUserBasicRes,
  /**
   * @param {!proto.partner.web_partnership.ListPartnerUserBasicReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.ListPartnerUserBasicRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.ListPartnerUserBasicReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.ListPartnerUserBasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.ListPartnerUserBasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerUserServiceClient.prototype.listPartnerUserBasicInfo =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/ListPartnerUserBasicInfo',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_ListPartnerUserBasicInfo,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.ListPartnerUserBasicReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.ListPartnerUserBasicRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerUserServicePromiseClient.prototype.listPartnerUserBasicInfo =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/ListPartnerUserBasicInfo',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_ListPartnerUserBasicInfo);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partner.web_partnership.CreatePartnerUserWithRolesReq,
 *   !proto.partner.web_partnership.CreatePartnerUserRes>}
 */
const methodDescriptor_PartnerUserService_CreatePartnerUserWithRoles = new grpc.web.MethodDescriptor(
  '/partner.web_partnership.PartnerUserService/CreatePartnerUserWithRoles',
  grpc.web.MethodType.UNARY,
  proto.partner.web_partnership.CreatePartnerUserWithRolesReq,
  proto.partner.web_partnership.CreatePartnerUserRes,
  /**
   * @param {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partner.web_partnership.CreatePartnerUserRes.deserializeBinary
);


/**
 * @param {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partner.web_partnership.CreatePartnerUserRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partner.web_partnership.CreatePartnerUserRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partner.web_partnership.PartnerUserServiceClient.prototype.createPartnerUserWithRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/CreatePartnerUserWithRoles',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_CreatePartnerUserWithRoles,
      callback);
};


/**
 * @param {!proto.partner.web_partnership.CreatePartnerUserWithRolesReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partner.web_partnership.CreatePartnerUserRes>}
 *     Promise that resolves to the response
 */
proto.partner.web_partnership.PartnerUserServicePromiseClient.prototype.createPartnerUserWithRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partner.web_partnership.PartnerUserService/CreatePartnerUserWithRoles',
      request,
      metadata || {},
      methodDescriptor_PartnerUserService_CreatePartnerUserWithRoles);
};


module.exports = proto.partner.web_partnership;

