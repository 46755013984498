import { Form } from '@common-ui';
import { IAirlineHub, IFlightServiceFee } from '@tixlabs/grpc-client';
import { SelectOptionItem } from '@tixlabs/types';
import { ConfirmDelete } from '@vemaybay-admin/components/ServiceFee';
import { IModal } from '@vemaybay-admin/types';
import { Modal } from 'antd';
import { SetStateAction, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormServiceFee from './FormServiceFee';

export enum ESwitchModal {
  NONE = 'NONE',
  ADD_SERVICE_FEE = 'ADD_SERVICE_FEE',
  UPDATE_SERVICE_FEE = 'UPDATE_SERVICE_FEE',
  CONFIRM_ADD_SERVICE_FEE = 'CONFIRM_ADD_SERVICE_FEE',
  CONFIRM_UPDATE_SERVICE_FEE = 'CONFIRM_UPDATE_SERVICE_FEE',
  CONFIRM_DELETE = 'CONFIRM_DELETE',
}

export type TModalServiceFee = IModal & {
  state: ESwitchModal;
  serviceFee?: IFlightServiceFee;
};

type Props = {
  modalInfo: TModalServiceFee;
  handleUpdateModalInfo: (value: SetStateAction<TModalServiceFee>) => void;
  airlineOptions: SelectOptionItem[];
  handleSubmit: SubmitHandler<IFlightServiceFee>;
  handleDelete: (data: IFlightServiceFee) => Promise<void>;
  airlineObjectByCode: Record<string, IAirlineHub>;
  handleSearch: (keywordSearch: string) => void;
};

export const ServiceFeeModal = ({
  modalInfo,
  handleUpdateModalInfo,
  airlineOptions,
  handleSubmit,
  airlineObjectByCode,
  handleDelete,
  handleSearch,
}: Props) => {
  const methods = useForm<IFlightServiceFee>({
    defaultValues: { amount: 0, id: '', vat: 0, airlineCode: '' },
  });

  async function onSubmit() {
    await methods.handleSubmit(async (data) => {
      try {
        await handleSubmit(data);
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
      } catch (error) {
        console.log('error', error);
      }
    })();
  }

  const handleCancel = () => {
    switch (modalInfo.state) {
      case ESwitchModal.ADD_SERVICE_FEE:
      case ESwitchModal.UPDATE_SERVICE_FEE:
      case ESwitchModal.CONFIRM_DELETE:
        if (methods.formState.isSubmitting) {
          return;
        }
        handleUpdateModalInfo((pre) => ({ ...pre, open: false }));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      modalInfo.serviceFee &&
      modalInfo.state === ESwitchModal.UPDATE_SERVICE_FEE
    ) {
      const { amount, id, type, vat, airlineCode } = modalInfo.serviceFee;
      methods.setValue('amount', amount);
      methods.setValue('id', id);
      methods.setValue('type', type);
      methods.setValue('vat', vat);
      methods.setValue('airlineCode', airlineCode);
    }

    if (modalInfo.open === false) {
      methods.reset();
    }
  }, [modalInfo]);

  return (
    <Modal {...modalInfo} closeIcon={false} footer={null}>
      {modalInfo.state === ESwitchModal.CONFIRM_DELETE && (
        <ConfirmDelete
          serviceFee={modalInfo.serviceFee}
          airlineObjectByCode={airlineObjectByCode}
          onSubmit={async (data) =>
            handleDelete(data)
              .then(() => {
                handleCancel();
              })
              .catch(() => {
                //
              })
          }
          onCancel={handleCancel}
        />
      )}
      {[ESwitchModal.ADD_SERVICE_FEE, ESwitchModal.UPDATE_SERVICE_FEE].includes(
        modalInfo.state
      ) && (
        <Form
          methods={methods}
          onSubmit={() => {
            //
          }}>
          <FormServiceFee
            handleSearch={handleSearch}
            onCancel={handleCancel}
            onSubmit={onSubmit}
            airlineOptions={airlineOptions}
            airlineObjectByCode={airlineObjectByCode}
          />
        </Form>
      )}
    </Modal>
  );
};

export default ServiceFeeModal;
