import React from 'react';
import { RecipientInfo } from './RecipientInfo';
import { IInvoicingInformation } from '@tixlabs/grpc-client/web-partnership';

interface Props {
  invoicingInfo: IInvoicingInformation;
}
export const InfoInvoiceDB = ({ invoicingInfo }: Props) => {
  return (
    <div className='bg-white border-neutral-3 flex flex-col'>
      <div className='flex gap-3 mb-3'>
        <span className='w-1/4 text-lg font-semibold'>
          {' '}
          Thông tin xuất hóa đơn
        </span>

        <div className='flex-1'>
          <div className='grid md:grid-cols-3 gap-3 mb-4'>
            <div className='flex flex-col md:col-span-2 gap-1'>
              <span className='text-gray-500'>Tên công ty</span>
              <span>{invoicingInfo?.companyInfo?.name}</span>
            </div>

            <div className='flex flex-col gap-1'>
              <span className='text-gray-500'>Mã số thuế</span>
              <span>{invoicingInfo?.companyInfo?.taxCode}</span>
            </div>
          </div>

          <div className='flex flex-col gap-1'>
            <span className='text-gray-500'>Địa chỉ</span>
            <span>{invoicingInfo?.companyInfo?.address}</span>
          </div>
        </div>
      </div>
      {invoicingInfo.receiverInfo && (
        <RecipientInfo receiver={invoicingInfo.receiverInfo} />
      )}
    </div>
  );
};
