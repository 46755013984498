import { flightApiService } from '@tixlabs/grpc-client/web-partnership';
import { useAppDispatch, useAppSelector } from '@vemaybay-admin/stores';
import { updateAirports } from '@vemaybay-admin/stores/reducers/common';
import { useAppMutation } from '../internals';

export function useAirport() {
  const { airports, airportsObjectByCode } = useAppSelector(
    (state) => state.common
  );
  const dispatch = useAppDispatch();
  const {
    mutateAsync: getAirports,
    isLoading,
    isError,
    isSuccess,
  } = useAppMutation({
    mutationKey: ['flightApiService', 'getAirports'],
    mutationFn: flightApiService.getAirports,
    onSuccess: (data) => {
      data.itemsList.forEach((item) => {
        dispatch(updateAirports(item));
      });
    },
  });

  async function fetchAirports(listCode: string[]) {
    try {
      if (listCode.length) {
        const filterList = listCode.filter(
          (code) => !airportsObjectByCode?.[code]
        );
        if (filterList.length) {
          await getAirports({
            codesList: listCode.filter((code) => !airportsObjectByCode?.[code]),
          });
        }
      }
    } catch (error) {}
  }

  return {
    // airportsList,
    isLoading,
    isError,
    isSuccess,
    airports,
    airportsObjectByCode,
    fetchAirports,
  };
}

export default useAirport;
