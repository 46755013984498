import { ServiceFeeServiceClient } from '@api/airplane/web_partnership/service_fee_grpc_web_pb';
import { DeleteFlightServiceFeeReq } from '@api/airplane/web_partnership/service_fee_pb';
import { BasicRes, PaginationReq } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import {
  AddFlightServiceFeeReq,
  ListFlightServiceFeeReq,
  ListFlightServiceFeeRes,
  UpdateFlightServiceFeeReq,
} from './types/service_fee_pb';
import { BaseApi } from '../base-api';

class ServiceFeeApi extends BaseApi<ServiceFeeServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new ServiceFeeServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }
  addFlightServiceFee = ({
    type,
    amount,
    vat,
    airlineCode,
  }: AddFlightServiceFeeReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new AddFlightServiceFeeReq();
    req.setAmount(amount);
    req.setType(type);
    req.setVat(vat);
    req.setAirlineCode(airlineCode);
    return this.grpc<AddFlightServiceFeeReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.addFlightServiceFee,
      req,
      {}
    );
  };
  deleteFlightServiceFee = ({
    id,
  }: DeleteFlightServiceFeeReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new DeleteFlightServiceFeeReq();
    req.setId(id);

    return this.grpc<DeleteFlightServiceFeeReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.deleteFlightServiceFee,
      req,
      {}
    );
  };
  updateFlightServiceFee = ({
    amount,
    id,
    type,
    vat,
    airlineCode,
  }: UpdateFlightServiceFeeReq.AsObject) => {
    const req = new UpdateFlightServiceFeeReq();
    req.setAmount(amount);
    req.setId(id);
    req.setType(type);
    req.setVat(vat);
    req.setAirlineCode(airlineCode);
    return this.grpc<UpdateFlightServiceFeeReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.updateFlightServiceFee,
      req,
      {}
    );
  };
  listFlightServiceFee = ({
    filter,
    pagination,
  }: ListFlightServiceFeeReq.AsObject): Promise<ListFlightServiceFeeRes.AsObject> => {
    const req = new ListFlightServiceFeeReq();
    const filterReq = new ListFlightServiceFeeReq.Filter();
    const paginationReq = new PaginationReq();

    if (pagination) {
      paginationReq.setPageLimit(pagination.pageLimit);
      paginationReq.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationReq);
    }

    if (filter) {
      filterReq.setAirlineCode(filter.airlineCode);
      filterReq.setType(filter.type);
      req.setFilter(filterReq);
    }

    return this.grpc<
      ListFlightServiceFeeReq,
      ListFlightServiceFeeRes,
      ListFlightServiceFeeRes.AsObject
    >(this.serviceClient.listFlightServiceFee, req, {});
  };
}

export const serviceFeeService = new ServiceFeeApi();
export default serviceFeeService;
