import { ArrowDown, ArrowUp } from '@icon';
import { IBookingDetail } from '@tixlabs/grpc-client/web-partnership';
import { useCurrency } from '@vemaybay-admin/hooks/internals';
import { useState } from 'react';
interface Props {
  bookingData: IBookingDetail;
}
export const DetailPrice = ({ bookingData }: Props) => {
  const { formatPrice } = useCurrency();
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const averageRate = bookingData?.priceInfo?.totalRateAmount
    ? bookingData.priceInfo.totalRateAmount /
      ((bookingData.roomsList.length || 1) * (bookingData.totalNights || 1))
    : 0;

  const totalPayAtHotelListPrice = bookingData.priceInfo?.totalPayAtHotelList
    ? bookingData.priceInfo.totalPayAtHotelList.reduce(
        (acc, item) => acc + (item?.amount || 0),
        0
      )
    : 0;
  return (
    <div className='bg-white border border-neutral-3 p-4 rounded text-md'>
      <h3 className='text-lg font-semibold mb-4'>Chi tiết giá:</h3>

      <div className='flex justify-between mb-2'>
        <span>{`${bookingData.roomsList.length} phòng x ${bookingData.totalNights} đêm`}</span>
        <span>
          {formatPrice(bookingData?.priceInfo?.totalRateAmount || 0)} đ
        </span>
      </div>
      <div className='text-sm text-gray-500 mb-2'>
        <span>{formatPrice(averageRate)} đ/đêm</span>
      </div>
      <div className='flex justify-between mb-2'>
        <span>Thuế & phí</span>
        <span>
          {formatPrice(bookingData?.priceInfo?.totalTaxesAndFees || 0)} đ
        </span>
      </div>

      <div className='flex justify-between mb-2'>
        <span>Phí tiện ích</span>
        <span>{formatPrice(bookingData?.priceInfo?.additionalFee || 0)} đ</span>
      </div>

      <div className='flex justify-between mb-2'>
        <span>Phương thức: </span>
        <span>{`-------------`} </span>
      </div>

      <div className='my-3 border-[1px] border-slate-300 border-dashed'></div>

      <div className='flex flex-col gap-2'>
        <div className='text-lg flex justify-between font-semibold'>
          <span>Tổng khách thanh toán</span>
          <span>
            {bookingData?.priceInfo &&
              formatPrice(
                bookingData?.priceInfo?.payNow +
                  bookingData?.priceInfo?.additionalFee || 0
              )}{' '}
            đ
          </span>
        </div>
        <div className='flex justify-between'>
          <div className='flex gap-2'>
            <span>Thanh toán tại nơi lưu trú</span>
            <div
              onClick={toggleVisibility}
              className='flex items-center p-0 hover:cursor-pointer text-gray-500 '>
              {isVisible ? (
                <ArrowDown className='w-4 h-5' />
              ) : (
                <ArrowUp className='w-4 h-5' />
              )}
            </div>
          </div>
          <span>{formatPrice(totalPayAtHotelListPrice || 0)} đ</span>
        </div>
        {isVisible && (
          <div className='flex flex-col text-gray-500 gap-2'>
            {bookingData?.priceInfo?.totalPayAtHotelList?.map((item, index) => (
              <div key={index} className='pl-3 flex justify-between'>
                <span>{item.description}</span>
                <span>{formatPrice(item.amount)} đ</span>
              </div>
            ))}
          </div>
        )}

        <div className='flex justify-between'>
          <span>Tổng thanh toán</span>
          <span>{formatPrice(bookingData?.priceInfo?.totalPrice || 0)} đ</span>
        </div>
      </div>
    </div>
  );
};
