import { HiddenServiceFeeServiceClient } from '@api/airplane/web_partnership/hidden_service_fee_grpc_web_pb';
import {
  AddFlightHiddenServiceFeeReq,
  DeleteFlightHiddenServiceFeeReq,
  ListFlightHiddenServiceFeeReq,
  ListFlightHiddenServiceFeeRes,
  UpdateFlightHiddenServiceFeeReq,
} from '@api/airplane/web_partnership/hidden_service_fee_pb';
import { BasicRes, PaginationReq } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';

import { BaseApi } from '../base-api';

class ConfigHiddenFeeService extends BaseApi<HiddenServiceFeeServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new HiddenServiceFeeServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }
  addFlightHiddenServiceFee = ({
    type,
    vat,
    amount,
    officeId,
    airlineCode,
    route,
    bookingClassList,
    percent,
  }: AddFlightHiddenServiceFeeReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new AddFlightHiddenServiceFeeReq();
    req.setVat(vat);
    req.setType(type);
    req.setAmount(amount);
    req.setOfficeId(officeId);
    req.setAirlineCode(airlineCode);
    req.setRoute(route);
    if (bookingClassList.length === 0) {
      bookingClassList = [''];
    }
    
    req.setBookingClassList(bookingClassList);
    req.setPercent(percent);
    return this.grpc<AddFlightHiddenServiceFeeReq, BasicRes, BasicRes.AsObject>(
      this.serviceClient.addFlightHiddenServiceFee,
      req,
      {}
    );
  };

  deleteFlightHiddenServiceFee = ({
    id,
  }: DeleteFlightHiddenServiceFeeReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new DeleteFlightHiddenServiceFeeReq();
    req.setId(id);

    return this.grpc<
      DeleteFlightHiddenServiceFeeReq,
      BasicRes,
      BasicRes.AsObject
    >(this.serviceClient.deleteFlightHiddenServiceFee, req, {});
  };

  updateFlightHiddenServiceFee = ({
    id,
    vat,
    type,
    amount,
    airlineCode,
    route,
    bookingClass,
    percent,
  }: UpdateFlightHiddenServiceFeeReq.AsObject) => {
    const req = new UpdateFlightHiddenServiceFeeReq();
    req.setId(id);
    req.setVat(vat);
    req.setType(type);
    req.setAmount(amount);
    req.setAirlineCode(airlineCode);
    req.setBookingClass(bookingClass);
    req.setPercent(percent);
    req.setRoute(route);
    return this.grpc<
      UpdateFlightHiddenServiceFeeReq,
      BasicRes,
      BasicRes.AsObject
    >(this.serviceClient.updateFlightHiddenServiceFee, req, {});
  };

  listFlightHiddenServiceFee = ({
    filter,
    pagination,
  }: ListFlightHiddenServiceFeeReq.AsObject): Promise<ListFlightHiddenServiceFeeRes.AsObject> => {
    const req = new ListFlightHiddenServiceFeeReq();
    const filterReq = new ListFlightHiddenServiceFeeReq.Filter();
    const paginationReq = new PaginationReq();

    if (pagination) {
      paginationReq.setPageLimit(pagination.pageLimit);
      paginationReq.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationReq);
    }

    if (filter) {
      filterReq.setAirlineCode(filter.airlineCode);
      filterReq.setOfficeId(filter.officeId);
      filterReq.setType(filter.type);
      req.setFilter(filterReq);
    }

    return this.grpc<
      ListFlightHiddenServiceFeeReq,
      ListFlightHiddenServiceFeeRes,
      ListFlightHiddenServiceFeeRes.AsObject
    >(this.serviceClient.listFlightHiddenServiceFee, req, {});
  };
}

export const hiddenFeeService = new ConfigHiddenFeeService();
export default hiddenFeeService;
