import { WalletServiceClient } from '@api/airplane/web_partnership/wallet_grpc_web_pb';
import {
  RetrieveBalanceReq,
  RetrieveBalanceRes,
  ListTransactionReq,
  ListTransactionRes,
  ExportTransactionReq,
  ExportTransactionRes,
} from '@api/airplane/web_partnership/wallet_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import { PaginationReq } from '@api/base/base_pb';

class WalletApi extends BaseApi<WalletServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new WalletServiceClient(this.url, null, this.optsDev);
  }

  retrieveBalance = (): Promise<RetrieveBalanceRes.AsObject> => {
    const req = new RetrieveBalanceReq();
    return this.grpc<
      RetrieveBalanceReq,
      RetrieveBalanceRes,
      RetrieveBalanceRes.AsObject
    >(this.serviceClient.retrieveBalance, req);
  };

  listTransaction = ({
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListTransactionReq.AsObject): Promise<ListTransactionRes.AsObject> => {
    const req = new ListTransactionReq();
    const filterObj = new ListTransactionReq.Filter();
    const paginationObj = new PaginationReq();

    if (filter) {
      filter.bookerId && filterObj.setBookerId(filter.bookerId?.trim() || '');
      filter.partnershopId &&
        filterObj.setPartnershopId(filter.partnershopId?.trim() || '');
      filter.toDate && filterObj.setToDate(filter.toDate);
      filter.fromDate && filterObj.setFromDate(filter.fromDate);
      filter.bookingCode &&
        filterObj.setBookingCode(filter.bookingCode?.trim() || '');
      filterObj.setTransactionType(filter.transactionType);
      filter.transactionSource &&
        filterObj.setTransactionSource(filter.transactionSource);
    }

    req.setFilter(filterObj);

    paginationObj.setPageNumber(pagination.pageNumber);
    paginationObj.setPageLimit(pagination.pageLimit);
    req.setPagination(paginationObj);

    return this.grpc<
      ListTransactionReq,
      ListTransactionRes,
      ListTransactionRes.AsObject
    >(this.serviceClient?.listTransaction, req, {});
  };

  exportTransactions = ({
    filter,
  }: ExportTransactionReq.AsObject): Promise<ExportTransactionRes.AsObject> => {
    const req = new ExportTransactionReq();
    const filterObj = new ListTransactionReq.Filter();

    if (filter) {
      filter.bookerId && filterObj.setBookerId(filter.bookerId);
      filter.partnershopId && filterObj.setPartnershopId(filter.partnershopId);
      filter.toDate && filterObj.setToDate(filter.toDate);
      filter.fromDate && filterObj.setFromDate(filter.fromDate);
      filter.bookingCode &&
        filterObj.setBookingCode(filter.bookingCode?.trim() || '');
      filterObj.setTransactionType(filter.transactionType);
      filter.transactionSource &&
        filterObj.setTransactionSource(filter.transactionSource);
    }

    req.setFilter(filterObj);

    return this.grpc<
      ExportTransactionReq,
      ExportTransactionRes,
      ExportTransactionRes.AsObject
    >(this.serviceClient?.exportTransactions, req, {});
  };
}

export const walletApiService = new WalletApi();
export default walletApiService;
