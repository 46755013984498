import { LabelInput } from '@common-ui';

type CompanyInfo = {
  name: string;
  taxCode: string;
  address: string;
};

type ReceiverInfo = {
  name: string;
  phone: string;
  email: string;
  address: string;
  note?: string;
};

type InvoiceInfo = {
  companyInfo?: CompanyInfo;
  receiverInfo?: ReceiverInfo;
};

type InvoiceInfoViewProps = {
  data: InvoiceInfo;
};

export const InvoiceInfoView = ({ data }: InvoiceInfoViewProps) => {
  return (
    <div className='flex flex-col rounded-lg bg-white'>
      <h3 className='text-lg font-semibold py-3 px-4 mb-0'>
        Thông tin xuất hóa đơn
      </h3>
      <div className='flex flex-col gap-4 px-4 py-3'>
        <h3 className='text-lg font-semibold mb-0'>Thông tin công ty</h3>
        <div
          style={{
            gridTemplateColumns: '1fr 153px 1fr 389px',
          }}
          className='grid grid-cols-4 grid-flow-row-dense gap-2.5'>
          <div className='col-span-3'>
            <LabelInput className='space-y-[5px]' label='Tên công ty'>
              <span className='font-semibold'>
                {data.companyInfo?.name || '-'}
              </span>
            </LabelInput>
          </div>
          <div className='col-span-1'>
            <LabelInput className='space-y-[5px]' label='Mã số thuế'>
              <span className='font-semibold'>
                {data.companyInfo?.taxCode || '-'}
              </span>
            </LabelInput>
          </div>
          <div className='col-span-4'>
            <LabelInput className='space-y-[5px]' label='Địa chỉ'>
              <span className='font-semibold'>
                {data.companyInfo?.address || '-'}
              </span>
            </LabelInput>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-4 px-4 py-3'>
        <h3 className='text-lg font-semibold mb-0'>Thông tin người nhận</h3>
        <div
          style={{
            gridTemplateColumns: '440px 200px 1fr',
          }}
          className='grid grid-cols-3 grid-flow-row-dense gap-2.5'>
          <div className=''>
            <LabelInput className='space-y-[5px]' label='Họ tên'>
              <span className='font-semibold'>
                {data.receiverInfo?.name || '-'}
              </span>
            </LabelInput>
          </div>

          <div className=''>
            <LabelInput className='space-y-[5px]' label='Số điện thoại'>
              <span className='font-semibold'>
                {data.receiverInfo?.phone || '-'}
              </span>
            </LabelInput>
          </div>
          <div className=''>
            <LabelInput className='space-y-[5px]' label='Email'>
              <span className='font-semibold'>
                {data.receiverInfo?.email || '-'}
              </span>
            </LabelInput>
          </div>
          <div className=''>
            <LabelInput className='space-y-[5px]' label='Địa chỉ'>
              <span className='font-semibold'>
                {data.receiverInfo?.address || '-'}
              </span>
            </LabelInput>
          </div>
          <div className='col-span-2'>
            <LabelInput className='space-y-[5px]' label='Ghi chú'>
              <span className='font-semibold'>
                {data.receiverInfo?.note || '-'}
              </span>
            </LabelInput>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceInfoView;
