import { ISegment } from '@tixlabs/grpc-client/';
import { useTime } from '@vemaybay-admin/hooks/internals';
import useAirport from '@vemaybay-admin/hooks/stores/useAirport';

type Props = {
  segmentsList: ISegment[];
};

export const ItinerarySegmentInfo = ({ segmentsList }: Props) => {
  const { airportsObjectByCode } = useAirport();
  const { formatDuration, formatDateUTCOnlyTime } = useTime();

  return (
    <div className='flex min-h-[80px] gap-5'>
      <div className='flex flex-col justify-between'>
        <span>{formatDateUTCOnlyTime(segmentsList[0].departDate)}</span>
        <span>
          {formatDateUTCOnlyTime(
            segmentsList[segmentsList.length - 1].arrivalDate
          )}
        </span>
      </div>
      <div className='relative flex flex-col gap-3 justify-between'>
        <div className='absolute w-px h-[calc(100%_-_21px)] top-1/2 left-[3px] -translate-x-1/2 -translate-y-1/2 bg-neutral-gray-30'></div>
        <div className='relative flex gap-5 items-center'>
          <div className='w-1.5 h-1.5 bg-neutral-gray-30 rounded-full'></div>
          <span>
            {airportsObjectByCode?.[segmentsList[0].departPlace]?.name}
          </span>
        </div>
        {segmentsList.length > 1 &&
          segmentsList.map((segment, index) => {
            return (
              index !== segmentsList.length - 1 && (
                <div key={index} className='relative flex gap-5 items-center'>
                  <div className='w-1.5 h-1.5 border-neutral-gray-30 bg-white border rounded-full'></div>
                  <span>
                    {`Quá cảnh tại ${
                      airportsObjectByCode?.[segment.arrivalPlace]?.name
                    } ${formatDuration(
                      (segmentsList[index + 1].departDate -
                        segment.arrivalDate) /
                        1000 /
                        60
                    )}`}
                  </span>
                </div>
              )
            );
          })}
        <div className='relative flex gap-5 items-center'>
          <div className='w-1.5 h-1.5 bg-neutral-gray-30 rounded-full'></div>
          <span>
            {
              airportsObjectByCode?.[
                segmentsList[segmentsList.length - 1].arrivalPlace
              ]?.name
            }
          </span>
        </div>
      </div>
    </div>
  );
};
