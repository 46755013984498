import { BasicRes, PaginationReq } from '@api/base/base_pb';
import { TransferBookingConfigServiceClient } from '@api/airplane/web_partnership/transfer_booking_config_grpc_web_pb';

import { BaseApi, BaseApiOptions } from '@core/grpc-client';
import {
  CreateTransferBookingConfigsReq,
  CreateTransferBookingConfigsRes,
  ListTransferBookingConfigReq,
  ListTransferBookingConfigRes,
  UpdateTransferBookingConfigReq,
} from '@api/airplane/web_partnership/transfer_booking_config_pb';

class TransferBookingConfigApi extends BaseApi<TransferBookingConfigServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new TransferBookingConfigServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  createTransferBookingConfigs = ({
    agentCodesList,
    fee,
    status,
  }: CreateTransferBookingConfigsReq.AsObject): Promise<CreateTransferBookingConfigsRes.AsObject> => {
    const req = new CreateTransferBookingConfigsReq();
    req.setAgentCodesList(agentCodesList);
    req.setFee(fee);
    req.setStatus(status);

    return this.grpc<
      CreateTransferBookingConfigsReq,
      CreateTransferBookingConfigsRes,
      CreateTransferBookingConfigsRes.AsObject
    >(this.serviceClient.createTransferBookingConfigs, req, {});
  };

  updateTransferBookingConfig = ({
    id,
    fee,
    status,
  }: UpdateTransferBookingConfigReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new UpdateTransferBookingConfigReq();
    req.setId(id);
    req.setFee(fee || 0);
    req.setStatus(status);

    return this.grpc<
      UpdateTransferBookingConfigReq,
      BasicRes,
      BasicRes.AsObject
    >(this.serviceClient.updateTransferBookingConfig, req, {});
  };

  listTransferBookingConfig = ({
    filter,
    pagination,
  }: ListTransferBookingConfigReq.AsObject): Promise<ListTransferBookingConfigRes.AsObject> => {
    const req = new ListTransferBookingConfigReq();

    if (filter) {
      const filterReq = new ListTransferBookingConfigReq.Filter();
      filter?.agentCodesList &&
        filterReq.setAgentCodesList(filter.agentCodesList);
      filter?.fee && filterReq.setFee(filter.fee);
      filter?.status && filterReq.setStatus(filter.status);
      req.setFilter(filterReq);
    }

    if (pagination) {
      const paginationObj = new PaginationReq();
      paginationObj.setPageLimit(pagination.pageLimit);
      paginationObj.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationObj);
    }

    return this.grpc<
      ListTransferBookingConfigReq,
      ListTransferBookingConfigRes,
      ListTransferBookingConfigRes.AsObject
    >(this.serviceClient.listTransferBookingConfig, req, {});
  };
}
const transferBookingConfigClient = new TransferBookingConfigApi();
export default transferBookingConfigClient;
