// source: airplane/web_partnership/config.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.airplane.web_partnership.CommissionRate', null, global);
goog.exportSymbol('proto.airplane.web_partnership.GetConfigByOfficeIDReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.GetConfigRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.GetConfigRes.Data', null, global);
goog.exportSymbol('proto.airplane.web_partnership.GetListOfficeRes', null, global);
goog.exportSymbol('proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo', null, global);
goog.exportSymbol('proto.airplane.web_partnership.SaleServiceFee', null, global);
goog.exportSymbol('proto.airplane.web_partnership.UpdateConfigByOfficeIDReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.UpdateConfigReq', null, global);
goog.exportSymbol('proto.airplane.web_partnership.WalletConfig', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.CommissionRate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.CommissionRate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.CommissionRate.displayName = 'proto.airplane.web_partnership.CommissionRate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.SaleServiceFee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.SaleServiceFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.SaleServiceFee.displayName = 'proto.airplane.web_partnership.SaleServiceFee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.WalletConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.WalletConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.WalletConfig.displayName = 'proto.airplane.web_partnership.WalletConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.GetConfigByOfficeIDReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.GetConfigByOfficeIDReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.GetConfigByOfficeIDReq.displayName = 'proto.airplane.web_partnership.GetConfigByOfficeIDReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.UpdateConfigReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.UpdateConfigReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.UpdateConfigReq.displayName = 'proto.airplane.web_partnership.UpdateConfigReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.UpdateConfigByOfficeIDReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.displayName = 'proto.airplane.web_partnership.UpdateConfigByOfficeIDReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.GetConfigRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.GetConfigRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.GetConfigRes.displayName = 'proto.airplane.web_partnership.GetConfigRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.GetConfigRes.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.GetConfigRes.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.GetConfigRes.Data.displayName = 'proto.airplane.web_partnership.GetConfigRes.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.GetListOfficeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.airplane.web_partnership.GetListOfficeRes.repeatedFields_, null);
};
goog.inherits(proto.airplane.web_partnership.GetListOfficeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.GetListOfficeRes.displayName = 'proto.airplane.web_partnership.GetListOfficeRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.displayName = 'proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.CommissionRate.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.CommissionRate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.CommissionRate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CommissionRate.toObject = function(includeInstance, msg) {
  var f, obj = {
    airTicket: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    sim: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    visa: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.CommissionRate}
 */
proto.airplane.web_partnership.CommissionRate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.CommissionRate;
  return proto.airplane.web_partnership.CommissionRate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.CommissionRate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.CommissionRate}
 */
proto.airplane.web_partnership.CommissionRate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAirTicket(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSim(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVisa(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.CommissionRate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.CommissionRate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.CommissionRate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.CommissionRate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAirTicket();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getSim();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getVisa();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double air_ticket = 1;
 * @return {number}
 */
proto.airplane.web_partnership.CommissionRate.prototype.getAirTicket = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CommissionRate} returns this
 */
proto.airplane.web_partnership.CommissionRate.prototype.setAirTicket = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double sim = 2;
 * @return {number}
 */
proto.airplane.web_partnership.CommissionRate.prototype.getSim = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CommissionRate} returns this
 */
proto.airplane.web_partnership.CommissionRate.prototype.setSim = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double visa = 3;
 * @return {number}
 */
proto.airplane.web_partnership.CommissionRate.prototype.getVisa = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.CommissionRate} returns this
 */
proto.airplane.web_partnership.CommissionRate.prototype.setVisa = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.SaleServiceFee.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.SaleServiceFee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.SaleServiceFee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SaleServiceFee.toObject = function(includeInstance, msg) {
  var f, obj = {
    supportRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    rate: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.SaleServiceFee}
 */
proto.airplane.web_partnership.SaleServiceFee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.SaleServiceFee;
  return proto.airplane.web_partnership.SaleServiceFee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.SaleServiceFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.SaleServiceFee}
 */
proto.airplane.web_partnership.SaleServiceFee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSupportRate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.SaleServiceFee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.SaleServiceFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.SaleServiceFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.SaleServiceFee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSupportRate();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getRate();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double support_rate = 1;
 * @return {number}
 */
proto.airplane.web_partnership.SaleServiceFee.prototype.getSupportRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.SaleServiceFee} returns this
 */
proto.airplane.web_partnership.SaleServiceFee.prototype.setSupportRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double rate = 2;
 * @return {number}
 */
proto.airplane.web_partnership.SaleServiceFee.prototype.getRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.SaleServiceFee} returns this
 */
proto.airplane.web_partnership.SaleServiceFee.prototype.setRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.WalletConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.WalletConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.WalletConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.WalletConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    depositBalance: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    advanceBalance: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    guaranteeAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.WalletConfig}
 */
proto.airplane.web_partnership.WalletConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.WalletConfig;
  return proto.airplane.web_partnership.WalletConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.WalletConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.WalletConfig}
 */
proto.airplane.web_partnership.WalletConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDepositBalance(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAdvanceBalance(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGuaranteeAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.WalletConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.WalletConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.WalletConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.WalletConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepositBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getAdvanceBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getGuaranteeAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double deposit_balance = 1;
 * @return {number}
 */
proto.airplane.web_partnership.WalletConfig.prototype.getDepositBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.WalletConfig} returns this
 */
proto.airplane.web_partnership.WalletConfig.prototype.setDepositBalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double advance_balance = 2;
 * @return {number}
 */
proto.airplane.web_partnership.WalletConfig.prototype.getAdvanceBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.WalletConfig} returns this
 */
proto.airplane.web_partnership.WalletConfig.prototype.setAdvanceBalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double guarantee_amount = 3;
 * @return {number}
 */
proto.airplane.web_partnership.WalletConfig.prototype.getGuaranteeAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.WalletConfig} returns this
 */
proto.airplane.web_partnership.WalletConfig.prototype.setGuaranteeAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.GetConfigByOfficeIDReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.GetConfigByOfficeIDReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.GetConfigByOfficeIDReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.GetConfigByOfficeIDReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    officeId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.GetConfigByOfficeIDReq}
 */
proto.airplane.web_partnership.GetConfigByOfficeIDReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.GetConfigByOfficeIDReq;
  return proto.airplane.web_partnership.GetConfigByOfficeIDReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.GetConfigByOfficeIDReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.GetConfigByOfficeIDReq}
 */
proto.airplane.web_partnership.GetConfigByOfficeIDReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.GetConfigByOfficeIDReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.GetConfigByOfficeIDReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.GetConfigByOfficeIDReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.GetConfigByOfficeIDReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string office_id = 1;
 * @return {string}
 */
proto.airplane.web_partnership.GetConfigByOfficeIDReq.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.GetConfigByOfficeIDReq} returns this
 */
proto.airplane.web_partnership.GetConfigByOfficeIDReq.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.UpdateConfigReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.UpdateConfigReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.UpdateConfigReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.UpdateConfigReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    vat: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.UpdateConfigReq}
 */
proto.airplane.web_partnership.UpdateConfigReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.UpdateConfigReq;
  return proto.airplane.web_partnership.UpdateConfigReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.UpdateConfigReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.UpdateConfigReq}
 */
proto.airplane.web_partnership.UpdateConfigReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.UpdateConfigReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.UpdateConfigReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.UpdateConfigReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.UpdateConfigReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVat();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
};


/**
 * optional double vat = 1;
 * @return {number}
 */
proto.airplane.web_partnership.UpdateConfigReq.prototype.getVat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.UpdateConfigReq} returns this
 */
proto.airplane.web_partnership.UpdateConfigReq.prototype.setVat = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    officeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    commission: (f = msg.getCommission()) && proto.airplane.web_partnership.CommissionRate.toObject(includeInstance, f),
    saleServiceFee: (f = msg.getSaleServiceFee()) && proto.airplane.web_partnership.SaleServiceFee.toObject(includeInstance, f),
    platformFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    walletConfig: (f = msg.getWalletConfig()) && proto.airplane.web_partnership.WalletConfig.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq}
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.UpdateConfigByOfficeIDReq;
  return proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq}
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 2:
      var value = new proto.airplane.web_partnership.CommissionRate;
      reader.readMessage(value,proto.airplane.web_partnership.CommissionRate.deserializeBinaryFromReader);
      msg.setCommission(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.SaleServiceFee;
      reader.readMessage(value,proto.airplane.web_partnership.SaleServiceFee.deserializeBinaryFromReader);
      msg.setSaleServiceFee(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPlatformFee(value);
      break;
    case 5:
      var value = new proto.airplane.web_partnership.WalletConfig;
      reader.readMessage(value,proto.airplane.web_partnership.WalletConfig.deserializeBinaryFromReader);
      msg.setWalletConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommission();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.airplane.web_partnership.CommissionRate.serializeBinaryToWriter
    );
  }
  f = message.getSaleServiceFee();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partnership.SaleServiceFee.serializeBinaryToWriter
    );
  }
  f = message.getPlatformFee();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getWalletConfig();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.airplane.web_partnership.WalletConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string office_id = 1;
 * @return {string}
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq} returns this
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CommissionRate commission = 2;
 * @return {?proto.airplane.web_partnership.CommissionRate}
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.getCommission = function() {
  return /** @type{?proto.airplane.web_partnership.CommissionRate} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.CommissionRate, 2));
};


/**
 * @param {?proto.airplane.web_partnership.CommissionRate|undefined} value
 * @return {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq} returns this
*/
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.setCommission = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq} returns this
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.clearCommission = function() {
  return this.setCommission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.hasCommission = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SaleServiceFee sale_service_fee = 3;
 * @return {?proto.airplane.web_partnership.SaleServiceFee}
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.getSaleServiceFee = function() {
  return /** @type{?proto.airplane.web_partnership.SaleServiceFee} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.SaleServiceFee, 3));
};


/**
 * @param {?proto.airplane.web_partnership.SaleServiceFee|undefined} value
 * @return {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq} returns this
*/
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.setSaleServiceFee = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq} returns this
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.clearSaleServiceFee = function() {
  return this.setSaleServiceFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.hasSaleServiceFee = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double platform_fee = 4;
 * @return {number}
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.getPlatformFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq} returns this
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.setPlatformFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional WalletConfig wallet_config = 5;
 * @return {?proto.airplane.web_partnership.WalletConfig}
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.getWalletConfig = function() {
  return /** @type{?proto.airplane.web_partnership.WalletConfig} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.WalletConfig, 5));
};


/**
 * @param {?proto.airplane.web_partnership.WalletConfig|undefined} value
 * @return {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq} returns this
*/
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.setWalletConfig = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq} returns this
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.clearWalletConfig = function() {
  return this.setWalletConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.UpdateConfigByOfficeIDReq.prototype.hasWalletConfig = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.GetConfigRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.GetConfigRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.GetConfigRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.GetConfigRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.airplane.web_partnership.GetConfigRes.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.GetConfigRes}
 */
proto.airplane.web_partnership.GetConfigRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.GetConfigRes;
  return proto.airplane.web_partnership.GetConfigRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.GetConfigRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.GetConfigRes}
 */
proto.airplane.web_partnership.GetConfigRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.GetConfigRes.Data;
      reader.readMessage(value,proto.airplane.web_partnership.GetConfigRes.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.GetConfigRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.GetConfigRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.GetConfigRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.GetConfigRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partnership.GetConfigRes.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.GetConfigRes.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.GetConfigRes.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.GetConfigRes.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    vat: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    commission: (f = msg.getCommission()) && proto.airplane.web_partnership.CommissionRate.toObject(includeInstance, f),
    saleServiceFee: (f = msg.getSaleServiceFee()) && proto.airplane.web_partnership.SaleServiceFee.toObject(includeInstance, f),
    platformFee: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    walletConfig: (f = msg.getWalletConfig()) && proto.airplane.web_partnership.WalletConfig.toObject(includeInstance, f),
    officeBalance: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.GetConfigRes.Data}
 */
proto.airplane.web_partnership.GetConfigRes.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.GetConfigRes.Data;
  return proto.airplane.web_partnership.GetConfigRes.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.GetConfigRes.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.GetConfigRes.Data}
 */
proto.airplane.web_partnership.GetConfigRes.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVat(value);
      break;
    case 2:
      var value = new proto.airplane.web_partnership.CommissionRate;
      reader.readMessage(value,proto.airplane.web_partnership.CommissionRate.deserializeBinaryFromReader);
      msg.setCommission(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.SaleServiceFee;
      reader.readMessage(value,proto.airplane.web_partnership.SaleServiceFee.deserializeBinaryFromReader);
      msg.setSaleServiceFee(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPlatformFee(value);
      break;
    case 5:
      var value = new proto.airplane.web_partnership.WalletConfig;
      reader.readMessage(value,proto.airplane.web_partnership.WalletConfig.deserializeBinaryFromReader);
      msg.setWalletConfig(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOfficeBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.GetConfigRes.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.GetConfigRes.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.GetConfigRes.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVat();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getCommission();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.airplane.web_partnership.CommissionRate.serializeBinaryToWriter
    );
  }
  f = message.getSaleServiceFee();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.airplane.web_partnership.SaleServiceFee.serializeBinaryToWriter
    );
  }
  f = message.getPlatformFee();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getWalletConfig();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.airplane.web_partnership.WalletConfig.serializeBinaryToWriter
    );
  }
  f = message.getOfficeBalance();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional double vat = 1;
 * @return {number}
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.getVat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.GetConfigRes.Data} returns this
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.setVat = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional CommissionRate commission = 2;
 * @return {?proto.airplane.web_partnership.CommissionRate}
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.getCommission = function() {
  return /** @type{?proto.airplane.web_partnership.CommissionRate} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.CommissionRate, 2));
};


/**
 * @param {?proto.airplane.web_partnership.CommissionRate|undefined} value
 * @return {!proto.airplane.web_partnership.GetConfigRes.Data} returns this
*/
proto.airplane.web_partnership.GetConfigRes.Data.prototype.setCommission = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.GetConfigRes.Data} returns this
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.clearCommission = function() {
  return this.setCommission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.hasCommission = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SaleServiceFee sale_service_fee = 3;
 * @return {?proto.airplane.web_partnership.SaleServiceFee}
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.getSaleServiceFee = function() {
  return /** @type{?proto.airplane.web_partnership.SaleServiceFee} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.SaleServiceFee, 3));
};


/**
 * @param {?proto.airplane.web_partnership.SaleServiceFee|undefined} value
 * @return {!proto.airplane.web_partnership.GetConfigRes.Data} returns this
*/
proto.airplane.web_partnership.GetConfigRes.Data.prototype.setSaleServiceFee = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.GetConfigRes.Data} returns this
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.clearSaleServiceFee = function() {
  return this.setSaleServiceFee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.hasSaleServiceFee = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double platform_fee = 4;
 * @return {number}
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.getPlatformFee = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.GetConfigRes.Data} returns this
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.setPlatformFee = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional WalletConfig wallet_config = 5;
 * @return {?proto.airplane.web_partnership.WalletConfig}
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.getWalletConfig = function() {
  return /** @type{?proto.airplane.web_partnership.WalletConfig} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.WalletConfig, 5));
};


/**
 * @param {?proto.airplane.web_partnership.WalletConfig|undefined} value
 * @return {!proto.airplane.web_partnership.GetConfigRes.Data} returns this
*/
proto.airplane.web_partnership.GetConfigRes.Data.prototype.setWalletConfig = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.GetConfigRes.Data} returns this
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.clearWalletConfig = function() {
  return this.setWalletConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.hasWalletConfig = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional double office_balance = 6;
 * @return {number}
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.getOfficeBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.airplane.web_partnership.GetConfigRes.Data} returns this
 */
proto.airplane.web_partnership.GetConfigRes.Data.prototype.setOfficeBalance = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.GetConfigRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.GetConfigRes} returns this
 */
proto.airplane.web_partnership.GetConfigRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.GetConfigRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.GetConfigRes} returns this
 */
proto.airplane.web_partnership.GetConfigRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.airplane.web_partnership.GetConfigRes.Data}
 */
proto.airplane.web_partnership.GetConfigRes.prototype.getData = function() {
  return /** @type{?proto.airplane.web_partnership.GetConfigRes.Data} */ (
    jspb.Message.getWrapperField(this, proto.airplane.web_partnership.GetConfigRes.Data, 3));
};


/**
 * @param {?proto.airplane.web_partnership.GetConfigRes.Data|undefined} value
 * @return {!proto.airplane.web_partnership.GetConfigRes} returns this
*/
proto.airplane.web_partnership.GetConfigRes.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.airplane.web_partnership.GetConfigRes} returns this
 */
proto.airplane.web_partnership.GetConfigRes.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.airplane.web_partnership.GetConfigRes.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.airplane.web_partnership.GetListOfficeRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.GetListOfficeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.GetListOfficeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.GetListOfficeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.GetListOfficeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    dataList: jspb.Message.toObjectList(msg.getDataList(),
    proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.GetListOfficeRes}
 */
proto.airplane.web_partnership.GetListOfficeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.GetListOfficeRes;
  return proto.airplane.web_partnership.GetListOfficeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.GetListOfficeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.GetListOfficeRes}
 */
proto.airplane.web_partnership.GetListOfficeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo;
      reader.readMessage(value,proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.deserializeBinaryFromReader);
      msg.addData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.GetListOfficeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.GetListOfficeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.GetListOfficeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.GetListOfficeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    officeId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    officeName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo}
 */
proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo;
  return proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo}
 */
proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOfficeName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOfficeId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOfficeName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string office_id = 1;
 * @return {string}
 */
proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.prototype.getOfficeId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo} returns this
 */
proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.prototype.setOfficeId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string office_name = 2;
 * @return {string}
 */
proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.prototype.getOfficeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo} returns this
 */
proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.prototype.setOfficeName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo} returns this
 */
proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.airplane.web_partnership.GetListOfficeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.airplane.web_partnership.GetListOfficeRes} returns this
 */
proto.airplane.web_partnership.GetListOfficeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.airplane.web_partnership.GetListOfficeRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.airplane.web_partnership.GetListOfficeRes} returns this
 */
proto.airplane.web_partnership.GetListOfficeRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated OfficeInfo data = 3;
 * @return {!Array<!proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo>}
 */
proto.airplane.web_partnership.GetListOfficeRes.prototype.getDataList = function() {
  return /** @type{!Array<!proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo, 3));
};


/**
 * @param {!Array<!proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo>} value
 * @return {!proto.airplane.web_partnership.GetListOfficeRes} returns this
*/
proto.airplane.web_partnership.GetListOfficeRes.prototype.setDataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo}
 */
proto.airplane.web_partnership.GetListOfficeRes.prototype.addData = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.airplane.web_partnership.GetListOfficeRes.OfficeInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.airplane.web_partnership.GetListOfficeRes} returns this
 */
proto.airplane.web_partnership.GetListOfficeRes.prototype.clearDataList = function() {
  return this.setDataList([]);
};


goog.object.extend(exports, proto.airplane.web_partnership);
