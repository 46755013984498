// source: partnership/web_partnership/partnership_user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
var partnership_web_partnership_base_pb = require('../../partnership/web_partnership/base_pb.js');
goog.object.extend(proto, partnership_web_partnership_base_pb);
var partnership_base_pb = require('../../partnership/base_pb.js');
goog.object.extend(proto, partnership_base_pb);
var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
goog.exportSymbol('proto.partnership.web_partnership.AdminChangeUserPasswordReq', null, global);
goog.exportSymbol('proto.partnership.web_partnership.AdminChangeUserPasswordRes', null, global);
goog.exportSymbol('proto.partnership.web_partnership.BankInfo', null, global);
goog.exportSymbol('proto.partnership.web_partnership.ChangePasswordReq', null, global);
goog.exportSymbol('proto.partnership.web_partnership.ChangePasswordV2Res', null, global);
goog.exportSymbol('proto.partnership.web_partnership.ContactInfo', null, global);
goog.exportSymbol('proto.partnership.web_partnership.CreateUserReq', null, global);
goog.exportSymbol('proto.partnership.web_partnership.CreateUserRes', null, global);
goog.exportSymbol('proto.partnership.web_partnership.CreateUserV2Req', null, global);
goog.exportSymbol('proto.partnership.web_partnership.CreateUserV2Res', null, global);
goog.exportSymbol('proto.partnership.web_partnership.CreateUserV2Res.Data', null, global);
goog.exportSymbol('proto.partnership.web_partnership.DeleteUserReq', null, global);
goog.exportSymbol('proto.partnership.web_partnership.DisableUserReq', null, global);
goog.exportSymbol('proto.partnership.web_partnership.GetBasicUsersReq', null, global);
goog.exportSymbol('proto.partnership.web_partnership.GetBasicUsersRes', null, global);
goog.exportSymbol('proto.partnership.web_partnership.GetMeReq', null, global);
goog.exportSymbol('proto.partnership.web_partnership.GetMeRes', null, global);
goog.exportSymbol('proto.partnership.web_partnership.GetMeV2Res', null, global);
goog.exportSymbol('proto.partnership.web_partnership.GetMeV2Res.Data', null, global);
goog.exportSymbol('proto.partnership.web_partnership.GetUserDetailV2Req', null, global);
goog.exportSymbol('proto.partnership.web_partnership.GetUserDetailV2Res', null, global);
goog.exportSymbol('proto.partnership.web_partnership.GetUserDetailV2Res.Data', null, global);
goog.exportSymbol('proto.partnership.web_partnership.GetUsersReq', null, global);
goog.exportSymbol('proto.partnership.web_partnership.GetUsersRes', null, global);
goog.exportSymbol('proto.partnership.web_partnership.GetUsersV2Res', null, global);
goog.exportSymbol('proto.partnership.web_partnership.GetUsersV2Res.Data', null, global);
goog.exportSymbol('proto.partnership.web_partnership.IdInfo', null, global);
goog.exportSymbol('proto.partnership.web_partnership.ResetPasswordReq', null, global);
goog.exportSymbol('proto.partnership.web_partnership.SendOtpToResetPasswordReq', null, global);
goog.exportSymbol('proto.partnership.web_partnership.SendOtpToResetPasswordRes', null, global);
goog.exportSymbol('proto.partnership.web_partnership.UpdateMeReq', null, global);
goog.exportSymbol('proto.partnership.web_partnership.UpdateMeV2Res', null, global);
goog.exportSymbol('proto.partnership.web_partnership.UpdateUserContactInfo', null, global);
goog.exportSymbol('proto.partnership.web_partnership.UpdateUserReq', null, global);
goog.exportSymbol('proto.partnership.web_partnership.UpdateUserV2Req', null, global);
goog.exportSymbol('proto.partnership.web_partnership.UpdateUserV2Res', null, global);
goog.exportSymbol('proto.partnership.web_partnership.UserBasicInfo', null, global);
goog.exportSymbol('proto.partnership.web_partnership.UserInfo', null, global);
goog.exportSymbol('proto.partnership.web_partnership.UserInfo.ListItem', null, global);
goog.exportSymbol('proto.partnership.web_partnership.VerifyOtpToResetPasswordReq', null, global);
goog.exportSymbol('proto.partnership.web_partnership.VerifyOtpToResetPasswordRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.UserInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partnership.web_partnership.UserInfo.repeatedFields_, null);
};
goog.inherits(proto.partnership.web_partnership.UserInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.UserInfo.displayName = 'proto.partnership.web_partnership.UserInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.UserInfo.ListItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.UserInfo.ListItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.UserInfo.ListItem.displayName = 'proto.partnership.web_partnership.UserInfo.ListItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.ContactInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.ContactInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.ContactInfo.displayName = 'proto.partnership.web_partnership.ContactInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.UpdateUserContactInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.UpdateUserContactInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.UpdateUserContactInfo.displayName = 'proto.partnership.web_partnership.UpdateUserContactInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.UserBasicInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.UserBasicInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.UserBasicInfo.displayName = 'proto.partnership.web_partnership.UserBasicInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.CreateUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.CreateUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.CreateUserReq.displayName = 'proto.partnership.web_partnership.CreateUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.CreateUserV2Req = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partnership.web_partnership.CreateUserV2Req.repeatedFields_, null);
};
goog.inherits(proto.partnership.web_partnership.CreateUserV2Req, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.CreateUserV2Req.displayName = 'proto.partnership.web_partnership.CreateUserV2Req';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.IdInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.IdInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.IdInfo.displayName = 'proto.partnership.web_partnership.IdInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.BankInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.BankInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.BankInfo.displayName = 'proto.partnership.web_partnership.BankInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.UpdateMeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.UpdateMeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.UpdateMeReq.displayName = 'proto.partnership.web_partnership.UpdateMeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.ChangePasswordReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.ChangePasswordReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.ChangePasswordReq.displayName = 'proto.partnership.web_partnership.ChangePasswordReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.GetMeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.GetMeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.GetMeReq.displayName = 'proto.partnership.web_partnership.GetMeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.GetUserDetailV2Req = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.GetUserDetailV2Req, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.GetUserDetailV2Req.displayName = 'proto.partnership.web_partnership.GetUserDetailV2Req';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.SendOtpToResetPasswordReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.SendOtpToResetPasswordReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.SendOtpToResetPasswordReq.displayName = 'proto.partnership.web_partnership.SendOtpToResetPasswordReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.VerifyOtpToResetPasswordReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.displayName = 'proto.partnership.web_partnership.VerifyOtpToResetPasswordReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.ResetPasswordReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.ResetPasswordReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.ResetPasswordReq.displayName = 'proto.partnership.web_partnership.ResetPasswordReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.GetBasicUsersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partnership.web_partnership.GetBasicUsersReq.repeatedFields_, null);
};
goog.inherits(proto.partnership.web_partnership.GetBasicUsersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.GetBasicUsersReq.displayName = 'proto.partnership.web_partnership.GetBasicUsersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.UpdateUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.UpdateUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.UpdateUserReq.displayName = 'proto.partnership.web_partnership.UpdateUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.UpdateUserV2Req = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partnership.web_partnership.UpdateUserV2Req.repeatedFields_, null);
};
goog.inherits(proto.partnership.web_partnership.UpdateUserV2Req, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.UpdateUserV2Req.displayName = 'proto.partnership.web_partnership.UpdateUserV2Req';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.DeleteUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.DeleteUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.DeleteUserReq.displayName = 'proto.partnership.web_partnership.DeleteUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.DisableUserReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.DisableUserReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.DisableUserReq.displayName = 'proto.partnership.web_partnership.DisableUserReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.GetUsersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partnership.web_partnership.GetUsersReq.repeatedFields_, null);
};
goog.inherits(proto.partnership.web_partnership.GetUsersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.GetUsersReq.displayName = 'proto.partnership.web_partnership.GetUsersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.AdminChangeUserPasswordReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.AdminChangeUserPasswordReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.AdminChangeUserPasswordReq.displayName = 'proto.partnership.web_partnership.AdminChangeUserPasswordReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.GetMeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partnership.web_partnership.GetMeRes.repeatedFields_, null);
};
goog.inherits(proto.partnership.web_partnership.GetMeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.GetMeRes.displayName = 'proto.partnership.web_partnership.GetMeRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.GetMeV2Res = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.GetMeV2Res, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.GetMeV2Res.displayName = 'proto.partnership.web_partnership.GetMeV2Res';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.GetMeV2Res.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.GetMeV2Res.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.GetMeV2Res.Data.displayName = 'proto.partnership.web_partnership.GetMeV2Res.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.GetUserDetailV2Res = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.GetUserDetailV2Res, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.GetUserDetailV2Res.displayName = 'proto.partnership.web_partnership.GetUserDetailV2Res';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.GetUserDetailV2Res.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.GetUserDetailV2Res.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.GetUserDetailV2Res.Data.displayName = 'proto.partnership.web_partnership.GetUserDetailV2Res.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.GetBasicUsersRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partnership.web_partnership.GetBasicUsersRes.repeatedFields_, null);
};
goog.inherits(proto.partnership.web_partnership.GetBasicUsersRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.GetBasicUsersRes.displayName = 'proto.partnership.web_partnership.GetBasicUsersRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.SendOtpToResetPasswordRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.SendOtpToResetPasswordRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.SendOtpToResetPasswordRes.displayName = 'proto.partnership.web_partnership.SendOtpToResetPasswordRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.VerifyOtpToResetPasswordRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.displayName = 'proto.partnership.web_partnership.VerifyOtpToResetPasswordRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.UpdateMeV2Res = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.UpdateMeV2Res, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.UpdateMeV2Res.displayName = 'proto.partnership.web_partnership.UpdateMeV2Res';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.UpdateUserV2Res = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.UpdateUserV2Res, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.UpdateUserV2Res.displayName = 'proto.partnership.web_partnership.UpdateUserV2Res';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.CreateUserRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.CreateUserRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.CreateUserRes.displayName = 'proto.partnership.web_partnership.CreateUserRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.CreateUserV2Res = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.CreateUserV2Res, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.CreateUserV2Res.displayName = 'proto.partnership.web_partnership.CreateUserV2Res';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.CreateUserV2Res.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.CreateUserV2Res.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.CreateUserV2Res.Data.displayName = 'proto.partnership.web_partnership.CreateUserV2Res.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.ChangePasswordV2Res = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.ChangePasswordV2Res, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.ChangePasswordV2Res.displayName = 'proto.partnership.web_partnership.ChangePasswordV2Res';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.AdminChangeUserPasswordRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.AdminChangeUserPasswordRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.AdminChangeUserPasswordRes.displayName = 'proto.partnership.web_partnership.AdminChangeUserPasswordRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.GetUsersRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partnership.web_partnership.GetUsersRes.repeatedFields_, null);
};
goog.inherits(proto.partnership.web_partnership.GetUsersRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.GetUsersRes.displayName = 'proto.partnership.web_partnership.GetUsersRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.GetUsersV2Res = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.partnership.web_partnership.GetUsersV2Res, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.GetUsersV2Res.displayName = 'proto.partnership.web_partnership.GetUsersV2Res';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.partnership.web_partnership.GetUsersV2Res.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.partnership.web_partnership.GetUsersV2Res.Data.repeatedFields_, null);
};
goog.inherits(proto.partnership.web_partnership.GetUsersV2Res.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.partnership.web_partnership.GetUsersV2Res.Data.displayName = 'proto.partnership.web_partnership.GetUsersV2Res.Data';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partnership.web_partnership.UserInfo.repeatedFields_ = [17,26,27];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.UserInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.UserInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.UserInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UserInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    email: jspb.Message.getFieldWithDefault(msg, 4, ""),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    profilePicture: jspb.Message.getFieldWithDefault(msg, 7, ""),
    description: jspb.Message.getFieldWithDefault(msg, 8, ""),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    isOwner: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 11, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 12, ""),
    username: jspb.Message.getFieldWithDefault(msg, 13, ""),
    contact: (f = msg.getContact()) && proto.partnership.web_partnership.ContactInfo.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 16, 0),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    dob: jspb.Message.getFieldWithDefault(msg, 18, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 19, 0),
    address: jspb.Message.getFieldWithDefault(msg, 22, ""),
    idInfo: (f = msg.getIdInfo()) && proto.partnership.web_partnership.IdInfo.toObject(includeInstance, f),
    bankInfo: (f = msg.getBankInfo()) && proto.partnership.web_partnership.BankInfo.toObject(includeInstance, f),
    taxCode: jspb.Message.getFieldWithDefault(msg, 25, ""),
    assignedOfficesList: jspb.Message.toObjectList(msg.getAssignedOfficesList(),
    proto.partnership.web_partnership.UserInfo.ListItem.toObject, includeInstance),
    providerIdsList: (f = jspb.Message.getRepeatedField(msg, 27)) == null ? undefined : f,
    domainId: jspb.Message.getFieldWithDefault(msg, 28, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.UserInfo}
 */
proto.partnership.web_partnership.UserInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.UserInfo;
  return proto.partnership.web_partnership.UserInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.UserInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.UserInfo}
 */
proto.partnership.web_partnership.UserInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicture(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOwner(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 14:
      var value = new proto.partnership.web_partnership.ContactInfo;
      reader.readMessage(value,proto.partnership.web_partnership.ContactInfo.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    case 16:
      var value = /** @type {!proto.partnership.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoles(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDob(value);
      break;
    case 19:
      var value = /** @type {!proto.base.GENDER} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 23:
      var value = new proto.partnership.web_partnership.IdInfo;
      reader.readMessage(value,proto.partnership.web_partnership.IdInfo.deserializeBinaryFromReader);
      msg.setIdInfo(value);
      break;
    case 24:
      var value = new proto.partnership.web_partnership.BankInfo;
      reader.readMessage(value,proto.partnership.web_partnership.BankInfo.deserializeBinaryFromReader);
      msg.setBankInfo(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxCode(value);
      break;
    case 26:
      var value = new proto.partnership.web_partnership.UserInfo.ListItem;
      reader.readMessage(value,proto.partnership.web_partnership.UserInfo.ListItem.deserializeBinaryFromReader);
      msg.addAssignedOffices(value);
      break;
    case 27:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProviderIds(values[i]);
      }
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.UserInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.UserInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.UserInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UserInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProfilePicture();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIsOwner();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.partnership.web_partnership.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      16,
      f
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
  f = message.getDob();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      19,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getIdInfo();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.partnership.web_partnership.IdInfo.serializeBinaryToWriter
    );
  }
  f = message.getBankInfo();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.partnership.web_partnership.BankInfo.serializeBinaryToWriter
    );
  }
  f = message.getTaxCode();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getAssignedOfficesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.partnership.web_partnership.UserInfo.ListItem.serializeBinaryToWriter
    );
  }
  f = message.getProviderIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      27,
      f
    );
  }
  f = message.getDomainId();
  if (f !== 0) {
    writer.writeInt64(
      28,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.UserInfo.ListItem.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.UserInfo.ListItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.UserInfo.ListItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UserInfo.ListItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.UserInfo.ListItem}
 */
proto.partnership.web_partnership.UserInfo.ListItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.UserInfo.ListItem;
  return proto.partnership.web_partnership.UserInfo.ListItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.UserInfo.ListItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.UserInfo.ListItem}
 */
proto.partnership.web_partnership.UserInfo.ListItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.UserInfo.ListItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.UserInfo.ListItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.UserInfo.ListItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UserInfo.ListItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.partnership.web_partnership.UserInfo.ListItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserInfo.ListItem} returns this
 */
proto.partnership.web_partnership.UserInfo.ListItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.partnership.web_partnership.UserInfo.ListItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserInfo.ListItem} returns this
 */
proto.partnership.web_partnership.UserInfo.ListItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partnership.web_partnership.UserInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.partnership.web_partnership.UserInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 updated_at = 3;
 * @return {number}
 */
proto.partnership.web_partnership.UserInfo.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string email = 4;
 * @return {string}
 */
proto.partnership.web_partnership.UserInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.partnership.web_partnership.UserInfo.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.partnership.web_partnership.UserInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string profile_picture = 7;
 * @return {string}
 */
proto.partnership.web_partnership.UserInfo.prototype.getProfilePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setProfilePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string description = 8;
 * @return {string}
 */
proto.partnership.web_partnership.UserInfo.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string partnership_id = 9;
 * @return {string}
 */
proto.partnership.web_partnership.UserInfo.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional bool is_owner = 10;
 * @return {boolean}
 */
proto.partnership.web_partnership.UserInfo.prototype.getIsOwner = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setIsOwner = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string phone_code = 11;
 * @return {string}
 */
proto.partnership.web_partnership.UserInfo.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string phone_number = 12;
 * @return {string}
 */
proto.partnership.web_partnership.UserInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string username = 13;
 * @return {string}
 */
proto.partnership.web_partnership.UserInfo.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional ContactInfo contact = 14;
 * @return {?proto.partnership.web_partnership.ContactInfo}
 */
proto.partnership.web_partnership.UserInfo.prototype.getContact = function() {
  return /** @type{?proto.partnership.web_partnership.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.ContactInfo, 14));
};


/**
 * @param {?proto.partnership.web_partnership.ContactInfo|undefined} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
*/
proto.partnership.web_partnership.UserInfo.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.UserInfo.prototype.hasContact = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional partnership.UserStatus status = 16;
 * @return {!proto.partnership.UserStatus}
 */
proto.partnership.web_partnership.UserInfo.prototype.getStatus = function() {
  return /** @type {!proto.partnership.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {!proto.partnership.UserStatus} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 16, value);
};


/**
 * repeated string roles = 17;
 * @return {!Array<string>}
 */
proto.partnership.web_partnership.UserInfo.prototype.getRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};


/**
 * optional int64 dob = 18;
 * @return {number}
 */
proto.partnership.web_partnership.UserInfo.prototype.getDob = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setDob = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional base.GENDER gender = 19;
 * @return {!proto.base.GENDER}
 */
proto.partnership.web_partnership.UserInfo.prototype.getGender = function() {
  return /** @type {!proto.base.GENDER} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {!proto.base.GENDER} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 19, value);
};


/**
 * optional string address = 22;
 * @return {string}
 */
proto.partnership.web_partnership.UserInfo.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional IdInfo id_info = 23;
 * @return {?proto.partnership.web_partnership.IdInfo}
 */
proto.partnership.web_partnership.UserInfo.prototype.getIdInfo = function() {
  return /** @type{?proto.partnership.web_partnership.IdInfo} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.IdInfo, 23));
};


/**
 * @param {?proto.partnership.web_partnership.IdInfo|undefined} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
*/
proto.partnership.web_partnership.UserInfo.prototype.setIdInfo = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.clearIdInfo = function() {
  return this.setIdInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.UserInfo.prototype.hasIdInfo = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional BankInfo bank_info = 24;
 * @return {?proto.partnership.web_partnership.BankInfo}
 */
proto.partnership.web_partnership.UserInfo.prototype.getBankInfo = function() {
  return /** @type{?proto.partnership.web_partnership.BankInfo} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.BankInfo, 24));
};


/**
 * @param {?proto.partnership.web_partnership.BankInfo|undefined} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
*/
proto.partnership.web_partnership.UserInfo.prototype.setBankInfo = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.clearBankInfo = function() {
  return this.setBankInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.UserInfo.prototype.hasBankInfo = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional string tax_code = 25;
 * @return {string}
 */
proto.partnership.web_partnership.UserInfo.prototype.getTaxCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setTaxCode = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * repeated ListItem assigned_offices = 26;
 * @return {!Array<!proto.partnership.web_partnership.UserInfo.ListItem>}
 */
proto.partnership.web_partnership.UserInfo.prototype.getAssignedOfficesList = function() {
  return /** @type{!Array<!proto.partnership.web_partnership.UserInfo.ListItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partnership.web_partnership.UserInfo.ListItem, 26));
};


/**
 * @param {!Array<!proto.partnership.web_partnership.UserInfo.ListItem>} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
*/
proto.partnership.web_partnership.UserInfo.prototype.setAssignedOfficesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.partnership.web_partnership.UserInfo.ListItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.UserInfo.ListItem}
 */
proto.partnership.web_partnership.UserInfo.prototype.addAssignedOffices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.partnership.web_partnership.UserInfo.ListItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.clearAssignedOfficesList = function() {
  return this.setAssignedOfficesList([]);
};


/**
 * repeated int64 provider_ids = 27;
 * @return {!Array<number>}
 */
proto.partnership.web_partnership.UserInfo.prototype.getProviderIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 27));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setProviderIdsList = function(value) {
  return jspb.Message.setField(this, 27, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.addProviderIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 27, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.clearProviderIdsList = function() {
  return this.setProviderIdsList([]);
};


/**
 * optional int64 domain_id = 28;
 * @return {number}
 */
proto.partnership.web_partnership.UserInfo.prototype.getDomainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.partnership.web_partnership.UserInfo} returns this
 */
proto.partnership.web_partnership.UserInfo.prototype.setDomainId = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.ContactInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.ContactInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.ContactInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.ContactInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.ContactInfo}
 */
proto.partnership.web_partnership.ContactInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.ContactInfo;
  return proto.partnership.web_partnership.ContactInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.ContactInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.ContactInfo}
 */
proto.partnership.web_partnership.ContactInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.ContactInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.ContactInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.ContactInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.ContactInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.partnership.web_partnership.ContactInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.ContactInfo} returns this
 */
proto.partnership.web_partnership.ContactInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.partnership.web_partnership.ContactInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.ContactInfo} returns this
 */
proto.partnership.web_partnership.ContactInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.partnership.web_partnership.ContactInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.ContactInfo} returns this
 */
proto.partnership.web_partnership.ContactInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.UpdateUserContactInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.UpdateUserContactInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.UpdateUserContactInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UpdateUserContactInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.UpdateUserContactInfo}
 */
proto.partnership.web_partnership.UpdateUserContactInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.UpdateUserContactInfo;
  return proto.partnership.web_partnership.UpdateUserContactInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.UpdateUserContactInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.UpdateUserContactInfo}
 */
proto.partnership.web_partnership.UpdateUserContactInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.UpdateUserContactInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.UpdateUserContactInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.UpdateUserContactInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UpdateUserContactInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateUserContactInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateUserContactInfo} returns this
 */
proto.partnership.web_partnership.UpdateUserContactInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateUserContactInfo.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateUserContactInfo} returns this
 */
proto.partnership.web_partnership.UpdateUserContactInfo.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partnership.web_partnership.UpdateUserContactInfo} returns this
 */
proto.partnership.web_partnership.UpdateUserContactInfo.prototype.clearPhoneNumber = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.UpdateUserContactInfo.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateUserContactInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateUserContactInfo} returns this
 */
proto.partnership.web_partnership.UpdateUserContactInfo.prototype.setEmail = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.partnership.web_partnership.UpdateUserContactInfo} returns this
 */
proto.partnership.web_partnership.UpdateUserContactInfo.prototype.clearEmail = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.UpdateUserContactInfo.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.UserBasicInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.UserBasicInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.UserBasicInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UserBasicInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profilePicture: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.UserBasicInfo}
 */
proto.partnership.web_partnership.UserBasicInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.UserBasicInfo;
  return proto.partnership.web_partnership.UserBasicInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.UserBasicInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.UserBasicInfo}
 */
proto.partnership.web_partnership.UserBasicInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicture(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.UserBasicInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.UserBasicInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.UserBasicInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UserBasicInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfilePicture();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.partnership.web_partnership.UserBasicInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserBasicInfo} returns this
 */
proto.partnership.web_partnership.UserBasicInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.partnership.web_partnership.UserBasicInfo.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserBasicInfo} returns this
 */
proto.partnership.web_partnership.UserBasicInfo.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.partnership.web_partnership.UserBasicInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserBasicInfo} returns this
 */
proto.partnership.web_partnership.UserBasicInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profile_picture = 4;
 * @return {string}
 */
proto.partnership.web_partnership.UserBasicInfo.prototype.getProfilePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UserBasicInfo} returns this
 */
proto.partnership.web_partnership.UserBasicInfo.prototype.setProfilePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.CreateUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.CreateUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.CreateUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.CreateUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profilePicture: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    phoneCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.CreateUserReq}
 */
proto.partnership.web_partnership.CreateUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.CreateUserReq;
  return proto.partnership.web_partnership.CreateUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.CreateUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.CreateUserReq}
 */
proto.partnership.web_partnership.CreateUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicture(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.CreateUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.CreateUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.CreateUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.CreateUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfilePicture();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.partnership.web_partnership.CreateUserReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.CreateUserReq} returns this
 */
proto.partnership.web_partnership.CreateUserReq.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partnership.web_partnership.CreateUserReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.CreateUserReq} returns this
 */
proto.partnership.web_partnership.CreateUserReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.partnership.web_partnership.CreateUserReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.CreateUserReq} returns this
 */
proto.partnership.web_partnership.CreateUserReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profile_picture = 4;
 * @return {string}
 */
proto.partnership.web_partnership.CreateUserReq.prototype.getProfilePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.CreateUserReq} returns this
 */
proto.partnership.web_partnership.CreateUserReq.prototype.setProfilePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.partnership.web_partnership.CreateUserReq.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.CreateUserReq} returns this
 */
proto.partnership.web_partnership.CreateUserReq.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string partnership_id = 6;
 * @return {string}
 */
proto.partnership.web_partnership.CreateUserReq.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.CreateUserReq} returns this
 */
proto.partnership.web_partnership.CreateUserReq.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string phone_code = 7;
 * @return {string}
 */
proto.partnership.web_partnership.CreateUserReq.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.CreateUserReq} returns this
 */
proto.partnership.web_partnership.CreateUserReq.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string phone_number = 8;
 * @return {string}
 */
proto.partnership.web_partnership.CreateUserReq.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.CreateUserReq} returns this
 */
proto.partnership.web_partnership.CreateUserReq.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partnership.web_partnership.CreateUserV2Req.repeatedFields_ = [2,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.CreateUserV2Req.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.CreateUserV2Req} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.CreateUserV2Req.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    roleList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    contactInfo: (f = msg.getContactInfo()) && proto.partnership.web_partnership.UpdateUserContactInfo.toObject(includeInstance, f),
    password: jspb.Message.getFieldWithDefault(msg, 5, ""),
    officeIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    providerIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    domainId: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.CreateUserV2Req}
 */
proto.partnership.web_partnership.CreateUserV2Req.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.CreateUserV2Req;
  return proto.partnership.web_partnership.CreateUserV2Req.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.CreateUserV2Req} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.CreateUserV2Req}
 */
proto.partnership.web_partnership.CreateUserV2Req.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addRole(value);
      break;
    case 3:
      var value = /** @type {!proto.partnership.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.partnership.web_partnership.UpdateUserContactInfo;
      reader.readMessage(value,proto.partnership.web_partnership.UpdateUserContactInfo.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addOfficeIds(value);
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProviderIds(values[i]);
      }
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.CreateUserV2Req.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.CreateUserV2Req} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.CreateUserV2Req.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRoleList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.partnership.web_partnership.UpdateUserContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOfficeIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getProviderIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      7,
      f
    );
  }
  f = message.getDomainId();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string role = 2;
 * @return {!Array<string>}
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.getRoleList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.setRoleList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.addRole = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.clearRoleList = function() {
  return this.setRoleList([]);
};


/**
 * optional partnership.UserStatus status = 3;
 * @return {!proto.partnership.UserStatus}
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.getStatus = function() {
  return /** @type {!proto.partnership.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.partnership.UserStatus} value
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional UpdateUserContactInfo contact_info = 4;
 * @return {?proto.partnership.web_partnership.UpdateUserContactInfo}
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.getContactInfo = function() {
  return /** @type{?proto.partnership.web_partnership.UpdateUserContactInfo} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.UpdateUserContactInfo, 4));
};


/**
 * @param {?proto.partnership.web_partnership.UpdateUserContactInfo|undefined} value
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
*/
proto.partnership.web_partnership.CreateUserV2Req.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string password = 5;
 * @return {string}
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string office_ids = 6;
 * @return {!Array<string>}
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.getOfficeIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.setOfficeIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.addOfficeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.clearOfficeIdsList = function() {
  return this.setOfficeIdsList([]);
};


/**
 * repeated int64 provider_ids = 7;
 * @return {!Array<number>}
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.getProviderIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.setProviderIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.addProviderIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.clearProviderIdsList = function() {
  return this.setProviderIdsList([]);
};


/**
 * optional int64 domain_id = 8;
 * @return {number}
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.getDomainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.partnership.web_partnership.CreateUserV2Req} returns this
 */
proto.partnership.web_partnership.CreateUserV2Req.prototype.setDomainId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.IdInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.IdInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.IdInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.IdInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    number: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    issueBy: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.IdInfo}
 */
proto.partnership.web_partnership.IdInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.IdInfo;
  return proto.partnership.web_partnership.IdInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.IdInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.IdInfo}
 */
proto.partnership.web_partnership.IdInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIssueBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.IdInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.IdInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.IdInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.IdInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getIssueBy();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string number = 1;
 * @return {string}
 */
proto.partnership.web_partnership.IdInfo.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.IdInfo} returns this
 */
proto.partnership.web_partnership.IdInfo.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.partnership.web_partnership.IdInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.partnership.web_partnership.IdInfo} returns this
 */
proto.partnership.web_partnership.IdInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string issue_by = 3;
 * @return {string}
 */
proto.partnership.web_partnership.IdInfo.prototype.getIssueBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.IdInfo} returns this
 */
proto.partnership.web_partnership.IdInfo.prototype.setIssueBy = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.BankInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.BankInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.BankInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.BankInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    branch: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.BankInfo}
 */
proto.partnership.web_partnership.BankInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.BankInfo;
  return proto.partnership.web_partnership.BankInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.BankInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.BankInfo}
 */
proto.partnership.web_partnership.BankInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.BankInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.BankInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.BankInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.BankInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBranch();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string account_number = 1;
 * @return {string}
 */
proto.partnership.web_partnership.BankInfo.prototype.getAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.BankInfo} returns this
 */
proto.partnership.web_partnership.BankInfo.prototype.setAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.partnership.web_partnership.BankInfo.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.BankInfo} returns this
 */
proto.partnership.web_partnership.BankInfo.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string branch = 3;
 * @return {string}
 */
proto.partnership.web_partnership.BankInfo.prototype.getBranch = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.BankInfo} returns this
 */
proto.partnership.web_partnership.BankInfo.prototype.setBranch = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.UpdateMeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.UpdateMeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UpdateMeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profilePicture: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contactInfo: (f = msg.getContactInfo()) && proto.partnership.web_partnership.ContactInfo.toObject(includeInstance, f),
    dob: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gender: jspb.Message.getFieldWithDefault(msg, 6, 0),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    address: jspb.Message.getFieldWithDefault(msg, 8, ""),
    idInfo: (f = msg.getIdInfo()) && proto.partnership.web_partnership.IdInfo.toObject(includeInstance, f),
    bankInfo: (f = msg.getBankInfo()) && proto.partnership.web_partnership.BankInfo.toObject(includeInstance, f),
    taxCode: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.UpdateMeReq}
 */
proto.partnership.web_partnership.UpdateMeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.UpdateMeReq;
  return proto.partnership.web_partnership.UpdateMeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.UpdateMeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.UpdateMeReq}
 */
proto.partnership.web_partnership.UpdateMeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicture(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.partnership.web_partnership.ContactInfo;
      reader.readMessage(value,proto.partnership.web_partnership.ContactInfo.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDob(value);
      break;
    case 6:
      var value = /** @type {!proto.base.GENDER} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 9:
      var value = new proto.partnership.web_partnership.IdInfo;
      reader.readMessage(value,proto.partnership.web_partnership.IdInfo.deserializeBinaryFromReader);
      msg.setIdInfo(value);
      break;
    case 10:
      var value = new proto.partnership.web_partnership.BankInfo;
      reader.readMessage(value,proto.partnership.web_partnership.BankInfo.deserializeBinaryFromReader);
      msg.setBankInfo(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.UpdateMeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.UpdateMeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UpdateMeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfilePicture();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.partnership.web_partnership.ContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getDob();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIdInfo();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.partnership.web_partnership.IdInfo.serializeBinaryToWriter
    );
  }
  f = message.getBankInfo();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.partnership.web_partnership.BankInfo.serializeBinaryToWriter
    );
  }
  f = message.getTaxCode();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateMeReq} returns this
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string profile_picture = 2;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.getProfilePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateMeReq} returns this
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.setProfilePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateMeReq} returns this
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ContactInfo contact_info = 4;
 * @return {?proto.partnership.web_partnership.ContactInfo}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.getContactInfo = function() {
  return /** @type{?proto.partnership.web_partnership.ContactInfo} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.ContactInfo, 4));
};


/**
 * @param {?proto.partnership.web_partnership.ContactInfo|undefined} value
 * @return {!proto.partnership.web_partnership.UpdateMeReq} returns this
*/
proto.partnership.web_partnership.UpdateMeReq.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.UpdateMeReq} returns this
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 dob = 5;
 * @return {number}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.getDob = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.partnership.web_partnership.UpdateMeReq} returns this
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.setDob = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional base.GENDER gender = 6;
 * @return {!proto.base.GENDER}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.getGender = function() {
  return /** @type {!proto.base.GENDER} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.base.GENDER} value
 * @return {!proto.partnership.web_partnership.UpdateMeReq} returns this
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateMeReq} returns this
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string address = 8;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateMeReq} returns this
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional IdInfo id_info = 9;
 * @return {?proto.partnership.web_partnership.IdInfo}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.getIdInfo = function() {
  return /** @type{?proto.partnership.web_partnership.IdInfo} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.IdInfo, 9));
};


/**
 * @param {?proto.partnership.web_partnership.IdInfo|undefined} value
 * @return {!proto.partnership.web_partnership.UpdateMeReq} returns this
*/
proto.partnership.web_partnership.UpdateMeReq.prototype.setIdInfo = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.UpdateMeReq} returns this
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.clearIdInfo = function() {
  return this.setIdInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.hasIdInfo = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional BankInfo bank_info = 10;
 * @return {?proto.partnership.web_partnership.BankInfo}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.getBankInfo = function() {
  return /** @type{?proto.partnership.web_partnership.BankInfo} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.BankInfo, 10));
};


/**
 * @param {?proto.partnership.web_partnership.BankInfo|undefined} value
 * @return {!proto.partnership.web_partnership.UpdateMeReq} returns this
*/
proto.partnership.web_partnership.UpdateMeReq.prototype.setBankInfo = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.UpdateMeReq} returns this
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.clearBankInfo = function() {
  return this.setBankInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.hasBankInfo = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string tax_code = 11;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.getTaxCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateMeReq} returns this
 */
proto.partnership.web_partnership.UpdateMeReq.prototype.setTaxCode = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.ChangePasswordReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.ChangePasswordReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.ChangePasswordReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.ChangePasswordReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldPassword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.ChangePasswordReq}
 */
proto.partnership.web_partnership.ChangePasswordReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.ChangePasswordReq;
  return proto.partnership.web_partnership.ChangePasswordReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.ChangePasswordReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.ChangePasswordReq}
 */
proto.partnership.web_partnership.ChangePasswordReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.ChangePasswordReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.ChangePasswordReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.ChangePasswordReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.ChangePasswordReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string old_password = 1;
 * @return {string}
 */
proto.partnership.web_partnership.ChangePasswordReq.prototype.getOldPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.ChangePasswordReq} returns this
 */
proto.partnership.web_partnership.ChangePasswordReq.prototype.setOldPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.partnership.web_partnership.ChangePasswordReq.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.ChangePasswordReq} returns this
 */
proto.partnership.web_partnership.ChangePasswordReq.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.GetMeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.GetMeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.GetMeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetMeReq.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.GetMeReq}
 */
proto.partnership.web_partnership.GetMeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.GetMeReq;
  return proto.partnership.web_partnership.GetMeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.GetMeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.GetMeReq}
 */
proto.partnership.web_partnership.GetMeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.GetMeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.GetMeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.GetMeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetMeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.GetUserDetailV2Req.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.GetUserDetailV2Req.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.GetUserDetailV2Req} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetUserDetailV2Req.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.GetUserDetailV2Req}
 */
proto.partnership.web_partnership.GetUserDetailV2Req.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.GetUserDetailV2Req;
  return proto.partnership.web_partnership.GetUserDetailV2Req.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.GetUserDetailV2Req} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.GetUserDetailV2Req}
 */
proto.partnership.web_partnership.GetUserDetailV2Req.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.GetUserDetailV2Req.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.GetUserDetailV2Req.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.GetUserDetailV2Req} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetUserDetailV2Req.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.partnership.web_partnership.GetUserDetailV2Req.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.GetUserDetailV2Req} returns this
 */
proto.partnership.web_partnership.GetUserDetailV2Req.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.SendOtpToResetPasswordReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.SendOtpToResetPasswordReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.SendOtpToResetPasswordReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.SendOtpToResetPasswordReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    phoneCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.SendOtpToResetPasswordReq}
 */
proto.partnership.web_partnership.SendOtpToResetPasswordReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.SendOtpToResetPasswordReq;
  return proto.partnership.web_partnership.SendOtpToResetPasswordReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.SendOtpToResetPasswordReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.SendOtpToResetPasswordReq}
 */
proto.partnership.web_partnership.SendOtpToResetPasswordReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.SendOtpToResetPasswordReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.SendOtpToResetPasswordReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.SendOtpToResetPasswordReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.SendOtpToResetPasswordReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string phone_code = 1;
 * @return {string}
 */
proto.partnership.web_partnership.SendOtpToResetPasswordReq.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.SendOtpToResetPasswordReq} returns this
 */
proto.partnership.web_partnership.SendOtpToResetPasswordReq.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.partnership.web_partnership.SendOtpToResetPasswordReq.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.SendOtpToResetPasswordReq} returns this
 */
proto.partnership.web_partnership.SendOtpToResetPasswordReq.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string partnership_id = 3;
 * @return {string}
 */
proto.partnership.web_partnership.SendOtpToResetPasswordReq.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.SendOtpToResetPasswordReq} returns this
 */
proto.partnership.web_partnership.SendOtpToResetPasswordReq.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.VerifyOtpToResetPasswordReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    phoneCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phoneNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    partnershipId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    otp: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.VerifyOtpToResetPasswordReq}
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.VerifyOtpToResetPasswordReq;
  return proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.VerifyOtpToResetPasswordReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.VerifyOtpToResetPasswordReq}
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPartnershipId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.VerifyOtpToResetPasswordReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPhoneCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhoneNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPartnershipId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOtp();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string phone_code = 1;
 * @return {string}
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.prototype.getPhoneCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.VerifyOtpToResetPasswordReq} returns this
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.prototype.setPhoneCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone_number = 2;
 * @return {string}
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.prototype.getPhoneNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.VerifyOtpToResetPasswordReq} returns this
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string partnership_id = 3;
 * @return {string}
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.prototype.getPartnershipId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.VerifyOtpToResetPasswordReq} returns this
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.prototype.setPartnershipId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string otp = 4;
 * @return {string}
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.prototype.getOtp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.VerifyOtpToResetPasswordReq} returns this
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordReq.prototype.setOtp = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.ResetPasswordReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.ResetPasswordReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.ResetPasswordReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.ResetPasswordReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    newPassword: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.ResetPasswordReq}
 */
proto.partnership.web_partnership.ResetPasswordReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.ResetPasswordReq;
  return proto.partnership.web_partnership.ResetPasswordReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.ResetPasswordReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.ResetPasswordReq}
 */
proto.partnership.web_partnership.ResetPasswordReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.ResetPasswordReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.ResetPasswordReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.ResetPasswordReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.ResetPasswordReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string new_password = 1;
 * @return {string}
 */
proto.partnership.web_partnership.ResetPasswordReq.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.ResetPasswordReq} returns this
 */
proto.partnership.web_partnership.ResetPasswordReq.prototype.setNewPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partnership.web_partnership.GetBasicUsersReq.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.GetBasicUsersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.GetBasicUsersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.GetBasicUsersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetBasicUsersReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.GetBasicUsersReq}
 */
proto.partnership.web_partnership.GetBasicUsersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.GetBasicUsersReq;
  return proto.partnership.web_partnership.GetBasicUsersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.GetBasicUsersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.GetBasicUsersReq}
 */
proto.partnership.web_partnership.GetBasicUsersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.GetBasicUsersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.GetBasicUsersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.GetBasicUsersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetBasicUsersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.partnership.web_partnership.GetBasicUsersReq.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.partnership.web_partnership.GetBasicUsersReq} returns this
 */
proto.partnership.web_partnership.GetBasicUsersReq.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.GetBasicUsersReq} returns this
 */
proto.partnership.web_partnership.GetBasicUsersReq.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.GetBasicUsersReq} returns this
 */
proto.partnership.web_partnership.GetBasicUsersReq.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.UpdateUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.UpdateUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.UpdateUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UpdateUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profilePicture: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.UpdateUserReq}
 */
proto.partnership.web_partnership.UpdateUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.UpdateUserReq;
  return proto.partnership.web_partnership.UpdateUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.UpdateUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.UpdateUserReq}
 */
proto.partnership.web_partnership.UpdateUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePicture(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.UpdateUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.UpdateUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.UpdateUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UpdateUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfilePicture();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateUserReq.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateUserReq} returns this
 */
proto.partnership.web_partnership.UpdateUserReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateUserReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateUserReq} returns this
 */
proto.partnership.web_partnership.UpdateUserReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string profile_picture = 3;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateUserReq.prototype.getProfilePicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateUserReq} returns this
 */
proto.partnership.web_partnership.UpdateUserReq.prototype.setProfilePicture = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateUserReq.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateUserReq} returns this
 */
proto.partnership.web_partnership.UpdateUserReq.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partnership.web_partnership.UpdateUserV2Req.repeatedFields_ = [4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.UpdateUserV2Req.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.UpdateUserV2Req} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UpdateUserV2Req.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contactInfo: (f = msg.getContactInfo()) && proto.partnership.web_partnership.UpdateUserContactInfo.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    roleList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    officeIdsList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    providerIdsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    domainId: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req}
 */
proto.partnership.web_partnership.UpdateUserV2Req.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.UpdateUserV2Req;
  return proto.partnership.web_partnership.UpdateUserV2Req.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.UpdateUserV2Req} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req}
 */
proto.partnership.web_partnership.UpdateUserV2Req.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto.partnership.web_partnership.UpdateUserContactInfo;
      reader.readMessage(value,proto.partnership.web_partnership.UpdateUserContactInfo.deserializeBinaryFromReader);
      msg.setContactInfo(value);
      break;
    case 3:
      var value = /** @type {!proto.partnership.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addRole(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addOfficeIds(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addProviderIds(values[i]);
      }
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDomainId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.UpdateUserV2Req.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.UpdateUserV2Req} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UpdateUserV2Req.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContactInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.partnership.web_partnership.UpdateUserContactInfo.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRoleList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getOfficeIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getProviderIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getDomainId();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UpdateUserContactInfo contact_info = 2;
 * @return {?proto.partnership.web_partnership.UpdateUserContactInfo}
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.getContactInfo = function() {
  return /** @type{?proto.partnership.web_partnership.UpdateUserContactInfo} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.UpdateUserContactInfo, 2));
};


/**
 * @param {?proto.partnership.web_partnership.UpdateUserContactInfo|undefined} value
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req} returns this
*/
proto.partnership.web_partnership.UpdateUserV2Req.prototype.setContactInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.clearContactInfo = function() {
  return this.setContactInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.hasContactInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional partnership.UserStatus status = 3;
 * @return {!proto.partnership.UserStatus}
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.getStatus = function() {
  return /** @type {!proto.partnership.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.partnership.UserStatus} value
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string role = 4;
 * @return {!Array<string>}
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.getRoleList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.setRoleList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.addRole = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.clearRoleList = function() {
  return this.setRoleList([]);
};


/**
 * repeated string office_ids = 5;
 * @return {!Array<string>}
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.getOfficeIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.setOfficeIdsList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.addOfficeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.clearOfficeIdsList = function() {
  return this.setOfficeIdsList([]);
};


/**
 * repeated int64 provider_ids = 6;
 * @return {!Array<number>}
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.getProviderIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.setProviderIdsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.addProviderIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.clearProviderIdsList = function() {
  return this.setProviderIdsList([]);
};


/**
 * optional int64 domain_id = 7;
 * @return {number}
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.getDomainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.partnership.web_partnership.UpdateUserV2Req} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Req.prototype.setDomainId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.DeleteUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.DeleteUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.DeleteUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.DeleteUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.DeleteUserReq}
 */
proto.partnership.web_partnership.DeleteUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.DeleteUserReq;
  return proto.partnership.web_partnership.DeleteUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.DeleteUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.DeleteUserReq}
 */
proto.partnership.web_partnership.DeleteUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.DeleteUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.DeleteUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.DeleteUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.DeleteUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.partnership.web_partnership.DeleteUserReq.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.DeleteUserReq} returns this
 */
proto.partnership.web_partnership.DeleteUserReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.DisableUserReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.DisableUserReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.DisableUserReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.DisableUserReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.DisableUserReq}
 */
proto.partnership.web_partnership.DisableUserReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.DisableUserReq;
  return proto.partnership.web_partnership.DisableUserReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.DisableUserReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.DisableUserReq}
 */
proto.partnership.web_partnership.DisableUserReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.DisableUserReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.DisableUserReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.DisableUserReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.DisableUserReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.partnership.web_partnership.DisableUserReq.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.DisableUserReq} returns this
 */
proto.partnership.web_partnership.DisableUserReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partnership.web_partnership.GetUsersReq.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.GetUsersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.GetUsersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.GetUsersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetUsersReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, 0),
    roleList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.GetUsersReq}
 */
proto.partnership.web_partnership.GetUsersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.GetUsersReq;
  return proto.partnership.web_partnership.GetUsersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.GetUsersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.GetUsersReq}
 */
proto.partnership.web_partnership.GetUsersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {!proto.partnership.UserStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addRole(value);
      break;
    case 5:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.GetUsersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.GetUsersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.GetUsersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetUsersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getRoleList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.partnership.web_partnership.GetUsersReq.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.GetUsersReq} returns this
 */
proto.partnership.web_partnership.GetUsersReq.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.partnership.web_partnership.GetUsersReq.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.GetUsersReq} returns this
 */
proto.partnership.web_partnership.GetUsersReq.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional partnership.UserStatus status = 3;
 * @return {!proto.partnership.UserStatus}
 */
proto.partnership.web_partnership.GetUsersReq.prototype.getStatus = function() {
  return /** @type {!proto.partnership.UserStatus} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.partnership.UserStatus} value
 * @return {!proto.partnership.web_partnership.GetUsersReq} returns this
 */
proto.partnership.web_partnership.GetUsersReq.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * repeated string role = 4;
 * @return {!Array<string>}
 */
proto.partnership.web_partnership.GetUsersReq.prototype.getRoleList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.partnership.web_partnership.GetUsersReq} returns this
 */
proto.partnership.web_partnership.GetUsersReq.prototype.setRoleList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.GetUsersReq} returns this
 */
proto.partnership.web_partnership.GetUsersReq.prototype.addRole = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.GetUsersReq} returns this
 */
proto.partnership.web_partnership.GetUsersReq.prototype.clearRoleList = function() {
  return this.setRoleList([]);
};


/**
 * optional base.PaginationReq pagination = 5;
 * @return {?proto.base.PaginationReq}
 */
proto.partnership.web_partnership.GetUsersReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 5));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.partnership.web_partnership.GetUsersReq} returns this
*/
proto.partnership.web_partnership.GetUsersReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.GetUsersReq} returns this
 */
proto.partnership.web_partnership.GetUsersReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.GetUsersReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.AdminChangeUserPasswordReq.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.AdminChangeUserPasswordReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.AdminChangeUserPasswordReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.AdminChangeUserPasswordReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newPassword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.AdminChangeUserPasswordReq}
 */
proto.partnership.web_partnership.AdminChangeUserPasswordReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.AdminChangeUserPasswordReq;
  return proto.partnership.web_partnership.AdminChangeUserPasswordReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.AdminChangeUserPasswordReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.AdminChangeUserPasswordReq}
 */
proto.partnership.web_partnership.AdminChangeUserPasswordReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewPassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.AdminChangeUserPasswordReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.AdminChangeUserPasswordReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.AdminChangeUserPasswordReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.AdminChangeUserPasswordReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.partnership.web_partnership.AdminChangeUserPasswordReq.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.AdminChangeUserPasswordReq} returns this
 */
proto.partnership.web_partnership.AdminChangeUserPasswordReq.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string new_password = 2;
 * @return {string}
 */
proto.partnership.web_partnership.AdminChangeUserPasswordReq.prototype.getNewPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.AdminChangeUserPasswordReq} returns this
 */
proto.partnership.web_partnership.AdminChangeUserPasswordReq.prototype.setNewPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partnership.web_partnership.GetMeRes.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.GetMeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.GetMeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.GetMeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetMeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    userInfo: (f = msg.getUserInfo()) && proto.partnership.web_partnership.UserInfo.toObject(includeInstance, f),
    rolesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.GetMeRes}
 */
proto.partnership.web_partnership.GetMeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.GetMeRes;
  return proto.partnership.web_partnership.GetMeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.GetMeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.GetMeRes}
 */
proto.partnership.web_partnership.GetMeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partnership.web_partnership.UserInfo;
      reader.readMessage(value,proto.partnership.web_partnership.UserInfo.deserializeBinaryFromReader);
      msg.setUserInfo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.GetMeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.GetMeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.GetMeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetMeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.partnership.web_partnership.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getRolesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional UserInfo user_info = 1;
 * @return {?proto.partnership.web_partnership.UserInfo}
 */
proto.partnership.web_partnership.GetMeRes.prototype.getUserInfo = function() {
  return /** @type{?proto.partnership.web_partnership.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.UserInfo, 1));
};


/**
 * @param {?proto.partnership.web_partnership.UserInfo|undefined} value
 * @return {!proto.partnership.web_partnership.GetMeRes} returns this
*/
proto.partnership.web_partnership.GetMeRes.prototype.setUserInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.GetMeRes} returns this
 */
proto.partnership.web_partnership.GetMeRes.prototype.clearUserInfo = function() {
  return this.setUserInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.GetMeRes.prototype.hasUserInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string roles = 2;
 * @return {!Array<string>}
 */
proto.partnership.web_partnership.GetMeRes.prototype.getRolesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.partnership.web_partnership.GetMeRes} returns this
 */
proto.partnership.web_partnership.GetMeRes.prototype.setRolesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.GetMeRes} returns this
 */
proto.partnership.web_partnership.GetMeRes.prototype.addRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.GetMeRes} returns this
 */
proto.partnership.web_partnership.GetMeRes.prototype.clearRolesList = function() {
  return this.setRolesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.GetMeV2Res.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.GetMeV2Res.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.GetMeV2Res} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetMeV2Res.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.partnership.web_partnership.GetMeV2Res.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.GetMeV2Res}
 */
proto.partnership.web_partnership.GetMeV2Res.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.GetMeV2Res;
  return proto.partnership.web_partnership.GetMeV2Res.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.GetMeV2Res} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.GetMeV2Res}
 */
proto.partnership.web_partnership.GetMeV2Res.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.partnership.web_partnership.GetMeV2Res.Data;
      reader.readMessage(value,proto.partnership.web_partnership.GetMeV2Res.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.GetMeV2Res.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.GetMeV2Res.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.GetMeV2Res} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetMeV2Res.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partnership.web_partnership.GetMeV2Res.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.GetMeV2Res.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.GetMeV2Res.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.GetMeV2Res.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetMeV2Res.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    userInfo: (f = msg.getUserInfo()) && proto.partnership.web_partnership.UserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.GetMeV2Res.Data}
 */
proto.partnership.web_partnership.GetMeV2Res.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.GetMeV2Res.Data;
  return proto.partnership.web_partnership.GetMeV2Res.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.GetMeV2Res.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.GetMeV2Res.Data}
 */
proto.partnership.web_partnership.GetMeV2Res.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partnership.web_partnership.UserInfo;
      reader.readMessage(value,proto.partnership.web_partnership.UserInfo.deserializeBinaryFromReader);
      msg.setUserInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.GetMeV2Res.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.GetMeV2Res.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.GetMeV2Res.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetMeV2Res.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.partnership.web_partnership.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserInfo user_info = 1;
 * @return {?proto.partnership.web_partnership.UserInfo}
 */
proto.partnership.web_partnership.GetMeV2Res.Data.prototype.getUserInfo = function() {
  return /** @type{?proto.partnership.web_partnership.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.UserInfo, 1));
};


/**
 * @param {?proto.partnership.web_partnership.UserInfo|undefined} value
 * @return {!proto.partnership.web_partnership.GetMeV2Res.Data} returns this
*/
proto.partnership.web_partnership.GetMeV2Res.Data.prototype.setUserInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.GetMeV2Res.Data} returns this
 */
proto.partnership.web_partnership.GetMeV2Res.Data.prototype.clearUserInfo = function() {
  return this.setUserInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.GetMeV2Res.Data.prototype.hasUserInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partnership.web_partnership.GetMeV2Res.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partnership.web_partnership.GetMeV2Res} returns this
 */
proto.partnership.web_partnership.GetMeV2Res.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partnership.web_partnership.GetMeV2Res.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.GetMeV2Res} returns this
 */
proto.partnership.web_partnership.GetMeV2Res.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.partnership.web_partnership.GetMeV2Res.Data}
 */
proto.partnership.web_partnership.GetMeV2Res.prototype.getData = function() {
  return /** @type{?proto.partnership.web_partnership.GetMeV2Res.Data} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.GetMeV2Res.Data, 3));
};


/**
 * @param {?proto.partnership.web_partnership.GetMeV2Res.Data|undefined} value
 * @return {!proto.partnership.web_partnership.GetMeV2Res} returns this
*/
proto.partnership.web_partnership.GetMeV2Res.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.GetMeV2Res} returns this
 */
proto.partnership.web_partnership.GetMeV2Res.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.GetMeV2Res.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.GetUserDetailV2Res.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.GetUserDetailV2Res.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.GetUserDetailV2Res} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetUserDetailV2Res.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.partnership.web_partnership.GetUserDetailV2Res.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.GetUserDetailV2Res}
 */
proto.partnership.web_partnership.GetUserDetailV2Res.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.GetUserDetailV2Res;
  return proto.partnership.web_partnership.GetUserDetailV2Res.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.GetUserDetailV2Res} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.GetUserDetailV2Res}
 */
proto.partnership.web_partnership.GetUserDetailV2Res.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.partnership.web_partnership.GetUserDetailV2Res.Data;
      reader.readMessage(value,proto.partnership.web_partnership.GetUserDetailV2Res.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.GetUserDetailV2Res.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.GetUserDetailV2Res.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.GetUserDetailV2Res} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetUserDetailV2Res.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partnership.web_partnership.GetUserDetailV2Res.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.GetUserDetailV2Res.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.GetUserDetailV2Res.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.GetUserDetailV2Res.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetUserDetailV2Res.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    userInfo: (f = msg.getUserInfo()) && proto.partnership.web_partnership.UserInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.GetUserDetailV2Res.Data}
 */
proto.partnership.web_partnership.GetUserDetailV2Res.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.GetUserDetailV2Res.Data;
  return proto.partnership.web_partnership.GetUserDetailV2Res.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.GetUserDetailV2Res.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.GetUserDetailV2Res.Data}
 */
proto.partnership.web_partnership.GetUserDetailV2Res.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partnership.web_partnership.UserInfo;
      reader.readMessage(value,proto.partnership.web_partnership.UserInfo.deserializeBinaryFromReader);
      msg.setUserInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.GetUserDetailV2Res.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.GetUserDetailV2Res.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.GetUserDetailV2Res.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetUserDetailV2Res.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.partnership.web_partnership.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserInfo user_info = 1;
 * @return {?proto.partnership.web_partnership.UserInfo}
 */
proto.partnership.web_partnership.GetUserDetailV2Res.Data.prototype.getUserInfo = function() {
  return /** @type{?proto.partnership.web_partnership.UserInfo} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.UserInfo, 1));
};


/**
 * @param {?proto.partnership.web_partnership.UserInfo|undefined} value
 * @return {!proto.partnership.web_partnership.GetUserDetailV2Res.Data} returns this
*/
proto.partnership.web_partnership.GetUserDetailV2Res.Data.prototype.setUserInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.GetUserDetailV2Res.Data} returns this
 */
proto.partnership.web_partnership.GetUserDetailV2Res.Data.prototype.clearUserInfo = function() {
  return this.setUserInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.GetUserDetailV2Res.Data.prototype.hasUserInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partnership.web_partnership.GetUserDetailV2Res.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partnership.web_partnership.GetUserDetailV2Res} returns this
 */
proto.partnership.web_partnership.GetUserDetailV2Res.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partnership.web_partnership.GetUserDetailV2Res.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.GetUserDetailV2Res} returns this
 */
proto.partnership.web_partnership.GetUserDetailV2Res.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.partnership.web_partnership.GetUserDetailV2Res.Data}
 */
proto.partnership.web_partnership.GetUserDetailV2Res.prototype.getData = function() {
  return /** @type{?proto.partnership.web_partnership.GetUserDetailV2Res.Data} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.GetUserDetailV2Res.Data, 3));
};


/**
 * @param {?proto.partnership.web_partnership.GetUserDetailV2Res.Data|undefined} value
 * @return {!proto.partnership.web_partnership.GetUserDetailV2Res} returns this
*/
proto.partnership.web_partnership.GetUserDetailV2Res.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.GetUserDetailV2Res} returns this
 */
proto.partnership.web_partnership.GetUserDetailV2Res.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.GetUserDetailV2Res.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partnership.web_partnership.GetBasicUsersRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.GetBasicUsersRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.GetBasicUsersRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.GetBasicUsersRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetBasicUsersRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.partnership.web_partnership.UserBasicInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.GetBasicUsersRes}
 */
proto.partnership.web_partnership.GetBasicUsersRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.GetBasicUsersRes;
  return proto.partnership.web_partnership.GetBasicUsersRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.GetBasicUsersRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.GetBasicUsersRes}
 */
proto.partnership.web_partnership.GetBasicUsersRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partnership.web_partnership.UserBasicInfo;
      reader.readMessage(value,proto.partnership.web_partnership.UserBasicInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.GetBasicUsersRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.GetBasicUsersRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.GetBasicUsersRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetBasicUsersRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.partnership.web_partnership.UserBasicInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserBasicInfo items = 1;
 * @return {!Array<!proto.partnership.web_partnership.UserBasicInfo>}
 */
proto.partnership.web_partnership.GetBasicUsersRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.partnership.web_partnership.UserBasicInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partnership.web_partnership.UserBasicInfo, 1));
};


/**
 * @param {!Array<!proto.partnership.web_partnership.UserBasicInfo>} value
 * @return {!proto.partnership.web_partnership.GetBasicUsersRes} returns this
*/
proto.partnership.web_partnership.GetBasicUsersRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.partnership.web_partnership.UserBasicInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.UserBasicInfo}
 */
proto.partnership.web_partnership.GetBasicUsersRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.partnership.web_partnership.UserBasicInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.GetBasicUsersRes} returns this
 */
proto.partnership.web_partnership.GetBasicUsersRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.SendOtpToResetPasswordRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.SendOtpToResetPasswordRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.SendOtpToResetPasswordRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.SendOtpToResetPasswordRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeRemaining: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.SendOtpToResetPasswordRes}
 */
proto.partnership.web_partnership.SendOtpToResetPasswordRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.SendOtpToResetPasswordRes;
  return proto.partnership.web_partnership.SendOtpToResetPasswordRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.SendOtpToResetPasswordRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.SendOtpToResetPasswordRes}
 */
proto.partnership.web_partnership.SendOtpToResetPasswordRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTimeRemaining(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.SendOtpToResetPasswordRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.SendOtpToResetPasswordRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.SendOtpToResetPasswordRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.SendOtpToResetPasswordRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeRemaining();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 time_remaining = 1;
 * @return {number}
 */
proto.partnership.web_partnership.SendOtpToResetPasswordRes.prototype.getTimeRemaining = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.partnership.web_partnership.SendOtpToResetPasswordRes} returns this
 */
proto.partnership.web_partnership.SendOtpToResetPasswordRes.prototype.setTimeRemaining = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.VerifyOtpToResetPasswordRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expireTime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.VerifyOtpToResetPasswordRes}
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.VerifyOtpToResetPasswordRes;
  return proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.VerifyOtpToResetPasswordRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.VerifyOtpToResetPasswordRes}
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setExpireTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.VerifyOtpToResetPasswordRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpireTime();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.VerifyOtpToResetPasswordRes} returns this
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 expire_time = 2;
 * @return {number}
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.prototype.getExpireTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.partnership.web_partnership.VerifyOtpToResetPasswordRes} returns this
 */
proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.prototype.setExpireTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.UpdateMeV2Res.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.UpdateMeV2Res.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.UpdateMeV2Res} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UpdateMeV2Res.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.UpdateMeV2Res}
 */
proto.partnership.web_partnership.UpdateMeV2Res.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.UpdateMeV2Res;
  return proto.partnership.web_partnership.UpdateMeV2Res.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.UpdateMeV2Res} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.UpdateMeV2Res}
 */
proto.partnership.web_partnership.UpdateMeV2Res.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.UpdateMeV2Res.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.UpdateMeV2Res.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.UpdateMeV2Res} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UpdateMeV2Res.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partnership.web_partnership.UpdateMeV2Res.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partnership.web_partnership.UpdateMeV2Res} returns this
 */
proto.partnership.web_partnership.UpdateMeV2Res.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateMeV2Res.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateMeV2Res} returns this
 */
proto.partnership.web_partnership.UpdateMeV2Res.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.UpdateUserV2Res.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.UpdateUserV2Res.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.UpdateUserV2Res} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UpdateUserV2Res.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.UpdateUserV2Res}
 */
proto.partnership.web_partnership.UpdateUserV2Res.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.UpdateUserV2Res;
  return proto.partnership.web_partnership.UpdateUserV2Res.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.UpdateUserV2Res} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.UpdateUserV2Res}
 */
proto.partnership.web_partnership.UpdateUserV2Res.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.UpdateUserV2Res.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.UpdateUserV2Res.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.UpdateUserV2Res} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.UpdateUserV2Res.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partnership.web_partnership.UpdateUserV2Res.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partnership.web_partnership.UpdateUserV2Res} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Res.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partnership.web_partnership.UpdateUserV2Res.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.UpdateUserV2Res} returns this
 */
proto.partnership.web_partnership.UpdateUserV2Res.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.CreateUserRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.CreateUserRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.CreateUserRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.CreateUserRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.CreateUserRes}
 */
proto.partnership.web_partnership.CreateUserRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.CreateUserRes;
  return proto.partnership.web_partnership.CreateUserRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.CreateUserRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.CreateUserRes}
 */
proto.partnership.web_partnership.CreateUserRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.CreateUserRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.CreateUserRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.CreateUserRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.CreateUserRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.partnership.web_partnership.CreateUserRes.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.CreateUserRes} returns this
 */
proto.partnership.web_partnership.CreateUserRes.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.CreateUserV2Res.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.CreateUserV2Res.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.CreateUserV2Res} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.CreateUserV2Res.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.partnership.web_partnership.CreateUserV2Res.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.CreateUserV2Res}
 */
proto.partnership.web_partnership.CreateUserV2Res.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.CreateUserV2Res;
  return proto.partnership.web_partnership.CreateUserV2Res.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.CreateUserV2Res} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.CreateUserV2Res}
 */
proto.partnership.web_partnership.CreateUserV2Res.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.partnership.web_partnership.CreateUserV2Res.Data;
      reader.readMessage(value,proto.partnership.web_partnership.CreateUserV2Res.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.CreateUserV2Res.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.CreateUserV2Res.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.CreateUserV2Res} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.CreateUserV2Res.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partnership.web_partnership.CreateUserV2Res.Data.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.CreateUserV2Res.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.CreateUserV2Res.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.CreateUserV2Res.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.CreateUserV2Res.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.CreateUserV2Res.Data}
 */
proto.partnership.web_partnership.CreateUserV2Res.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.CreateUserV2Res.Data;
  return proto.partnership.web_partnership.CreateUserV2Res.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.CreateUserV2Res.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.CreateUserV2Res.Data}
 */
proto.partnership.web_partnership.CreateUserV2Res.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.CreateUserV2Res.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.CreateUserV2Res.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.CreateUserV2Res.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.CreateUserV2Res.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.partnership.web_partnership.CreateUserV2Res.Data.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.CreateUserV2Res.Data} returns this
 */
proto.partnership.web_partnership.CreateUserV2Res.Data.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partnership.web_partnership.CreateUserV2Res.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partnership.web_partnership.CreateUserV2Res} returns this
 */
proto.partnership.web_partnership.CreateUserV2Res.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partnership.web_partnership.CreateUserV2Res.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.CreateUserV2Res} returns this
 */
proto.partnership.web_partnership.CreateUserV2Res.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.partnership.web_partnership.CreateUserV2Res.Data}
 */
proto.partnership.web_partnership.CreateUserV2Res.prototype.getData = function() {
  return /** @type{?proto.partnership.web_partnership.CreateUserV2Res.Data} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.CreateUserV2Res.Data, 3));
};


/**
 * @param {?proto.partnership.web_partnership.CreateUserV2Res.Data|undefined} value
 * @return {!proto.partnership.web_partnership.CreateUserV2Res} returns this
*/
proto.partnership.web_partnership.CreateUserV2Res.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.CreateUserV2Res} returns this
 */
proto.partnership.web_partnership.CreateUserV2Res.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.CreateUserV2Res.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.ChangePasswordV2Res.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.ChangePasswordV2Res.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.ChangePasswordV2Res} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.ChangePasswordV2Res.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.ChangePasswordV2Res}
 */
proto.partnership.web_partnership.ChangePasswordV2Res.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.ChangePasswordV2Res;
  return proto.partnership.web_partnership.ChangePasswordV2Res.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.ChangePasswordV2Res} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.ChangePasswordV2Res}
 */
proto.partnership.web_partnership.ChangePasswordV2Res.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.ChangePasswordV2Res.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.ChangePasswordV2Res.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.ChangePasswordV2Res} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.ChangePasswordV2Res.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partnership.web_partnership.ChangePasswordV2Res.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partnership.web_partnership.ChangePasswordV2Res} returns this
 */
proto.partnership.web_partnership.ChangePasswordV2Res.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partnership.web_partnership.ChangePasswordV2Res.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.ChangePasswordV2Res} returns this
 */
proto.partnership.web_partnership.ChangePasswordV2Res.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.AdminChangeUserPasswordRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.AdminChangeUserPasswordRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.AdminChangeUserPasswordRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.AdminChangeUserPasswordRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.AdminChangeUserPasswordRes}
 */
proto.partnership.web_partnership.AdminChangeUserPasswordRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.AdminChangeUserPasswordRes;
  return proto.partnership.web_partnership.AdminChangeUserPasswordRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.AdminChangeUserPasswordRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.AdminChangeUserPasswordRes}
 */
proto.partnership.web_partnership.AdminChangeUserPasswordRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.AdminChangeUserPasswordRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.AdminChangeUserPasswordRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.AdminChangeUserPasswordRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.AdminChangeUserPasswordRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partnership.web_partnership.AdminChangeUserPasswordRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partnership.web_partnership.AdminChangeUserPasswordRes} returns this
 */
proto.partnership.web_partnership.AdminChangeUserPasswordRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partnership.web_partnership.AdminChangeUserPasswordRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.AdminChangeUserPasswordRes} returns this
 */
proto.partnership.web_partnership.AdminChangeUserPasswordRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partnership.web_partnership.GetUsersRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.GetUsersRes.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.GetUsersRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.GetUsersRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetUsersRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.partnership.web_partnership.UserInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.GetUsersRes}
 */
proto.partnership.web_partnership.GetUsersRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.GetUsersRes;
  return proto.partnership.web_partnership.GetUsersRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.GetUsersRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.GetUsersRes}
 */
proto.partnership.web_partnership.GetUsersRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partnership.web_partnership.UserInfo;
      reader.readMessage(value,proto.partnership.web_partnership.UserInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.GetUsersRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.GetUsersRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.GetUsersRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetUsersRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.partnership.web_partnership.UserInfo.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserInfo items = 1;
 * @return {!Array<!proto.partnership.web_partnership.UserInfo>}
 */
proto.partnership.web_partnership.GetUsersRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.partnership.web_partnership.UserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partnership.web_partnership.UserInfo, 1));
};


/**
 * @param {!Array<!proto.partnership.web_partnership.UserInfo>} value
 * @return {!proto.partnership.web_partnership.GetUsersRes} returns this
*/
proto.partnership.web_partnership.GetUsersRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.partnership.web_partnership.UserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.UserInfo}
 */
proto.partnership.web_partnership.GetUsersRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.partnership.web_partnership.UserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.GetUsersRes} returns this
 */
proto.partnership.web_partnership.GetUsersRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.GetUsersV2Res.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.GetUsersV2Res.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.GetUsersV2Res} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetUsersV2Res.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.partnership.web_partnership.GetUsersV2Res.Data.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.GetUsersV2Res}
 */
proto.partnership.web_partnership.GetUsersV2Res.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.GetUsersV2Res;
  return proto.partnership.web_partnership.GetUsersV2Res.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.GetUsersV2Res} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.GetUsersV2Res}
 */
proto.partnership.web_partnership.GetUsersV2Res.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.partnership.web_partnership.GetUsersV2Res.Data;
      reader.readMessage(value,proto.partnership.web_partnership.GetUsersV2Res.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.GetUsersV2Res.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.GetUsersV2Res.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.GetUsersV2Res} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetUsersV2Res.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.partnership.web_partnership.GetUsersV2Res.Data.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.partnership.web_partnership.GetUsersV2Res.Data.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.partnership.web_partnership.GetUsersV2Res.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.partnership.web_partnership.GetUsersV2Res.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.partnership.web_partnership.GetUsersV2Res.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetUsersV2Res.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.partnership.web_partnership.UserInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.partnership.web_partnership.GetUsersV2Res.Data}
 */
proto.partnership.web_partnership.GetUsersV2Res.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.partnership.web_partnership.GetUsersV2Res.Data;
  return proto.partnership.web_partnership.GetUsersV2Res.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.partnership.web_partnership.GetUsersV2Res.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.partnership.web_partnership.GetUsersV2Res.Data}
 */
proto.partnership.web_partnership.GetUsersV2Res.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.partnership.web_partnership.UserInfo;
      reader.readMessage(value,proto.partnership.web_partnership.UserInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.partnership.web_partnership.GetUsersV2Res.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.partnership.web_partnership.GetUsersV2Res.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.partnership.web_partnership.GetUsersV2Res.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.partnership.web_partnership.GetUsersV2Res.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.partnership.web_partnership.UserInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserInfo items = 1;
 * @return {!Array<!proto.partnership.web_partnership.UserInfo>}
 */
proto.partnership.web_partnership.GetUsersV2Res.Data.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.partnership.web_partnership.UserInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.partnership.web_partnership.UserInfo, 1));
};


/**
 * @param {!Array<!proto.partnership.web_partnership.UserInfo>} value
 * @return {!proto.partnership.web_partnership.GetUsersV2Res.Data} returns this
*/
proto.partnership.web_partnership.GetUsersV2Res.Data.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.partnership.web_partnership.UserInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.partnership.web_partnership.UserInfo}
 */
proto.partnership.web_partnership.GetUsersV2Res.Data.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.partnership.web_partnership.UserInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.partnership.web_partnership.GetUsersV2Res.Data} returns this
 */
proto.partnership.web_partnership.GetUsersV2Res.Data.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 2;
 * @return {?proto.base.PaginationRes}
 */
proto.partnership.web_partnership.GetUsersV2Res.Data.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 2));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.partnership.web_partnership.GetUsersV2Res.Data} returns this
*/
proto.partnership.web_partnership.GetUsersV2Res.Data.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.GetUsersV2Res.Data} returns this
 */
proto.partnership.web_partnership.GetUsersV2Res.Data.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.GetUsersV2Res.Data.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.partnership.web_partnership.GetUsersV2Res.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.partnership.web_partnership.GetUsersV2Res} returns this
 */
proto.partnership.web_partnership.GetUsersV2Res.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.partnership.web_partnership.GetUsersV2Res.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.partnership.web_partnership.GetUsersV2Res} returns this
 */
proto.partnership.web_partnership.GetUsersV2Res.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Data data = 3;
 * @return {?proto.partnership.web_partnership.GetUsersV2Res.Data}
 */
proto.partnership.web_partnership.GetUsersV2Res.prototype.getData = function() {
  return /** @type{?proto.partnership.web_partnership.GetUsersV2Res.Data} */ (
    jspb.Message.getWrapperField(this, proto.partnership.web_partnership.GetUsersV2Res.Data, 3));
};


/**
 * @param {?proto.partnership.web_partnership.GetUsersV2Res.Data|undefined} value
 * @return {!proto.partnership.web_partnership.GetUsersV2Res} returns this
*/
proto.partnership.web_partnership.GetUsersV2Res.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.partnership.web_partnership.GetUsersV2Res} returns this
 */
proto.partnership.web_partnership.GetUsersV2Res.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.partnership.web_partnership.GetUsersV2Res.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};


goog.object.extend(exports, proto.partnership.web_partnership);
