import { ArrowDown, ArrowUp } from '@icon';
import { IBookingDetail } from '@tixlabs/grpc-client/web-partnership';
import { useCurrency } from '@vemaybay-admin/hooks/internals';
import React, { useState } from 'react';
interface Props {
  bookingData: IBookingDetail;
}
export const DifferentPrice = ({ bookingData }: Props) => {
  const { formatPrice } = useCurrency();
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  // const averageRate = bookingData?.priceInfo?.totalRateAmount
  //   ? bookingData.priceInfo.totalRateAmount /
  //     ((bookingData.totalRooms || 1) * (bookingData.totalNights || 1))
  //   : 0;

  const totalPayAtHotelList = bookingData.priceInfo?.totalPayAtHotelList
    ? bookingData.priceInfo.totalPayAtHotelList.reduce(
        (acc, item) => acc + (item?.amount || 0),
        0
      )
    : 0;
  return (
    <div className='bg-white border border-neutral-3 p-4 rounded text-md'>
      <div className='flex flex-col gap-2'>
        <div className='text-lg font-semibold'>Chênh lệch giá:</div>

        <div className='flex justify-between'>
          <span>Giá thực tế</span>
          {/* <span>{formatPrice(bookingData?.priceInfo?.totalPrice || 0)} đ</span> */}
          <span>
            {formatPrice(bookingData.priceInfo?.actualPaidAmount || 0)} đ
          </span>
        </div>

        <div className='flex justify-between'>
          <span>Tiền chêch lệch</span>
          <span>
            {bookingData?.priceInfo &&
              formatPrice(
                bookingData?.priceInfo?.payNow +
                  bookingData?.priceInfo?.additionalFee -
                  bookingData?.priceInfo?.actualPaidAmount || 0
              )}{' '}
            đ
          </span>
        </div>

        <div className='flex justify-between'>
          <span>Nguồn mua</span>
          <span>{bookingData?.source}</span>
        </div>
      </div>
    </div>
  );
};
