import { StatisticCardInfo } from '@common-ui/sky-booking';
import { useAppMutation } from '@vemaybay-admin/hooks/internals';
import {
  ICrossCheckStatsData,
  ICrossCheckStatsReq,
  reportService,
} from '@tixlabs/grpc-client/web-partnership';
import { useCurrency } from '@vemaybay-admin/hooks/internals';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

type Props = {
  filterCrossCheckStats: ICrossCheckStatsReq;
};

const CrossCheckStats = ({ filterCrossCheckStats }: Props) => {
  const { formatCurrency } = useCurrency();

  const [crossCheckStatsData, setCrossCheckStatsData] =
    useState<ICrossCheckStatsData>({
      approved: 0,
      profit: 0,
      serviceFee: 0,
    });

  const { mutateAsync: crossCheckStats, isLoading } = useAppMutation({
    mutationKey: ['reportServicePartnerShip', 'crossCheckStats'],
    mutationFn: reportService.crossCheckStats,
    onSuccess({ isSuccess, errorCode, data }) {
      if (isSuccess && data) {
        setCrossCheckStatsData(data);
      } else {
        toast.error(`Something error: ${errorCode}`);
      }
    },
    onError: (error) => {
      toast.error('Something error');
    },
  });

  useEffect(() => {
    crossCheckStats(filterCrossCheckStats);
  }, [filterCrossCheckStats.date]);

  return (
    <div className='grid grid-cols-3 gap-3 w-full pb-5'>
      {isLoading ? (
        <>
          {[0, 1, 2].map((item, index) => (
            <div
              key={index}
              className='flex-1 rounded-lg h-[90px] animate-pulse bg-neutral-3'></div>
          ))}
        </>
      ) : (
        <>
          <StatisticCardInfo
            className='border-none bg-white'
            title='Tổng phí dịch vụ'
            value={
              <span className='text-primary'>
                {formatCurrency(crossCheckStatsData.serviceFee)}
              </span>
            }
            iconKey='price'
          />
          <StatisticCardInfo
            className='border-none bg-white'
            title='Tổng hoa hồng đã xác nhận'
            value={
              <span className='text-primary'>
                {formatCurrency(crossCheckStatsData.approved)}
              </span>
            }
            iconKey='price'
          />
          <StatisticCardInfo
            className='border-none bg-white'
            title='Tổng lợi nhuận'
            value={
              <span className='text-green-6'>
                {formatCurrency(crossCheckStatsData.profit)}
              </span>
            }
            iconKey='price'
          />
        </>
      )}
    </div>
  );
};

export default CrossCheckStats;
