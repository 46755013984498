import { EFlightItineraryType } from '@tixlabs/grpc-client';

export const SERVICE_FEE_LABEL = {
  [EFlightItineraryType.IDOMESTIC]: 'Bay trong nước',
  [EFlightItineraryType.IINTERNATIONAL]: 'Bay quốc tế',
};

export const OPTION_SERVICE_FEE = [
  {
    label: SERVICE_FEE_LABEL[EFlightItineraryType.IDOMESTIC],
    value: EFlightItineraryType.IDOMESTIC,
  },
  {
    label: SERVICE_FEE_LABEL[EFlightItineraryType.IINTERNATIONAL],
    value: EFlightItineraryType.IINTERNATIONAL,
  },
];
