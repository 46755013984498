import { PaginationReq } from '@api/base/base_pb';
import { PartnerServiceClient } from '@api/partner/web_partnership/partner_grpc_web_pb';
import {
  ChangeOfficePasswordReq,
  ChangeOfficePasswordRes,
  CreateOfficeReq,
  CreateOfficeRes,
  GetDomainsReq,
  GetDomainsRes,
  GetOfficeDetailReq,
  GetOfficeDetailRes,
  GetProvidersReq,
  GetProvidersRes,
  GetUnassignedOfficesReq,
  GetUnassignedOfficesRes,
  ListMyOfficesReq,
  ListMyOfficesRes,
  UpdateOfficeProvidersReq,
  UpdateOfficeProvidersRes,
  UpdateOfficeReq,
  UpdateOfficeRes,
} from '@api/partner/web_partnership/partner_pb';
import { BaseApi, BaseApiOptions } from '@core/grpc-client';

export class PartnerServiceApi extends BaseApi<PartnerServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new PartnerServiceClient(this.url, null, this.optsDev);
  }

  createOffice = ({
    email,
    enabledProvidersList,
    name,
    password,
    phone,
    shopName,
    status,
    userName,
  }: CreateOfficeReq.AsObject): Promise<CreateOfficeRes.AsObject> => {
    const req = new CreateOfficeReq();
    req.setEmail(email);
    req.setEnabledProvidersList(enabledProvidersList);
    req.setName(name);
    req.setPassword(password);
    req.setPhone(phone);
    req.setShopName(shopName);
    req.setStatus(status);
    req.setUserName(userName);
    return this.grpc<
      CreateOfficeReq,
      CreateOfficeRes,
      CreateOfficeRes.AsObject
    >(this.serviceClient.createOffice, req, {});
  };

  listMyOffices = ({
    filter,
    pagination,
  }: ListMyOfficesReq.AsObject): Promise<ListMyOfficesRes.AsObject> => {
    const req = new ListMyOfficesReq();

    if (filter) {
      const filterReq = new ListMyOfficesReq.Filter();
      filter?.provider && filterReq.setProvider(filter.provider);
      filter?.agentCode && filterReq.setAgentCode(filter.agentCode);
      req.setFilter(filterReq);
    }

    if (pagination) {
      const paginationObj = new PaginationReq();
      paginationObj.setPageLimit(pagination.pageLimit);
      paginationObj.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationObj);
    }

    return this.grpc<
      ListMyOfficesReq,
      ListMyOfficesRes,
      ListMyOfficesRes.AsObject
    >(this.serviceClient.listMyOffices, req, {});
  };

  getOfficeDetail = ({
    id,
  }: GetOfficeDetailReq.AsObject): Promise<GetOfficeDetailRes.AsObject> => {
    const req = new GetOfficeDetailReq();
    req.setId(id);

    return this.grpc<
      GetOfficeDetailReq,
      GetOfficeDetailRes,
      GetOfficeDetailRes.AsObject
    >(this.serviceClient.getOfficeDetail, req);
  };

  updateOffice = ({
    email,
    id,
    name,
    phone,
    shopName,
    status,
  }: UpdateOfficeReq.AsObject): Promise<UpdateOfficeRes.AsObject> => {
    const req = new UpdateOfficeReq();
    req.setId(id);
    req.setEmail(email);
    req.setName(name);
    req.setPhone(phone);
    req.setShopName(shopName);
    req.setStatus(status);
    return this.grpc<
      UpdateOfficeReq,
      UpdateOfficeRes,
      UpdateOfficeRes.AsObject
    >(this.serviceClient.updateOffice, req, {});
  };

  getDomains = (): Promise<GetDomainsRes.AsObject> => {
    const req = new GetDomainsReq();
    return this.grpc<GetDomainsReq, GetDomainsRes, GetDomainsRes.AsObject>(
      this.serviceClient.getDomains,
      req,
      {}
    );
  };

  updateOfficeProviders = ({
    id,
    enabledProvidersList,
  }: UpdateOfficeProvidersReq.AsObject): Promise<UpdateOfficeProvidersRes.AsObject> => {
    const req = new UpdateOfficeProvidersReq();

    req.setId(id);
    req.setEnabledProvidersList(enabledProvidersList);

    return this.grpc<
      UpdateOfficeProvidersReq,
      UpdateOfficeProvidersRes,
      UpdateOfficeProvidersRes.AsObject
    >(this.serviceClient.updateOfficeProviders, req, {});
  };

  getProviders = (): Promise<GetProvidersRes.AsObject> => {
    const req = new GetProvidersReq();
    return this.grpc<
      GetProvidersReq,
      GetProvidersRes,
      GetProvidersRes.AsObject
    >(this.serviceClient.getProviders, req, {});
  };

  getUnassignedOffices = (): Promise<GetUnassignedOfficesRes.AsObject> => {
    const req = new GetUnassignedOfficesReq();
    return this.grpc<
      GetUnassignedOfficesReq,
      GetUnassignedOfficesRes,
      GetUnassignedOfficesRes.AsObject
    >(this.serviceClient.getUnassignedOffices, req, {});
  };

  changeOfficePassword = ({
    newPassword,
    shopId,
    userId,
  }: ChangeOfficePasswordReq.AsObject): Promise<ChangeOfficePasswordRes.AsObject> => {
    const req = new ChangeOfficePasswordReq();
    req.setNewPassword(newPassword);
    req.setShopId(shopId);
    req.setUserId(userId);
    return this.grpc<
      ChangeOfficePasswordReq,
      ChangeOfficePasswordRes,
      ChangeOfficePasswordRes.AsObject
    >(this.serviceClient.changeOfficePassword, req, {});
  };
}

export const partnerService = new PartnerServiceApi();
export default partnerService;
