/**
 * @fileoverview gRPC-Web generated client stub for customer.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: customer/web_partnership/customer.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var customer_customer_pb = require('../../customer/customer_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.customer = {};
proto.customer.web_partnership = require('./customer_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.customer.web_partnership.CustomerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.customer.web_partnership.CustomerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web_partnership.GetUserReq,
 *   !proto.customer.web_partnership.GetUserRes>}
 */
const methodDescriptor_CustomerService_GetUser = new grpc.web.MethodDescriptor(
  '/customer.web_partnership.CustomerService/GetUser',
  grpc.web.MethodType.UNARY,
  proto.customer.web_partnership.GetUserReq,
  proto.customer.web_partnership.GetUserRes,
  /**
   * @param {!proto.customer.web_partnership.GetUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.customer.web_partnership.GetUserRes.deserializeBinary
);


/**
 * @param {!proto.customer.web_partnership.GetUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.web_partnership.GetUserRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.web_partnership.GetUserRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web_partnership.CustomerServiceClient.prototype.getUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web_partnership.CustomerService/GetUser',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetUser,
      callback);
};


/**
 * @param {!proto.customer.web_partnership.GetUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.web_partnership.GetUserRes>}
 *     Promise that resolves to the response
 */
proto.customer.web_partnership.CustomerServicePromiseClient.prototype.getUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web_partnership.CustomerService/GetUser',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web_partnership.GetCustomerByIdsReq,
 *   !proto.customer.web_partnership.GetCustomerByIdsRes>}
 */
const methodDescriptor_CustomerService_GetCustomerByIds = new grpc.web.MethodDescriptor(
  '/customer.web_partnership.CustomerService/GetCustomerByIds',
  grpc.web.MethodType.UNARY,
  proto.customer.web_partnership.GetCustomerByIdsReq,
  proto.customer.web_partnership.GetCustomerByIdsRes,
  /**
   * @param {!proto.customer.web_partnership.GetCustomerByIdsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.customer.web_partnership.GetCustomerByIdsRes.deserializeBinary
);


/**
 * @param {!proto.customer.web_partnership.GetCustomerByIdsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.web_partnership.GetCustomerByIdsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.web_partnership.GetCustomerByIdsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web_partnership.CustomerServiceClient.prototype.getCustomerByIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web_partnership.CustomerService/GetCustomerByIds',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetCustomerByIds,
      callback);
};


/**
 * @param {!proto.customer.web_partnership.GetCustomerByIdsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.web_partnership.GetCustomerByIdsRes>}
 *     Promise that resolves to the response
 */
proto.customer.web_partnership.CustomerServicePromiseClient.prototype.getCustomerByIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web_partnership.CustomerService/GetCustomerByIds',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetCustomerByIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web_partnership.GetBasicCustomerReq,
 *   !proto.customer.web_partnership.GetBasicCustomerRes>}
 */
const methodDescriptor_CustomerService_GetBasicCustomers = new grpc.web.MethodDescriptor(
  '/customer.web_partnership.CustomerService/GetBasicCustomers',
  grpc.web.MethodType.UNARY,
  proto.customer.web_partnership.GetBasicCustomerReq,
  proto.customer.web_partnership.GetBasicCustomerRes,
  /**
   * @param {!proto.customer.web_partnership.GetBasicCustomerReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.customer.web_partnership.GetBasicCustomerRes.deserializeBinary
);


/**
 * @param {!proto.customer.web_partnership.GetBasicCustomerReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.web_partnership.GetBasicCustomerRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.web_partnership.GetBasicCustomerRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web_partnership.CustomerServiceClient.prototype.getBasicCustomers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web_partnership.CustomerService/GetBasicCustomers',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetBasicCustomers,
      callback);
};


/**
 * @param {!proto.customer.web_partnership.GetBasicCustomerReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.web_partnership.GetBasicCustomerRes>}
 *     Promise that resolves to the response
 */
proto.customer.web_partnership.CustomerServicePromiseClient.prototype.getBasicCustomers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web_partnership.CustomerService/GetBasicCustomers',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetBasicCustomers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web_partnership.FindCustomersReq,
 *   !proto.customer.web_partnership.FindCustomersRes>}
 */
const methodDescriptor_CustomerService_FindCustomers = new grpc.web.MethodDescriptor(
  '/customer.web_partnership.CustomerService/FindCustomers',
  grpc.web.MethodType.UNARY,
  proto.customer.web_partnership.FindCustomersReq,
  proto.customer.web_partnership.FindCustomersRes,
  /**
   * @param {!proto.customer.web_partnership.FindCustomersReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.customer.web_partnership.FindCustomersRes.deserializeBinary
);


/**
 * @param {!proto.customer.web_partnership.FindCustomersReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.web_partnership.FindCustomersRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.web_partnership.FindCustomersRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web_partnership.CustomerServiceClient.prototype.findCustomers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web_partnership.CustomerService/FindCustomers',
      request,
      metadata || {},
      methodDescriptor_CustomerService_FindCustomers,
      callback);
};


/**
 * @param {!proto.customer.web_partnership.FindCustomersReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.web_partnership.FindCustomersRes>}
 *     Promise that resolves to the response
 */
proto.customer.web_partnership.CustomerServicePromiseClient.prototype.findCustomers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web_partnership.CustomerService/FindCustomers',
      request,
      metadata || {},
      methodDescriptor_CustomerService_FindCustomers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq,
 *   !proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes>}
 */
const methodDescriptor_CustomerService_FindCustomersByPhoneOrEmail = new grpc.web.MethodDescriptor(
  '/customer.web_partnership.CustomerService/FindCustomersByPhoneOrEmail',
  grpc.web.MethodType.UNARY,
  proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq,
  proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes,
  /**
   * @param {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes.deserializeBinary
);


/**
 * @param {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web_partnership.CustomerServiceClient.prototype.findCustomersByPhoneOrEmail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web_partnership.CustomerService/FindCustomersByPhoneOrEmail',
      request,
      metadata || {},
      methodDescriptor_CustomerService_FindCustomersByPhoneOrEmail,
      callback);
};


/**
 * @param {!proto.customer.web_partnership.FindCustomersByPhoneOrEmailReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.web_partnership.FindCustomersByPhoneOrEmailRes>}
 *     Promise that resolves to the response
 */
proto.customer.web_partnership.CustomerServicePromiseClient.prototype.findCustomersByPhoneOrEmail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web_partnership.CustomerService/FindCustomersByPhoneOrEmail',
      request,
      metadata || {},
      methodDescriptor_CustomerService_FindCustomersByPhoneOrEmail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.customer.web_partnership.GetCustomerByIDReq,
 *   !proto.customer.web_partnership.GetCustomerByIDRes>}
 */
const methodDescriptor_CustomerService_GetCustomerByID = new grpc.web.MethodDescriptor(
  '/customer.web_partnership.CustomerService/GetCustomerByID',
  grpc.web.MethodType.UNARY,
  proto.customer.web_partnership.GetCustomerByIDReq,
  proto.customer.web_partnership.GetCustomerByIDRes,
  /**
   * @param {!proto.customer.web_partnership.GetCustomerByIDReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.customer.web_partnership.GetCustomerByIDRes.deserializeBinary
);


/**
 * @param {!proto.customer.web_partnership.GetCustomerByIDReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.customer.web_partnership.GetCustomerByIDRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.customer.web_partnership.GetCustomerByIDRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.customer.web_partnership.CustomerServiceClient.prototype.getCustomerByID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/customer.web_partnership.CustomerService/GetCustomerByID',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetCustomerByID,
      callback);
};


/**
 * @param {!proto.customer.web_partnership.GetCustomerByIDReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.customer.web_partnership.GetCustomerByIDRes>}
 *     Promise that resolves to the response
 */
proto.customer.web_partnership.CustomerServicePromiseClient.prototype.getCustomerByID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/customer.web_partnership.CustomerService/GetCustomerByID',
      request,
      metadata || {},
      methodDescriptor_CustomerService_GetCustomerByID);
};


module.exports = proto.customer.web_partnership;

