import { CustomerDemoImg } from '@common-ui';

export const patternValidateUsername = /^[a-zA-ZÀ-ỹ0-9]+$/;
export const patternValidateNameBooker = /^[a-zA-ZÀ-ỹ\s]+$/;
export const patternValidatePhoneNumber = /^[0-9]+$/;
export const patternValidateEmail =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const getAvatarUrl = (url: string) => {
  if (!url) {
    return CustomerDemoImg;
  }
  if (url.includes('http')) {
    return url;
  } else {
    const storageDomain =
      process.env.NX_STORAGE_URL || 'https://assets.deeptech.vn';
    return `${storageDomain}/${url}`;
  }
};
