import React from 'react';
import { CustomerDemoImg } from '@common-ui';
import { ICustomerDetailInfo } from '@tixlabs/grpc-client/';
import { useTime } from '@vemaybay-admin/hooks/internals';
import {
  GENDER_CUSTOMER_TYPE_LABEL,
  getAvatarUrl,
} from '@vemaybay-admin/utils';
import { useCities, useCountry } from '@vemaybay-admin/hooks/apps';
import { getUserName } from '@core/utils';

type Props = {
  customerInfo: ICustomerDetailInfo;
};

export const InformationCustomer = ({ customerInfo }: Props) => {
  const { formatDate } = useTime();
  const { countriesObjectByCode } = useCountry();
  const avatarUrl = getAvatarUrl(customerInfo.profilePicture);
  console.log(avatarUrl);
  return (
    <div className='flex flex-col rounded-lg bg-white'>
      <h3 className='text-lg font-semibold py-3 px-4 mb-0'>
        Thông tin hành khách
      </h3>
      <div className='flex flex-col gap-4 py-3 px-4'>
        <img
          className='w-[110px] h-[110px] self-center rounded-full object-cover'
          src={avatarUrl}
          onError={(e) => {
            e.currentTarget.src = CustomerDemoImg;
          }}
          alt='Customer demo avt'
        />
        <div className='grid grid-cols-3'>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Họ và tên</span>
            <span className='text-neutral-black'>
              {getUserName({
                firstName: customerInfo.firstName,
                lastName: customerInfo.lastName,
              })}
            </span>
          </div>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Ngày sinh</span>
            <span className='text-neutral-black'>
              {customerInfo.birthday > 0 && formatDate(customerInfo.birthday)}
            </span>
          </div>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Giới tính</span>
            <span className='text-neutral-black'>
              {GENDER_CUSTOMER_TYPE_LABEL[customerInfo.gender]}
            </span>
          </div>
        </div>
        <div className='grid grid-cols-3'>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Số điện thoại</span>
            <span className='text-neutral-black'>{`(+${customerInfo.phoneCode}) ${customerInfo.phoneNumber}`}</span>
          </div>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Email</span>
            <span className='text-neutral-black'>{customerInfo.email}</span>
          </div>
        </div>
        <div className='grid grid-cols-3'>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Địa chỉ</span>
            <span className='text-neutral-black'>{customerInfo.addr}</span>
          </div>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>
              Tỉnh / Thành phố
            </span>
            <span className='text-neutral-black'>{customerInfo.city}</span>
          </div>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Quốc gia</span>
            <span className='text-neutral-black'>
              {countriesObjectByCode?.[customerInfo.country]?.country}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
