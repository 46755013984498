import { Button, DeleteBin6Line, Edit2FillIcon } from '@common-ui';
import { IAirlineHub, IFlightServiceFee } from '@tixlabs/grpc-client';
import { useCurrency } from '@vemaybay-admin/hooks/internals';
import { EModalMode } from '@vemaybay-admin/types';
import { SERVICE_FEE_LABEL } from '@vemaybay-admin/utils';
import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { SetStateAction } from 'react';
import { ESwitchModal, TModalServiceFee } from './ServiceFeeModal';

type Props = {
  data: IFlightServiceFee[];
  handleUpdateModalInfo: (value: SetStateAction<TModalServiceFee>) => void;
  airlineObjectByCode: Record<string, IAirlineHub>;
  pagination: TablePaginationConfig;
  isLoading: boolean;
};

type TServiceFeeItem = {
  key: string;
} & IFlightServiceFee;

const TitleColumns = ({ title }: { title: string }) => {
  return (
    <span className='font-semibold leading-[25px] text-neutral-7'>{title}</span>
  );
};

const TableServiceFee = ({
  data,
  handleUpdateModalInfo,
  airlineObjectByCode,
  pagination,
  isLoading,
}: Props) => {
  const { formatCurrency } = useCurrency();
  const dataSource: TServiceFeeItem[] = data.map((serviceFee, index) => ({
    ...serviceFee,
    key: serviceFee.id,
  }));

  const columns: ColumnsType<TServiceFeeItem> = [
    {
      key: 'type',
      dataIndex: 'type',
      title: <TitleColumns title='Loại chuyến bay' />,
      render: (text, record) => {
        return SERVICE_FEE_LABEL[record.type];
      },
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: <TitleColumns title='Số tiền' />,
      render: (text, record) => {
        return formatCurrency(record.amount);
      },
    },
    {
      key: 'airlineCode',
      dataIndex: 'airlineCode',
      title: <TitleColumns title='Hãng hàng không' />,
      render: (value, record) => {
        return value ? airlineObjectByCode?.[value]?.name : 'Tất cả';
      },
    },
    // {
    //   key: 'vat',
    //   dataIndex: 'vat',
    //   title: <TitleColumns title='Thuế VAT %' />,
    // },
    {
      key: 'action',
      dataIndex: 'action',
      title: <TitleColumns title='Thao tác' />,
      render: (text, record) => {
        return (
          <div className='flex items-center gap-x-1.5'>
            <Button
              variant='ghost'
              prefixIcon={<Edit2FillIcon />}
              onClick={() =>
                handleUpdateModalInfo((pre) => ({
                  ...pre,
                  open: true,
                  state: ESwitchModal.UPDATE_SERVICE_FEE,
                  mode: EModalMode.ACTION,
                  closeIcon: true,
                  serviceFee: record,
                }))
              }>
              Sửa
            </Button>
            <Button
              variant='ghost'
              theme='red'
              prefixIcon={<DeleteBin6Line />}
              onClick={() => {
                handleUpdateModalInfo((pre) => ({
                  ...pre,
                  open: true,
                  state: ESwitchModal.CONFIRM_DELETE,
                  mode: EModalMode.ACTION,
                  closeIcon: true,
                  serviceFee: record,
                }));
              }}>
              Xóa
            </Button>
          </div>
        );
      },
    },
  ];
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      size='small'
      pagination={pagination}
      loading={isLoading}
    />
  );
};

export default TableServiceFee;
