import cn from 'classnames';
import { PropsWithChildren } from 'react';

type Props = {
  title: string;
  className?: string;
};

export function StatisticCardBox({
  title,
  className,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div
      className={cn(
        className,
        'p-3 flex flex-col gap-y-[30px] bg-neutral-1 border border-neutral-5 rounded'
      )}>
      <div className='text-xl'>{title}</div>
      {children}
    </div>
  );
}

export default StatisticCardBox;
