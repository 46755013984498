import {
  Button,
  ButtonLoading,
  CloseIcon,
  InfoIcon,
  Spam2LineIcon,
  FormInput,
  FormSelect,
  FormSelectSearch,
} from '@common-ui';
import { IAirlineHub, IFlightServiceFee } from '@tixlabs/grpc-client';
import { SelectOptionItem } from '@tixlabs/types';

import ModalContent from '@vemaybay-admin/components/Modal/ModalContent';
import { useCurrency } from '@vemaybay-admin/hooks/internals';
import { EModalMode } from '@vemaybay-admin/types';
import { OPTION_SERVICE_FEE, SERVICE_FEE_LABEL } from '@vemaybay-admin/utils';
import { Modal } from 'antd';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  onCancel: () => void;
  onSubmit: () => Promise<void>;
  airlineOptions: SelectOptionItem[];
  airlineObjectByCode: Record<string, IAirlineHub>;
  handleSearch: (keyword: string) => void;
};

const FormServiceFee = ({
  onCancel,
  airlineOptions,
  onSubmit,
  airlineObjectByCode,
  handleSearch,
}: Props) => {
  const { formatPrice } = useCurrency();
  const methods = useFormContext<IFlightServiceFee>();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const airlineCodeValue = methods.getValues('airlineCode');
  const airlineCodeValueWatch = methods.watch('airlineCode');

  return (
    <>
      <Modal
        open={isOpenModal}
        centered={true}
        closeIcon={false}
        footer={() => null}>
        <ModalContent
          className='w-full'
          mode={EModalMode.WARNING}
          title='Thay đổi phí dịch vụ & thuế'
          content={
            <div className='flex flex-col gap-4'>
              <div className='mt-5 flex flex-col items-start gap-2'>
                <span className='text-[#121212]'>
                  Vui lòng kiểm tra & xác nhận thông tin phí dịch vụ và thuế sẽ
                  được thay đổi.
                </span>
                <div className='flex flex-col items-start gap-3 mt-3 w-full'>
                  <div className='flex items-center w-full'>
                    <span className='text-neutral-7 font-semibold'>
                      Loại máy bay
                    </span>
                    <span className='flex-1 text-end'>
                      {SERVICE_FEE_LABEL[methods.getValues('type')]}
                    </span>
                  </div>
                  <div className='flex items-center w-full'>
                    <span className='text-neutral-7 font-semibold'>
                      Hãng hàng không
                    </span>
                    <span className='flex-1 text-end'>
                      {airlineObjectByCode?.[airlineCodeValue]?.name ||
                        'Tất cả'}
                    </span>
                  </div>
                  <div className='flex items-center w-full'>
                    <span className='text-neutral-7 font-semibold'>
                      Phí dịch vụ
                    </span>
                    <span className='flex-1 text-end'>
                      {formatPrice(methods.getValues('amount'))} VND
                    </span>
                  </div>
                  {/* <div className='flex items-center w-full'>
                <span className='text-neutral-7 font-semibold'>Thuế VAT</span>
                <span className='flex-1 text-end'>
                  {methods.getValues('vat')} %
                </span>
              </div> */}
                </div>
              </div>
              <div className='flex items-center w-full space-x-2.5'>
                <ButtonLoading
                  type='button'
                  theme='primary'
                  className='flex-1'
                  variant='ghost'
                  onClick={() => {
                    setIsOpenModal(false);
                  }}
                  disabled={methods.formState.isSubmitting}>
                  Hủy bỏ
                </ButtonLoading>
                <ButtonLoading
                  className='flex-1'
                  type='button'
                  onClick={() =>
                    onSubmit().finally(() => {
                      setIsOpenModal(false);
                    })
                  }
                  loading={methods.formState.isSubmitting}>
                  Xác nhận
                </ButtonLoading>
              </div>
            </div>
          }
        />
      </Modal>
      <div className='w-[500px] space-y-5'>
        <div className='flex items-center '>
          <h3 className='flex-1 text-lg font-semibold text-black'>
            Thay đổi phí dịch vụ
          </h3>
          <CloseIcon
            className='w-5 h-5 text-neutral-7 hover:text-neutral-8 cursor-pointer'
            onClick={() => {
              onCancel();
            }}
          />
        </div>
        <div className='space-y-5'>
          <FormSelect
            name='type'
            selectOptions={OPTION_SERVICE_FEE}
            label='Loại chuyến bay'
            placeholder='Chọn loại chuyến bay'
            inputProps={{ isOnlyValue: true }}
            rules={{
              required: {
                value: true,
                message: 'Chọn loại chuyến bay',
              },
            }}
          />
          <div className='flex flex-col space-y-1.5'>
            <FormSelectSearch
              name='airlineCode'
              label='Hãng hàng không'
              placeholder='Chọn hãng hàng không'
              handleSearch={handleSearch}
              selectOptions={airlineOptions}
              labelProps={{ isRequired: true }}
              inputProps={{
                isOnlyValue: true,
              }}
              rules={{
                validate: {
                  checkValid: (v) => {
                    return v === '' || !!v || 'Chọn hãng hàng không';
                  },
                },
              }}
            />
            {!airlineCodeValueWatch && (
              <div className='flex space-x-1.5 items-center'>
                <Spam2LineIcon className='text-secondary-6 w-4 h-4' />
                <span className='text-secondary-6 text-[13px] leading-[25px]'>
                  Không bao gồm các hãng hàng không được cài đặt phí riêng
                </span>
              </div>
            )}
          </div>

          <FormInput
            name='amount'
            inputProps={{ type: 'number' }}
            label='Phí dịch vụ'
            placeholder='Nhập phí dịch vụ'
          />
          {/* <FormInput
            name='vat'
            inputProps={{ type: 'number' }}
            label='Thuế VAT %'
            placeholder='Nhập thuế VAT %'
          /> */}
        </div>
        <div className='flex items-center gap-[5px]'>
          <InfoIcon className='w-5 h-5 text-neutral-7' />
          <span className='text-neutral-7 text-base leading-[25px]'>
            Phí dịch vụ được áp dụng cho tất cả người dùng thuộc đại lý.
          </span>
        </div>
        <div className='flex w-full space-x-2.5 whitespace-nowrap'>
          <Button
            type='button'
            theme='primary'
            variant='ghost'
            className='flex-1'
            onClick={() => !methods.formState.isSubmitting && onCancel()}
            disabled={methods.formState.isSubmitting}>
            Hủy bỏ
          </Button>
          <Button
            type='button'
            className='flex-1'
            onClick={() => {
              setIsOpenModal(true);
            }}>
            Lưu thông tin
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormServiceFee;
