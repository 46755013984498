import { IRetrieveBookingFlightData } from '@tixlabs/grpc-client/web-partnership';
import React from 'react';
import { ItineraryItem } from './ItineraryItem';
import { EBookingStatus } from '@tixlabs/grpc-client';

type Props = {
  bookingFlight: IRetrieveBookingFlightData;
};

export const ItineraryList = ({ bookingFlight }: Props) => {
  return (
    <div className='flex flex-col rounded-lg bg-white'>
      <h3 className='text-lg font-semibold py-3 px-4 mb-0'>
        Thông tin hành trình
      </h3>
      {bookingFlight.itinerariesList.map((itinerary, index) => {
        return (
          <ItineraryItem
            key={index}
            passengersList={bookingFlight.passengersList}
            itinerary={itinerary}
          />
        );
      })}
    </div>
  );
};
