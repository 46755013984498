import { CustomerServiceClient } from '@api/customer/web_partnership/customer_grpc_web_pb';

import { BaseApiOptions } from '@core/grpc-client';
import {
  GetCustomerByIDReq,
  GetCustomerByIDRes,
} from '@api/customer/web_partnership/customer_pb';
import { BaseApi } from '../base-api';

class CustomerServiceApi extends BaseApi<CustomerServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);

    this.serviceClient = new CustomerServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  getCustomerByID = ({
    id,
  }: GetCustomerByIDReq.AsObject): Promise<GetCustomerByIDRes.AsObject> => {
    const req = new GetCustomerByIDReq();
    req.setId(id);
    return this.grpc<
      GetCustomerByIDReq,
      GetCustomerByIDRes,
      GetCustomerByIDRes.AsObject
    >(this.serviceClient.getCustomerByID, req, {});
  };
}

export const customerServiceApiService = new CustomerServiceApi();
export default customerServiceApiService;
