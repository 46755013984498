import { STATUS_COMMISSION_LABEL } from '@vemaybay-admin/utils';
import { ECommissionStatus } from '@tixlabs/grpc-client/web-partnership';
import cn from 'classnames';

type Props = {
  status: ECommissionStatus;
  className?: string;
  classNameText?: string;
};

const objClassName = {
  [ECommissionStatus.APPROVED]: 'text-common-success',
  [ECommissionStatus.WAITINGREVIEW]: 'text-common-warning',
  [ECommissionStatus.CANCEL]: 'text-neutral-40',
};

export const Status = ({ status, className, classNameText }: Props) => {
  return (
    <div className={cn('', className)}>
      <span
        className={cn('font-semibold', objClassName[status], classNameText)}>
        {STATUS_COMMISSION_LABEL[status]}
      </span>
    </div>
  );
};
