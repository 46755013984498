import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import sampleEn from '../translations/en/sample.json';
import sampleVi from '../translations/vi/sample.json';

import authVi from '../translations/vi/auth.json';
import authEn from '../translations/vi/auth.json';

import { initReactI18next } from 'react-i18next';

const i18nOption = {
  fallbackLng: 'vi',
  debug: false,
  default: 'vi',

  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  resources: {
    en: {
      sample: sampleEn,
      auth: authEn,
    },
    vi: {
      sample: sampleVi,
      auth: authVi,
    },
  },
  detection: {
    order: [
      'localStorage',
      // 'navigator',
      // 'querystring',
      // 'path',
      // 'sessionStorage',
    ],
  },

  // react i18next special options (optional)
  // override if needed - omit if ok with defaults

  react: {
    // bindI18n: 'languageChanged',
    // bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  },
};

export type TLanguageNS = keyof typeof i18nOption.resources.en;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init(i18nOption);

export default i18n;
