import '../../../../styles/react-datepicker.min.css';
import cn from 'classnames';
import ReactDatePicker, {
  CalendarContainerProps,
  ReactDatePickerProps,
} from 'react-datepicker';
import './styles.css';

import {
  InputSize,
  OPTION_DATE_RANGE_LIST,
  PassPropsType,
  TBaseInputProps,
  TFromToDate,
  TOptionFilterRangeDate,
} from '@common-ui';
import { addDay } from '@core/utils';
import { configDatePicker, defaultConfigDatePicker } from '@common-ui';

import { ForwardedRef, forwardRef, useState } from 'react';
import SelectItem from '../../../Popover/SelectItem';

export type InputDateFromToProps = {
  placeholderStart?: string;
  placeholderEnd?: string;
  showFilter?: boolean;
  listOptionFilterRangeDate?: TOptionFilterRangeDate[];
  isShowIcon?: boolean;
  minStartDate?: Date | null;
  maxStartDate?: Date | null;
  minEndDate?: Date | null;
  maxEndDate?: Date | null;
  isFullTime?: boolean;
  defaultFilterOption?: TOptionFilterRangeDate;
} & TBaseInputProps &
  PassPropsType<TFromToDate> &
  Omit<ReactDatePickerProps, 'onChange'>;

// const CustomInputDate = forwardRef(
//   ({ value, onClick }: any, ref: ForwardedRef<HTMLDivElement>) => (
//     <div className='w-full flex-1' onClick={onClick} ref={ref}>
//       {value}
//     </div>
//   )
// );
// CustomInputDate.displayName = 'CustomInputDate';

type TCalendarContainerWithFilter = CalendarContainerProps & {
  defaultFilterOption?: TOptionFilterRangeDate;
  showFilter?: boolean;
  optionList: TOptionFilterRangeDate[];
  activeOption?: TOptionFilterRangeDate;
  handleChangeOption: (data: TOptionFilterRangeDate) => void;
};

const currentMonth = new Date().getMonth();
const currentYear = new Date().getFullYear();
const numberOfDaysInCurrentMonth = new Date(
  currentYear,
  currentMonth + 1,
  0
).getDate();

function CalendarContainerWithFilter({
  className,
  children,
  showFilter,
  optionList,
  activeOption,
  handleChangeOption,
}: TCalendarContainerWithFilter) {
  return (
    <div className={cn('filter-calender', className)}>
      {showFilter && (
        <div>
          <div className='w-[170px] bg-theme-white text-md'>
            {optionList.map((selectOptionItem, index) => (
              <SelectItem
                className='!p-3'
                key={index}
                label={selectOptionItem.label}
                isActive={
                  activeOption &&
                  activeOption.period.from === selectOptionItem.period.from &&
                  activeOption.period.to === selectOptionItem.period.to
                }
                onClick={() => handleChangeOption(selectOptionItem)}
              />
            ))}
          </div>
        </div>
      )}
      <div className='relative'>{children}</div>
    </div>
  );
}

function InputDateFromToInner(
  {
    // datePickerOptions,

    name,
    className,
    isError,
    isDirty,
    inputSize = InputSize.MD,
    value,
    onChange,
    placeholderStart = 'Ngày đi',
    placeholderEnd = 'Ngày về',
    showFilter,
    listOptionFilterRangeDate,
    isShowIcon = false,
    minStartDate,
    maxStartDate,
    minEndDate,
    maxEndDate,
    isFullTime = false,
    defaultFilterOption,
    required,
    ...rest
  }: InputDateFromToProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [activeOptionFilter, setActiveOptionFilter] = useState<
    TOptionFilterRangeDate | undefined
  >(showFilter ? defaultFilterOption || OPTION_DATE_RANGE_LIST[0] : undefined);

  const handleChangeOptionFilter = (option: TOptionFilterRangeDate) => {
    setActiveOptionFilter(option);
    if (option.period.from === -1 && option.period.to === -1) {
      onChange && onChange({ startDate: new Date() });
      return;
    }
    const newDurationdate: TFromToDate = {
      endDate: new Date(option.period.to),
      startDate: new Date(option.period.from),
    };
    onChange && onChange(newDurationdate);
  };

  const handleChangeValueStartDate = (date: Date | null) => {
    if (
      showFilter &&
      activeOptionFilter?.period.from !== -1 &&
      activeOptionFilter?.period.to !== -1
    ) {
      setActiveOptionFilter(
        OPTION_DATE_RANGE_LIST[OPTION_DATE_RANGE_LIST.length - 1]
      );
    }
    const newDate: TFromToDate = {
      startDate: date,
      endDate: value?.endDate,
    };
    if (date && value?.endDate && new Date(date) >= new Date(value.endDate)) {
      newDate.endDate = new Date(addDay(date, 7));
    }
    if (isFullTime && date) {
      newDate.startDate = new Date(date.setHours(0, 0, 0, 0));
    }
    if (isFullTime && value?.endDate) {
      newDate.endDate = new Date(value?.endDate.setHours(23, 59, 59, 999));
    }

    onChange?.(newDate);
  };

  const handleChangeValueEndDate = (date: Date | null) => {
    if (
      showFilter &&
      activeOptionFilter?.period.from !== -1 &&
      activeOptionFilter?.period.to !== -1
    ) {
      setActiveOptionFilter(
        OPTION_DATE_RANGE_LIST[OPTION_DATE_RANGE_LIST.length - 1]
      );
    }
    const newDate: TFromToDate = {
      startDate: value?.startDate,
      endDate: date,
    };
    if (isFullTime && date) {
      newDate.endDate = new Date(date.setHours(23, 59, 59, 999));
    }
    if (isFullTime && value?.startDate) {
      newDate.startDate = new Date(value?.startDate.setHours(0, 0, 0, 0));
    }
    onChange?.(newDate);
  };

  return (
    <div
      className={cn(
        `base-input base-input-${inputSize}`,
        'inline-flex items-center focus-within:border-primary focus-within:outline-none',
        {
          error: isError,
        },
        className
      )}>
      {isShowIcon && 'icon'}
      {/* <CalendarLineIcon
        className={cn('mr-2 h-6 w-6 shrink-0 text-theme-black/50')}
      /> */}
      <div className='flex w-full items-center justify-between'>
        <div className='w-full'>
          <ReactDatePicker
            calendarContainer={(props) => (
              <CalendarContainerWithFilter
                {...props}
                showFilter={showFilter}
                activeOption={activeOptionFilter}
                handleChangeOption={handleChangeOptionFilter}
                optionList={listOptionFilterRangeDate || OPTION_DATE_RANGE_LIST}
              />
            )}
            monthsShown={2}
            {...defaultConfigDatePicker}
            {...rest}
            name={`${name}.startDate`}
            placeholderText={placeholderStart}
            onChange={handleChangeValueStartDate}
            selected={value?.startDate}
            startDate={value?.startDate}
            endDate={value?.endDate}
            calendarClassName='!flex'
            minDate={minStartDate}
            maxDate={value?.endDate || maxStartDate}
            required={required}
            // customInput={<CustomInputDate />}
            // ref={(dateRef: any) => {
            //   (ref as any)?.({
            //     focus: () => {
            //       dateRef?.setFocus();
            //       dateRef?.input?.scrollIntoView?.({
            //         block: 'center',
            //       });
            //     },
            //   });
            // }}
            {...configDatePicker}
            selectsStart
          />
        </div>

        {value?.startDate && <span className='ml-[-16px]'>-</span>}
        <div className='w-full'>
          <ReactDatePicker
            calendarContainer={(props) => (
              <CalendarContainerWithFilter
                {...props}
                showFilter={showFilter}
                activeOption={activeOptionFilter}
                handleChangeOption={handleChangeOptionFilter}
                optionList={listOptionFilterRangeDate || OPTION_DATE_RANGE_LIST}
              />
            )}
            required={required}
            monthsShown={2}
            {...defaultConfigDatePicker}
            {...rest}
            name={`${name}.endDate`}
            placeholderText={placeholderEnd}
            onChange={handleChangeValueEndDate}
            selected={value?.endDate}
            startDate={value?.startDate}
            endDate={value?.endDate}
            // minDate={addDay(value?.startDate || new Date(), 1)}
            // minDate={value?.startDate || new Date()}
            minDate={value?.startDate || minEndDate}
            maxDate={maxEndDate}
            selectsEnd
            {...configDatePicker}
            className={cn(configDatePicker.className, 'pl-2')}
            calendarClassName='!flex'
          />
        </div>
      </div>
    </div>
  );
}
export const InputDateFromTo = forwardRef(InputDateFromToInner);
InputDateFromTo.displayName = 'InputDateFromTo';

export default InputDateFromTo;
