import { useAppSelector } from '@vemaybay-admin/stores';

export function useErrorMessageData() {
  const { errors } = useAppSelector((state) => state.common);

  function getErrorMessage(code: string): string {
    return errors[code] || code;
  }

  return {
    getErrorMessage,
  };
}

export default useErrorMessageData;
