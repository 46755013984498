/**
 * @fileoverview gRPC-Web generated client stub for partnership.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: partnership/web_partnership/partnership_user.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var validate_validate_pb = require('../../validate/validate_pb.js')

var partnership_web_partnership_base_pb = require('../../partnership/web_partnership/base_pb.js')

var partnership_base_pb = require('../../partnership/base_pb.js')

var base_base_pb = require('../../base/base_pb.js')
const proto = {};
proto.partnership = {};
proto.partnership.web_partnership = require('./partnership_user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.partnership.web_partnership.PartnershipUserServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.CreateUserReq,
 *   !proto.partnership.web_partnership.CreateUserRes>}
 */
const methodDescriptor_PartnershipUserService_CreateUser = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/CreateUser',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.CreateUserReq,
  proto.partnership.web_partnership.CreateUserRes,
  /**
   * @param {!proto.partnership.web_partnership.CreateUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partnership.web_partnership.CreateUserRes.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.CreateUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.CreateUserRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.CreateUserRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.createUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_CreateUser,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.CreateUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.CreateUserRes>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.createUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_CreateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.CreateUserV2Req,
 *   !proto.partnership.web_partnership.CreateUserV2Res>}
 */
const methodDescriptor_PartnershipUserService_CreateUserV2 = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/CreateUserV2',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.CreateUserV2Req,
  proto.partnership.web_partnership.CreateUserV2Res,
  /**
   * @param {!proto.partnership.web_partnership.CreateUserV2Req} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partnership.web_partnership.CreateUserV2Res.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.CreateUserV2Req} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.CreateUserV2Res)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.CreateUserV2Res>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.createUserV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/CreateUserV2',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_CreateUserV2,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.CreateUserV2Req} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.CreateUserV2Res>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.createUserV2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/CreateUserV2',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_CreateUserV2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.GetMeReq,
 *   !proto.partnership.web_partnership.GetMeRes>}
 */
const methodDescriptor_PartnershipUserService_GetMe = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/GetMe',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.GetMeReq,
  proto.partnership.web_partnership.GetMeRes,
  /**
   * @param {!proto.partnership.web_partnership.GetMeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partnership.web_partnership.GetMeRes.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.GetMeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.GetMeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.GetMeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.getMe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/GetMe',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_GetMe,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.GetMeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.GetMeRes>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.getMe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/GetMe',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_GetMe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.GetMeReq,
 *   !proto.partnership.web_partnership.GetMeV2Res>}
 */
const methodDescriptor_PartnershipUserService_GetMeV2 = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/GetMeV2',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.GetMeReq,
  proto.partnership.web_partnership.GetMeV2Res,
  /**
   * @param {!proto.partnership.web_partnership.GetMeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partnership.web_partnership.GetMeV2Res.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.GetMeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.GetMeV2Res)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.GetMeV2Res>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.getMeV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/GetMeV2',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_GetMeV2,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.GetMeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.GetMeV2Res>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.getMeV2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/GetMeV2',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_GetMeV2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.UpdateMeReq,
 *   !proto.partnership.web_partnership.Empty>}
 */
const methodDescriptor_PartnershipUserService_UpdateMe = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/UpdateMe',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.UpdateMeReq,
  partnership_web_partnership_base_pb.Empty,
  /**
   * @param {!proto.partnership.web_partnership.UpdateMeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  partnership_web_partnership_base_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.UpdateMeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.updateMe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/UpdateMe',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_UpdateMe,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.UpdateMeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.Empty>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.updateMe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/UpdateMe',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_UpdateMe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.UpdateMeReq,
 *   !proto.partnership.web_partnership.UpdateMeV2Res>}
 */
const methodDescriptor_PartnershipUserService_UpdateMeV2 = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/UpdateMeV2',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.UpdateMeReq,
  proto.partnership.web_partnership.UpdateMeV2Res,
  /**
   * @param {!proto.partnership.web_partnership.UpdateMeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partnership.web_partnership.UpdateMeV2Res.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.UpdateMeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.UpdateMeV2Res)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.UpdateMeV2Res>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.updateMeV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/UpdateMeV2',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_UpdateMeV2,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.UpdateMeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.UpdateMeV2Res>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.updateMeV2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/UpdateMeV2',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_UpdateMeV2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.ChangePasswordReq,
 *   !proto.partnership.web_partnership.Empty>}
 */
const methodDescriptor_PartnershipUserService_ChangePassword = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/ChangePassword',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.ChangePasswordReq,
  partnership_web_partnership_base_pb.Empty,
  /**
   * @param {!proto.partnership.web_partnership.ChangePasswordReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  partnership_web_partnership_base_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.ChangePasswordReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.changePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_ChangePassword,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.ChangePasswordReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.Empty>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.changePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_ChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.ChangePasswordReq,
 *   !proto.partnership.web_partnership.ChangePasswordV2Res>}
 */
const methodDescriptor_PartnershipUserService_ChangePasswordV2 = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/ChangePasswordV2',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.ChangePasswordReq,
  proto.partnership.web_partnership.ChangePasswordV2Res,
  /**
   * @param {!proto.partnership.web_partnership.ChangePasswordReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partnership.web_partnership.ChangePasswordV2Res.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.ChangePasswordReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.ChangePasswordV2Res)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.ChangePasswordV2Res>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.changePasswordV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/ChangePasswordV2',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_ChangePasswordV2,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.ChangePasswordReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.ChangePasswordV2Res>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.changePasswordV2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/ChangePasswordV2',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_ChangePasswordV2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.GetBasicUsersReq,
 *   !proto.partnership.web_partnership.GetBasicUsersRes>}
 */
const methodDescriptor_PartnershipUserService_GetBasicUsers = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/GetBasicUsers',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.GetBasicUsersReq,
  proto.partnership.web_partnership.GetBasicUsersRes,
  /**
   * @param {!proto.partnership.web_partnership.GetBasicUsersReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partnership.web_partnership.GetBasicUsersRes.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.GetBasicUsersReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.GetBasicUsersRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.GetBasicUsersRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.getBasicUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/GetBasicUsers',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_GetBasicUsers,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.GetBasicUsersReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.GetBasicUsersRes>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.getBasicUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/GetBasicUsers',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_GetBasicUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.SendOtpToResetPasswordReq,
 *   !proto.partnership.web_partnership.SendOtpToResetPasswordRes>}
 */
const methodDescriptor_PartnershipUserService_SendOtpToResetPassword = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/SendOtpToResetPassword',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.SendOtpToResetPasswordReq,
  proto.partnership.web_partnership.SendOtpToResetPasswordRes,
  /**
   * @param {!proto.partnership.web_partnership.SendOtpToResetPasswordReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partnership.web_partnership.SendOtpToResetPasswordRes.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.SendOtpToResetPasswordReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.SendOtpToResetPasswordRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.SendOtpToResetPasswordRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.sendOtpToResetPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/SendOtpToResetPassword',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_SendOtpToResetPassword,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.SendOtpToResetPasswordReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.SendOtpToResetPasswordRes>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.sendOtpToResetPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/SendOtpToResetPassword',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_SendOtpToResetPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.VerifyOtpToResetPasswordReq,
 *   !proto.partnership.web_partnership.VerifyOtpToResetPasswordRes>}
 */
const methodDescriptor_PartnershipUserService_VerifyOtpToResetPassword = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/VerifyOtpToResetPassword',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.VerifyOtpToResetPasswordReq,
  proto.partnership.web_partnership.VerifyOtpToResetPasswordRes,
  /**
   * @param {!proto.partnership.web_partnership.VerifyOtpToResetPasswordReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partnership.web_partnership.VerifyOtpToResetPasswordRes.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.VerifyOtpToResetPasswordReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.VerifyOtpToResetPasswordRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.VerifyOtpToResetPasswordRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.verifyOtpToResetPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/VerifyOtpToResetPassword',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_VerifyOtpToResetPassword,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.VerifyOtpToResetPasswordReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.VerifyOtpToResetPasswordRes>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.verifyOtpToResetPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/VerifyOtpToResetPassword',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_VerifyOtpToResetPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.ResetPasswordReq,
 *   !proto.partnership.web_partnership.Empty>}
 */
const methodDescriptor_PartnershipUserService_ResetPassword = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/ResetPassword',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.ResetPasswordReq,
  partnership_web_partnership_base_pb.Empty,
  /**
   * @param {!proto.partnership.web_partnership.ResetPasswordReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  partnership_web_partnership_base_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.ResetPasswordReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.resetPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/ResetPassword',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_ResetPassword,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.ResetPasswordReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.Empty>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.resetPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/ResetPassword',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_ResetPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.UpdateUserReq,
 *   !proto.partnership.web_partnership.Empty>}
 */
const methodDescriptor_PartnershipUserService_UpdateUser = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/UpdateUser',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.UpdateUserReq,
  partnership_web_partnership_base_pb.Empty,
  /**
   * @param {!proto.partnership.web_partnership.UpdateUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  partnership_web_partnership_base_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.UpdateUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.updateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_UpdateUser,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.UpdateUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.Empty>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.updateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_UpdateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.UpdateUserV2Req,
 *   !proto.partnership.web_partnership.UpdateUserV2Res>}
 */
const methodDescriptor_PartnershipUserService_UpdateUserV2 = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/UpdateUserV2',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.UpdateUserV2Req,
  proto.partnership.web_partnership.UpdateUserV2Res,
  /**
   * @param {!proto.partnership.web_partnership.UpdateUserV2Req} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partnership.web_partnership.UpdateUserV2Res.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.UpdateUserV2Req} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.UpdateUserV2Res)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.UpdateUserV2Res>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.updateUserV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/UpdateUserV2',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_UpdateUserV2,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.UpdateUserV2Req} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.UpdateUserV2Res>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.updateUserV2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/UpdateUserV2',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_UpdateUserV2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.DeleteUserReq,
 *   !proto.partnership.web_partnership.Empty>}
 */
const methodDescriptor_PartnershipUserService_DeleteUser = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/DeleteUser',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.DeleteUserReq,
  partnership_web_partnership_base_pb.Empty,
  /**
   * @param {!proto.partnership.web_partnership.DeleteUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  partnership_web_partnership_base_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.DeleteUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.deleteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_DeleteUser,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.DeleteUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.Empty>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.deleteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_DeleteUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.DisableUserReq,
 *   !proto.partnership.web_partnership.Empty>}
 */
const methodDescriptor_PartnershipUserService_DisableUser = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/DisableUser',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.DisableUserReq,
  partnership_web_partnership_base_pb.Empty,
  /**
   * @param {!proto.partnership.web_partnership.DisableUserReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  partnership_web_partnership_base_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.DisableUserReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.disableUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/DisableUser',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_DisableUser,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.DisableUserReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.Empty>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.disableUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/DisableUser',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_DisableUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.GetUsersReq,
 *   !proto.partnership.web_partnership.GetUsersRes>}
 */
const methodDescriptor_PartnershipUserService_GetUsers = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/GetUsers',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.GetUsersReq,
  proto.partnership.web_partnership.GetUsersRes,
  /**
   * @param {!proto.partnership.web_partnership.GetUsersReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partnership.web_partnership.GetUsersRes.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.GetUsersReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.GetUsersRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.GetUsersRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.getUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/GetUsers',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_GetUsers,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.GetUsersReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.GetUsersRes>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.getUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/GetUsers',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_GetUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.GetUsersReq,
 *   !proto.partnership.web_partnership.GetUsersV2Res>}
 */
const methodDescriptor_PartnershipUserService_GetUsersV2 = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/GetUsersV2',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.GetUsersReq,
  proto.partnership.web_partnership.GetUsersV2Res,
  /**
   * @param {!proto.partnership.web_partnership.GetUsersReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partnership.web_partnership.GetUsersV2Res.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.GetUsersReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.GetUsersV2Res)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.GetUsersV2Res>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.getUsersV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/GetUsersV2',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_GetUsersV2,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.GetUsersReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.GetUsersV2Res>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.getUsersV2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/GetUsersV2',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_GetUsersV2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.GetUserDetailV2Req,
 *   !proto.partnership.web_partnership.GetUserDetailV2Res>}
 */
const methodDescriptor_PartnershipUserService_GetUserDetailV2 = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/GetUserDetailV2',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.GetUserDetailV2Req,
  proto.partnership.web_partnership.GetUserDetailV2Res,
  /**
   * @param {!proto.partnership.web_partnership.GetUserDetailV2Req} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partnership.web_partnership.GetUserDetailV2Res.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.GetUserDetailV2Req} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.GetUserDetailV2Res)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.GetUserDetailV2Res>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.getUserDetailV2 =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/GetUserDetailV2',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_GetUserDetailV2,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.GetUserDetailV2Req} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.GetUserDetailV2Res>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.getUserDetailV2 =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/GetUserDetailV2',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_GetUserDetailV2);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.partnership.web_partnership.AdminChangeUserPasswordReq,
 *   !proto.partnership.web_partnership.AdminChangeUserPasswordRes>}
 */
const methodDescriptor_PartnershipUserService_AdminChangeUserPassword = new grpc.web.MethodDescriptor(
  '/partnership.web_partnership.PartnershipUserService/AdminChangeUserPassword',
  grpc.web.MethodType.UNARY,
  proto.partnership.web_partnership.AdminChangeUserPasswordReq,
  proto.partnership.web_partnership.AdminChangeUserPasswordRes,
  /**
   * @param {!proto.partnership.web_partnership.AdminChangeUserPasswordReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.partnership.web_partnership.AdminChangeUserPasswordRes.deserializeBinary
);


/**
 * @param {!proto.partnership.web_partnership.AdminChangeUserPasswordReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.partnership.web_partnership.AdminChangeUserPasswordRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.partnership.web_partnership.AdminChangeUserPasswordRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.partnership.web_partnership.PartnershipUserServiceClient.prototype.adminChangeUserPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/AdminChangeUserPassword',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_AdminChangeUserPassword,
      callback);
};


/**
 * @param {!proto.partnership.web_partnership.AdminChangeUserPasswordReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.partnership.web_partnership.AdminChangeUserPasswordRes>}
 *     Promise that resolves to the response
 */
proto.partnership.web_partnership.PartnershipUserServicePromiseClient.prototype.adminChangeUserPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/partnership.web_partnership.PartnershipUserService/AdminChangeUserPassword',
      request,
      metadata || {},
      methodDescriptor_PartnershipUserService_AdminChangeUserPassword);
};


module.exports = proto.partnership.web_partnership;

