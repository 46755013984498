import { ReportServiceClient } from '@api/airplane/web_partnership/report_grpc_web_pb';
import {
  CrossCheckStatsReq,
  CrossCheckStatsRes,
  CurrentBookingStatsRes,
  DownloadReportCommissionReq,
  DownloadReportCommissionRes,
  DownloadReportCrossCheckReq,
  DownloadReportCrossCheckRes,
  ReferenceListReq,
  ReferenceListRes,
  ReferenceStatsRes,
  ReportAirlineBookingReq,
  ReportAirlineBookingRes,
  ReportBookingReq,
  ReportBookingRes,
  ReportCommissionReq,
  ReportCommissionRes,
  ReportCommissionStatsReq,
  ReportCommissionStatsRes,
  ReportCrossCheckReq,
  ReportCrossCheckRes,
  ExportReportBookingReq,
  ExportReportBookingRes,
} from '@api/airplane/web_partnership/report_pb';
import { Empty, PaginationReq } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class ReportApi extends BaseApi<ReportServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new ReportServiceClient(this.url, null, this.optsDev);
  }

  getReportBooking = ({
    from,
    to,
  }: ReportBookingReq.AsObject): Promise<ReportBookingRes.AsObject> => {
    const req = new ReportBookingReq();

    req.setFrom(from);
    req.setTo(to);

    return this.grpc<
      ReportBookingReq,
      ReportBookingRes,
      ReportBookingRes.AsObject
    >(this.serviceClient.reportBooking, req, {});
  };

  getReportAirlineBooking = ({
    from,
    to,
  }: ReportAirlineBookingReq.AsObject): Promise<ReportAirlineBookingRes.AsObject> => {
    const req = new ReportAirlineBookingReq();

    req.setFrom(from);
    req.setTo(to);

    return this.grpc<
      ReportAirlineBookingReq,
      ReportAirlineBookingRes,
      ReportAirlineBookingRes.AsObject
    >(this.serviceClient.reportAirlineBooking, req, {});
  };

  currentBookingStats = (): Promise<CurrentBookingStatsRes.AsObject> => {
    const req = new Empty();

    return this.grpc<
      Empty,
      CurrentBookingStatsRes,
      CurrentBookingStatsRes.AsObject
    >(this.serviceClient.currentBookingStats, req, {});
  };

  reportCommissionStats = ({
    from,
    to,
  }: ReportCommissionStatsReq.AsObject): Promise<ReportCommissionStatsRes.AsObject> => {
    const req = new ReportCommissionStatsReq();
    req.setFrom(new Date(from).setHours(0, 0, 0, 0));
    req.setTo(new Date(to).setHours(23, 59, 59, 99));
    return this.grpc<
      ReportCommissionStatsReq,
      ReportCommissionStatsRes,
      ReportCommissionStatsRes.AsObject
    >(this.serviceClient.reportCommissionStats, req, {});
  };

  reportCommission = ({
    filter,
    pagination,
  }: ReportCommissionReq.AsObject): Promise<ReportCommissionRes.AsObject> => {
    const req = new ReportCommissionReq();
    const filterObj = new ReportCommissionReq.Filter();

    if (filter) {
      filter.to && filterObj.setTo(filter.to);
      filter.from && filterObj.setFrom(filter.from);
      filter.bookingCode &&
        filterObj.setBookingCode(filter.bookingCode.trim() || '');
      filter.status && filterObj.setStatus(filter.status);
    }
    req.setFilter(filterObj);

    if (pagination) {
      const paginationReq = new PaginationReq();
      paginationReq.setPageLimit(pagination.pageLimit);
      paginationReq.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationReq);
    } else {
      const paginationReq = new PaginationReq();
      paginationReq.setPageLimit(9999);
      paginationReq.setPageNumber(1);
      req.setPagination(paginationReq);
    }

    return this.grpc<
      ReportCommissionReq,
      ReportCommissionRes,
      ReportCommissionRes.AsObject
    >(this.serviceClient.reportCommission, req, {});
  };

  downloadReportCommission = ({
    bookingCode,
    from,
    status,
    to,
  }: DownloadReportCommissionReq.AsObject): Promise<DownloadReportCommissionRes.AsObject> => {
    const req = new DownloadReportCommissionReq();

    req.setBookingCode(bookingCode);
    req.setStatus(status);
    req.setFrom(from);
    req.setTo(to);

    return this.grpc<
      DownloadReportCommissionReq,
      DownloadReportCommissionRes,
      DownloadReportCommissionRes.AsObject
    >(this.serviceClient.downloadReportCommission, req, {});
  };

  crossCheckStats = ({
    date,
  }: CrossCheckStatsReq.AsObject): Promise<CrossCheckStatsRes.AsObject> => {
    const req = new CrossCheckStatsReq();

    req.setDate(date);

    return this.grpc<
      CrossCheckStatsReq,
      CrossCheckStatsRes,
      CrossCheckStatsRes.AsObject
    >(this.serviceClient.crossCheckStats, req, {});
  };

  reportCrossCheck = ({
    filter,
  }: ReportCrossCheckReq.AsObject): Promise<ReportCrossCheckRes.AsObject> => {
    const req = new ReportCrossCheckReq();

    if (filter) {
      const filterObj = new ReportCrossCheckReq.Filter();

      filterObj.setDate(filter.date);

      req.setFilter(filterObj);
    }

    return this.grpc<
      ReportCrossCheckReq,
      ReportCrossCheckRes,
      ReportCrossCheckRes.AsObject
    >(this.serviceClient.reportCrossCheck, req, {});
  };

  downloadReportCrossCheck = ({
    date,
  }: DownloadReportCrossCheckReq.AsObject): Promise<DownloadReportCrossCheckRes.AsObject> => {
    const req = new DownloadReportCrossCheckReq();

    req.setDate(date);

    return this.grpc<
      DownloadReportCrossCheckReq,
      DownloadReportCrossCheckRes,
      DownloadReportCrossCheckRes.AsObject
    >(this.serviceClient.downloadReportCrossCheck, req, {});
  };

  referenceStats = (): Promise<ReferenceStatsRes.AsObject> => {
    const req = new Empty();

    return this.grpc<Empty, ReferenceStatsRes, ReferenceStatsRes.AsObject>(
      this.serviceClient.referenceStats,
      req,
      {}
    );
  };

  referenceList = ({
    pagination,
  }: ReferenceListReq.AsObject): Promise<ReferenceListRes.AsObject> => {
    const req = new ReferenceListReq();

    if (pagination) {
      const paginationObj = new PaginationReq();
      paginationObj.setPageLimit(pagination.pageLimit);
      paginationObj.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationObj);
    }

    return this.grpc<
      ReferenceListReq,
      ReferenceListRes,
      ReferenceListRes.AsObject
    >(this.serviceClient.referenceList, req, {});
  };

  exportReportBookingAirplane = ({
    filter,
  }: ExportReportBookingReq.AsObject): Promise<ExportReportBookingRes.AsObject> => {
    const req = new ExportReportBookingReq();

    if (filter) {
      const reqFilter = new ExportReportBookingReq.Filter();
      reqFilter.setBookingCode(filter.bookingCode);
      reqFilter.setFromDate(filter.fromDate);
      reqFilter.setToDate(filter.toDate);
      reqFilter.setStatusesList(filter.statusesList);
      req.setFilter(reqFilter);
    }

    return this.grpc<
      ExportReportBookingReq,
      ExportReportBookingRes,
      ExportReportBookingRes.AsObject
    >(this.serviceClient.exportReportBooking, req, {});
  };
}

export const reportService = new ReportApi();
export default reportService;
