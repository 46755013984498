import {
  BackdropLoading,
  ButtonLoading,
  Form,
  FormInputDateFromTo,
  SearchLineIcon,
  TFromToDate,
} from '@common-ui';
import { StatisticCardBox, StatisticCardInfo } from '@common-ui/sky-booking';
import { addDay, subDay } from '@core/utils';
import cn from 'classnames';

import { useDashboard } from '@vemaybay-admin/hooks/apps';
import { useCurrency, useTime } from '@vemaybay-admin/hooks/internals';

import { ONE_DAY_TIMESTAMP } from '@vemaybay-admin/utils';
import { ChartOptions, Plugin } from 'chart.js';

import { useMemo } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';

import { IReportAirlineBookingItem } from '@tixlabs/grpc-client';
import { useAirlines } from '@vemaybay-admin/hooks/stores';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const MAX_FILTER_DAY = 30;

const lineOptions: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  elements: {
    point: {
      borderWidth: 0,
    },
  },
};

const pieOptions: ChartOptions<'pie'> = {
  responsive: true,
  maintainAspectRatio: false,

  plugins: {
    legend: {
      position: 'left' as const,
      display: true,
      align: 'end',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 8,
        boxHeight: 8,
      },
    },
    title: {
      display: false,
    },
    datalabels: {
      formatter: (value, ctx) => {
        if (!value) {
          return '';
        }

        let sum = 0;
        const dataArr = ctx.chart.data.datasets[0].data as number[];
        dataArr.map((data) => {
          sum += data;
        });
        const percent = calcPercent(value, sum).toFixed(1);
        return `${value} (${percent}%)`;
      },
      color: '#fff',
    },
  },
};
const barOptions: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,

  plugins: {
    legend: {
      position: 'bottom' as const,
      display: true,
      align: 'center',
      labels: {
        boxWidth: 8,
        boxHeight: 8,
      },
    },
    title: {
      display: false,
    },
  },
  elements: {
    point: {
      borderWidth: 0,
    },
  },
};

function StatisticLineChart({
  maxY,
  labelY,
  labelX,
  data,
  aspectRatio,
}: {
  maxY: number;
  labelY: string[];
  labelX: string[];
  data: number[];
  aspectRatio?: number;
}) {
  return (
    <Line
      options={{
        ...lineOptions,
        aspectRatio,
        scales: {
          customBegin: {
            axis: 'y',
            beginAtZero: true,
            max: maxY,
            labels: labelY,
          },
          customX: {
            axis: 'x',
            labels: labelX,
          },
        },
      }}
      data={{
        datasets: [
          {
            fill: true,
            label: undefined,

            data: data,
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            yAxisID: 'customBegin',
            xAxisID: 'customX',
          },
        ],
      }}
    />
  );
}

function StatisticPieChart({
  labels,
  colors,
  data,
}: {
  labels: string[];
  colors: string[];
  data: number[];
}) {
  return (
    <Pie
      options={{
        ...pieOptions,
      }}
      data={{
        datasets: [
          {
            label: 'Tổng',
            data: data,
            backgroundColor: colors,
            borderWidth: 0,
          },
        ],
        labels,
      }}
      plugins={[ChartDataLabels as Plugin<'pie'>]}
    />
  );
}

function calcPercent(value: number, sum: number) {
  return (value * 100) / sum;
}

export function DashboardCtn() {
  const { formatDate } = useTime();
  const { getAirlineNameByCode } = useAirlines();
  const { formatPrice, formatCurrency } = useCurrency();
  const {
    filterMethods,
    isLoading,
    reportBookingData,
    onSubmit,
    saveFilter,
    reportBookingDataByDate,
    reportAirlineBookingData,
    reportCommissionStatsData,
  } = useDashboard();

  const {
    formState: { isValid, isSubmitting },
  } = filterMethods;

  const { startDate: fromDate, endDate: toDate } =
    filterMethods.watch('filterDate');

  const listLabelDate = useMemo<string[]>(() => {
    //
    if (!saveFilter.from || !saveFilter.to) {
      return [formatDate(saveFilter.from || saveFilter.to || Date.now())];
    }

    const listLabel: string[] = [];
    let currentTime = saveFilter.from;
    while (currentTime <= saveFilter.to) {
      listLabel.push(formatDate(currentTime));
      currentTime += ONE_DAY_TIMESTAMP;
    }

    return listLabel;
  }, [saveFilter]);

  const calcData = useMemo(() => {
    return reportBookingData.reduce(
      (obj, item) => {
        if (item.totalBooking > obj.maxBooking) {
          obj.maxBooking = item.totalBooking;
        }

        if (item.totalIssueBooking > obj.maxIssueBooking) {
          obj.maxIssueBooking = item.totalIssueBooking;
        }

        if (item.totalTicket > obj.maxTicket) {
          obj.maxTicket = item.totalTicket;
        }

        obj.totalRevenue += item.revenue;
        obj.totalProfit += item.profit;
        obj.totalIssueBooking += item.totalIssueBooking;

        obj.totalCountBookingOneWay += item.countBookingOneWay;
        obj.totalCountBookingRoundTrip += item.countBookingRoundTrip;
        obj.totalCountBookingMultiLegs += item.countBookingMultiLegs;

        obj.totalCountTicketOneWay += item.countTicketOneWay;
        obj.totalCountTicketRoundTrip += item.countTicketRoundTrip;
        obj.totalCountTicketMultiLegs += item.countTicketMultiLegs;

        obj.totalCountBooking =
          obj.totalCountBookingOneWay +
          obj.totalCountBookingRoundTrip +
          obj.totalCountBookingMultiLegs;

        obj.totalCountTicket =
          obj.totalCountTicketOneWay +
          obj.totalCountTicketRoundTrip +
          obj.totalCountTicketMultiLegs;
        return obj;
      },
      {
        maxIssueBooking: 0,
        totalRevenue: 0,
        totalRevenueIssueBooking: 0,
        totalProfit: 0,
        totalIssueBooking: 0,

        maxBooking: 0,
        totalCountBooking: 0,
        totalCountBookingOneWay: 0,
        totalCountBookingRoundTrip: 0,
        totalCountBookingMultiLegs: 0,

        maxTicket: 0,
        totalCountTicket: 0,
        totalCountTicketOneWay: 0,
        totalCountTicketRoundTrip: 0,
        totalCountTicketMultiLegs: 0,
      }
    );
  }, [reportBookingData]);

  const calcAirlineData = useMemo(() => {
    let totalBooking = 0;
    let totalIssueBooking = 0;
    let totalTicket = 0;
    const resultAirlineData: Omit<IReportAirlineBookingItem, 'date'>[] = [];

    const listAirlineCode = [
      ...new Set(reportAirlineBookingData.map((item) => item.airline)),
    ];

    listAirlineCode.forEach((airlineCode) => {
      const sumReportByAirline = reportAirlineBookingData.reduce<
        Pick<
          IReportAirlineBookingItem,
          'totalBooking' | 'totalIssueBooking' | 'totalTicket'
        >
      >(
        (arr, item) => {
          if (item.airline === airlineCode) {
            return {
              totalBooking: arr.totalBooking + item.totalBooking,
              totalIssueBooking: arr.totalIssueBooking + item.totalIssueBooking,
              totalTicket: arr.totalTicket + item.totalTicket,
            };
          }
          return arr;
        },
        {
          totalBooking: 0,
          totalIssueBooking: 0,
          totalTicket: 0,
        }
      );

      totalBooking += sumReportByAirline.totalBooking;
      totalIssueBooking += sumReportByAirline.totalIssueBooking;
      totalTicket += sumReportByAirline.totalTicket;
      resultAirlineData.push({
        airline: airlineCode,
        ...sumReportByAirline,
      });
    });

    return {
      totalBooking,
      totalIssueBooking,
      totalTicket,
      airlineReportList: resultAirlineData,
    };
  }, [reportAirlineBookingData]);

  return (
    <div className='space-y-5'>
      <div className='py-5 flex justify-between'>
        <Form
          methods={filterMethods}
          onSubmit={onSubmit}
          className='flex justify-between w-full'>
          <div className='flex space-x-2.5'>
            <FormInputDateFromTo
              className='w-[250px]'
              inputProps={{
                placeholderStart: 'Từ ngày',
                placeholderEnd: 'Đến ngày',
                showFilter: true,
                inputSize: 'sm',
                minStartDate: toDate
                  ? subDay(toDate, MAX_FILTER_DAY)
                  : undefined,
                maxStartDate: new Date(),
                maxEndDate: fromDate
                  ? addDay(fromDate, MAX_FILTER_DAY) < new Date()
                    ? addDay(fromDate, MAX_FILTER_DAY)
                    : new Date()
                  : new Date(),
                isFullTime: true,
              }}
              label='Thời gian'
              name='filterDate'
              rules={{
                validate: {
                  hasFromTo: (v: TFromToDate) =>
                    (!!v.startDate && !!v.endDate) || '',
                },
              }}
              isShowError={false}
            />

            <div className='flex flex-col justify-end'>
              <ButtonLoading
                type='submit'
                isShowChildWhenLoading
                loading={isSubmitting}
                prefixIcon={<SearchLineIcon />}
                className='font-semibold w-[125px] px-4'
                isDisabled={!isValid}
                loadingSize='sm'
                loadingProps={{
                  className: '!border-t-white',
                }}>
                Tìm kiếm
              </ButtonLoading>
            </div>
          </div>
        </Form>
      </div>
      <div className='divide-y'></div>
      <div className='space-y-7'>
        <div className='grid grid-cols-2 lg:grid-cols-5 gap-3 w-full'>
          <StatisticCardInfo
            className='border-none bg-white'
            title='Đơn hàng thành công'
            value={
              <span className='text-green-6'>
                {formatPrice(calcData.totalIssueBooking)}
              </span>
            }
            iconKey='booking'
          />
          <StatisticCardInfo
            className='border-none bg-white'
            title='Tổng tiền'
            value={
              <span className='text-secondary'>
                {formatCurrency(calcData.totalRevenue)}
              </span>
            }
            iconKey='price'
          />
          {/* <StatisticCardInfo
            className='border-none bg-white'
            title='Tổng hoa hồng'
            value={
              <span className='text-green-6'>
                {formatCurrency(reportCommissionStatsData.totalCommission)}
              </span>
            }
            iconKey='price'
          />
          <StatisticCardInfo
            className='border-none bg-white'
            title='Phí dịch vụ'
            value={
              <span className='text-secondary'>
                {formatCurrency(reportCommissionStatsData.serviceFee)}
              </span>
            }
            iconKey='price'
          />
          <StatisticCardInfo
            className='border-none bg-white'
            title='Lợi nhuận'
            value={
              <span className='text-primary'>
                {formatCurrency(reportCommissionStatsData.profit)}
              </span>
            }
            iconKey='price'
          /> */}
        </div>
        <div>
          <div className='font-semibold text-neutral-8 uppercase mb-3'>
            lưu lượng đặt chỗ
          </div>
          <div className=''>
            <div
              style={{
                gridTemplateColumns: 'repeat(3, minmax(0, 1fr)) ',
                gridTemplateRows: 'minmax(0, 360px)',
              }}
              className='grid grid-cols-3 gap-x-3 '>
              <StatisticCardBox
                title='Đặt chỗ theo ngày'
                className='shrink-0 border-none bg-white'>
                <BackdropLoading show={isLoading} className='h-full !z-0'>
                  <StatisticLineChart
                    maxY={calcData.maxBooking + 2}
                    data={listLabelDate.map(
                      (dateString) =>
                        reportBookingDataByDate?.[dateString]?.totalBooking || 0
                    )}
                    labelX={listLabelDate}
                    labelY={reportBookingData.map(
                      (item) => `${item.totalBooking}`
                    )}
                  />
                </BackdropLoading>
              </StatisticCardBox>
              <StatisticCardBox
                title='Loại hành trình'
                className='shrink-0 border-none bg-white'>
                <BackdropLoading
                  show={isLoading}
                  className='flex justify-center h-full !z-0'>
                  <StatisticPieChart
                    data={[
                      calcData.totalCountBookingOneWay,
                      calcData.totalCountBookingRoundTrip,
                      calcData.totalCountBookingMultiLegs,
                    ]}
                    colors={['#4285F4', '#EA4236', '#FCBC00']}
                    labels={['Một chiều', 'Khứ hồi', 'Đa chặng']}
                  />
                </BackdropLoading>
              </StatisticCardBox>
              <StatisticCardBox
                title='Hãng hàng không'
                className='w-full border-none bg-white'>
                <BackdropLoading
                  show={isLoading}
                  className='flex justify-center relative !z-0'>
                  <table className='w-full view-scroll-table table-auto h-full '>
                    <thead className='text-neutral-8 font-semibold'>
                      <tr>
                        <th align='left' colSpan={2}>
                          Hãng hàng không
                        </th>
                        <th align='center'>Số booking</th>
                        <th align='right'>Tỉ lệ</th>
                      </tr>
                    </thead>

                    <tbody className='font-semibold text-neutral-10 h-full  overflow-y-auto scroll-w-sm max-h-[235px]'>
                      {calcAirlineData.airlineReportList?.length ? (
                        calcAirlineData.airlineReportList
                          ?.sort((a, b) => b.totalBooking - a.totalBooking)
                          .map(
                            (item, index) =>
                              item.totalBooking > 0 && (
                                <tr key={index}>
                                  <td align='left' colSpan={2}>
                                    {getAirlineNameByCode(item.airline)}
                                  </td>
                                  <td align='center'>{item.totalBooking}</td>

                                  <td align='right'>
                                    {calcPercent(
                                      item.totalBooking,
                                      calcAirlineData.totalBooking
                                    ).toFixed(1)}
                                    %
                                  </td>
                                </tr>
                              )
                          )
                      ) : (
                        <tr>
                          <td className='not-found'>No data</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </BackdropLoading>
              </StatisticCardBox>
            </div>
          </div>
        </div>
        <div>
          <div className='font-semibold text-neutral-8 uppercase mb-3'>
            lưu lượng xuất vé
          </div>
          <div
            style={{
              gridTemplateRows: 'repeat(2, minmax(0, 360px))',
            }}
            className='grid grid-cols-2 grid-rows-2 gap-3'>
            <StatisticCardBox
              title='Xuất vé theo ngày '
              className='border-none bg-white'>
              <BackdropLoading show={isLoading} className='h-full !z-0'>
                <StatisticLineChart
                  maxY={calcData.maxTicket + 2}
                  data={listLabelDate.map(
                    (dateString) =>
                      reportBookingDataByDate?.[dateString]?.totalTicket || 0
                  )}
                  labelX={listLabelDate}
                  labelY={reportBookingData.map(
                    (item) => `${item.totalTicket}`
                  )}
                />
              </BackdropLoading>
            </StatisticCardBox>
            <StatisticCardBox
              title='Loại hành trình'
              className='border-none bg-white'>
              <BackdropLoading
                show={isLoading}
                className='flex justify-center h-full !z-0'>
                <StatisticPieChart
                  data={[
                    calcData.totalCountTicketOneWay,
                    calcData.totalCountTicketRoundTrip,
                    calcData.totalCountTicketMultiLegs,
                  ]}
                  colors={['#4285F4', '#EA4236', '#FCBC00']}
                  labels={['Một chiều', 'Khứ hồi', 'Đa chặng']}
                />
              </BackdropLoading>
            </StatisticCardBox>
            <StatisticCardBox
              title='Tỉ lệ xuất vé thành công'
              className='w-full border-none bg-white'>
              <BackdropLoading show={isLoading} className='h-full !z-0'>
                <Bar
                  options={{
                    ...barOptions,
                    scales: {
                      x: {
                        display: false,
                        stacked: true,
                      },
                      y: {
                        beginAtZero: true,
                        // max:
                        //   calcData.totalCountBooking +
                        //   calcData.totalIssueBooking,
                        max: calcData.totalCountBooking,
                        min: 0,
                        stacked: true,
                        ticks: {
                          stepSize: calcData.totalCountBooking / 4,
                          callback: function (value) {
                            const sum = calcData.totalCountBooking;
                            if (!sum) return '';
                            return (Number(value) / sum) * 100 + '%';
                          },

                          // min: 0,
                        },
                      },
                    },
                  }}
                  data={{
                    labels: [''],
                    datasets: [
                      {
                        label: 'Đặt chỗ xuất vé thành công',
                        data: [calcData.totalIssueBooking],
                        backgroundColor: '#4285F4',
                        datalabels: {
                          anchor: 'center',
                          align: 'center',
                        },
                        borderWidth: 0,
                      },
                      // {
                      //   label: 'Tổng số booking',
                      //   data: [calcData.totalCountBooking],

                      //   backgroundColor: '#EA4236',
                      //   datalabels: {
                      //     align: 'center',
                      //     anchor: 'center',
                      //   },
                      //   borderWidth: 0,
                      // },
                    ],
                  }}
                />
              </BackdropLoading>
            </StatisticCardBox>
            <StatisticCardBox
              title='Hãng hàng không'
              className='w-full border-none bg-white'>
              <BackdropLoading
                show={isLoading}
                className='flex justify-center !z-0'>
                <table
                  // className='w-full view-scroll-table table-auto'
                  className='w-full view-scroll-table table-auto h-full '>
                  <thead className='text-neutral-8 font-semibold'>
                    <tr>
                      <th align='left'>Hãng hàng không</th>
                      <th align='center'>Số vé xuất</th>
                      <th align='right'>Tỉ lệ</th>
                    </tr>
                  </thead>
                  <tbody className='font-semibold text-neutral-10 overflow-y-auto scroll-w-sm max-h-[235px]'>
                    {calcAirlineData.airlineReportList.length ? (
                      calcAirlineData.airlineReportList
                        ?.sort((a, b) => b.totalTicket - a.totalTicket)
                        .map(
                          (item, index) =>
                            item.totalTicket > 0 && (
                              <tr key={index}>
                                <td align='left'>
                                  {getAirlineNameByCode(item.airline)}
                                </td>
                                <td align='center'>{item.totalTicket}</td>

                                <td align='right'>
                                  {calcPercent(
                                    item.totalTicket,
                                    calcAirlineData.totalTicket
                                  ).toFixed(1)}
                                  %
                                </td>
                              </tr>
                            )
                        )
                    ) : (
                      <tr>
                        <td className='not-found'>No data</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </BackdropLoading>
            </StatisticCardBox>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCtn;
