/**
 * @fileoverview gRPC-Web generated client stub for airplane.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: airplane/web_partnership/config.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.airplane = {};
proto.airplane.web_partnership = require('./config_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partnership.ConfigServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partnership.ConfigServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.UpdateConfigReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_ConfigService_UpdateConfig = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ConfigService/UpdateConfig',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.UpdateConfigReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partnership.UpdateConfigReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.UpdateConfigReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ConfigServiceClient.prototype.updateConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ConfigService/UpdateConfig',
      request,
      metadata || {},
      methodDescriptor_ConfigService_UpdateConfig,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.UpdateConfigReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ConfigServicePromiseClient.prototype.updateConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ConfigService/UpdateConfig',
      request,
      metadata || {},
      methodDescriptor_ConfigService_UpdateConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.UpdateConfigByOfficeIDReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_ConfigService_UpdateConfigByOfficeID = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ConfigService/UpdateConfigByOfficeID',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.UpdateConfigByOfficeIDReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ConfigServiceClient.prototype.updateConfigByOfficeID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ConfigService/UpdateConfigByOfficeID',
      request,
      metadata || {},
      methodDescriptor_ConfigService_UpdateConfigByOfficeID,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.UpdateConfigByOfficeIDReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ConfigServicePromiseClient.prototype.updateConfigByOfficeID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ConfigService/UpdateConfigByOfficeID',
      request,
      metadata || {},
      methodDescriptor_ConfigService_UpdateConfigByOfficeID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.base.Empty,
 *   !proto.airplane.web_partnership.GetConfigRes>}
 */
const methodDescriptor_ConfigService_GetConfig = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ConfigService/GetConfig',
  grpc.web.MethodType.UNARY,
  base_base_pb.Empty,
  proto.airplane.web_partnership.GetConfigRes,
  /**
   * @param {!proto.base.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.GetConfigRes.deserializeBinary
);


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.GetConfigRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.GetConfigRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ConfigServiceClient.prototype.getConfig =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ConfigService/GetConfig',
      request,
      metadata || {},
      methodDescriptor_ConfigService_GetConfig,
      callback);
};


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.GetConfigRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ConfigServicePromiseClient.prototype.getConfig =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ConfigService/GetConfig',
      request,
      metadata || {},
      methodDescriptor_ConfigService_GetConfig);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.GetConfigByOfficeIDReq,
 *   !proto.airplane.web_partnership.GetConfigRes>}
 */
const methodDescriptor_ConfigService_GetConfigByOfficeID = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ConfigService/GetConfigByOfficeID',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.GetConfigByOfficeIDReq,
  proto.airplane.web_partnership.GetConfigRes,
  /**
   * @param {!proto.airplane.web_partnership.GetConfigByOfficeIDReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.GetConfigRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.GetConfigByOfficeIDReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.GetConfigRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.GetConfigRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ConfigServiceClient.prototype.getConfigByOfficeID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ConfigService/GetConfigByOfficeID',
      request,
      metadata || {},
      methodDescriptor_ConfigService_GetConfigByOfficeID,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.GetConfigByOfficeIDReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.GetConfigRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ConfigServicePromiseClient.prototype.getConfigByOfficeID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ConfigService/GetConfigByOfficeID',
      request,
      metadata || {},
      methodDescriptor_ConfigService_GetConfigByOfficeID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.base.Empty,
 *   !proto.airplane.web_partnership.GetListOfficeRes>}
 */
const methodDescriptor_ConfigService_GetListOffice = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ConfigService/GetListOffice',
  grpc.web.MethodType.UNARY,
  base_base_pb.Empty,
  proto.airplane.web_partnership.GetListOfficeRes,
  /**
   * @param {!proto.base.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.GetListOfficeRes.deserializeBinary
);


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.GetListOfficeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.GetListOfficeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ConfigServiceClient.prototype.getListOffice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ConfigService/GetListOffice',
      request,
      metadata || {},
      methodDescriptor_ConfigService_GetListOffice,
      callback);
};


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.GetListOfficeRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ConfigServicePromiseClient.prototype.getListOffice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ConfigService/GetListOffice',
      request,
      metadata || {},
      methodDescriptor_ConfigService_GetListOffice);
};


module.exports = proto.airplane.web_partnership;

