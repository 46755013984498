/**
 * @fileoverview gRPC-Web generated client stub for skyhub.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: skyhub/web_partnership/ev_booking.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var base_enum_pb = require('../../base/enum_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.skyhub = {};
proto.skyhub.web_partnership = require('./ev_booking_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.skyhub.web_partnership.EVBookingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.skyhub.web_partnership.EVBookingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.GetBookingsReq,
 *   !proto.skyhub.web_partnership.GetBookingsRes>}
 */
const methodDescriptor_EVBookingService_GetBookings = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.EVBookingService/GetBookings',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.GetBookingsReq,
  proto.skyhub.web_partnership.GetBookingsRes,
  /**
   * @param {!proto.skyhub.web_partnership.GetBookingsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.GetBookingsRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.GetBookingsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.GetBookingsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.GetBookingsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.EVBookingServiceClient.prototype.getBookings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.EVBookingService/GetBookings',
      request,
      metadata || {},
      methodDescriptor_EVBookingService_GetBookings,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.GetBookingsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.GetBookingsRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.EVBookingServicePromiseClient.prototype.getBookings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.EVBookingService/GetBookings',
      request,
      metadata || {},
      methodDescriptor_EVBookingService_GetBookings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.GetBookingByCodeReq,
 *   !proto.skyhub.web_partnership.GetBookingByCodeRes>}
 */
const methodDescriptor_EVBookingService_GetBookingByCode = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.EVBookingService/GetBookingByCode',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.GetBookingByCodeReq,
  proto.skyhub.web_partnership.GetBookingByCodeRes,
  /**
   * @param {!proto.skyhub.web_partnership.GetBookingByCodeReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.GetBookingByCodeRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.GetBookingByCodeReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.GetBookingByCodeRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.GetBookingByCodeRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.EVBookingServiceClient.prototype.getBookingByCode =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.EVBookingService/GetBookingByCode',
      request,
      metadata || {},
      methodDescriptor_EVBookingService_GetBookingByCode,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.GetBookingByCodeReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.GetBookingByCodeRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.EVBookingServicePromiseClient.prototype.getBookingByCode =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.EVBookingService/GetBookingByCode',
      request,
      metadata || {},
      methodDescriptor_EVBookingService_GetBookingByCode);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.UpdateBookingReq,
 *   !proto.skyhub.web_partnership.UpdateBookingRes>}
 */
const methodDescriptor_EVBookingService_UpdateBooking = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.EVBookingService/UpdateBooking',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.UpdateBookingReq,
  proto.skyhub.web_partnership.UpdateBookingRes,
  /**
   * @param {!proto.skyhub.web_partnership.UpdateBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.UpdateBookingRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.UpdateBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.UpdateBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.UpdateBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.EVBookingServiceClient.prototype.updateBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.EVBookingService/UpdateBooking',
      request,
      metadata || {},
      methodDescriptor_EVBookingService_UpdateBooking,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.UpdateBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.UpdateBookingRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.EVBookingServicePromiseClient.prototype.updateBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.EVBookingService/UpdateBooking',
      request,
      metadata || {},
      methodDescriptor_EVBookingService_UpdateBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.skyhub.web_partnership.CancelBookingReq,
 *   !proto.skyhub.web_partnership.CancelBookingRes>}
 */
const methodDescriptor_EVBookingService_CancelBooking = new grpc.web.MethodDescriptor(
  '/skyhub.web_partnership.EVBookingService/CancelBooking',
  grpc.web.MethodType.UNARY,
  proto.skyhub.web_partnership.CancelBookingReq,
  proto.skyhub.web_partnership.CancelBookingRes,
  /**
   * @param {!proto.skyhub.web_partnership.CancelBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.skyhub.web_partnership.CancelBookingRes.deserializeBinary
);


/**
 * @param {!proto.skyhub.web_partnership.CancelBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.skyhub.web_partnership.CancelBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.skyhub.web_partnership.CancelBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.skyhub.web_partnership.EVBookingServiceClient.prototype.cancelBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/skyhub.web_partnership.EVBookingService/CancelBooking',
      request,
      metadata || {},
      methodDescriptor_EVBookingService_CancelBooking,
      callback);
};


/**
 * @param {!proto.skyhub.web_partnership.CancelBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.skyhub.web_partnership.CancelBookingRes>}
 *     Promise that resolves to the response
 */
proto.skyhub.web_partnership.EVBookingServicePromiseClient.prototype.cancelBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/skyhub.web_partnership.EVBookingService/CancelBooking',
      request,
      metadata || {},
      methodDescriptor_EVBookingService_CancelBooking);
};


module.exports = proto.skyhub.web_partnership;

