import { IPaginationRes } from '@tixlabs/grpc-client';
import { ICrossCheckItem } from '@tixlabs/grpc-client/web-partnership';
import { useCurrency, useTime } from '@vemaybay-admin/hooks/internals';
import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import cn from 'classnames';
import { Link } from 'react-router-dom';

type Props = {
  listCrossCheckItem: ICrossCheckItem[];
  isLoading: boolean;
  pagination?: IPaginationRes;
  isExistPagination?: boolean;
  onPageChange?: (page: number) => void;
};

const TitleColumns = ({
  title,
  subTitle,
  className,
}: {
  title: string;
  subTitle: string;
  className?: string;
}) => {
  return (
    <div className={cn('flex flex-col gap-1', className)}>
      <span className='text-neutral-gray-60'>{title}</span>
      <span className='text-neutral-gray-60'>{subTitle}</span>
    </div>
  );
};

const TableContent = ({
  isLoading,
  listCrossCheckItem,
  pagination,
  isExistPagination = true,
  onPageChange,
}: Props) => {
  const { formatMonthYear } = useTime();
  const { formatPrice } = useCurrency();

  const columns: ColumnProps<ICrossCheckItem>[] = [
    {
      title: () => {
        return <TitleColumns title='Kỳ đối soát' subTitle='(1)' />;
      },
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, crossCheckItem) => {
        return (
          <Link to={`../profit?filterDate=${crossCheckItem.date}`}>
            {formatMonthYear(crossCheckItem.date)}
          </Link>
        );
      },
    },
    {
      title: () => {
        return <TitleColumns title='Tổng phí dịch vụ' subTitle='(2)' />;
      },
      dataIndex: 'serviceFee',
      key: 'serviceFee',
      render: (_, crossCheckItem) => {
        return <span>{formatPrice(crossCheckItem.serviceFee)}</span>;
      },
    },
    {
      title: () => {
        return (
          <TitleColumns title='Tổng Hoa hồng đã xác nhận' subTitle='(3)' />
        );
      },
      dataIndex: 'approved',
      key: 'approved',
      render: (_, crossCheckItem) => {
        return <span>{formatPrice(crossCheckItem.approved)}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Tổng lợi nhuận' subTitle='(4)= (2)+(3)' />;
      },
      dataIndex: 'profit',
      key: 'profit',
      render: (_, crossCheckItem) => {
        return <span>{formatPrice(crossCheckItem.profit)}</span>;
      },
    },
  ];

  return (
    <div className='p-2.5 bg-white rounded-lg'>
      <Table
        dataSource={listCrossCheckItem.map((order, index) => {
          return {
            ...order,
            key: index,
          };
        })}
        columns={columns}
        size='small'
        rowClassName={(record, index) => {
          return cn('font-semibold whitespace-nowrap', {
            'bg-[#F8F8FB]': index % 2 === 0,
          });
        }}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: () => {
        //       navigate(`./${record.bookingCode}`);
        //     },
        //   };
        // }}
        scroll={{ x: 1000 }}
        pagination={
          isExistPagination
            ? {
                pageSize: pagination?.pageLimit || 1,
                current: pagination?.pageCurrent || 1,
                total: pagination?.totalRecord || 1,
                className: 'space-x-2.5',
                onChange: onPageChange,
              }
            : false
        }
        loading={isLoading}
      />
    </div>
  );
};

export default TableContent;
