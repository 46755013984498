import {
  IBookingDetail,
  IRetrieveBookingFlightData,
} from '@tixlabs/grpc-client/web-partnership';

import { IPaginationRes } from '@tixlabs/grpc-client';
import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Button, PencilFillIcon } from '@common-ui';
import { getUserName } from '@core/utils';
import { Status } from './Status';
import { BookingStatus } from '@api/hotel/base_pb';
import { useCurrency, useTime } from '@vemaybay-admin/hooks/internals';

type Props = {
  listOrder: IBookingDetail[];
  isLoading: boolean;
  pagination?: IPaginationRes;
  isExistPagination?: boolean;
  onPageChange?: (page: number) => void;
};

const TitleColumns = ({ title }: { title: string }) => {
  return <span className='text-[#333333]'>{title}</span>;
};

const TableContent = ({
  isLoading,
  listOrder,
  pagination,
  isExistPagination = true,
  onPageChange,
}: Props) => {
  const navigate = useNavigate();
  const { formatHourDateTime } = useTime();
  const { formatPrice } = useCurrency();

  const columns: ColumnProps<any>[] = [
    {
      title: () => {
        return <TitleColumns title='Ngày giờ đặt' />;
      },
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => {
        return record.createdAt ? (
          <span>{formatHourDateTime(record.createdAt)}</span>
        ) : (
          ''
        );
      },
    },
    {
      title: () => {
        return <TitleColumns title='Mã đơn hàng' />;
      },
      dataIndex: 'orderCode',
      key: 'orderCode',
      render: (value: string) => {
        return value ? (
          <span className='font-semibold text-primary-50'>{value}</span>
        ) : (
          ''
        );
      },
    },
    {
      title: () => {
        return <TitleColumns title='Mã đặt chỗ' />;
      },
      dataIndex: 'reservationCode',
      key: 'reservationCode',
      render: (_, record: IBookingDetail) => {
        const roomsToShow = record.roomsList.slice(0, 2); // Lấy 2 phần tử đầu tiên
        const isMoreThanTwo = record.roomsList.length > 2;
        const isMoreThanOrEqualTwo = record.roomsList.length >= 2;

        return (
          <div>
            {roomsToShow.map((room, i) => (
              <div key={i} className='text-primary-50'>
                {room.confirmationId}
                {room.confirmationId && i === 0 && isMoreThanOrEqualTwo && ','}
                {room.confirmationId && i === 1 && isMoreThanTwo && ', ...'}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: () => {
        return <TitleColumns title='Khách hàng' />;
      },
      dataIndex: 'contactInfo',
      key: 'contactInfo',
      render: (contactInfo: IRetrieveBookingFlightData['contactInfo']) => {
        return getUserName({
          firstName: contactInfo?.firstName || '',
          lastName: contactInfo?.lastName || '',
        });
      },
      className: 'min-w-[120px]',
    },
    {
      title: () => {
        return <TitleColumns title='Số điện thoại' />;
      },
      dataIndex: 'contactInfo',
      key: 'contactInfo',
      render: (_, record: IBookingDetail) => {
        return (
          <span>
            {record?.roomsList[0]?.holder?.phoneCode &&
            record?.roomsList[0]?.holder?.phoneNumber
              ? `(${record?.roomsList[0]?.holder?.phoneCode}) ${record?.roomsList[0]?.holder?.phoneNumber}`
              : ''}
          </span>
        );
      },
    },
    {
      title: () => {
        return <TitleColumns title='Email' />;
      },
      dataIndex: 'email',
      key: 'email',
      render: (_, record: IBookingDetail) => {
        return (
          <span>
            {record?.roomsList.length > 0 && record?.roomsList[0]?.holder
              ? `${record?.roomsList[0]?.holder?.email}`
              : ''}
          </span>
        );
      },
      className: 'min-w-[130px]',
    },
    {
      title: () => {
        return <TitleColumns title='Tổng tiền' />;
      },
      dataIndex: 'fee',
      key: 'fee',
      render: (_, record) => {
        return <span>{formatPrice(record?.priceInfo?.totalPrice || 0)}đ</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Trạng thái' />;
      },
      dataIndex: 'status',
      key: 'status',
      render: (status: BookingStatus) => {
        return <Status status={status} className='' classNameText='' />;
      },
    },
    {
      dataIndex: 'orderCode',
      key: 'orderCode',
      render: (orderCode: BookingStatus) => {
        return (
          <Button
            onClick={() => {
              navigate(`/order-management-hotel/${orderCode}`);
            }}
            prefixIcon={<PencilFillIcon />}
            variant='ghost'>
            Chi tiết
          </Button>
        );
      },
      className: 'min-w-[150px]',
    },
  ];

  return (
    <Table
      dataSource={listOrder}
      columns={columns}
      size='small'
      rowClassName={(record, index) => {
        return cn('font-semibold whitespace-nowrap', {
          'bg-[#F8F8FB]': index % 2 === 0,
        });
      }}
      // onRow={(record, rowIndex) => {
      //   return {
      //     onClick: () => {
      //       navigate(`./${record.bookingCode}`);
      //     },
      //   };
      // }}
      scroll={{ x: 1000 }}
      pagination={
        isExistPagination
          ? {
              pageSize: pagination?.pageLimit || 1,
              current: pagination?.pageCurrent || 1,
              total: pagination?.totalRecord || 1,
              className: 'space-x-2.5',
              onChange: onPageChange,
            }
          : false
      }
      loading={isLoading}
    />
  );
};

export default TableContent;
