import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import { NotificationServiceClient as SkyhubNotificationServiceClient } from '@api/skyhub/web_partnership/notification_grpc_web_pb';
import { NotificationServiceClient } from '@api/notification/web_partnership/notification_grpc_web_pb';
import {
  FileAttachment,
  GetHistoryBookingNotificationsReq,
  GetHistoryBookingNotificationsRes,
  SendRawBookingNotificationReq,
} from '@api/skyhub/web_partnership/notification_pb';
import { BasicRes } from '@api/base/base_pb';

import {
  GetSendersReq,
  GetSendersRes,
} from '@api/notification/web_partnership/notification_pb';

class SkyHubNotificationServiceApi extends BaseApi<SkyhubNotificationServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new SkyhubNotificationServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  getHistoryBookingNotifications = ({
    bookingCode,
  }: GetHistoryBookingNotificationsReq.AsObject): Promise<GetHistoryBookingNotificationsRes.AsObject> => {
    const req = new GetHistoryBookingNotificationsReq();
    req.setBookingCode(bookingCode);

    return this.grpc<
      GetHistoryBookingNotificationsReq,
      GetHistoryBookingNotificationsRes,
      GetHistoryBookingNotificationsRes.AsObject
    >(this.serviceClient.getHistoryBookingNotifications, req, {});
  };

  sendRawBookingNotification = ({
    body,
    senderId,
    bookingCode,
    title,
    method,
    recipientsList,
    attachmentsList,
  }: SendRawBookingNotificationReq.AsObject): Promise<BasicRes.AsObject> => {
    const req = new SendRawBookingNotificationReq();
    console.log('attachmentsList', attachmentsList);

    req.setBody(body);
    req.setSenderId(senderId);
    req.setBookingCode(bookingCode);
    req.setTitle(title);
    req.setMethod(method);
    req.setRecipientsList(recipientsList);

    // req.setAttachmentsList(attachmentsList)

    const attachmentsListObj: FileAttachment[] = [];

    for (let i = 0; i < attachmentsList?.length; i++) {
      const attachmentsListItem = new FileAttachment();

      attachmentsListItem.setName(attachmentsList[i].name);
      attachmentsListItem.setUrl(attachmentsList[i].url);

      attachmentsListObj.push(attachmentsListItem);
    }
    console.log('attachmentsListObj', attachmentsListObj);

    req.setAttachmentsList(attachmentsListObj);

    return this.grpc<
      SendRawBookingNotificationReq,
      BasicRes,
      BasicRes.AsObject
    >(this.serviceClient.sendRawBookingNotification, req, {});
  };
}

export const skyHubNotificationServiceApi = new SkyHubNotificationServiceApi();

class NotificationServiceApi extends BaseApi<NotificationServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new NotificationServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  getSenders = ({
    method,
  }: GetSendersReq.AsObject): Promise<GetSendersRes.AsObject> => {
    const req = new GetSendersReq();
    req.setMethod(method);

    return this.grpc<GetSendersReq, GetSendersRes, GetSendersRes.AsObject>(
      this.serviceClient.getSenders,
      req,
      {}
    );
  };
}

export const notificationServiceApi = new NotificationServiceApi();
