import { grpcErrorObservable } from '@core/grpc-client/grpc-observers';
import { IObservableData } from '@core/utils';
import { LANGUAGES_CODE } from '@tixlabs/types';
import { useAppLanguage, useToast } from '@vemaybay-admin/hooks/internals';
import { useAppDispatch } from '@vemaybay-admin/stores';
import { logout } from '@vemaybay-admin/stores/reducers/user';
import type * as grpcWeb from 'grpc-web';
import debounce from 'lodash.debounce';
import { PropsWithChildren, useCallback, useEffect } from 'react';
import { useCities, useErrorMessage } from '../../hooks/apps';
import { useAirlines } from '../../hooks/stores';

type Props = {
  //
};

export function AppLogicProvider({ children }: PropsWithChildren<Props>) {
  const { changeLanguage } = useAppLanguage();

  useAirlines();
  useCities();
  useErrorMessage();
  const dispatch = useAppDispatch();
  const { warning } = useToast();

  function handleInvalidToken(message?: string) {
    dispatch(logout())
      .unwrap()
      .then(() => {
        warning(message || 'Phiên làm việc hết hiệu lực');
      });
  }

  const handleGrpcError = useCallback(
    (data: IObservableData<grpcWeb.RpcError>) => {
      if (data.data) {
        const { message } = data.data;

        switch (message) {
          case 'INVALID_TOKEN':
            handleInvalidToken();
            break;
          case 'PERMISSION_DENIED':
            handleInvalidToken('PERMISSION DENIED');
            break;

          default:
            break;
        }
      }
    },
    []
  );

  const handleGrpcErrorDebounce = debounce(handleGrpcError, 300);
  useEffect(() => {
    changeLanguage(LANGUAGES_CODE.VIETNAM);
    grpcErrorObservable.subscribe(handleGrpcErrorDebounce);
    return () => {
      grpcErrorObservable.unsubscribe(handleGrpcErrorDebounce);
    };
  }, []);

  return <>{children}</>;
}

export default AppLogicProvider;
