import { CustomerServiceClient } from '@api/airplane/web_partnership/customer_grpc_web_pb';
import {
  SearchCustomerReq,
  SearchCustomerRes,
} from '@api/airplane/web_partnership/customer_pb';

import { PaginationReq } from '@api/base/base_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class CustomerApi extends BaseApi<CustomerServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);

    this.serviceClient = new CustomerServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  search = ({
    filter,
    pagination,
  }: SearchCustomerReq.AsObject): Promise<SearchCustomerRes.AsObject> => {
    const req = new SearchCustomerReq();
    if (filter) {
      const filterObj = new SearchCustomerReq.Filter();
      filter.name && filterObj.setName(filter.name);
      filter.email && filterObj.setEmail(filter.email);
      filter.phone && filterObj.setPhone(filter.phone);
      req.setFilter(filterObj);
    }
    if (pagination) {
      const paginationObj = new PaginationReq();
      paginationObj.setPageLimit(pagination.pageLimit);
      paginationObj.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationObj);
    }
    return this.grpc<
      SearchCustomerReq,
      SearchCustomerRes,
      SearchCustomerRes.AsObject
    >(this.serviceClient.search, req, {});
  };
}

export const customerApiService = new CustomerApi();
export default customerApiService;
