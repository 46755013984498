import { useAppDispatch } from '@vemaybay-admin/stores';
import { checkAuthenticate } from '@vemaybay-admin/stores/reducers/user';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../stores';

export const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userData, isLogin, isFetching } = useUserStore();

  useEffect(() => {
    if (isLogin && !userData) {
      dispatch(checkAuthenticate());
    }
  }, []);

  useEffect(() => {
    if (!isFetching && !isLogin) {
      navigate('/auth/login');
    }
  }, [isFetching, isLogin, userData]);
};

export default useAuth;
