import { PaginationReq } from '@api/base/base_pb';
import { HotelServiceClient } from '@api/hotel/web_partnership/hotel_grpc_web_pb';
import {
  GetBookingDetailRequest,
  GetBookingDetailResponse,
  ListBookingsFilter,
  ListBookingsRequest,
  ListBookingsResponse,
  OccupancyUpdateInfo,
  UpdateBookingStatusRequest,
  UpdateBookingStatusResponse,
} from '@api/hotel/web_partnership/hotel_pb';

import { BaseApi, BaseApiOptions } from '@core/grpc-client';

export class HotelServiceApi extends BaseApi<HotelServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new HotelServiceClient(this.url, null, this.optsDev);
  }

  getBookingDetail = ({
    orderCode,
  }: GetBookingDetailRequest.AsObject): Promise<GetBookingDetailResponse.AsObject> => {
    const req = new GetBookingDetailRequest();
    req.setOrderCode(orderCode);
    return this.grpc<
      GetBookingDetailRequest,
      GetBookingDetailResponse,
      GetBookingDetailResponse.AsObject
    >(this.serviceClient.getBookingDetail, req, {});
  };

  listBookings = ({
    filter,
    pagination = {
      pageLimit: 100,
      pageNumber: 1,
    },
  }: ListBookingsRequest.AsObject): Promise<ListBookingsResponse.AsObject> => {
    const req = new ListBookingsRequest();
    const paginationObj = new PaginationReq();
    paginationObj.setPageLimit(pagination.pageLimit);
    paginationObj.setPageNumber(pagination.pageNumber);

    const listBookingsFilter = new ListBookingsFilter();
    if (filter) {
      listBookingsFilter.setStatusList(filter.statusList);
      listBookingsFilter.setOrderCode(filter.orderCode);
      listBookingsFilter.setFrom(filter.from);
      listBookingsFilter.setTo(filter.to);
      listBookingsFilter.setNotInStatusesList(filter.notInStatusesList);
      req.setFilter(listBookingsFilter);
    }

    req.setPagination(paginationObj);

    return this.grpc<
      ListBookingsRequest,
      ListBookingsResponse,
      ListBookingsResponse.AsObject
    >(this.serviceClient.listBookings, req, {});
  };

  updateBookingStatus = ({
    actualAmount,
    orderCode,
    cancelReason,
    status,
    occupancyUpdatesList,
    source,
  }: UpdateBookingStatusRequest.AsObject): Promise<UpdateBookingStatusResponse.AsObject> => {
    const req = new UpdateBookingStatusRequest();
    req.setActualAmount(actualAmount);
    req.setOrderCode(orderCode);
    req.setStatus(status);
    req.setCancelReason(cancelReason);
    req.setSource(source);

    const occupancyUpdatesListObj: OccupancyUpdateInfo[] = [];
    for (let i = 0; i < occupancyUpdatesList.length; i++) {
      const occupancyUpdatesListItem = new OccupancyUpdateInfo();
      occupancyUpdatesListItem.setOccupancyIndex(
        occupancyUpdatesList[i].occupancyIndex
      );
      occupancyUpdatesListItem.setConfirmationId(
        occupancyUpdatesList[i].confirmationId
      );
      occupancyUpdatesListObj.push(occupancyUpdatesListItem);
    }
    req.setOccupancyUpdatesList(occupancyUpdatesListObj);
    return this.grpc<
      UpdateBookingStatusRequest,
      UpdateBookingStatusResponse,
      UpdateBookingStatusResponse.AsObject
    >(this.serviceClient.updateBookingStatus, req, {});
  };
}

export const hotelApiService = new HotelServiceApi();
export default hotelApiService;
