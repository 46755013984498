/**
 * @fileoverview gRPC-Web generated client stub for notification.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: notification/web_partnership/notification.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var notification_enum_pb = require('../../notification/enum_pb.js')
const proto = {};
proto.notification = {};
proto.notification.web_partnership = require('./notification_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.notification.web_partnership.NotificationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.notification.web_partnership.NotificationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notification.web_partnership.GetSendersReq,
 *   !proto.notification.web_partnership.GetSendersRes>}
 */
const methodDescriptor_NotificationService_GetSenders = new grpc.web.MethodDescriptor(
  '/notification.web_partnership.NotificationService/GetSenders',
  grpc.web.MethodType.UNARY,
  proto.notification.web_partnership.GetSendersReq,
  proto.notification.web_partnership.GetSendersRes,
  /**
   * @param {!proto.notification.web_partnership.GetSendersReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.notification.web_partnership.GetSendersRes.deserializeBinary
);


/**
 * @param {!proto.notification.web_partnership.GetSendersReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.notification.web_partnership.GetSendersRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notification.web_partnership.GetSendersRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notification.web_partnership.NotificationServiceClient.prototype.getSenders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notification.web_partnership.NotificationService/GetSenders',
      request,
      metadata || {},
      methodDescriptor_NotificationService_GetSenders,
      callback);
};


/**
 * @param {!proto.notification.web_partnership.GetSendersReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notification.web_partnership.GetSendersRes>}
 *     Promise that resolves to the response
 */
proto.notification.web_partnership.NotificationServicePromiseClient.prototype.getSenders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notification.web_partnership.NotificationService/GetSenders',
      request,
      metadata || {},
      methodDescriptor_NotificationService_GetSenders);
};


module.exports = proto.notification.web_partnership;

