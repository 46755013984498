import { Empty } from '@api/base/base_pb';

import { AuthServiceClient } from '@api/partnership/web_partnership/auth_grpc_web_pb';
import {
  LoginReq,
  LoginRes,
  LoginV2Req,
  LoginV2Res,
  LogoutReq,
} from '@api/partnership/web_partnership/auth_pb';

import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class AuthApi extends BaseApi<AuthServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new AuthServiceClient(this.url, null, this.optsDev);
  }

  login = ({
    password,
    phoneCode,
    phoneNumber,
  }: Omit<LoginReq.AsObject, 'partnershipId'>): Promise<LoginRes.AsObject> => {
    const req = new LoginReq();

    req.setPartnershipId(process.env?.['NX_PARTNERSHIP_ID'] || '');
    req.setPhoneCode(phoneCode);
    req.setPhoneNumber(phoneNumber);

    req.setPassword(password);
    return this.grpc<LoginReq, LoginRes, LoginRes.AsObject>(
      this.serviceClient.login,
      req,
      {}
    );
  };
  loginV2 = ({
    username,
    password,
  }: LoginV2Req.AsObject): Promise<LoginV2Res.AsObject> => {
    const req = new LoginV2Req();

    req.setUsername(username);
    req.setPassword(password);

    return this.grpc<LoginV2Req, LoginV2Res, LoginV2Res.AsObject>(
      this.serviceClient.loginV2,
      req,
      {}
    );
  };

  logout = (): Promise<Empty.AsObject> => {
    const req = new LogoutReq();
    return this.grpc<LogoutReq, Empty, Empty.AsObject>(
      this.serviceClient.logout,
      req,
      {
        noNoty: true,
      }
    );
  };
}

export const authServiceClientApi = new AuthApi();
export default authServiceClientApi;
