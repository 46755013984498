import { ReportServiceClient } from '@api/skyhub/web_partnership/report_grpc_web_pb';
import {
  ExportReportBookingReq,
  ExportReportBookingRes,
} from '@api/skyhub/web_partnership/report_pb';
import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';

class ReportApi extends BaseApi<ReportServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new ReportServiceClient(this.url, null, this.optsDev);
  }

  exportReportBooking = ({
    filter,
  }: ExportReportBookingReq.AsObject): Promise<ExportReportBookingRes.AsObject> => {
    const req = new ExportReportBookingReq();
    const filterReq = new ExportReportBookingReq.Filter();
    if (filter) {
      filterReq.setBookingCode(filter.bookingCode);
      filterReq.setFromDate(filter.fromDate);
      filterReq.setToDate(filter.toDate);
      filterReq.setStatusesList(filter.statusesList);
    }
    req.setFilter(filterReq);

    return this.grpc<
      ExportReportBookingReq,
      ExportReportBookingRes,
      ExportReportBookingRes.AsObject
    >(this.serviceClient.exportReportBooking, req, {});
  };
}

export const reportBookingService = new ReportApi();
export default reportBookingService;
