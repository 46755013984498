// source: notification/web_partnership/notification.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var notification_enum_pb = require('../../notification/enum_pb.js');
goog.object.extend(proto, notification_enum_pb);
goog.exportSymbol('proto.notification.web_partnership.GetSendersReq', null, global);
goog.exportSymbol('proto.notification.web_partnership.GetSendersRes', null, global);
goog.exportSymbol('proto.notification.web_partnership.NotificationSenderInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification.web_partnership.GetSendersReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notification.web_partnership.GetSendersReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification.web_partnership.GetSendersReq.displayName = 'proto.notification.web_partnership.GetSendersReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification.web_partnership.GetSendersRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.notification.web_partnership.GetSendersRes.repeatedFields_, null);
};
goog.inherits(proto.notification.web_partnership.GetSendersRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification.web_partnership.GetSendersRes.displayName = 'proto.notification.web_partnership.GetSendersRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.notification.web_partnership.NotificationSenderInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.notification.web_partnership.NotificationSenderInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.notification.web_partnership.NotificationSenderInfo.displayName = 'proto.notification.web_partnership.NotificationSenderInfo';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification.web_partnership.GetSendersReq.prototype.toObject = function(opt_includeInstance) {
  return proto.notification.web_partnership.GetSendersReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification.web_partnership.GetSendersReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.web_partnership.GetSendersReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    method: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification.web_partnership.GetSendersReq}
 */
proto.notification.web_partnership.GetSendersReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification.web_partnership.GetSendersReq;
  return proto.notification.web_partnership.GetSendersReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification.web_partnership.GetSendersReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification.web_partnership.GetSendersReq}
 */
proto.notification.web_partnership.GetSendersReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.notification.NotificationMethod} */ (reader.readEnum());
      msg.setMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification.web_partnership.GetSendersReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification.web_partnership.GetSendersReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification.web_partnership.GetSendersReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.web_partnership.GetSendersReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional notification.NotificationMethod method = 1;
 * @return {!proto.notification.NotificationMethod}
 */
proto.notification.web_partnership.GetSendersReq.prototype.getMethod = function() {
  return /** @type {!proto.notification.NotificationMethod} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.notification.NotificationMethod} value
 * @return {!proto.notification.web_partnership.GetSendersReq} returns this
 */
proto.notification.web_partnership.GetSendersReq.prototype.setMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.notification.web_partnership.GetSendersRes.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification.web_partnership.GetSendersRes.prototype.toObject = function(opt_includeInstance) {
  return proto.notification.web_partnership.GetSendersRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification.web_partnership.GetSendersRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.web_partnership.GetSendersRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.notification.web_partnership.NotificationSenderInfo.toObject, includeInstance),
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification.web_partnership.GetSendersRes}
 */
proto.notification.web_partnership.GetSendersRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification.web_partnership.GetSendersRes;
  return proto.notification.web_partnership.GetSendersRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification.web_partnership.GetSendersRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification.web_partnership.GetSendersRes}
 */
proto.notification.web_partnership.GetSendersRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.notification.web_partnership.NotificationSenderInfo;
      reader.readMessage(value,proto.notification.web_partnership.NotificationSenderInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification.web_partnership.GetSendersRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification.web_partnership.GetSendersRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification.web_partnership.GetSendersRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.web_partnership.GetSendersRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.notification.web_partnership.NotificationSenderInfo.serializeBinaryToWriter
    );
  }
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * repeated NotificationSenderInfo items = 1;
 * @return {!Array<!proto.notification.web_partnership.NotificationSenderInfo>}
 */
proto.notification.web_partnership.GetSendersRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.notification.web_partnership.NotificationSenderInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.notification.web_partnership.NotificationSenderInfo, 1));
};


/**
 * @param {!Array<!proto.notification.web_partnership.NotificationSenderInfo>} value
 * @return {!proto.notification.web_partnership.GetSendersRes} returns this
*/
proto.notification.web_partnership.GetSendersRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.notification.web_partnership.NotificationSenderInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.notification.web_partnership.NotificationSenderInfo}
 */
proto.notification.web_partnership.GetSendersRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.notification.web_partnership.NotificationSenderInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.notification.web_partnership.GetSendersRes} returns this
 */
proto.notification.web_partnership.GetSendersRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool is_success = 2;
 * @return {boolean}
 */
proto.notification.web_partnership.GetSendersRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.notification.web_partnership.GetSendersRes} returns this
 */
proto.notification.web_partnership.GetSendersRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string error_code = 3;
 * @return {string}
 */
proto.notification.web_partnership.GetSendersRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.web_partnership.GetSendersRes} returns this
 */
proto.notification.web_partnership.GetSendersRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.notification.web_partnership.NotificationSenderInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.notification.web_partnership.NotificationSenderInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.notification.web_partnership.NotificationSenderInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.web_partnership.NotificationSenderInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    sender: jspb.Message.getFieldWithDefault(msg, 1, ""),
    senderName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    senderId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    method: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.notification.web_partnership.NotificationSenderInfo}
 */
proto.notification.web_partnership.NotificationSenderInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.notification.web_partnership.NotificationSenderInfo;
  return proto.notification.web_partnership.NotificationSenderInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.notification.web_partnership.NotificationSenderInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.notification.web_partnership.NotificationSenderInfo}
 */
proto.notification.web_partnership.NotificationSenderInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSender(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSenderId(value);
      break;
    case 4:
      var value = /** @type {!proto.notification.NotificationMethod} */ (reader.readEnum());
      msg.setMethod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.notification.web_partnership.NotificationSenderInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.notification.web_partnership.NotificationSenderInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.notification.web_partnership.NotificationSenderInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.notification.web_partnership.NotificationSenderInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSender();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSenderName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSenderId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMethod();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string sender = 1;
 * @return {string}
 */
proto.notification.web_partnership.NotificationSenderInfo.prototype.getSender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.web_partnership.NotificationSenderInfo} returns this
 */
proto.notification.web_partnership.NotificationSenderInfo.prototype.setSender = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string sender_name = 2;
 * @return {string}
 */
proto.notification.web_partnership.NotificationSenderInfo.prototype.getSenderName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.web_partnership.NotificationSenderInfo} returns this
 */
proto.notification.web_partnership.NotificationSenderInfo.prototype.setSenderName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sender_id = 3;
 * @return {string}
 */
proto.notification.web_partnership.NotificationSenderInfo.prototype.getSenderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.notification.web_partnership.NotificationSenderInfo} returns this
 */
proto.notification.web_partnership.NotificationSenderInfo.prototype.setSenderId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional notification.NotificationMethod method = 4;
 * @return {!proto.notification.NotificationMethod}
 */
proto.notification.web_partnership.NotificationSenderInfo.prototype.getMethod = function() {
  return /** @type {!proto.notification.NotificationMethod} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.notification.NotificationMethod} value
 * @return {!proto.notification.web_partnership.NotificationSenderInfo} returns this
 */
proto.notification.web_partnership.NotificationSenderInfo.prototype.setMethod = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


goog.object.extend(exports, proto.notification.web_partnership);
