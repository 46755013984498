import { PaginationReq } from '@api/base/base_pb';
import { EVBookingServiceClient } from '@api/skyhub/web_partnership/ev_booking_grpc_web_pb';
import {
  BookingInfo,
  CancelBookingReq,
  CancelBookingRes,
  GetBookingByCodeReq,
  GetBookingByCodeRes,
  GetBookingsReq,
  GetBookingsRes,
  Itinerary,
  PaxInfo,
  UpdateBookingReq,
  UpdateBookingRes,
} from '@api/skyhub/web_partnership/ev_booking_pb';
import { BaseApi, BaseApiOptions } from '@core/grpc-client';

class EVBookingServiceApi extends BaseApi<EVBookingServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);

    this.serviceClient = new EVBookingServiceClient(
      this.url,
      null,
      this.optsDev
    );
  }

  getBookings = ({
    bookingCode,
    pnrCode,
    ticketStatusesList,
    pagination,
  }: GetBookingsReq.AsObject): Promise<GetBookingsRes.AsObject> => {
    const req = new GetBookingsReq();
    bookingCode && req.setBookingCode(bookingCode);
    pnrCode && req.setPnrCode(pnrCode);
    ticketStatusesList && req.setTicketStatusesList(ticketStatusesList);
    if (pagination) {
      const paginationReq = new PaginationReq();
      paginationReq.setPageLimit(pagination.pageLimit);
      paginationReq.setPageNumber(pagination.pageNumber);
      req.setPagination(paginationReq);
    }
    return this.grpc<GetBookingsReq, GetBookingsRes, GetBookingsRes.AsObject>(
      this.serviceClient?.getBookings,
      req,
      {}
    );
  };

  getBookingByCode = ({
    bookingCode,
  }: GetBookingByCodeReq.AsObject): Promise<GetBookingByCodeRes.AsObject> => {
    const req = new GetBookingByCodeReq();
    req.setBookingCode(bookingCode);
    return this.grpc<
      GetBookingByCodeReq,
      GetBookingByCodeRes,
      GetBookingByCodeRes.AsObject
    >(this.serviceClient.getBookingByCode, req, {});
  };

  updateBooking = ({
    bookingInfo,
  }: UpdateBookingReq.AsObject): Promise<UpdateBookingRes.AsObject> => {
    const req = new UpdateBookingReq();
    if (bookingInfo) {
      const { bookingCode, itinerariesList, pnrCode } = bookingInfo;
      const bookingReq = new BookingInfo();
      bookingReq.setBookingCode(bookingCode);
      bookingReq.setPnrCode(pnrCode);

      for (let i = 0; i < itinerariesList.length; i++) {
        const itineraryReq = new Itinerary();

        const {
          arrivalPlace,
          departDate,
          departPlace,
          id,
          paxInfoList,
          reservationCode,
        } = itinerariesList[i];

        itineraryReq.setArrivalPlace(arrivalPlace);
        itineraryReq.setDepartPlace(departPlace);
        itineraryReq.setDepartDate(departDate);
        itineraryReq.setReservationCode(reservationCode);
        itineraryReq.setId(id);

        for (let j = 0; j < paxInfoList.length; j++) {
          const paxReq = new PaxInfo();
          paxReq.setFirstName(paxInfoList[j].firstName);
          paxReq.setLastName(paxInfoList[j].lastName);
          paxReq.setId(paxInfoList[j].id);
          paxReq.setPassengerType(paxInfoList[j].passengerType);
          paxReq.setTicketNumber(paxInfoList[j].ticketNumber);
          itineraryReq.addPaxInfo(paxReq);
        }

        bookingReq.addItineraries(itineraryReq);
      }

      req.setBookingInfo(bookingReq);
    }

    return this.grpc<
      UpdateBookingReq,
      UpdateBookingRes,
      UpdateBookingRes.AsObject
    >(this.serviceClient.updateBooking, req, {});
  };

  cancelBooking = ({
    bookingCode,
  }: CancelBookingReq.AsObject): Promise<CancelBookingRes.AsObject> => {
    const req = new CancelBookingReq();
    req.setBookingCode(bookingCode);
    return this.grpc<
      CancelBookingReq,
      CancelBookingRes,
      CancelBookingRes.AsObject
    >(this.serviceClient.cancelBooking, req, {});
  };
}

export const evBookingService = new EVBookingServiceApi();

export default evBookingService;
