import { IReceiver } from '@tixlabs/grpc-client/web-partnership';
import React from 'react';
interface Props {
  receiver: IReceiver;
}
export const RecipientInfo = ({ receiver }: Props) => {
  return (
    <div className='flex flex-col gap-3'>
      <div className='flex gap-3'>
        <span className='w-1/4 text-lg font-semibold'>
          {' '}
          Thông tin người nhận
        </span>

        <div className='flex-1'>
          <div className='grid md:grid-cols-3 gap-3 mb-4'>
            <div className='flex flex-col gap-1'>
              <span className='text-gray-500'>Họ và tên</span>
              <span>{receiver.name}</span>
            </div>

            <div className='flex flex-col gap-1'>
              <span className='text-gray-500'>Số điện thoại</span>
              <span>{receiver.phone}</span>
            </div>

            <div className='flex flex-col gap-1'>
              <span className='text-gray-500'>Email</span>
              <span>{receiver.email}</span>
            </div>
          </div>

          <div className='flex flex-col gap-1 mb-4'>
            <span className='text-gray-500'>Địa chỉ</span>
            <span>{receiver.address}</span>
          </div>

          <div className='flex flex-col gap-1'>
            <span className='text-gray-500'>Ghi chú</span>
            <span>{receiver?.note}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
