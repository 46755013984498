import { Button } from '@common-ui';
import { useUserStore } from '@vemaybay-admin/hooks/stores';
import React from 'react';

type Props = {
  //
};

export const AccountCtn = (props: Props) => {
  const { userData } = useUserStore();
  return (
    <div className='flex flex-col gap-y-4 items-center'>
      <img
        className='rounded-full w-[110px] h-[110px]'
        src={'imgUrl'}
        alt='avatar'
        onError={(e) => {
          e.currentTarget.src = '/assets/images/user-avatar.png';
        }}
      />
      <div className='w-full'>
        <div className='grid grid-cols-3'>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Họ & tên</span>
            <span className='text-neutral-black'>Dương Nguyễn Duy Thanh</span>
          </div>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Ngày sinh</span>
            <span className='text-neutral-black'>26/11/1995</span>
          </div>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Giới tính</span>
            <span className='text-neutral-black'>Nam</span>
          </div>
        </div>
        <div className='grid grid-cols-3'>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Số điện thoại</span>
            <span className='text-neutral-black'>(+84)908790246</span>
          </div>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Email</span>
            <span className='text-neutral-black'>tinikun@gmail.com</span>
          </div>
        </div>
        <div className='grid grid-cols-3'>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Địa chỉ</span>
            <span className='text-neutral-black'>
              60M Trường Sơn, P2, Tân Bình
            </span>
          </div>
        </div>
        <div className='grid grid-cols-3'>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Số CMND/CCCD</span>
            <span className='text-neutral-black'>250865401536</span>
          </div>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Ngày cấp</span>
            <span className='text-neutral-black'>15/11/2023</span>
          </div>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Nơi cấp</span>
            <span className='text-neutral-black'>15/11/2023</span>
          </div>
        </div>
        <div className='grid grid-cols-3'>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Mã số TNCN</span>
            <span className='text-neutral-black'>8653087613</span>
          </div>
        </div>
        <div className='grid grid-cols-3'>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>
              Số tài khoản ngân hàng
            </span>
            <span className='text-neutral-black'>192345764319841</span>
          </div>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Ngân hàng</span>
            <span className='text-neutral-black'>Techcombank</span>
          </div>
          <div className='col-span-1 flex flex-col gap-0.5 py-2'>
            <span className='text-xs text-neutral-gray-50'>Chi nhánh</span>
            <span className='text-neutral-black'>Hồ Chí Minh</span>
          </div>
        </div>
        <div className='flex justify-between mt-4'>
          <Button>Đổi mật khẩu</Button>
          <Button>Cập nhật</Button>
        </div>
      </div>
    </div>
  );
};

export default AccountCtn;
