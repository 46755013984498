import { Dispatch, SetStateAction, useState } from 'react';
import { Button } from '@common-ui';
import Input from 'antd/es/input/Input';
import { Form, InputNumber } from 'antd';
import {
  IBookingDetail,
  IOccupancyUpdateInfo,
} from '@tixlabs/grpc-client/web-partnership';
import { BookingStatus } from '@api/hotel/base_pb';
import { useHotel } from '@vemaybay-admin/hooks/apps';
import { useToast } from '@vemaybay-admin/hooks/internals';
import TextArea from 'antd/es/input/TextArea';

interface Props {
  setModalConfirm: Dispatch<SetStateAction<boolean>>;
  bookingDetail: IBookingDetail;
  orderCode: string;
  getBookingDetail: (params: { orderCode: string }) => void;
}

interface PropsSubmit {
  actualPrice: number;
  confirmationCodes: {
    [key: string]: string;
  };
  originalBuy: string;
}

const ModalConfirmOrder = ({
  setModalConfirm,
  bookingDetail,
  orderCode,
  getBookingDetail,
}: Props) => {
  const { updateBookingStatus } = useHotel();
  const toast = useToast();
  const customerPay = bookingDetail?.priceInfo
    ? bookingDetail?.priceInfo?.payNow + bookingDetail?.priceInfo?.additionalFee
    : 0;
  const [form] = Form.useForm();

  const handleSubmit = async (data: PropsSubmit) => {
    console.log(Object?.keys(data.confirmationCodes));

    const occupancyUpdatesList: IOccupancyUpdateInfo[] = Object?.keys(
      data.confirmationCodes || {}
    ).map((confirm, i) => {
      return {
        occupancyIndex: i + 1,
        confirmationId: data?.confirmationCodes[confirm],
      };
    });

    await updateBookingStatus({
      actualAmount: data?.actualPrice,
      orderCode,
      cancelReason: '',
      status: BookingStatus.SUCCESS,
      occupancyUpdatesList: occupancyUpdatesList,
      source: data?.originalBuy,
    }).then((res) => {
      toast.success('Xác nhận đơn hàng thành công');
      getBookingDetail({ orderCode });
    });

    form.resetFields();
    setDifference(customerPay);
    setModalConfirm(false);
  };

  const [difference, setDifference] = useState<number>(0);

  const onValuesChange = (changedValues: any) => {
    if (changedValues.actualPrice !== undefined) {
      const actualPrice = changedValues.actualPrice;
      const newDifference = customerPay - actualPrice;
      setDifference(newDifference);
    }
  };
  return (
    <Form
      onValuesChange={onValuesChange}
      form={form}
      onFinish={handleSubmit}
      requiredMark={false}
      layout='horizontal'
      className='py-6 px-5 bg-white text-md'>
      <p className='text-gray-700 mb-4 font-semibold'>
        Vui lòng cập nhật mã xác nhận tại khách sạn & chênh lệch giá vé thực tế
        để hoàn thành yêu cầu
      </p>

      <Form.Item
        label='Giá thực tế *'
        className='text-start mb-3'
        name='actualPrice'
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        colon={false}
        rules={[
          { required: true, message: 'Vui lòng nhập giá thực tế' },
          {
            validator: (_, value) => {
              if (value === undefined || value <= customerPay) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('Giá thực tế không được lớn hơn Giá khách thanh toán')
              );
            },
          },
        ]}>
        <InputNumber<number>
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          controls={false}
          parser={(value) =>
            value?.replace(/\$\s?|(,*)/g, '') as unknown as number
          }
          min={0}
          placeholder='Giá thực tế'
          className='custom-input-number w-full'
        />
      </Form.Item>

      <div className='grid grid-cols-2 items-center mb-3'>
        <span className='text-gray-800'>Giá khách thanh toán:</span>
        <span className='font-semibold '>{customerPay.toLocaleString()} đ</span>
      </div>

      <div className='grid grid-cols-2 items-center mb-3'>
        <span className='text-gray-800'>Tiền chênh lệch:</span>
        <span className='font-semibold'>{difference.toLocaleString()} đ</span>
      </div>

      <Form.Item
        label='Nguồn mua *'
        name='originalBuy'
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        colon={false}
        rules={[{ required: true, message: 'Vui lòng nhập nguồn mua' }]}>
        <TextArea rows={3} style={{ resize: 'none' }} placeholder='Nguồn mua' />
      </Form.Item>

      {bookingDetail.roomsList?.map((room, index) => (
        <div key={index}>
          <div className='font-semibold'>
            {`Phòng ${index + 1}:`}
            <span className='font-light'>
              {room.occupancy?.adt && room.occupancy?.adt > 0 ? (
                <span>{` ${room.occupancy?.adt} người lớn`}</span>
              ) : null}
            </span>
            <span className='font-light'>
              {room.occupancy?.chd &&
              room.occupancy?.chd > 0 &&
              room.occupancy?.adt &&
              room.occupancy?.adt > 0
                ? `, `
                : ''}
              {room.occupancy?.chd && room.occupancy?.chd > 0 ? (
                <span>{`${room.occupancy?.chd} trẻ em`}</span>
              ) : null}
            </span>
          </div>
          <Form.Item
            label='Mã xác nhận tại khách sạn *'
            name={['confirmationCodes', `room${index + 1}`]}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            colon={false}
            rules={[
              { required: true, message: 'Vui lòng nhập mã xác nhận' },
              {
                pattern: /^[\p{L}\d\s]*$/u,
                message: 'Mã xác nhận không được chứa ký tự đặc biệt',
              },
            ]}>
            <Input placeholder='Mã xác nhận tại khách sạn' />
          </Form.Item>
        </div>
      ))}

      <div className='flex gap-3 justify-center'>
        <Button
          onClick={() => {
            setModalConfirm(false);
            setDifference(customerPay);
            form.resetFields();
          }}
          theme='neutral'
          className='w-1/4'>
          Hủy
        </Button>
        <Button type='submit' theme='primary' className='w-1/4'>
          Xác nhận
        </Button>
      </div>
    </Form>
  );
};

export default ModalConfirmOrder;
