import { IRetrieveBookingFlightData } from '@tixlabs/grpc-client/web-partnership';
import { EBookingStatus, EPaxType } from '@tixlabs/grpc-client';

import { IPaginationRes, IBookingPassenger } from '@tixlabs/grpc-client';
import { useCurrency, useTime } from '@vemaybay-admin/hooks/internals';
import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Status } from './Status';
import { Button } from '@common-ui';
import { getUserName } from '@core/utils';
import { mapReservationCode } from '@vemaybay-admin/utils';
import { useAirlines } from '@vemaybay-admin/hooks/stores';

type Props = {
  listOrder: IRetrieveBookingFlightData[];
  isLoading: boolean;
  pagination?: IPaginationRes;
  isExistPagination?: boolean;
  onPageChange?: (page: number) => void;
};

const TitleColumns = ({ title }: { title: string }) => {
  return <span className='text-[#333333]'>{title}</span>;
};

const TableContent = ({
  isLoading,
  listOrder,
  pagination,
  isExistPagination = true,
  onPageChange,
}: Props) => {
  const navigate = useNavigate();
  const { formatDateTime } = useTime();
  const { formatPrice } = useCurrency();
  const { getAirlineNameByCode } = useAirlines();

  const columns: ColumnProps<IRetrieveBookingFlightData>[] = [
    {
      title: () => {
        return <TitleColumns title='Ngày đặt' />;
      },
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => {
        return <span>{formatDateTime(record.createdAt)}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Mã đơn hàng' />;
      },
      dataIndex: 'bookingCode',
      key: 'bookingCode',
      render: (value: string) => {
        return <span className='font-semibold text-primary-50'>{value}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Mã đặt chỗ' />;
      },
      dataIndex: 'reservationCode',
      key: 'reservationCode',
      render: (value) => {
        if (!value) {
          return <span className='text-primary-50'>-</span>;
        }
        return <span className='text-primary-50'>{value}</span>;
      },
    },
    {
      title: () => {
        return <TitleColumns title='Hãng bay' />;
      },
      dataIndex: 'itinerariesList',
      key: 'itinerariesList',
      render: (
        itinerariesList: IRetrieveBookingFlightData['itinerariesList']
      ) => {
        if (!itinerariesList) {
          return <span className='text-primary-50'>-</span>;
        }
        return (
          <span className='text-primary-50'>
            {itinerariesList
              .map((item) => getAirlineNameByCode(item.carrierMarketing))
              .join(', ')}
          </span>
        );
      },
    },
    {
      title: () => {
        return <TitleColumns title='Tên khách hàng' />;
      },
      dataIndex: 'contactInfo',
      key: 'contactInfo',
      render: (contactInfo: IRetrieveBookingFlightData['contactInfo']) => {
        return getUserName({
          firstName: contactInfo?.firstName || '',
          lastName: contactInfo?.lastName || '',
        });
      },
      className: 'min-w-[150px]',
    },
    {
      title: () => {
        return <TitleColumns title='Số điện thoại' />;
      },
      dataIndex: 'contactInfo',
      key: 'contactInfo',
      render: (_, record) => {
        return (
          <span>{`+${record.contactInfo?.phoneCode} ${record.contactInfo?.phoneNumber}`}</span>
        );
      },
    },
    {
      title: () => {
        return <TitleColumns title='Utm Source' />;
      },
      dataIndex: 'utmSource',
      key: 'utmSource',
      render: (_, record) => {
        return (
          record?.accessTradeRefInfo?.utmSource && (
            <span>{`${record?.accessTradeRefInfo?.utmSource}`}</span>
          )
        );
      },
    },
    {
      title: () => {
        return <TitleColumns title='Aff Sid' />;
      },
      dataIndex: 'affSid',
      key: 'affSid',
      render: (_, record) => {
        return (
          record?.accessTradeRefInfo?.clickId && (
            <span>{`${record?.accessTradeRefInfo?.clickId}`}</span>
          )
        );
      },
    },
    {
      title: () => {
        return <TitleColumns title='Giá fare' />;
      },
      dataIndex: 'priceFare',
      key: 'priceFare',
      render: (_, record) => {
        const priceFare = record?.fee?.itemsList.reduce(
          (total, item) => total + item?.fareBasic * item?.quantity,
          0
        );

        return (
          <span>
            {formatPrice(priceFare || 0)} {record.currency}
          </span>
        );
      },
    },
    {
      title: () => {
        return <TitleColumns title='Thuế phí' />;
      },
      dataIndex: 'tax',
      key: 'tax',
      render: (_, record) => {
        console.log(record);

        const tax = record?.fee?.itemsList.reduce(
          (total, item) =>
            total +
            item?.taxAmount * item?.quantity +
            item?.serviceFee * item?.quantity,
          0
        );
        return (
          <span>
            {formatPrice(tax || 0)} {record.currency}
          </span>
        );
      },
    },
    {
      title: () => {
        return <TitleColumns title='Tổng giá' />;
      },
      dataIndex: 'fee',
      key: 'fee',
      render: (_, record) => {
        return (
          <span>
            {formatPrice(record.fee?.totalPrice || 0)} {record.currency}
          </span>
        );
      },
    },
    {
      title: () => {
        return <TitleColumns title='Trạng thái' />;
      },
      dataIndex: 'status',
      key: 'status',
      render: (status: EBookingStatus) => {
        return <Status status={status} className='' classNameText='' />;
      },
      className: 'min-w-[150px]',
    },
    {
      title: () => {
        return <TitleColumns title='Thao tác' />;
      },
      dataIndex: 'bookingCode',
      key: 'bookingCode',
      render: (bookingCode: EBookingStatus) => {
        return (
          <Button
            onClick={() => {
              navigate(`/order-management/${bookingCode}`);
            }}
            variant='ghost'>
            Chi tiết
          </Button>
        );
      },
      className: 'min-w-[150px]',
    },
  ];

  return (
    <Table
      dataSource={listOrder.map((order, index) => {
        return {
          ...order,
          key: index,
          reservationCode: mapReservationCode(order.itinerariesList),
        };
      })}
      columns={columns}
      size='small'
      rowClassName={(record, index) => {
        return cn('font-semibold whitespace-nowrap', {
          'bg-[#F8F8FB]': index % 2 === 0,
        });
      }}
      // onRow={(record, rowIndex) => {
      //   return {
      //     onClick: () => {
      //       navigate(`./${record.bookingCode}`);
      //     },
      //   };
      // }}
      scroll={{ x: 1000 }}
      pagination={
        isExistPagination
          ? {
              pageSize: pagination?.pageLimit || 1,
              current: pagination?.pageCurrent || 1,
              total: pagination?.totalRecord || 1,
              className: 'space-x-2.5',
              onChange: onPageChange,
            }
          : false
      }
      loading={isLoading}
    />
  );
};

export default TableContent;
