import { BookingStatus } from '@api/hotel/base_pb';
import { EBookingStatus, EPaxType } from '@tixlabs/grpc-client';
import { EGender, EGenderCustomer } from '@tixlabs/types';
import { SelectOptionItem } from '@tixlabs/types';

export const PAX_TYPE_LABEL = {
  [EPaxType.ADT]: 'Người lớn',
  [EPaxType.CHD]: 'Trẻ em',
  [EPaxType.INF]: 'Trẻ sơ sinh',
};

export const GENDER_TYPE_LABEL = {
  [EGender.GENDER_TYPE_MALE]: 'Nam',
  [EGender.GENDER_TYPE_FEMALE]: 'Nữ',
};
export const GENDER_CUSTOMER_TYPE_LABEL = {
  [EGenderCustomer.GENDER_MALE]: 'Nam',
  [EGenderCustomer.GENDER_FEMALE]: 'Nữ',
};

export enum EStepRefundBooking {
  CONFIRM,
  SUCCESS,
  FAILED,
}

export const BOOKING_STATUS_LABEL = {
  [EBookingStatus.COMPLETE]: 'Đã xuất vé',
  [EBookingStatus.INPROGRESS]: 'Chờ thanh toán',
  [EBookingStatus.BOOKINGSTATUSTICKETFAILED]: 'Xuất vé thất bại',
  [EBookingStatus.BOOKINGSTATUSCANCELLED]: 'Đã hủy',
  [EBookingStatus.BOOKINGSTATUSEXPIRED]: 'Đã hủy',
  [EBookingStatus.BOOKINGSTATUSFAILED]: 'Chờ hoàn tiền',
  [EBookingStatus.BOOKINGSTATUSPAID]: 'Đang xuất vé',
  [EBookingStatus.BOOKINGSTATUSREFUND]: 'Đã hoàn tiền',
  [EBookingStatus.NONE]: 'Tất cả',
};

export const BOOKING_DETAIL_STATUS_LABEL = {
  [EBookingStatus.COMPLETE]: 'Đã xuất vé',
  [EBookingStatus.INPROGRESS]: 'Chờ thanh toán',
  [EBookingStatus.BOOKINGSTATUSTICKETFAILED]: 'Xuất vé thất bại',
  [EBookingStatus.BOOKINGSTATUSCANCELLED]: 'Đã hủy',
  [EBookingStatus.BOOKINGSTATUSEXPIRED]: 'Đã hủy',
  [EBookingStatus.BOOKINGSTATUSFAILED]: 'Đã hủy, chờ hoàn tiền',
  [EBookingStatus.BOOKINGSTATUSPAID]: 'Đang xuất vé',
  [EBookingStatus.BOOKINGSTATUSREFUND]: 'Đã hoàn tiền',
  [EBookingStatus.NONE]: 'Tất cả',
};

export const SELECT_STATUS_BOOKING_OPTIONS: SelectOptionItem[] = [
  {
    value: EBookingStatus.NONE,
    label: BOOKING_STATUS_LABEL[EBookingStatus.NONE],
  },
  {
    value: EBookingStatus.INPROGRESS,
    label: BOOKING_STATUS_LABEL[EBookingStatus.INPROGRESS],
  },
  {
    value: EBookingStatus.BOOKINGSTATUSPAID,
    label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSPAID],
  },
  {
    value: EBookingStatus.COMPLETE,
    label: BOOKING_STATUS_LABEL[EBookingStatus.COMPLETE],
  },
  {
    value: EBookingStatus.BOOKINGSTATUSREFUND,
    label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSREFUND],
  },
  {
    value: EBookingStatus.BOOKINGSTATUSCANCELLED,
    label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSCANCELLED],
  },
  // {
  //   value: EBookingStatus.BOOKINGSTATUSEXPIRED,
  //   label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSEXPIRED],
  // },
  {
    value: EBookingStatus.BOOKINGSTATUSFAILED,
    label: BOOKING_STATUS_LABEL[EBookingStatus.BOOKINGSTATUSFAILED],
  },
];

export const BOOKING_STATUS_HOTEL_LABEL = {
  [BookingStatus.NONE]: 'Tất cả',
  [BookingStatus.SUCCESS]: 'Hoàn thành',
  [BookingStatus.PENDING]: 'Đang xử lý',
  [BookingStatus.CANCELLED]: 'Đã hủy, chờ hoàn tiền',
  [BookingStatus.FAILED]: 'Đã hủy, chờ hoàn tiền',
  [BookingStatus.REFUNDED]: 'Đã hoàn tiền',
  [BookingStatus.EXPIRED]: 'Đặt chỗ đã huỷ do hết hạn thanh toán',
};

export const SELECT_STATUS_BOOKING_HOTEL_OPTIONS: SelectOptionItem[] = [
  {
    value: BookingStatus.NONE,
    label: BOOKING_STATUS_HOTEL_LABEL[BookingStatus.NONE],
  },
  {
    value: BookingStatus.SUCCESS,
    label: BOOKING_STATUS_HOTEL_LABEL[BookingStatus.SUCCESS],
  },
  {
    value: BookingStatus.PENDING,
    label: BOOKING_STATUS_HOTEL_LABEL[BookingStatus.PENDING],
  },
  {
    value: BookingStatus.CANCELLED,
    label: BOOKING_STATUS_HOTEL_LABEL[BookingStatus.CANCELLED],
  },
  {
    value: BookingStatus.REFUNDED,
    label: BOOKING_STATUS_HOTEL_LABEL[BookingStatus.REFUNDED],
  },
];
