import { IBookingItinerary, IBookingPassenger } from '@tixlabs/grpc-client/';
import React from 'react';
import {
  ItineraryFromTo,
  AirlineInfo,
  ItinerarySegmentInfo,
  PassengerServiceInfo,
} from '.';

type Props = {
  //
  passengersList: IBookingPassenger[];
  itinerary: IBookingItinerary;
};

export const ItineraryItem = ({ passengersList, itinerary }: Props) => {
  return (
    <div className='px-4 py-3'>
      <div className='flex flex-col gap-3'>
        <ItineraryFromTo itinerary={itinerary} />
        <AirlineInfo
          code={itinerary.carrierMarketing}
          flightNumber={itinerary.flightNumber}
        />
        <ItinerarySegmentInfo segmentsList={itinerary.segmentsList} />
        <PassengerServiceInfo
          passengersList={passengersList}
          itinerary={itinerary}
        />
      </div>
    </div>
  );
};
