// source: skyhub/web_partnership/ev_booking.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var base_base_pb = require('../../base/base_pb.js');
goog.object.extend(proto, base_base_pb);
var base_enum_pb = require('../../base/enum_pb.js');
goog.object.extend(proto, base_enum_pb);
var validate_validate_pb = require('../../validate/validate_pb.js');
goog.object.extend(proto, validate_validate_pb);
goog.exportSymbol('proto.skyhub.web_partnership.BookingBasicInfo', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.BookingInfo', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.CancelBookingReq', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.CancelBookingRes', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.GetBookingByCodeReq', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.GetBookingByCodeRes', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.GetBookingsReq', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.GetBookingsRes', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.Itinerary', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.PaxInfo', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.UpdateBookingReq', null, global);
goog.exportSymbol('proto.skyhub.web_partnership.UpdateBookingRes', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.Itinerary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.web_partnership.Itinerary.repeatedFields_, null);
};
goog.inherits(proto.skyhub.web_partnership.Itinerary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.Itinerary.displayName = 'proto.skyhub.web_partnership.Itinerary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.PaxInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.web_partnership.PaxInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.PaxInfo.displayName = 'proto.skyhub.web_partnership.PaxInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.BookingInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.web_partnership.BookingInfo.repeatedFields_, null);
};
goog.inherits(proto.skyhub.web_partnership.BookingInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.BookingInfo.displayName = 'proto.skyhub.web_partnership.BookingInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.BookingBasicInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.web_partnership.BookingBasicInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.BookingBasicInfo.displayName = 'proto.skyhub.web_partnership.BookingBasicInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.GetBookingsReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.web_partnership.GetBookingsReq.repeatedFields_, null);
};
goog.inherits(proto.skyhub.web_partnership.GetBookingsReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.GetBookingsReq.displayName = 'proto.skyhub.web_partnership.GetBookingsReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.GetBookingByCodeReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.web_partnership.GetBookingByCodeReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.GetBookingByCodeReq.displayName = 'proto.skyhub.web_partnership.GetBookingByCodeReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.UpdateBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.web_partnership.UpdateBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.UpdateBookingReq.displayName = 'proto.skyhub.web_partnership.UpdateBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.CancelBookingReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.web_partnership.CancelBookingReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.CancelBookingReq.displayName = 'proto.skyhub.web_partnership.CancelBookingReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.GetBookingsRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.skyhub.web_partnership.GetBookingsRes.repeatedFields_, null);
};
goog.inherits(proto.skyhub.web_partnership.GetBookingsRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.GetBookingsRes.displayName = 'proto.skyhub.web_partnership.GetBookingsRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.GetBookingByCodeRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.web_partnership.GetBookingByCodeRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.GetBookingByCodeRes.displayName = 'proto.skyhub.web_partnership.GetBookingByCodeRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.UpdateBookingRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.web_partnership.UpdateBookingRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.UpdateBookingRes.displayName = 'proto.skyhub.web_partnership.UpdateBookingRes';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.skyhub.web_partnership.CancelBookingRes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.skyhub.web_partnership.CancelBookingRes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.skyhub.web_partnership.CancelBookingRes.displayName = 'proto.skyhub.web_partnership.CancelBookingRes';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.web_partnership.Itinerary.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.Itinerary.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.Itinerary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.Itinerary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.Itinerary.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    departPlace: jspb.Message.getFieldWithDefault(msg, 2, ""),
    arrivalPlace: jspb.Message.getFieldWithDefault(msg, 3, ""),
    departDate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    reservationCode: jspb.Message.getFieldWithDefault(msg, 5, ""),
    paxInfoList: jspb.Message.toObjectList(msg.getPaxInfoList(),
    proto.skyhub.web_partnership.PaxInfo.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.Itinerary}
 */
proto.skyhub.web_partnership.Itinerary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.Itinerary;
  return proto.skyhub.web_partnership.Itinerary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.Itinerary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.Itinerary}
 */
proto.skyhub.web_partnership.Itinerary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartPlace(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setArrivalPlace(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDepartDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReservationCode(value);
      break;
    case 6:
      var value = new proto.skyhub.web_partnership.PaxInfo;
      reader.readMessage(value,proto.skyhub.web_partnership.PaxInfo.deserializeBinaryFromReader);
      msg.addPaxInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.Itinerary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.Itinerary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.Itinerary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.Itinerary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDepartPlace();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getArrivalPlace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDepartDate();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getReservationCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPaxInfoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.skyhub.web_partnership.PaxInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.skyhub.web_partnership.Itinerary.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.Itinerary} returns this
 */
proto.skyhub.web_partnership.Itinerary.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string depart_place = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.Itinerary.prototype.getDepartPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.Itinerary} returns this
 */
proto.skyhub.web_partnership.Itinerary.prototype.setDepartPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string arrival_place = 3;
 * @return {string}
 */
proto.skyhub.web_partnership.Itinerary.prototype.getArrivalPlace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.Itinerary} returns this
 */
proto.skyhub.web_partnership.Itinerary.prototype.setArrivalPlace = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 depart_date = 4;
 * @return {number}
 */
proto.skyhub.web_partnership.Itinerary.prototype.getDepartDate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.web_partnership.Itinerary} returns this
 */
proto.skyhub.web_partnership.Itinerary.prototype.setDepartDate = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string reservation_code = 5;
 * @return {string}
 */
proto.skyhub.web_partnership.Itinerary.prototype.getReservationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.Itinerary} returns this
 */
proto.skyhub.web_partnership.Itinerary.prototype.setReservationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated PaxInfo pax_info = 6;
 * @return {!Array<!proto.skyhub.web_partnership.PaxInfo>}
 */
proto.skyhub.web_partnership.Itinerary.prototype.getPaxInfoList = function() {
  return /** @type{!Array<!proto.skyhub.web_partnership.PaxInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skyhub.web_partnership.PaxInfo, 6));
};


/**
 * @param {!Array<!proto.skyhub.web_partnership.PaxInfo>} value
 * @return {!proto.skyhub.web_partnership.Itinerary} returns this
*/
proto.skyhub.web_partnership.Itinerary.prototype.setPaxInfoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.skyhub.web_partnership.PaxInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skyhub.web_partnership.PaxInfo}
 */
proto.skyhub.web_partnership.Itinerary.prototype.addPaxInfo = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.skyhub.web_partnership.PaxInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.web_partnership.Itinerary} returns this
 */
proto.skyhub.web_partnership.Itinerary.prototype.clearPaxInfoList = function() {
  return this.setPaxInfoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.PaxInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.PaxInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.PaxInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.PaxInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    passengerType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.PaxInfo}
 */
proto.skyhub.web_partnership.PaxInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.PaxInfo;
  return proto.skyhub.web_partnership.PaxInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.PaxInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.PaxInfo}
 */
proto.skyhub.web_partnership.PaxInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {!proto.base.PassengerType} */ (reader.readEnum());
      msg.setPassengerType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.PaxInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.PaxInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.PaxInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.PaxInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPassengerType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.skyhub.web_partnership.PaxInfo.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.web_partnership.PaxInfo} returns this
 */
proto.skyhub.web_partnership.PaxInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.PaxInfo.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.PaxInfo} returns this
 */
proto.skyhub.web_partnership.PaxInfo.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.skyhub.web_partnership.PaxInfo.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.PaxInfo} returns this
 */
proto.skyhub.web_partnership.PaxInfo.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional base.PassengerType passenger_type = 4;
 * @return {!proto.base.PassengerType}
 */
proto.skyhub.web_partnership.PaxInfo.prototype.getPassengerType = function() {
  return /** @type {!proto.base.PassengerType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.base.PassengerType} value
 * @return {!proto.skyhub.web_partnership.PaxInfo} returns this
 */
proto.skyhub.web_partnership.PaxInfo.prototype.setPassengerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string ticket_number = 5;
 * @return {string}
 */
proto.skyhub.web_partnership.PaxInfo.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.PaxInfo} returns this
 */
proto.skyhub.web_partnership.PaxInfo.prototype.setTicketNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.web_partnership.BookingInfo.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.BookingInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.BookingInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.BookingInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.BookingInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pnrCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itinerariesList: jspb.Message.toObjectList(msg.getItinerariesList(),
    proto.skyhub.web_partnership.Itinerary.toObject, includeInstance),
    ticketStatus: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.BookingInfo}
 */
proto.skyhub.web_partnership.BookingInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.BookingInfo;
  return proto.skyhub.web_partnership.BookingInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.BookingInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.BookingInfo}
 */
proto.skyhub.web_partnership.BookingInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPnrCode(value);
      break;
    case 3:
      var value = new proto.skyhub.web_partnership.Itinerary;
      reader.readMessage(value,proto.skyhub.web_partnership.Itinerary.deserializeBinaryFromReader);
      msg.addItineraries(value);
      break;
    case 4:
      var value = /** @type {!proto.base.SkyhubTicketStatus} */ (reader.readEnum());
      msg.setTicketStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.BookingInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.BookingInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.BookingInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.BookingInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPnrCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItinerariesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.skyhub.web_partnership.Itinerary.serializeBinaryToWriter
    );
  }
  f = message.getTicketStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.skyhub.web_partnership.BookingInfo.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.BookingInfo} returns this
 */
proto.skyhub.web_partnership.BookingInfo.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pnr_code = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.BookingInfo.prototype.getPnrCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.BookingInfo} returns this
 */
proto.skyhub.web_partnership.BookingInfo.prototype.setPnrCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Itinerary itineraries = 3;
 * @return {!Array<!proto.skyhub.web_partnership.Itinerary>}
 */
proto.skyhub.web_partnership.BookingInfo.prototype.getItinerariesList = function() {
  return /** @type{!Array<!proto.skyhub.web_partnership.Itinerary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skyhub.web_partnership.Itinerary, 3));
};


/**
 * @param {!Array<!proto.skyhub.web_partnership.Itinerary>} value
 * @return {!proto.skyhub.web_partnership.BookingInfo} returns this
*/
proto.skyhub.web_partnership.BookingInfo.prototype.setItinerariesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.skyhub.web_partnership.Itinerary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skyhub.web_partnership.Itinerary}
 */
proto.skyhub.web_partnership.BookingInfo.prototype.addItineraries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.skyhub.web_partnership.Itinerary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.web_partnership.BookingInfo} returns this
 */
proto.skyhub.web_partnership.BookingInfo.prototype.clearItinerariesList = function() {
  return this.setItinerariesList([]);
};


/**
 * optional base.SkyhubTicketStatus ticket_status = 4;
 * @return {!proto.base.SkyhubTicketStatus}
 */
proto.skyhub.web_partnership.BookingInfo.prototype.getTicketStatus = function() {
  return /** @type {!proto.base.SkyhubTicketStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.base.SkyhubTicketStatus} value
 * @return {!proto.skyhub.web_partnership.BookingInfo} returns this
 */
proto.skyhub.web_partnership.BookingInfo.prototype.setTicketStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.BookingBasicInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.BookingBasicInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.BookingBasicInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.BookingBasicInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pnrCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalNumberTicket: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    ticketStatus: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.BookingBasicInfo}
 */
proto.skyhub.web_partnership.BookingBasicInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.BookingBasicInfo;
  return proto.skyhub.web_partnership.BookingBasicInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.BookingBasicInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.BookingBasicInfo}
 */
proto.skyhub.web_partnership.BookingBasicInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPnrCode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalNumberTicket(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {!proto.base.SkyhubTicketStatus} */ (reader.readEnum());
      msg.setTicketStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.BookingBasicInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.BookingBasicInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.BookingBasicInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.BookingBasicInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPnrCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalNumberTicket();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getTicketStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.skyhub.web_partnership.BookingBasicInfo.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.BookingBasicInfo} returns this
 */
proto.skyhub.web_partnership.BookingBasicInfo.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pnr_code = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.BookingBasicInfo.prototype.getPnrCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.BookingBasicInfo} returns this
 */
proto.skyhub.web_partnership.BookingBasicInfo.prototype.setPnrCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 total_number_ticket = 3;
 * @return {number}
 */
proto.skyhub.web_partnership.BookingBasicInfo.prototype.getTotalNumberTicket = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.web_partnership.BookingBasicInfo} returns this
 */
proto.skyhub.web_partnership.BookingBasicInfo.prototype.setTotalNumberTicket = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.skyhub.web_partnership.BookingBasicInfo.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.skyhub.web_partnership.BookingBasicInfo} returns this
 */
proto.skyhub.web_partnership.BookingBasicInfo.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional base.SkyhubTicketStatus ticket_status = 5;
 * @return {!proto.base.SkyhubTicketStatus}
 */
proto.skyhub.web_partnership.BookingBasicInfo.prototype.getTicketStatus = function() {
  return /** @type {!proto.base.SkyhubTicketStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.base.SkyhubTicketStatus} value
 * @return {!proto.skyhub.web_partnership.BookingBasicInfo} returns this
 */
proto.skyhub.web_partnership.BookingBasicInfo.prototype.setTicketStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.web_partnership.GetBookingsReq.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.GetBookingsReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.GetBookingsReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.GetBookingsReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pnrCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ticketStatusesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationReq.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.GetBookingsReq}
 */
proto.skyhub.web_partnership.GetBookingsReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.GetBookingsReq;
  return proto.skyhub.web_partnership.GetBookingsReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.GetBookingsReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.GetBookingsReq}
 */
proto.skyhub.web_partnership.GetBookingsReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPnrCode(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.base.SkyhubTicketStatus>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTicketStatuses(values[i]);
      }
      break;
    case 4:
      var value = new base_base_pb.PaginationReq;
      reader.readMessage(value,base_base_pb.PaginationReq.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.GetBookingsReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.GetBookingsReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.GetBookingsReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTicketStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationReq.serializeBinaryToWriter
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.GetBookingsReq} returns this
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.skyhub.web_partnership.GetBookingsReq} returns this
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.clearBookingCode = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.hasBookingCode = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string pnr_code = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.getPnrCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.GetBookingsReq} returns this
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.setPnrCode = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.skyhub.web_partnership.GetBookingsReq} returns this
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.clearPnrCode = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.hasPnrCode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated base.SkyhubTicketStatus ticket_statuses = 3;
 * @return {!Array<!proto.base.SkyhubTicketStatus>}
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.getTicketStatusesList = function() {
  return /** @type {!Array<!proto.base.SkyhubTicketStatus>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.base.SkyhubTicketStatus>} value
 * @return {!proto.skyhub.web_partnership.GetBookingsReq} returns this
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.setTicketStatusesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.base.SkyhubTicketStatus} value
 * @param {number=} opt_index
 * @return {!proto.skyhub.web_partnership.GetBookingsReq} returns this
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.addTicketStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.web_partnership.GetBookingsReq} returns this
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.clearTicketStatusesList = function() {
  return this.setTicketStatusesList([]);
};


/**
 * optional base.PaginationReq pagination = 4;
 * @return {?proto.base.PaginationReq}
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationReq} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationReq, 4));
};


/**
 * @param {?proto.base.PaginationReq|undefined} value
 * @return {!proto.skyhub.web_partnership.GetBookingsReq} returns this
*/
proto.skyhub.web_partnership.GetBookingsReq.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.web_partnership.GetBookingsReq} returns this
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.web_partnership.GetBookingsReq.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.GetBookingByCodeReq.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.GetBookingByCodeReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.GetBookingByCodeReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.GetBookingByCodeReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.GetBookingByCodeReq}
 */
proto.skyhub.web_partnership.GetBookingByCodeReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.GetBookingByCodeReq;
  return proto.skyhub.web_partnership.GetBookingByCodeReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.GetBookingByCodeReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.GetBookingByCodeReq}
 */
proto.skyhub.web_partnership.GetBookingByCodeReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.GetBookingByCodeReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.GetBookingByCodeReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.GetBookingByCodeReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.GetBookingByCodeReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.skyhub.web_partnership.GetBookingByCodeReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.GetBookingByCodeReq} returns this
 */
proto.skyhub.web_partnership.GetBookingByCodeReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.UpdateBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.UpdateBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.UpdateBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.UpdateBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingInfo: (f = msg.getBookingInfo()) && proto.skyhub.web_partnership.BookingInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.UpdateBookingReq}
 */
proto.skyhub.web_partnership.UpdateBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.UpdateBookingReq;
  return proto.skyhub.web_partnership.UpdateBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.UpdateBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.UpdateBookingReq}
 */
proto.skyhub.web_partnership.UpdateBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.skyhub.web_partnership.BookingInfo;
      reader.readMessage(value,proto.skyhub.web_partnership.BookingInfo.deserializeBinaryFromReader);
      msg.setBookingInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.UpdateBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.UpdateBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.UpdateBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.UpdateBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.skyhub.web_partnership.BookingInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional BookingInfo booking_info = 1;
 * @return {?proto.skyhub.web_partnership.BookingInfo}
 */
proto.skyhub.web_partnership.UpdateBookingReq.prototype.getBookingInfo = function() {
  return /** @type{?proto.skyhub.web_partnership.BookingInfo} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.web_partnership.BookingInfo, 1));
};


/**
 * @param {?proto.skyhub.web_partnership.BookingInfo|undefined} value
 * @return {!proto.skyhub.web_partnership.UpdateBookingReq} returns this
*/
proto.skyhub.web_partnership.UpdateBookingReq.prototype.setBookingInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.web_partnership.UpdateBookingReq} returns this
 */
proto.skyhub.web_partnership.UpdateBookingReq.prototype.clearBookingInfo = function() {
  return this.setBookingInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.web_partnership.UpdateBookingReq.prototype.hasBookingInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.CancelBookingReq.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.CancelBookingReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.CancelBookingReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.CancelBookingReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookingCode: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.CancelBookingReq}
 */
proto.skyhub.web_partnership.CancelBookingReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.CancelBookingReq;
  return proto.skyhub.web_partnership.CancelBookingReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.CancelBookingReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.CancelBookingReq}
 */
proto.skyhub.web_partnership.CancelBookingReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.CancelBookingReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.CancelBookingReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.CancelBookingReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.CancelBookingReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookingCode();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string booking_code = 1;
 * @return {string}
 */
proto.skyhub.web_partnership.CancelBookingReq.prototype.getBookingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.CancelBookingReq} returns this
 */
proto.skyhub.web_partnership.CancelBookingReq.prototype.setBookingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.skyhub.web_partnership.GetBookingsRes.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.GetBookingsRes.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.GetBookingsRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.GetBookingsRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.GetBookingsRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.skyhub.web_partnership.BookingBasicInfo.toObject, includeInstance),
    pagination: (f = msg.getPagination()) && base_base_pb.PaginationRes.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.GetBookingsRes}
 */
proto.skyhub.web_partnership.GetBookingsRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.GetBookingsRes;
  return proto.skyhub.web_partnership.GetBookingsRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.GetBookingsRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.GetBookingsRes}
 */
proto.skyhub.web_partnership.GetBookingsRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.skyhub.web_partnership.BookingBasicInfo;
      reader.readMessage(value,proto.skyhub.web_partnership.BookingBasicInfo.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 4:
      var value = new base_base_pb.PaginationRes;
      reader.readMessage(value,base_base_pb.PaginationRes.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.GetBookingsRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.GetBookingsRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.GetBookingsRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.GetBookingsRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.skyhub.web_partnership.BookingBasicInfo.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      base_base_pb.PaginationRes.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.skyhub.web_partnership.GetBookingsRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skyhub.web_partnership.GetBookingsRes} returns this
 */
proto.skyhub.web_partnership.GetBookingsRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.GetBookingsRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.GetBookingsRes} returns this
 */
proto.skyhub.web_partnership.GetBookingsRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated BookingBasicInfo items = 3;
 * @return {!Array<!proto.skyhub.web_partnership.BookingBasicInfo>}
 */
proto.skyhub.web_partnership.GetBookingsRes.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.skyhub.web_partnership.BookingBasicInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.skyhub.web_partnership.BookingBasicInfo, 3));
};


/**
 * @param {!Array<!proto.skyhub.web_partnership.BookingBasicInfo>} value
 * @return {!proto.skyhub.web_partnership.GetBookingsRes} returns this
*/
proto.skyhub.web_partnership.GetBookingsRes.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.skyhub.web_partnership.BookingBasicInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.skyhub.web_partnership.BookingBasicInfo}
 */
proto.skyhub.web_partnership.GetBookingsRes.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.skyhub.web_partnership.BookingBasicInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.skyhub.web_partnership.GetBookingsRes} returns this
 */
proto.skyhub.web_partnership.GetBookingsRes.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional base.PaginationRes pagination = 4;
 * @return {?proto.base.PaginationRes}
 */
proto.skyhub.web_partnership.GetBookingsRes.prototype.getPagination = function() {
  return /** @type{?proto.base.PaginationRes} */ (
    jspb.Message.getWrapperField(this, base_base_pb.PaginationRes, 4));
};


/**
 * @param {?proto.base.PaginationRes|undefined} value
 * @return {!proto.skyhub.web_partnership.GetBookingsRes} returns this
*/
proto.skyhub.web_partnership.GetBookingsRes.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.web_partnership.GetBookingsRes} returns this
 */
proto.skyhub.web_partnership.GetBookingsRes.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.web_partnership.GetBookingsRes.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.GetBookingByCodeRes.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.GetBookingByCodeRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.GetBookingByCodeRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.GetBookingByCodeRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, ""),
    booking: (f = msg.getBooking()) && proto.skyhub.web_partnership.BookingInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.GetBookingByCodeRes}
 */
proto.skyhub.web_partnership.GetBookingByCodeRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.GetBookingByCodeRes;
  return proto.skyhub.web_partnership.GetBookingByCodeRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.GetBookingByCodeRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.GetBookingByCodeRes}
 */
proto.skyhub.web_partnership.GetBookingByCodeRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    case 3:
      var value = new proto.skyhub.web_partnership.BookingInfo;
      reader.readMessage(value,proto.skyhub.web_partnership.BookingInfo.deserializeBinaryFromReader);
      msg.setBooking(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.GetBookingByCodeRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.GetBookingByCodeRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.GetBookingByCodeRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.GetBookingByCodeRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBooking();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.skyhub.web_partnership.BookingInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.skyhub.web_partnership.GetBookingByCodeRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skyhub.web_partnership.GetBookingByCodeRes} returns this
 */
proto.skyhub.web_partnership.GetBookingByCodeRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.GetBookingByCodeRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.GetBookingByCodeRes} returns this
 */
proto.skyhub.web_partnership.GetBookingByCodeRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional BookingInfo booking = 3;
 * @return {?proto.skyhub.web_partnership.BookingInfo}
 */
proto.skyhub.web_partnership.GetBookingByCodeRes.prototype.getBooking = function() {
  return /** @type{?proto.skyhub.web_partnership.BookingInfo} */ (
    jspb.Message.getWrapperField(this, proto.skyhub.web_partnership.BookingInfo, 3));
};


/**
 * @param {?proto.skyhub.web_partnership.BookingInfo|undefined} value
 * @return {!proto.skyhub.web_partnership.GetBookingByCodeRes} returns this
*/
proto.skyhub.web_partnership.GetBookingByCodeRes.prototype.setBooking = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.skyhub.web_partnership.GetBookingByCodeRes} returns this
 */
proto.skyhub.web_partnership.GetBookingByCodeRes.prototype.clearBooking = function() {
  return this.setBooking(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.skyhub.web_partnership.GetBookingByCodeRes.prototype.hasBooking = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.UpdateBookingRes.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.UpdateBookingRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.UpdateBookingRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.UpdateBookingRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.UpdateBookingRes}
 */
proto.skyhub.web_partnership.UpdateBookingRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.UpdateBookingRes;
  return proto.skyhub.web_partnership.UpdateBookingRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.UpdateBookingRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.UpdateBookingRes}
 */
proto.skyhub.web_partnership.UpdateBookingRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.UpdateBookingRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.UpdateBookingRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.UpdateBookingRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.UpdateBookingRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.skyhub.web_partnership.UpdateBookingRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skyhub.web_partnership.UpdateBookingRes} returns this
 */
proto.skyhub.web_partnership.UpdateBookingRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.UpdateBookingRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.UpdateBookingRes} returns this
 */
proto.skyhub.web_partnership.UpdateBookingRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.skyhub.web_partnership.CancelBookingRes.prototype.toObject = function(opt_includeInstance) {
  return proto.skyhub.web_partnership.CancelBookingRes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.skyhub.web_partnership.CancelBookingRes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.CancelBookingRes.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuccess: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    errorCode: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.skyhub.web_partnership.CancelBookingRes}
 */
proto.skyhub.web_partnership.CancelBookingRes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.skyhub.web_partnership.CancelBookingRes;
  return proto.skyhub.web_partnership.CancelBookingRes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.skyhub.web_partnership.CancelBookingRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.skyhub.web_partnership.CancelBookingRes}
 */
proto.skyhub.web_partnership.CancelBookingRes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.skyhub.web_partnership.CancelBookingRes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.skyhub.web_partnership.CancelBookingRes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.skyhub.web_partnership.CancelBookingRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.skyhub.web_partnership.CancelBookingRes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getErrorCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_success = 1;
 * @return {boolean}
 */
proto.skyhub.web_partnership.CancelBookingRes.prototype.getIsSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.skyhub.web_partnership.CancelBookingRes} returns this
 */
proto.skyhub.web_partnership.CancelBookingRes.prototype.setIsSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string error_code = 2;
 * @return {string}
 */
proto.skyhub.web_partnership.CancelBookingRes.prototype.getErrorCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.skyhub.web_partnership.CancelBookingRes} returns this
 */
proto.skyhub.web_partnership.CancelBookingRes.prototype.setErrorCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


goog.object.extend(exports, proto.skyhub.web_partnership);
