import { BaseApiOptions } from '@core/grpc-client';
import { BaseApi } from '../base-api';
import { PartnerServiceClient } from '@api/partner/web_partnership/partner_grpc_web_pb';
import { GetOfficesRes } from '@api/partner/web_partnership/partner_pb';
import { Empty } from '@api/base/base_pb';

class PartnerWebApi extends BaseApi<PartnerServiceClient> {
  constructor(option?: BaseApiOptions) {
    super(option);
    this.serviceClient = new PartnerServiceClient(this.url, null, this.optsDev);
  }

  getOffices = (): Promise<GetOfficesRes.AsObject> => {
    const req = new Empty();
    return this.grpc<Empty, GetOfficesRes, GetOfficesRes.AsObject>(
      this.serviceClient.getOffices,
      req,
      {}
    );
  };
}

export const partnerWebServiceClient = new PartnerWebApi();
export default partnerWebServiceClient;
