import { getCountries } from '@tixlabs/axios-client';
import { ICountry, SelectOptionItem } from '@tixlabs/types';
import { useAppDispatch, useAppSelector } from '@vemaybay-admin/stores';
import { updateCountries } from '@vemaybay-admin/stores/reducers/common';
import { useEffect, useMemo } from 'react';

export function getPhoneCodeValue(value: string): {
  phoneCode?: string;
  countryCode?: string;
} {
  const [phoneCode, countryCode] = value.split('.');
  return { phoneCode, countryCode };
}

export function useCountry() {
  const { countries = [], countriesObjectByCode = {} } = useAppSelector(
    (state) => state.common
  );
  const dispatch = useAppDispatch();

  async function fetchCountries() {
    try {
      const { data } = await getCountries();
      const baseURL = process.env.NX_STORAGE_ASSETS;
      if (data && data.data.list) {
        dispatch(
          updateCountries(
            data.data.list.map((item) => ({
              ...item,
              flagUrl: `${baseURL}/country-flags/${item.countryCode}.svg`,
            }))
          )
        );
      }
    } catch (error) {}
  }

  function formatPhoneCodeValue(value: string) {
    const searchCountry = countries.find((c) => {
      if (value.startsWith('+')) {
        return c.dialingCode === `${value}`;
      }
      return c.dialingCode === `+${value}` || c.countryCode === value;
    });

    return searchCountry
      ? `${searchCountry?.dialingCode}.${searchCountry.countryCode}`
      : '';
  }

  const countryOptions = useMemo<SelectOptionItem[]>(
    () =>
      countries?.map((c: ICountry) => ({
        value: c.dialingCode,
        label: c.dialingCode,
        code: c.countryCode,
      })) || [],
    [countries]
  );

  const countryNameOptions = useMemo<SelectOptionItem[]>(
    () =>
      countries?.map((c) => ({
        value: c.country,
        label: c.country,
        code: c.countryCode,
      })) || [],
    [countries]
  );

  const phoneCodeOptions = useMemo<SelectOptionItem[]>(
    () =>
      countries?.map((c) => ({
        value: `${c.dialingCode}.${c.countryCode}`,
        label: c.country,
        displayValue: c.dialingCode,
        countryCode: c.countryCode,
      })) || [],
    [countries]
  );

  const countryNameOptionsValueCode = useMemo<SelectOptionItem[]>(
    () =>
      countries?.map((c) => ({
        value: c.countryCode,
        label: c.country,
        code: c.countryCode,
      })) || [],
    [countries]
  );

  useEffect(() => {
    if (!countries?.length) {
      fetchCountries();
    }
  }, []);

  const getCountryByCode = (code: string): ICountry | undefined => {
    return countries.find((country) => country.countryCode === code);
  };

  return {
    countries,
    countryOptions,
    countryNameOptions,
    phoneCodeOptions,
    countriesObjectByCode,
    formatPhoneCodeValue,
    getPhoneCodeValue,
    countryNameOptionsValueCode,
    getCountryByCode,
  };
}

export default useCountry;
