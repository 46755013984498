/**
 * @fileoverview gRPC-Web generated client stub for airplane.web_partnership
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: airplane/web_partnership/report.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var base_base_pb = require('../../base/base_pb.js')

var airplane_base_pb = require('../../airplane/base_pb.js')

var airplane_report_pb = require('../../airplane/report_pb.js')

var validate_validate_pb = require('../../validate/validate_pb.js')
const proto = {};
proto.airplane = {};
proto.airplane.web_partnership = require('./report_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partnership.ReportServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.airplane.web_partnership.ReportServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.ReportBookingReq,
 *   !proto.airplane.web_partnership.ReportBookingRes>}
 */
const methodDescriptor_ReportService_ReportBooking = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ReportService/ReportBooking',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.ReportBookingReq,
  proto.airplane.web_partnership.ReportBookingRes,
  /**
   * @param {!proto.airplane.web_partnership.ReportBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.ReportBookingRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.ReportBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.ReportBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.ReportBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ReportServiceClient.prototype.reportBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ReportBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReportBooking,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.ReportBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.ReportBookingRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ReportServicePromiseClient.prototype.reportBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ReportBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReportBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.ReportAirlineBookingReq,
 *   !proto.airplane.web_partnership.ReportAirlineBookingRes>}
 */
const methodDescriptor_ReportService_ReportAirlineBooking = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ReportService/ReportAirlineBooking',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.ReportAirlineBookingReq,
  proto.airplane.web_partnership.ReportAirlineBookingRes,
  /**
   * @param {!proto.airplane.web_partnership.ReportAirlineBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.ReportAirlineBookingRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.ReportAirlineBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.ReportAirlineBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.ReportAirlineBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ReportServiceClient.prototype.reportAirlineBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ReportAirlineBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReportAirlineBooking,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.ReportAirlineBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.ReportAirlineBookingRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ReportServicePromiseClient.prototype.reportAirlineBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ReportAirlineBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReportAirlineBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.AggregateReportBookingReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_ReportService_AggregateReportBooking = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ReportService/AggregateReportBooking',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.AggregateReportBookingReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partnership.AggregateReportBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.AggregateReportBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ReportServiceClient.prototype.aggregateReportBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/AggregateReportBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_AggregateReportBooking,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.AggregateReportBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ReportServicePromiseClient.prototype.aggregateReportBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/AggregateReportBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_AggregateReportBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.AggregateReportAirlineBookingReq,
 *   !proto.base.BasicRes>}
 */
const methodDescriptor_ReportService_AggregateReportAirlineBooking = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ReportService/AggregateReportAirlineBooking',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.AggregateReportAirlineBookingReq,
  base_base_pb.BasicRes,
  /**
   * @param {!proto.airplane.web_partnership.AggregateReportAirlineBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  base_base_pb.BasicRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.AggregateReportAirlineBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.base.BasicRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.base.BasicRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ReportServiceClient.prototype.aggregateReportAirlineBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/AggregateReportAirlineBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_AggregateReportAirlineBooking,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.AggregateReportAirlineBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.base.BasicRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ReportServicePromiseClient.prototype.aggregateReportAirlineBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/AggregateReportAirlineBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_AggregateReportAirlineBooking);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.base.Empty,
 *   !proto.airplane.web_partnership.CurrentBookingStatsRes>}
 */
const methodDescriptor_ReportService_CurrentBookingStats = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ReportService/CurrentBookingStats',
  grpc.web.MethodType.UNARY,
  base_base_pb.Empty,
  proto.airplane.web_partnership.CurrentBookingStatsRes,
  /**
   * @param {!proto.base.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.CurrentBookingStatsRes.deserializeBinary
);


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.CurrentBookingStatsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.CurrentBookingStatsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ReportServiceClient.prototype.currentBookingStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/CurrentBookingStats',
      request,
      metadata || {},
      methodDescriptor_ReportService_CurrentBookingStats,
      callback);
};


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.CurrentBookingStatsRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ReportServicePromiseClient.prototype.currentBookingStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/CurrentBookingStats',
      request,
      metadata || {},
      methodDescriptor_ReportService_CurrentBookingStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.ReportCommissionStatsReq,
 *   !proto.airplane.web_partnership.ReportCommissionStatsRes>}
 */
const methodDescriptor_ReportService_ReportCommissionStats = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ReportService/ReportCommissionStats',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.ReportCommissionStatsReq,
  proto.airplane.web_partnership.ReportCommissionStatsRes,
  /**
   * @param {!proto.airplane.web_partnership.ReportCommissionStatsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.ReportCommissionStatsRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.ReportCommissionStatsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.ReportCommissionStatsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.ReportCommissionStatsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ReportServiceClient.prototype.reportCommissionStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ReportCommissionStats',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReportCommissionStats,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.ReportCommissionStatsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.ReportCommissionStatsRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ReportServicePromiseClient.prototype.reportCommissionStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ReportCommissionStats',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReportCommissionStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.ReportCommissionReq,
 *   !proto.airplane.web_partnership.ReportCommissionRes>}
 */
const methodDescriptor_ReportService_ReportCommission = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ReportService/ReportCommission',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.ReportCommissionReq,
  proto.airplane.web_partnership.ReportCommissionRes,
  /**
   * @param {!proto.airplane.web_partnership.ReportCommissionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.ReportCommissionRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.ReportCommissionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.ReportCommissionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.ReportCommissionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ReportServiceClient.prototype.reportCommission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ReportCommission',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReportCommission,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.ReportCommissionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.ReportCommissionRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ReportServicePromiseClient.prototype.reportCommission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ReportCommission',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReportCommission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.DownloadReportCommissionReq,
 *   !proto.airplane.web_partnership.DownloadReportCommissionRes>}
 */
const methodDescriptor_ReportService_DownloadReportCommission = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ReportService/DownloadReportCommission',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.DownloadReportCommissionReq,
  proto.airplane.web_partnership.DownloadReportCommissionRes,
  /**
   * @param {!proto.airplane.web_partnership.DownloadReportCommissionReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.DownloadReportCommissionRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.DownloadReportCommissionReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.DownloadReportCommissionRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.DownloadReportCommissionRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ReportServiceClient.prototype.downloadReportCommission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/DownloadReportCommission',
      request,
      metadata || {},
      methodDescriptor_ReportService_DownloadReportCommission,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.DownloadReportCommissionReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.DownloadReportCommissionRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ReportServicePromiseClient.prototype.downloadReportCommission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/DownloadReportCommission',
      request,
      metadata || {},
      methodDescriptor_ReportService_DownloadReportCommission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.CrossCheckStatsReq,
 *   !proto.airplane.web_partnership.CrossCheckStatsRes>}
 */
const methodDescriptor_ReportService_CrossCheckStats = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ReportService/CrossCheckStats',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.CrossCheckStatsReq,
  proto.airplane.web_partnership.CrossCheckStatsRes,
  /**
   * @param {!proto.airplane.web_partnership.CrossCheckStatsReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.CrossCheckStatsRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.CrossCheckStatsReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.CrossCheckStatsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.CrossCheckStatsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ReportServiceClient.prototype.crossCheckStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/CrossCheckStats',
      request,
      metadata || {},
      methodDescriptor_ReportService_CrossCheckStats,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.CrossCheckStatsReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.CrossCheckStatsRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ReportServicePromiseClient.prototype.crossCheckStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/CrossCheckStats',
      request,
      metadata || {},
      methodDescriptor_ReportService_CrossCheckStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.ReportCrossCheckReq,
 *   !proto.airplane.web_partnership.ReportCrossCheckRes>}
 */
const methodDescriptor_ReportService_ReportCrossCheck = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ReportService/ReportCrossCheck',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.ReportCrossCheckReq,
  proto.airplane.web_partnership.ReportCrossCheckRes,
  /**
   * @param {!proto.airplane.web_partnership.ReportCrossCheckReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.ReportCrossCheckRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.ReportCrossCheckReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.ReportCrossCheckRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.ReportCrossCheckRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ReportServiceClient.prototype.reportCrossCheck =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ReportCrossCheck',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReportCrossCheck,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.ReportCrossCheckReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.ReportCrossCheckRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ReportServicePromiseClient.prototype.reportCrossCheck =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ReportCrossCheck',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReportCrossCheck);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.DownloadReportCrossCheckReq,
 *   !proto.airplane.web_partnership.DownloadReportCrossCheckRes>}
 */
const methodDescriptor_ReportService_DownloadReportCrossCheck = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ReportService/DownloadReportCrossCheck',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.DownloadReportCrossCheckReq,
  proto.airplane.web_partnership.DownloadReportCrossCheckRes,
  /**
   * @param {!proto.airplane.web_partnership.DownloadReportCrossCheckReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.DownloadReportCrossCheckRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.DownloadReportCrossCheckReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.DownloadReportCrossCheckRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.DownloadReportCrossCheckRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ReportServiceClient.prototype.downloadReportCrossCheck =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/DownloadReportCrossCheck',
      request,
      metadata || {},
      methodDescriptor_ReportService_DownloadReportCrossCheck,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.DownloadReportCrossCheckReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.DownloadReportCrossCheckRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ReportServicePromiseClient.prototype.downloadReportCrossCheck =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/DownloadReportCrossCheck',
      request,
      metadata || {},
      methodDescriptor_ReportService_DownloadReportCrossCheck);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.base.Empty,
 *   !proto.airplane.web_partnership.ReferenceStatsRes>}
 */
const methodDescriptor_ReportService_ReferenceStats = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ReportService/ReferenceStats',
  grpc.web.MethodType.UNARY,
  base_base_pb.Empty,
  proto.airplane.web_partnership.ReferenceStatsRes,
  /**
   * @param {!proto.base.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.ReferenceStatsRes.deserializeBinary
);


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.ReferenceStatsRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.ReferenceStatsRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ReportServiceClient.prototype.referenceStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ReferenceStats',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReferenceStats,
      callback);
};


/**
 * @param {!proto.base.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.ReferenceStatsRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ReportServicePromiseClient.prototype.referenceStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ReferenceStats',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReferenceStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.ReferenceListReq,
 *   !proto.airplane.web_partnership.ReferenceListRes>}
 */
const methodDescriptor_ReportService_ReferenceList = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ReportService/ReferenceList',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.ReferenceListReq,
  proto.airplane.web_partnership.ReferenceListRes,
  /**
   * @param {!proto.airplane.web_partnership.ReferenceListReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.ReferenceListRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.ReferenceListReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.ReferenceListRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.ReferenceListRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ReportServiceClient.prototype.referenceList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ReferenceList',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReferenceList,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.ReferenceListReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.ReferenceListRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ReportServicePromiseClient.prototype.referenceList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ReferenceList',
      request,
      metadata || {},
      methodDescriptor_ReportService_ReferenceList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.airplane.web_partnership.ExportReportBookingReq,
 *   !proto.airplane.web_partnership.ExportReportBookingRes>}
 */
const methodDescriptor_ReportService_ExportReportBooking = new grpc.web.MethodDescriptor(
  '/airplane.web_partnership.ReportService/ExportReportBooking',
  grpc.web.MethodType.UNARY,
  proto.airplane.web_partnership.ExportReportBookingReq,
  proto.airplane.web_partnership.ExportReportBookingRes,
  /**
   * @param {!proto.airplane.web_partnership.ExportReportBookingReq} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.airplane.web_partnership.ExportReportBookingRes.deserializeBinary
);


/**
 * @param {!proto.airplane.web_partnership.ExportReportBookingReq} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.airplane.web_partnership.ExportReportBookingRes)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.airplane.web_partnership.ExportReportBookingRes>|undefined}
 *     The XHR Node Readable Stream
 */
proto.airplane.web_partnership.ReportServiceClient.prototype.exportReportBooking =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ExportReportBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_ExportReportBooking,
      callback);
};


/**
 * @param {!proto.airplane.web_partnership.ExportReportBookingReq} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.airplane.web_partnership.ExportReportBookingRes>}
 *     Promise that resolves to the response
 */
proto.airplane.web_partnership.ReportServicePromiseClient.prototype.exportReportBooking =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/airplane.web_partnership.ReportService/ExportReportBooking',
      request,
      metadata || {},
      methodDescriptor_ReportService_ExportReportBooking);
};


module.exports = proto.airplane.web_partnership;

